import {
  ADMIN,
  CLAIM,
  CLAIM_ADMIN,
  CLAIM_REVIEW,
  CLIENT_ADMIN,
  DOCUMENT_ADMIN,
  HITL,
  HITL_AND_MI,
  MI,
  POV,
  SUPER_ADMIN,
  SYSTEM,
} from 'constants/roles';

const ROUTE_PERMISSIONS = {
  admin: [SUPER_ADMIN],
  claim: [ADMIN, CLAIM, CLAIM_ADMIN, CLIENT_ADMIN, SUPER_ADMIN, CLAIM_REVIEW],
  classification: [
    ADMIN,
    CLAIM,
    CLAIM_ADMIN,
    CLIENT_ADMIN,
    DOCUMENT_ADMIN,
    HITL,
    HITL_AND_MI,
    SUPER_ADMIN,
  ],
  configuration: [SUPER_ADMIN],
  dashboard: [
    ADMIN,
    CLAIM,
    CLAIM_ADMIN,
    CLAIM_REVIEW,
    CLIENT_ADMIN,
    DOCUMENT_ADMIN,
    HITL,
    HITL_AND_MI,
    MI,
    POV,
    SUPER_ADMIN,
    SYSTEM,
  ],
  docs: [SUPER_ADMIN],
  fileUpload: [
    SUPER_ADMIN,
    ADMIN,
    POV,
    DOCUMENT_ADMIN,
    CLAIM_ADMIN,
    CLIENT_ADMIN,
  ],
  hitl: [
    ADMIN,
    CLAIM,
    CLIENT_ADMIN,
    DOCUMENT_ADMIN,
    HITL,
    HITL_AND_MI,
    SUPER_ADMIN,
  ],
  mi: [ADMIN, CLAIM, SUPER_ADMIN, MI, HITL_AND_MI],
  miCreateUser: [SUPER_ADMIN],
  miManageUser: [SUPER_ADMIN],
  featureFlags: [SUPER_ADMIN],
  createClaim: [SUPER_ADMIN],
  redaction: [
    ADMIN,
    CLAIM,
    CLAIM_ADMIN,
    CLIENT_ADMIN,
    DOCUMENT_ADMIN,
    HITL,
    HITL_AND_MI,
    SUPER_ADMIN,
  ],
  redactions: [
    ADMIN,
    CLAIM,
    CLAIM_ADMIN,
    CLIENT_ADMIN,
    DOCUMENT_ADMIN,
    HITL,
    HITL_AND_MI,
    SUPER_ADMIN,
  ],
  split: [
    ADMIN,
    CLAIM,
    CLAIM_ADMIN,
    CLIENT_ADMIN,
    DOCUMENT_ADMIN,
    HITL,
    HITL_AND_MI,
    SUPER_ADMIN,
  ],
  splits: [
    ADMIN,
    CLAIM,
    CLAIM_ADMIN,
    CLIENT_ADMIN,
    DOCUMENT_ADMIN,
    HITL,
    HITL_AND_MI,
    SUPER_ADMIN,
  ],
  uploadDocument: [SUPER_ADMIN],
};

export default ROUTE_PERMISSIONS;
