import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import type { ClaimValidationResult } from 'types/Claims.ts';
import {
  ButtonWithTranslation,
  TypographyWithTranslation,
  useTranslationRoot,
} from 'components/with-translation.tsx';
import { Label } from 'components/Label.tsx';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import {
  REIMBURSEMENT,
  STAGE_LABEL_COLOR_MAP,
  statusTranslation,
} from 'constants/claims.ts';
import {
  CIRCLE_CHECK_ICON,
  SQUARE_MINUS_ICON,
} from 'constants/public-icons.ts';
import { getIsClaimAwaitingReview } from 'state/selectors/claims.ts';
import { formatDocumentDate } from 'utils/date.ts';
import { updateWindowPerformanceObject } from 'analytics/utils.ts';
import { ENRICHMENT_SUBMIT_START } from 'analytics/events.ts';

import { EnrichmentValidationResults } from './EnrichmentValidationResults.tsx';
import { useEnrichmentToolContext } from './useEnrichmentToolContext.tsx';
import { RejectClaimDialog } from './RejectClaimDialog.tsx';
import { useRejectDialog } from './useRejectDialog.ts';

interface FormHeaderProps {
  isFormSubmitting: boolean;
}

const StartIcon = styled(SvgIconStyle)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

function FormHeader({ isFormSubmitting }: FormHeaderProps) {
  const { t } = useTranslationRoot();
  const { businessUnit, claim, currentUser, navigateToNextContext } =
    useEnrichmentToolContext();
  const {
    closeDialog,
    handleChange,
    handleReject,
    isOpen,
    isSubmitting,
    openDialog,
    reason,
  } = useRejectDialog(claim.id, navigateToNextContext);

  const isNotLockedByCurrentUser =
    claim.locked && claim.lastLockedBy !== currentUser.email;
  const isAwaitingReview = getIsClaimAwaitingReview(claim);
  const { stage } = claim;
  const reviewStationNumber = (claim.metadata?.ReviewStationNumber ||
    claim.metadata?.reviewStationNumber) as string;
  const claimType = claim.properties?.claimType?.toLowerCase();

  return (
    <Stack
      component="header"
      direction="row"
      sx={{
        justifyContent: 'space-between',
        p: 2,
        borderBottom: ({ palette }) => `1px solid ${palette.divider}`,
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        sx={{
          alignItems: 'center',
        }}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          {claim.clientClaimId}
        </Typography>
        <Divider orientation="vertical" flexItem />
        <Stack>
          <TypographyWithTranslation
            i18nKey="enrichmentTool.claimType"
            variant="body2"
            sx={{ fontSize: 12 }}
          />
          <Typography variant="subtitle1" sx={{ fontSize: 12 }}>
            {claimType === REIMBURSEMENT
              ? t('enrichmentTool.reimbursement')
              : t('enrichmentTool.provider')}
          </Typography>
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack>
          <TypographyWithTranslation
            i18nKey="enrichmentTool.businessUnit"
            variant="body2"
            sx={{ fontSize: 12 }}
          />
          <Typography variant="subtitle1" sx={{ fontSize: 12 }}>
            {businessUnit}
          </Typography>
        </Stack>
        {!!reviewStationNumber && (
          <>
            <Divider orientation="vertical" flexItem />
            <Stack>
              <TypographyWithTranslation
                i18nKey="enrichmentTool.reviewStationNumber"
                variant="body2"
                sx={{ fontSize: 12 }}
              />
              <Typography variant="subtitle1" sx={{ fontSize: 12 }}>
                {reviewStationNumber}
              </Typography>
            </Stack>
          </>
        )}
        <Divider orientation="vertical" flexItem />
        <Box>
          <Label
            i18nKey={statusTranslation[stage]}
            variant="ghost"
            color={STAGE_LABEL_COLOR_MAP[stage]}
          />
        </Box>
        <Divider orientation="vertical" flexItem />
        <Stack>
          <TypographyWithTranslation
            i18nKey="header.lastUpdateTime"
            variant="body2"
            sx={{ fontSize: 12 }}
          />
          <Typography variant="subtitle1" sx={{ fontSize: 12 }}>
            {formatDocumentDate(claim.lastUpdatedOn)}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          alignItems: 'center',
        }}
      >
        <EnrichmentValidationResults
          validationResults={claim.validationResults as ClaimValidationResult[]}
        />

        <ButtonWithTranslation
          disabled={isNotLockedByCurrentUser || !isAwaitingReview}
          i18nKey="enrichmentTool.rejectButton"
          color="error"
          size="small"
          startIcon={
            <StartIcon height={17} src={SQUARE_MINUS_ICON} width={17} />
          }
          variant="contained"
          onClick={openDialog}
          sx={{
            whiteSpace: 'nowrap',
          }}
        />

        <RejectClaimDialog
          isOpen={isOpen}
          closeModal={closeDialog}
          handleChange={handleChange}
          reason={reason}
          onReject={handleReject}
          isSubmitting={isSubmitting}
        />

        <Tooltip title={t('enrichmentTool.shortcutKeySubmit')}>
          <span>
            <ButtonWithTranslation
              disabled={
                isNotLockedByCurrentUser ||
                isFormSubmitting ||
                !isAwaitingReview
              }
              i18nKey="enrichmentTool.submitButton"
              size="small"
              startIcon={
                <StartIcon height={17} src={CIRCLE_CHECK_ICON} width={17} />
              }
              variant="contained"
              type="submit"
              sx={{
                whiteSpace: 'nowrap',
              }}
              onClick={() => {
                updateWindowPerformanceObject(ENRICHMENT_SUBMIT_START);
              }}
            />
          </span>
        </Tooltip>
      </Stack>
    </Stack>
  );
}

export { FormHeader };
