import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import { CONFIGURATION_PAGE } from 'constants/translation-keys';
import { Page } from 'components/Page';
import { WithTranslationRoot } from 'components/with-translation';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import { AWS_APPCONFIG_ICON } from 'constants/public-icons.ts';
import { LinkWithRef } from 'components/Link';

import appConfigRoute from './appConfigRoute.route';

function ConfigurationPage() {
  const navigate = useNavigate();

  return (
    <WithTranslationRoot namespace={CONFIGURATION_PAGE}>
      <Page i18nKeyTitle="meta.title">
        <Container maxWidth="lg">
          <Typography variant="h4" sx={{ mb: 2 }}>
            Configuration
          </Typography>
          <Stack
            direction="row"
            sx={{
              gap: 2,
              flexWrap: 'wrap',
            }}
          >
            <Card
              sx={{
                cursor: 'pointer',
                p: 2,
                width: '32%',
                maxWidth: 240,
                textAlign: 'center',
              }}
              onClick={() => {
                navigate(appConfigRoute.createPath());
              }}
            >
              <Typography variant="body1">AWS AppConfig</Typography>
              <SvgIconStyle src={AWS_APPCONFIG_ICON} height={100} width={100} />
              <Link
                component={LinkWithRef}
                to={appConfigRoute.createPath()}
                sx={{
                  whiteSpace: 'nowrap',
                }}
              >
                Go to AWS AppConfig
              </Link>
            </Card>
          </Stack>
        </Container>
      </Page>
    </WithTranslationRoot>
  );
}

export default ConfigurationPage;
