import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';

function SplitsLayout() {
  return (
    <Box sx={{ px: 2 }}>
      <Outlet />
    </Box>
  );
}

export default SplitsLayout;
