import { useFormContext } from 'react-hook-form';
import merge from 'lodash.merge';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useTranslationRoot } from 'components/with-translation.tsx';
import { unique } from 'utils/array.ts';

import type { DocumentContentWithId, Row } from './types.ts';
import { useEnrichmentToolContext } from './useEnrichmentToolContext.tsx';

function EnrichedDropdown(props: Row) {
  const { t } = useTranslationRoot();
  const { setState, state } = useEnrichmentToolContext();
  const { register, setValue } = useFormContext();
  const { id, sproutaiKey, value } = props;

  const level = state.dropdownLevelMap[id];
  const enrichmentLevels = state.groupedEnrichmentLevels[level];
  const initialOptions = [{ id, sproutaiKey, value }];
  const enrichmentOptions = enrichmentLevels
    ? (Object.values(enrichmentLevels)
        .map((items) => {
          const result = Object.values(items).find(
            (item) => item.sproutaiKey === sproutaiKey
          );

          if (result && result.value) {
            return result;
          }

          return null;
        })
        .filter(Boolean) as DocumentContentWithId[])
    : [];
  const options = [...initialOptions, ...enrichmentOptions].filter(Boolean);

  const handleClear = () => {
    //find the group that contains the deleted field
    const group = Object.values(enrichmentLevels).find((group) =>
      Object.values(group).find((item) => item.sproutaiKey === sproutaiKey)
    );

    // set the form value of each item in the group
    if (group) {
      Object.entries(group).forEach(([key, item]) => {
        if (item.valueType !== sproutaiKey) {
          setValue(key, '');
        }
      });
    }
  };

  const updateValue = (newValue: any) => {
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [id]: {
          ...prevState.fields[id],
          value: newValue,
        },
      },
    }));
  };

  const handleChange = (newValue: string) => {
    // find the group that contains the selected value
    const group = Object.values(enrichmentLevels).find((group) =>
      Object.values(group).find(
        (item) =>
          item.sproutaiKey === props.sproutaiKey && item.value === newValue
      )
    );

    // set the form value of each item in the group
    if (group) {
      Object.entries(group).forEach(([key, item]) => {
        if (item.valueType !== sproutaiKey) {
          setValue(key, item.value);
        }
      });
    } else if (!group) {
      handleClear();
    } else if (newValue === '') {
      handleClear();
    }
  };

  return (
    <Autocomplete
      // defaultValue={value}
      value={value}
      freeSolo
      onBlur={(event) => {
        const newValue = (event.target as HTMLInputElement).value;

        setState((prevState) => ({
          ...prevState,
          fields: {
            ...prevState.fields,
            [id]: {
              ...prevState.fields[id],
              value: newValue as any,
            },
          },
        }));
      }}
      onChange={(_event, newValue, reason) => {
        if (reason === 'clear') {
          handleClear();
        } else if (reason === 'selectOption') {
          handleChange(newValue ?? '');
          updateValue(newValue);
        }
      }}
      onInputChange={(_event, newValue, reason) => {
        if (reason === 'input') {
          handleChange(newValue ?? '');
        }
      }}
      componentsProps={{
        popper: {
          sx: {
            boxShadow: (theme) => theme.shadows[1],
            width: 'auto !important',
          },
        },
      }}
      ListboxProps={{
        sx: { fontSize: 12 },
      }}
      options={unique(options.map(({ value }) => value))}
      renderInput={(params) => (
        <TextField
          {...merge(params, register(id))}
          aria-label={t(props.label) as string}
          error={!props.valid}
          variant="standard"
          inputProps={{ ...params.inputProps, sx: { fontSize: 12 } }}
        />
      )}
    />
  );
}

export { EnrichedDropdown };
