import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { LoadingIcon } from 'components/icons/LoadingIcon';

interface LoadingProps {
  columnLength: number;
}

function Loading({ columnLength }: LoadingProps) {
  return (
    <TableRow>
      <TableCell colSpan={columnLength}>
        <LoadingIcon />
      </TableCell>
    </TableRow>
  );
}

export { Loading };
