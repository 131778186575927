import type { TransitionProps } from '@mui/material/transitions';
import type { ReactElement, Ref } from 'react';
import { forwardRef, lazy, Suspense, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { DOCUMENT_ID, RESOURCE_ID } from 'constants/route-keys';
import { SvgIconStyle } from 'components/SvgIconStyle';
import {
  TypographyWithTranslation,
  WithTranslationRoot,
} from 'components/with-translation';
import { CLOSE_ICON } from 'constants/public-icons';
import {
  CLAIM_HIL_TOOL_PAGE,
  HITL_TOOL_PAGE,
} from 'constants/translation-keys';
import claimRoute from 'pages/Claim/claim.route';
import { LoadingIcon } from 'components/icons/LoadingIcon';
import { useInvalidateClaimsQueries } from 'state/queries/claims';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const HilTool = lazy(() => import('components/HilTool/HiLTool'));

function ClaimHILTool() {
  const navigate = useNavigate();
  const params = useParams();
  const claimId = params[RESOURCE_ID];
  const documentId = params[DOCUMENT_ID];
  const [open, setOpen] = useState(true);
  const invalidateClaims = useInvalidateClaimsQueries();

  const handleClose = () => {
    setOpen(false);
  };

  const navigateBackToClaim = () => {
    if (claimId) {
      invalidateClaims();
      navigate(claimRoute.createPath(claimId));
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      sx={{
        [`& .${dialogClasses.container}`]: {
          justifyContent: 'flex-end',
        },
      }}
      PaperProps={{
        sx: {
          maxWidth: '90%',
        },
      }}
      TransitionComponent={Transition}
      TransitionProps={{
        onExited: navigateBackToClaim,
      }}
    >
      <WithTranslationRoot namespace={CLAIM_HIL_TOOL_PAGE}>
        <AppBar
          color="transparent"
          sx={{ boxShadow: 'none', position: 'relative' }}
        >
          <Toolbar
            disableGutters
            variant="dense"
            sx={{ justifyContent: 'space-between', px: 2 }}
          >
            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
              }}
            >
              <TypographyWithTranslation
                i18nKey="common.documentId"
                sx={{ flex: 1, mr: 1 }}
                variant="h6"
                component="span"
              />
              <Typography
                variant="h6"
                component="span"
                sx={{
                  fontWeight: 'regular',
                }}
              >
                {documentId}
              </Typography>
            </Stack>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <SvgIconStyle src={CLOSE_ICON} />
            </IconButton>
          </Toolbar>
        </AppBar>
      </WithTranslationRoot>
      <WithTranslationRoot namespace={HITL_TOOL_PAGE}>
        {documentId ? (
          <Box sx={{ p: 2 }}>
            <Card sx={{ overflow: 'initial', contain: 'paint' }}>
              <Suspense fallback={<LoadingIcon />}>
                <HilTool
                  key={documentId}
                  documentId={documentId}
                  fromClaimView
                  closeClaimHilTool={handleClose}
                />
              </Suspense>
            </Card>
          </Box>
        ) : (
          <TypographyWithTranslation
            variant="body1"
            i18nKey="common.loadDocumentError"
          />
        )}
      </WithTranslationRoot>
    </Dialog>
  );
}

export default ClaimHILTool;
