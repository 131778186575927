import type { FabricObject } from 'fabric';

import type { FileCanvas } from './types.ts';
import { findImage, THUMBNAIL_EVENT } from './utils.ts';
// import { customEvent } from './events.ts';

export type CropData = {
  url: string;
  oCoords: any;
  scale: number;
};

const crop = new Map<number, CropData[]>();

function cropTool() {
  const setCrop = (index: number, cropData: CropData) => {
    const oldCrop = crop.get(index);

    if (oldCrop) {
      crop.set(index, [...oldCrop, cropData]);
    } else {
      crop.set(index, [cropData]);
    }
  };

  const getCrop = (index: number) => {
    return crop.get(index);
  };

  const getCropBySubPage = (index: number, subIndex: number) => {
    const cropData = crop.get(index);

    if (cropData) {
      return cropData[subIndex];
    }

    return null;
  };

  const deleteCrop = (index: number, subIndex: number) => {
    if (crop.has(index)) {
      const item = crop.get(index)?.filter((_, i) => i !== subIndex);

      crop.set(index, item || []);
    }
  };

  const clearCrop = () => {
    crop.clear();
  };

  const handleCrop = ({
    canvas,
    page,
  }: {
    canvas: FileCanvas;
    page: number;
  }): Promise<{ subPage: number; cropObject: FabricObject; src: string }> => {
    return new Promise((resolve) => {
      canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
      canvas.renderAll();

      const square = canvas.getActiveObject();
      const image = canvas.getObjects().find(findImage);
      const vpt = canvas.viewportTransform;

      if (square && image) {
        const src = canvas.toDataURL({
          left: square.left + vpt[4],
          top: square.top + vpt[5],
          width: square.width,
          height: square.height,

          format: 'jpeg',
          multiplier: image.width / (image.width * image.scaleX),
        });
        const cropped = new Image();
        cropped.src = src;
        cropped.onload = async () => {
          setCrop(page, {
            url: cropped.src,
            oCoords: square.oCoords,
            scale: image.get('scaleX'),
          });

          const updatedCrop = getCrop(page);
          const event = new CustomEvent(THUMBNAIL_EVENT, {
            detail: { page, url: cropped.src, updatedCrop },
          });
          document.dispatchEvent(event);

          // no need to load the cropped image on canvas for now
          // canvas.clear();
          //
          // const object = await loadImageWithSrc(src);
          // object.scale(image.get('scaleX'));
          // canvas.add(object);
          // canvas.centerObject(object);
          // canvas.renderAll();

          const subPage = (updatedCrop?.length || 0) - 1 ?? 0;
          // canvas.page = `${page}.${subPage}`;
          // canvas.fire(customEvent.PAGE_UPDATED);
          resolve({ subPage, cropObject: square, src });
        };
      }
    });
  };

  return {
    clearCrop,
    deleteCrop,
    handleCrop,
    setCrop,
    getCrop,
    getCropBySubPage,
  };
}

export { cropTool };
