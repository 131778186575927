import { lazy } from 'react';
import { generatePath } from 'react-router-dom';
import { loadable } from 'components/loadable';
import { FILE_PLUS_ICON } from 'constants/public-icons';
import ROUTE_PERMISSIONS from 'constants/route-permissions';
import { CREATE_CLAIM_PAGE } from 'constants/translation-keys';

const path = '/create-claim';
const CreateClaim = loadable(lazy(() => import('./CreateClaim')));

const createClaimRoute = {
  path,
  createPath: () => generatePath(path),
  element: <CreateClaim />,
  icon: FILE_PLUS_ICON,
  permission: ROUTE_PERMISSIONS.createClaim,
  i18nNamespace: CREATE_CLAIM_PAGE,
};

export default createClaimRoute;
