import { useParams } from 'react-router-dom';
import { WithTranslationRoot } from 'components/with-translation.tsx';
import { Page } from 'components/Page.tsx';
import { RESOURCE_ID } from 'constants/route-keys.ts';
import { SPLIT_PAGE } from 'constants/translation-keys.ts';

import { Landing } from './Landing.tsx';

function SplitPage() {
  const params = useParams();
  const pageId = params[RESOURCE_ID] as string;

  return (
    <WithTranslationRoot namespace={SPLIT_PAGE}>
      <Page i18nKeyTitle="meta.title">
        <Landing id={pageId} />
      </Page>
    </WithTranslationRoot>
  );
}

export default SplitPage;
