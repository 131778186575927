export const customEvent = {
  IMAGE_ADDED: 'image:added', // when canvas image is added

  REDACT_ACTIVE: 'redact:active', // user has enabled redact tool
  REDACT_INACTIVE: 'redact:inactive', // user has disabled redact tool
  REDACT_ADDED: 'redact:added', // user has added a redact

  SELECTION_TOOL_ACTIVE: 'selection-tool:active', // user has enabled selection tool
  SELECTION_TOOL_INACTIVE: 'selection-tool:inactive', // user has disabled selection tool

  TOOL_UNMOUNT: 'tool:unmount', // notify tool has been unmounted
  PAGE_UPDATED: 'page:updated', // notify page has been updated
} as const;
