import type { Theme } from '@mui/material/styles';
import { generalConfig } from 'config';
import { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { useResponsive } from 'components/customHooks/useResponsive';
import { useBoolean } from 'components/customHooks/useBoolean';
import { round } from 'utils/numbers';

import { OcrCard } from './OcrCard';
import type { Resources } from './types';

interface OcrPerformanceProps {
  list: string[];
  isSuccess: boolean;
  resources: Resources;
}

export type State = {
  color: (theme: Theme) => string;
  heading: string;
  id: string;
  label: string;
  summary: string;
  value: number;
  options: any;
};

export function generateLabelandValue(value: number) {
  let label = '';
  let options: any = {};

  if (value > 3540) {
    label = 'units.smallHours';
    options = { hours: Math.round(value / 3600) };
  } else if (value > 59) {
    label = 'units.smallMinutes';
    options = {
      minutes: Math.round(value / 60),
    };
  } else {
    label = 'units.smallSeconds';
    options = { seconds: round(value, 1) };
  }

  return { label, options };
}

// TODO: most likely not needed?
const ARBITRARY_ANIMATION_DELAY = 150;

function OcrPerformance({ list, isSuccess, resources }: OcrPerformanceProps) {
  const { showOCRIdentifiedKeyFields } = generalConfig;

  const isDesktop = useResponsive('up', 'md');
  const metricsArray: State[] = [
    {
      color: ({ palette }) => palette.chart.primary[2],
      id: 'AvgOcrIdentifiedFields',
      heading: 'ocr.avgOcrIdentifiedFields',
      summary: 'ocr.averageIdentified',
      value: 0,
      label: '',
      options: {},
    },
    {
      color: ({ palette }) => palette.chart.secondary[2],
      id: 'AvgInitialDocumentProcessingTime',
      heading: 'ocr.avgOverallProcessingTime',
      summary: 'ocr.averageTime',
      value: 0,
      label: '',
      options: {},
    },
  ];

  if (showOCRIdentifiedKeyFields) {
    metricsArray.push({
      color: ({ palette }) => palette.chart.violet[2],
      id: 'AvgOcrIdentifiedKeyFields',
      heading: 'ocr.avgOcrIdentifiedKeyFields',
      summary: 'ocr.averageIdentifiedKey',
      value: 0,
      label: '',
      options: {},
    });
  }

  const [stacks, setStacks] = useState<State[]>(metricsArray);
  const [isListSet, setIsListSetTrue] = useBoolean();
  const [one, two, three] = stacks;

  useEffect(
    function filterList() {
      if (!isListSet && list.length) {
        const newStacks = stacks.filter(({ id }) => list.includes(id));
        setStacks(newStacks);
        setIsListSetTrue();
      }
    },
    [isListSet, list, setIsListSetTrue, stacks]
  );

  useEffect(
    function animateRings() {
      // changing value afterwards causes animation to happen
      let timeoutId: NodeJS.Timeout;

      if (isListSet && list.length) {
        timeoutId = setTimeout(() => {
          setStacks((state) =>
            state
              .map((stack) => {
                const item = resources[stack.id];

                if (!item) {
                  return stack;
                }

                const { unit, value } = item;
                let label = '';
                let newValue = 0;
                let options: any = {};

                if (unit === 'Percent') {
                  newValue = round(Number(value) * 100, 2);
                  label = 'units.percent';
                  options = {
                    value: newValue,
                  };
                } else if (unit === 'Seconds') {
                  newValue = Number(value);
                  const generatedResults = generateLabelandValue(newValue);

                  label = generatedResults.label;
                  options = generatedResults.options;
                }

                return {
                  ...stack,
                  label,
                  options,
                  value: newValue,
                };
              })
              .filter(Boolean)
          );
        }, ARBITRARY_ANIMATION_DELAY);
      }

      return () => {
        clearTimeout(timeoutId);
      };
    },
    [isListSet, list] // eslint-disable-line
  );

  const stackList = isDesktop ? [one, two] : stacks;

  return (
    <>
      <Card>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          divider={
            <Divider
              orientation={isDesktop ? 'vertical' : 'horizontal'}
              flexItem
            />
          }
          sx={{
            '@media print': {
              flexDirection: 'row',
            },
          }}
        >
          {stackList.filter(Boolean).map((stack) => (
            <OcrCard key={stack.id} isSuccess={isSuccess} {...stack} />
          ))}
        </Stack>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          divider={
            <Divider
              orientation={isDesktop ? 'vertical' : 'horizontal'}
              flexItem
            />
          }
          sx={{
            '@media print': {
              flexDirection: 'row',
            },
          }}
        ></Stack>
      </Card>
      {three ? (
        <Card
          sx={{ display: { xs: 'none', md: 'block' }, width: '50%', mt: 3 }}
        >
          <OcrCard isSuccess={isSuccess} {...three} />
        </Card>
      ) : null}
    </>
  );
}

export { OcrPerformance };
