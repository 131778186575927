import { compile } from 'path-to-regexp';

export function isAbsoluteUrl(url: string): boolean {
  const absoluteUrlPattern = /^(?:[a-z]+:)?\/\//i;
  return absoluteUrlPattern.test(url);
}

export function toRelativeUrl(url = '', baseUrl: string) {
  if (isAbsoluteUrl(url)) {
    url = url.substring(baseUrl.length);
  }

  return url[0] === '/' ? url : `/${url}`;
}

export function pathToUrl<P extends object = object>(path: string, params?: P) {
  return compile(path)(params);
}
