import { FormProvider, useForm } from 'react-hook-form';
import type { SelectChangeEvent } from '@mui/material/Select';

import { DocumentTypeSelector } from './DocumentTypeSelector';

interface DocumentFilterFormProps {
  documentType: string;
  updateDocumentType: (value: string) => void;
}

function DocumentFilterForm({
  documentType,
  updateDocumentType,
}: DocumentFilterFormProps) {
  const methods = useForm({
    defaultValues: {
      documentType: documentType ?? null,
    },
  });

  function handleChange(event: SelectChangeEvent<HTMLInputElement>) {
    const value = event.target.value as string;
    updateDocumentType(value);
  }

  return (
    <FormProvider {...methods}>
      <DocumentTypeSelector handleChange={handleChange} />
    </FormProvider>
  );
}

export { DocumentFilterForm };
