import { useLocation, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';

function PageView() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { pathname } = location;
  const posthog = usePostHog();

  useEffect(() => {
    if (pathname) {
      let url = window.origin + pathname;

      if (searchParams.toString()) {
        url = url + `?${searchParams.toString()}`;
      }

      if (typeof window !== 'undefined' && window.dataLayer) {
        window.dataLayer.push({
          event: 'PageView',
        });
      }

      if (typeof window !== 'undefined' && posthog) {
        posthog.capture('$pageview', {
          $current_url: url,
        });
      }
    }
  }, [pathname, searchParams, posthog]);

  return null;
}

export { PageView };
