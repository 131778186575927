import { useEffect } from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {
  LoadingButtonWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';
import { ControllerWithTextField } from 'components/form/ControllerWithTextField';
import { ControllerWithDropDown } from 'components/form/ControllerWithDropDown';

import type { FeedbackFormValues } from './types';

interface FeedbackFormProps {
  onSubmit: (values: FeedbackFormValues) => void;
}

const REASON = 'reason';
const DESCRIPTION = 'description';
const menuItems = [
  { value: 'GOOD', label: 'feedback.good' },
  { value: 'WRONG', label: 'feedback.wrong' },
  { value: 'OTHER', label: 'feedback.other' },
];

function FeedbackForm({ onSubmit }: FeedbackFormProps) {
  const { t } = useTranslationRoot();
  const schema = object({
    [REASON]: string().required().label(t('feedback.reasonLabel')),
    [DESCRIPTION]: string().required().label(t('feedback.descriptionLabel')),
  });
  const methods = useForm({
    defaultValues: {
      [REASON]: '',
      [DESCRIPTION]: '',
    },
    resolver: yupResolver(schema),
  });
  const { isSubmitting, isSubmitted, isSubmitSuccessful } = useFormState({
    control: methods.control,
  });
  const { handleSubmit, reset } = methods;

  useEffect(
    function resetFormAfterSubmit() {
      if (isSubmitted && isSubmitSuccessful) {
        reset();
      }
    },
    [isSubmitSuccessful, isSubmitted, reset]
  );

  function handleFormSubmit(values: FeedbackFormValues) {
    if (onSubmit) {
      onSubmit(values);
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Stack
          sx={{
            gap: 2,
          }}
        >
          <Box sx={{ maxWidth: 300 }}>
            <ControllerWithDropDown
              htmlFor="result-selector"
              label={t('feedback.reasonLabel')}
              menuItems={menuItems}
              name={REASON}
            />
          </Box>
          <ControllerWithTextField
            label={t('feedback.descriptionLabel')}
            name={DESCRIPTION}
            multiline
            rows={4}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <LoadingButtonWithTranslation
              i18nKey="feedback.submitButton"
              variant="contained"
              loading={isSubmitting}
              color="primary"
              type="submit"
            />
          </Box>
        </Stack>
      </form>
    </FormProvider>
  );
}

export { FeedbackForm };
