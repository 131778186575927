import { lazy } from 'react';
import { generatePath } from 'react-router-dom';
import { loadable } from 'components/loadable';
import ROUTE_PERMISSIONS from 'constants/route-permissions';
import { REDACTION_PAGE } from 'constants/translation-keys';
import { RESOURCE_ID } from 'constants/route-keys.ts';

const path = `/r/:${RESOURCE_ID}`;
const RedactionPage = loadable(lazy(() => import('./Redaction.tsx')));

const redactionRoute = {
  path,
  createPath: (resourceId: string) => generatePath(path, { resourceId }),
  element: <RedactionPage />,
  permission: ROUTE_PERMISSIONS.redaction,
  i18nNamespace: REDACTION_PAGE,
};

export default redactionRoute;
