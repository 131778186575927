import type { ClaimSummaryWidgetsApiResponse } from 'api/transformers/types/widgets.ts';
import type { ClaimSummaryWidget } from 'types/Widgets';
import { transformWidgetsClaimSummaryApiResponse } from 'api/transformers/widgets';
import { WIDGETS_CLAIM_SUMMARY } from 'constants/api-endpoints';
import { useGet } from 'utils/react-query';
import {
  selectWidgetsClaimPolicyData,
  selectWidgetsClaimRecommendationData,
  selectWidgetsClaimSummaryData,
} from 'state/selectors/widgets.ts';

export function useGetWidgetsClaimSummary() {
  return useGet<ClaimSummaryWidgetsApiResponse, ClaimSummaryWidget[]>({
    url: WIDGETS_CLAIM_SUMMARY,
    select: transformWidgetsClaimSummaryApiResponse,
  });
}

export function useGetWidgetsClaimSummaryTab() {
  return useGet<ClaimSummaryWidgetsApiResponse, ClaimSummaryWidget[]>({
    url: WIDGETS_CLAIM_SUMMARY,
    select: selectWidgetsClaimSummaryData,
  });
}

export function useGetWidgetsClaimPolicyTab() {
  return useGet<ClaimSummaryWidgetsApiResponse, ClaimSummaryWidget[]>({
    url: WIDGETS_CLAIM_SUMMARY,
    select: selectWidgetsClaimPolicyData,
  });
}

export function useGetWidgetsClaimRecommendationTab() {
  return useGet<ClaimSummaryWidgetsApiResponse, ClaimSummaryWidget[]>({
    url: WIDGETS_CLAIM_SUMMARY,
    select: selectWidgetsClaimRecommendationData,
  });
}
