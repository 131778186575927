import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { PageNotFoundIllustration } from 'components/icons/Illustration404';
import { Page } from 'components/Page';
import {
  ButtonWithTranslation,
  TypographyWithTranslation,
  WithTranslationRoot,
} from 'components/with-translation';
import { COMMON } from 'constants/translation-keys';
import dashboardRoute from 'pages/Dashboard/dashboard.route';

function Page404() {
  return (
    <Box sx={{ height: 1 }}>
      <Page
        i18nKeyTitle="page404.meta.title"
        sx={{ height: 1 }}
        namespace={COMMON}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            height: 1,
            paddingTop: 15,
            paddingBottom: 10,
          }}
        >
          <Container>
            <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
              <WithTranslationRoot namespace={COMMON}>
                <TypographyWithTranslation
                  i18nKey="page404.heading"
                  variant="h3"
                  paragraph
                />
                <TypographyWithTranslation
                  i18nKey="page404.description"
                  sx={{ color: 'text.secondary' }}
                />
                <PageNotFoundIllustration
                  sx={{ height: 260, my: { xs: 5, sm: 10 } }}
                />
                <ButtonWithTranslation
                  href={dashboardRoute.createPath()}
                  size="large"
                  variant="contained"
                  component="a"
                  i18nKey="page404.link"
                />
              </WithTranslationRoot>
            </Box>
          </Container>
        </Box>
      </Page>
    </Box>
  );
}

export default Page404;
