import Skeleton from '@mui/material/Skeleton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

function Placeholder() {
  return (
    <TableRow sx={{ verticalAlign: 'top' }}>
      <TableCell>
        <Skeleton variant="rectangular" width={50} height={17} />
      </TableCell>
      <TableCell>
        <Skeleton variant="rectangular" width={140} height={140} />
      </TableCell>
      <TableCell>
        <Skeleton variant="rectangular" width={120} height={17} />
      </TableCell>
      <TableCell>
        <Skeleton variant="rectangular" width={200} height={40} />
      </TableCell>
      <TableCell>
        <Skeleton variant="rectangular" width={40} height={40} />
      </TableCell>
      <TableCell />
    </TableRow>
  );
}

export { Placeholder };
