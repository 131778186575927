import { useEffect, useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { useTranslationRoot } from 'components/with-translation.tsx';
import { useWatch, useFormContext } from 'react-hook-form';

import type { Column, Row } from './types.ts';
import { useEnrichmentToolContext } from './useEnrichmentToolContext.tsx';
import { baseTableStyles, HIDDEN_BY_BOOLEAN } from './constants.ts';

interface FieldsTableRowProps {
  columns: Column[];
  row: Row;
}

function FieldsTableRow({ columns, row }: FieldsTableRowProps) {
  const { t } = useTranslationRoot();
  const { isVisible } = useShowHideFields(row);
  const { id } = row;

  const isInput = (field: string) => field === 'value';

  return (
    <TableRow
      key={id}
      sx={{
        display: isVisible
          ? 'table-row'
          : row.sproutaiKey === 'reimbursed' ||
              row.sproutaiKey === 'reimbursed_value'
            ? 'none'
            : 'none',
      }}
    >
      {columns.map(({ field }, index) => (
        <TableCell
          key={index}
          sx={{
            ...baseTableStyles,
            '&:first-of-type': { pl: 1 },
            '&:last-of-type': { pr: 1 },
          }}
        >
          {isInput(field)
            ? row.renderCell
              ? row.renderCell(row)
              : row[field]
            : t(row[field])}
        </TableCell>
      ))}
    </TableRow>
  );
}

/**
 * more hardcoded shit just to hide fields if a checkbox deem it to
 */
function useShowHideFields(row: Row) {
  const { state } = useEnrichmentToolContext();
  const { control, getValues } = useFormContext();
  const { fields, fieldsListId, lists } = state;
  const flag = HIDDEN_BY_BOOLEAN[row.sproutaiKey];
  const itemId = flag
    ? lists[fieldsListId].find((id) => fields[id].sproutaiKey === flag)
    : undefined;
  const currentValue = useWatch({
    control,
    name: itemId as string,
    exact: true,
    disabled: !itemId,
  });
  const fieldValue = itemId ? getValues(itemId) : true;
  const [isVisible, setIsVisible] = useState<boolean>(flag ? fieldValue : true);

  useEffect(() => {
    if (flag && itemId) {
      setIsVisible(currentValue);
    }
  }, [flag, itemId, currentValue]);

  return {
    isVisible,
  };
}

export { FieldsTableRow };
