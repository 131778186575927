type AsyncInitScript = {
  async?: boolean;
  defer?: boolean;
  id: string;
  src?: string;
  code?: string;
};

export function asyncInitScript({
  id,
  src,
  defer = true,
  async = false,
  code,
}: AsyncInitScript) {
  const tagName = 'script';
  const firstScript = document.getElementsByTagName(tagName)[0];

  if (!document.getElementById(id)) {
    const script = document.createElement(tagName);

    if (src) {
      script.src = src;
    }

    if (code) {
      script.appendChild(document.createTextNode(code));
    }

    script.id = id;
    script.defer = defer;

    if (async) {
      script.async = async;
    }

    if (firstScript?.parentNode) {
      firstScript.parentNode.insertBefore(script, firstScript);
    }
  }
}
