import { asyncInitScript } from 'utils/async-init-script';
import { generalConfig } from 'config';

function initFabricSDK() {
  asyncInitScript({ id: generalConfig.fabridId, src: generalConfig.fabricLib });
}

function ensureFabricSdkInit(
  callback: (isSuccess: boolean) => void,
  maxRetries: number
) {
  initFabricSDK();

  let counter = 0;
  const interval = setInterval(() => {
    counter++;
    if (counter >= maxRetries) {
      clearInterval(interval);
    }

    if (window.fabric) {
      callback(true);
      clearInterval(interval);
    } else {
      callback(false);
    }
  }, 100);
}

export { ensureFabricSdkInit, initFabricSDK };
