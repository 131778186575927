import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';
import MenuItem, { menuItemClasses } from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { useLocales } from 'locales/useLocales';
import { useTooltip } from 'components/customHooks/useTooltip';
import { MenuPopover } from 'components/MenuPopover';
import { IconButtonAnimate } from 'components/IconButtonAnimate';
import { LanguageIcon } from 'components/icons/LanguageIcon';

function LanguageTooltip() {
  const { allLanguages, currentLanguage, onChangeLanguage } = useLocales();
  const { element, handleClose, handleOpen, isOpen } = useTooltip();

  return (
    <>
      <IconButtonAnimate
        isOpen={isOpen}
        onClick={handleOpen}
        data-testid="language-selector-button"
      >
        <LanguageIcon
          colors={currentLanguage.colors}
          text={currentLanguage.value}
          sx={{
            borderRadius: '50%',
            height: 40,
            width: 40,
          }}
        />
      </IconButtonAnimate>

      <MenuPopover
        open={isOpen}
        onClose={handleClose}
        anchorEl={element}
        sx={{
          mt: 1,
          ml: 0.25,
          width: 180,
          [`& .${menuItemClasses.root}`]: {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Stack spacing={0.5}>
          {allLanguages.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLanguage.value}
              onClick={() => {
                onChangeLanguage(option.value);
                handleClose();
              }}
              sx={{ height: 40, borderRadius: 1, px: 1 }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  maxHeight: 28,
                  maxWidth: 28,
                  mr: 2,
                }}
              >
                <LanguageIcon
                  colors={option.colors}
                  text={option.value}
                  sx={{
                    borderRadius: '50%',
                    height: 28,
                    width: 28,
                  }}
                />
              </Box>
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                {option.label}
              </ListItemText>
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}

export { LanguageTooltip };
