import { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { CanvasTool } from 'components/CanvasTool';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag';
import { TypographyWithTranslation } from 'components/with-translation';
import { LoadingIcon } from 'components/icons/LoadingIcon';
import { useGetNewClaimPageUrls } from 'state/queries/claims';

import { useReviewToolContext } from './useReviewToolContext';

interface ReviewToolGalleryProps {
  initialEnrichmentFormWidth: string | number;
}

function Wrapper({ children }: { children: React.ReactNode }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 1,
      }}
    >
      {children}
    </Box>
  );
}

function ReviewToolGallery({
  initialEnrichmentFormWidth,
}: ReviewToolGalleryProps) {
  const [page, setPage] = useState(0);
  const enableThumbnails = useFeatureFlag('enableReviewToolThumbnails') as true;
  const { claim } = useReviewToolContext();
  const {
    data: imageUrls,
    isLoading,
    isError,
  } = useGetNewClaimPageUrls(claim.id);

  return (
    <Stack
      sx={{
        order: { md: 2, lg: 3 },
        position: 'relative',

        width: {
          xs: '100%',
          md: `100% - ${Number(initialEnrichmentFormWidth)}px`,
        },

        overflow: {
          md: 'hidden',
        },
      }}
    >
      {isError ? (
        <Wrapper>
          <TypographyWithTranslation i18nKey="reviewTool.noUrls" />
        </Wrapper>
      ) : isLoading ? (
        <Wrapper>
          <LoadingIcon />
        </Wrapper>
      ) : imageUrls && imageUrls.length > 0 ? (
        <CanvasTool
          hasThumbnails={enableThumbnails}
          urls={imageUrls.map(({ url }) => url)}
          useCtrlZoom
          thumbnails={imageUrls}
          currentPage={page}
          setPage={setPage}
        />
      ) : (
        <Wrapper>
          <TypographyWithTranslation
            i18nKey="enrichmentTool.noUrls"
            data-testid="no-images-to-show"
          />
        </Wrapper>
      )}
    </Stack>
  );
}

export { ReviewToolGallery };
