import dashboardRoute from 'pages/Dashboard/dashboard.route.tsx';
import docsRoute from 'pages/Docs/docs.route.tsx';
import loginRoute from 'pages/Login/login.route.tsx';
import healthRoute from 'pages/Health/health.route.tsx';
import page404route from 'pages/Site/page404.route.tsx';

export const routes = [
  dashboardRoute,
  docsRoute,
  loginRoute,
  healthRoute,
  page404route,
];
