import Box from '@mui/material/Box';
import { WithTranslationRoot } from 'components/with-translation.tsx';
import { CLASSIFICATION_PAGE } from 'constants/translation-keys.ts';
import { Page } from 'components/Page.tsx';

import { ClassificationTable } from './ClassificationTable.tsx';

function Classification() {
  return (
    <WithTranslationRoot namespace={CLASSIFICATION_PAGE}>
      <Box sx={{ px: 2 }}>
        <Page i18nKeyTitle="meta.title">
          <ClassificationTable />
        </Page>
      </Box>
    </WithTranslationRoot>
  );
}

export default Classification;
