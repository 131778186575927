import type { ChangeEvent } from 'react';
import { Fragment } from 'react';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  useGetFeatureFlagsV1,
  useUpdateFeatureFlagsV1,
} from 'state/queries/feature-flags';
import { FeatureFlagV1 } from 'types/FeatureFlags';

import { Item } from './Item.tsx';

interface FeatureFlagsListProps {
  name: string;
}

function List({ name }: FeatureFlagsListProps) {
  const { data, isLoading, isError } = useGetFeatureFlagsV1(name);
  const toggleFeature = useUpdateFeatureFlagsV1(name);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.checked;
    const key = event.target.name;
    toggleFeature.mutate({ key, value });
  }

  return (
    <Card
      sx={{
        p: 3,
      }}
    >
      {isLoading ? (
        <Typography variant="body1">Loading</Typography>
      ) : isError ? (
        <Typography variant="body1">Error fetching feature flags</Typography>
      ) : data?.length ? (
        <Stack spacing={2}>
          {data.map((feature: FeatureFlagV1, index) => (
            <Fragment key={feature.key}>
              {index > 0 && <Divider sx={{ borderStyle: 'dashed' }} />}
              <Item feature={feature} handleChange={handleChange} />
            </Fragment>
          ))}
        </Stack>
      ) : (
        <Typography variant="body1">No feature flags found</Typography>
      )}
    </Card>
  );
}

export { List };
