import type { ListItemButtonProps } from '@mui/material/ListItemButton';
import type { ListSubheaderProps } from '@mui/material/ListSubheader';
import { alpha, styled } from '@mui/material/styles';
import Link from '@mui/material/Link';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { ICON, NAVBAR } from 'theme/layout';

interface ListItemStyleProps extends ListItemButtonProps {
  activeSub?: boolean;
  activeClassName?: string;
  component?: any;
  subItem?: boolean;
  to?: string;
  state?: any;
}

export const ListItemIconStyle = styled(ListItemIcon)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  height: ICON.NAVBAR_ITEM,
  marginRight: theme.spacing(2),
  minWidth: 'auto',
  width: ICON.NAVBAR_ITEM,
  '& svg': { width: '100%', height: '100%' },
}));

export const ListItemTextStyle = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== 'isCollapse',
})<ListItemButtonProps>(({ theme }) => ({
  whiteSpace: 'nowrap',
  transition: theme.transitions.create(['width', 'opacity'], {
    duration: theme.transitions.duration.shorter,
  }),
}));

export const ListItemStyle = styled(ListItemButton, {
  shouldForwardProp: (prop) =>
    prop !== 'isActiveRoot' && prop !== 'activeSub' && prop !== 'subItem',
})<ListItemStyleProps>(({ activeSub, subItem, theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  height: NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
  marginBottom: theme.spacing(0.5),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(1.5),
  position: 'relative',
  textTransform: 'capitalize',
  '&.isActiveRoot': {
    ...theme.typography.subtitle2,
    color: theme.palette.primary.main,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
    '&:before': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '2px 0px 0px 2px',
      content: '""',
      height: '100%',
      position: 'absolute',
      right: 0,
      top: 0,
      width: '3px',
    },
    '& svg path': {
      fill: theme.palette.primary.main,
    },
  },
  ...(activeSub && {
    ...theme.typography.subtitle2,
    color: theme.palette.text.primary,
  }),
  ...(subItem && {
    height: NAVBAR.DASHBOARD_ITEM_SUB_HEIGHT,
  }),
}));

export const ListSubheaderStyle = styled((props: ListSubheaderProps) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  color: theme.palette.text.primary,
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(4),
  paddingTop: theme.spacing(3),
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

export const MiniNavItem = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'isActiveRoot',
})(({ theme }) => ({
  borderRadius: theme.spacing(1),
  color: theme.palette.grey[500],
  cursor: 'pointer',
  position: 'relative',
  width: '100%',
  '&:hover': {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.grey[700],
    textDecoration: 'none',
  },
  '&.isActiveRoot': {
    color: theme.palette.primary.main,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
    '& svg path': {
      fill: theme.palette.primary.main,
    },
    span: {
      fontWeight: 'bold',
    },
  },
}));
