const avivauk = {
  palette: {
    primary: {
      light: '#577ae9',
      main: '#004FB6',
      dark: '#002885',
    },
    secondary: {
      light: '#43879A',
      main: '#005A6C',
      dark: '#003041',
    },
    chart: {
      primary: ['#9a8200', '#C7A800', '#FFD900', '#FFFF53'],
      secondary: ['#00222d', '#003041', '#005A6C', '#43879A'],
      blue: ['#001e64', '#002885', '#004FB6', '#577ae9'],
      yellow: ['#854d00', '#c67100', '#FFA000', '#ffd149'],
      green: ['#003600', '#035400', '#3E812C', '#6eb159'],
      red: ['#4b0000', '#7a0000', '#B10101', '#eb492d'],
    },
  },
};

export default avivauk;
