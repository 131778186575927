import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

function useRestoreFilters(key: string) {
  const location = useLocation();
  const storedFilters = localStorage.getItem(key);
  const [searchParams, setSearchParams] = useSearchParams(
    storedFilters ? storedFilters : ''
  );

  useEffect(() => {
    setSearchParams(searchParams.toString());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const newSearchParams = new URLSearchParams(location.search);

    if (storedFilters !== newSearchParams.toString()) {
      localStorage.setItem(key, newSearchParams.toString());
    }
  }, [key, location.search, searchParams, storedFilters]);

  return { searchParams, setSearchParams };
}

export { useRestoreFilters };
