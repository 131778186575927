import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { ServerErrorIllustration } from 'components/icons/Illustration500';
import { Page } from 'components/Page';
import {
  ButtonWithTranslation,
  TypographyWithTranslation,
} from 'components/with-translation';
import { COMMON } from 'constants/translation-keys';

interface Page500Props {
  error: Error | null;
  resetError: VoidFunction;
}

function Page500({ error, resetError }: Page500Props) {
  return (
    <Box sx={{ height: 1 }}>
      <Page
        i18nKeyTitle="page500.meta.title"
        sx={{ height: 1 }}
        namespace={COMMON}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            height: 1,
            paddingTop: 15,
            paddingBottom: 10,
          }}
        >
          <Container>
            <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
              <TypographyWithTranslation
                i18nKey="page500.heading"
                variant="h3"
                component="p"
              />
              <TypographyWithTranslation
                i18nKey="page500.description"
                sx={{ color: 'text.secondary' }}
              />
              <Typography sx={{ color: 'error.main' }}>
                {error?.message}
              </Typography>
              <ServerErrorIllustration
                sx={{ height: 260, my: { xs: 5, sm: 10 } }}
              />
              <ButtonWithTranslation
                size="large"
                variant="contained"
                onClick={resetError}
                i18nKey="page500.button"
              />
            </Box>
          </Container>
        </Box>
      </Page>
    </Box>
  );
}

export default Page500;
