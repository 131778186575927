import type { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { NAVBAR } from 'theme/layout';

interface SiteLayoutProps {
  children: ReactNode;
  header?: ReactNode;
  isMiniNavBarOpen: boolean;
  navbar?: ReactNode;
}

const MainStyle = styled('main', {
  shouldForwardProp: (prop) =>
    prop !== 'collapseClick' && prop !== 'isMiniNavBarOpen',
})<{ isMiniNavBarOpen: boolean }>(({ isMiniNavBarOpen, theme }) => ({
  flexGrow: 1,
  [theme.breakpoints.up('lg')]: {
    width: isMiniNavBarOpen
      ? `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`
      : `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

function SiteLayout({
  children,
  header,
  isMiniNavBarOpen,
  navbar,
}: SiteLayoutProps) {
  return (
    <Box sx={{ display: { lg: 'flex' }, minHeight: { lg: 1 } }}>
      {navbar}
      <MainStyle isMiniNavBarOpen={isMiniNavBarOpen}>
        {header}
        <Box
          sx={{
            py: 3,
          }}
        >
          {children}
        </Box>
      </MainStyle>
    </Box>
  );
}

export { SiteLayout };
