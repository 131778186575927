import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import type { ClaimsValidateResult } from 'types/Claims';
import { SvgIconStyle } from 'components/SvgIconStyle';
import {
  CHECK_FILL_ICON,
  CLOSE_CIRCLE_FILL_ICON,
  CLOSE_ICON,
} from 'constants/public-icons';
import {
  ButtonWithTranslation,
  TypographyWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';
import { useBoolean } from 'components/customHooks/useBoolean';

import { ValidationResultV2 } from './ValidationResultV2';
import { FULLSCREEN_ZINDEX } from './review-tool-utils';

interface ReviewValidationResultsProps {
  validationResults: ClaimsValidateResult[];
}

function ReviewValidationResults({
  validationResults,
}: ReviewValidationResultsProps) {
  const { t } = useTranslationRoot();
  const [isOpen, openDrawer, closeDrawer] = useBoolean();
  const hasValidationResults = validationResults
    ? validationResults.length > 0
    : false;
  const isAllValidationResultsPassed =
    hasValidationResults && validationResults
      ? validationResults.every((item) => {
          return item.result?.toLowerCase() === 'passed' || false;
        })
      : false;

  return (
    <>
      <ButtonWithTranslation
        color={isAllValidationResultsPassed ? 'success' : 'error'}
        i18nKey="enrichmentTool.validationResultsButton"
        onClick={openDrawer}
        disabled={!hasValidationResults}
        startIcon={
          <SvgIconStyle
            height={17}
            src={
              isAllValidationResultsPassed
                ? CHECK_FILL_ICON
                : CLOSE_CIRCLE_FILL_ICON
            }
            width={17}
          />
        }
        variant="text"
        sx={{
          whiteSpace: 'nowrap',
        }}
      />
      <Drawer
        data-testid="review-validation-results"
        anchor="right"
        open={isOpen}
        onClose={closeDrawer}
        sx={{
          zIndex: FULLSCREEN_ZINDEX + 1,
        }}
      >
        <Box role="presentation" sx={{ minWidth: 300, position: 'relative' }}>
          <IconButton
            data-testid="review-validation-results-close"
            aria-label={t('drawer.closeButtonText') as string}
            onClick={closeDrawer}
            sx={{
              position: 'absolute',
              right: ({ spacing }) => spacing(2),
              top: ({ spacing }) => spacing(2.5),
            }}
          >
            <SvgIconStyle src={CLOSE_ICON} />
          </IconButton>
          <Box
            component="header"
            sx={{
              p: 3,
            }}
          >
            <TypographyWithTranslation
              i18nKey="enrichmentTool.validationResultsTitle"
              variant="h5"
            />
          </Box>
          <Stack sx={{ maxWidth: 500, p: 3, pt: 0 }} spacing={2}>
            {validationResults.map((validationResult) => (
              <ValidationResultV2
                key={validationResult.ruleId}
                validationResult={validationResult}
              />
            ))}
          </Stack>
        </Box>
      </Drawer>
    </>
  );
}

export { ReviewValidationResults };
