import type { ReactNode } from 'react';
import { useLayoutEffect, useRef } from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid2';

interface LayoutProps {
  topNode: ReactNode;
  timelineNode: ReactNode;
  widgetsNode: ReactNode;
  canvasNode: ReactNode;
}

function Layout({
  timelineNode,
  topNode,
  widgetsNode,
  canvasNode,
}: LayoutProps) {
  const cardRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const card = cardRef.current;

    if (card) {
      const rect = card.getBoundingClientRect();
      // 24px for padding
      card.style.height = `calc(100vh - ${rect.top + 24}px)`;
    }
  }, []);

  return (
    <Card ref={cardRef}>
      <Grid container sx={{ height: 1 }}>
        <Grid
          size={12}
          sx={{
            borderBottom: '1px solid',
            borderBottomColor: 'divider',
            height: 71,
          }}
        >
          {topNode}
        </Grid>
        <Grid size={2} sx={{ height: 1 }}>
          {timelineNode}
        </Grid>
        <Grid size={3} sx={{ height: 1 }}>
          {widgetsNode}
        </Grid>
        <Grid size={7} sx={{ height: 1 }}>
          {canvasNode}
        </Grid>
      </Grid>
    </Card>
  );
}

export { Layout };
