import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import visuallyHidden from '@mui/utils/visuallyHidden';

interface LoadingIconProps {
  size?: number;
}

function LoadingIcon({ size }: LoadingIconProps) {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      <CircularProgress data-testid="loading" size={size} />
      <Typography sx={visuallyHidden}>Loading</Typography>
    </Box>
  );
}

export { LoadingIcon };
