import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import {
  TypographyWithTranslation,
  useTranslationRoot,
} from 'components/with-translation.tsx';
import { ARROW_FORWARD_ICON } from 'constants/public-icons.ts';
import { COMMON } from 'constants/translation-keys.ts';

const ICON_SIZE = 18;

interface NavigationProps {
  handlePrevPage: () => void;
  handleNextPage: () => void;
  page: number;
  total: number;
}

function Navigation({
  handleNextPage,
  handlePrevPage,
  page,
  total,
}: NavigationProps) {
  const { t } = useTranslationRoot(COMMON);

  const isButtonsDisabled = total <= 1;

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        alignItems: 'center',
      }}
    >
      <ButtonGroup
        color="inherit"
        variant="contained"
        size="small"
        sx={{ height: 1 }}
      >
        <Tooltip title={t('canvas.prevPage')}>
          <Box component="span" sx={{ display: 'flex' }}>
            <Button onClick={handlePrevPage} disabled={isButtonsDisabled}>
              <SvgIconStyle
                src={ARROW_FORWARD_ICON}
                height={ICON_SIZE}
                width={ICON_SIZE}
                sx={{ transform: 'rotate(180deg)' }}
              />
            </Button>
          </Box>
        </Tooltip>
        <Tooltip title={t('canvas.nextPage')}>
          <Box component="span" sx={{ display: 'flex' }}>
            <Button onClick={handleNextPage} disabled={isButtonsDisabled}>
              <SvgIconStyle
                src={ARROW_FORWARD_ICON}
                height={ICON_SIZE}
                width={ICON_SIZE}
              />
            </Button>
          </Box>
        </Tooltip>
      </ButtonGroup>
      <Box sx={{ minWidth: 85 }}>
        <TypographyWithTranslation
          i18nKey="canvas.pageOf"
          options={{ current: page + 1, total }}
          namespace={COMMON}
          variant="subtitle2"
        />
      </Box>
    </Stack>
  );
}

export { Navigation };
