import type { MetricsApiResponse } from 'api/transformers/types/metrics';
import type { Metrics } from 'types/Metrics';
import { useGet } from 'utils/react-query';
import { makeMetricsList, METRICS } from 'constants/api-endpoints';
import { selectMetricsResponse } from 'state/selectors/metrics.ts';

export function useGetMetrics(params: {
  startTime: Date;
  endTime: Date;
  documentType?: string;
}) {
  const list = makeMetricsList(params?.documentType);

  return useGet<MetricsApiResponse, Metrics>({
    url: list,
    params: {
      startTime: params.startTime.toISOString(),
      endTime: params.endTime.toISOString(),
      documentType: params.documentType,
    },
    prefix: METRICS,
    select: selectMetricsResponse,
  });
}
