import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import type { Claim } from 'types/Claims.ts';
import type { ClaimSummaryWidget } from 'types/Widgets.ts';
import { LoadingIcon } from 'components/icons/LoadingIcon.tsx';
import { useGetNewClaimPageUrls } from 'state/queries/claims.ts';

import { Layout } from './Layout.tsx';
import { HeaderSummary } from './HeaderSummary.tsx';
import { CanvasTool } from '../CanvasTool';
import { TimelineWidget } from './TimelineWidget.tsx';
import { Widgets } from './Widgets.tsx';

interface PolicyReviewToolProps {
  claim: Claim;
  widgets: ClaimSummaryWidget[];
}

function PolicyReviewTool({ claim, widgets }: PolicyReviewToolProps) {
  const pageUrlsQuery = useGetNewClaimPageUrls(claim.id);
  const [page, setPage] = useState(0);

  const updatePage = (page: number) => {
    // deduct 1 to match page number as array starts from 0
    setPage(page - 1);
  };

  if (pageUrlsQuery.isPending) {
    return <LoadingIcon />;
  }

  if (pageUrlsQuery.isError) {
    return <Typography>Failed to load claim images.</Typography>;
  }

  const topNode = (
    <Stack direction="row" sx={{ flexGrow: 1 }}>
      <HeaderSummary
        clientClaimId={claim.clientClaimId}
        lastUpdatedOn={claim.lastUpdatedOn}
      />
    </Stack>
  );
  const widgetsNode = (
    <Widgets claim={claim} updatePage={updatePage} widgets={widgets} />
  );
  const canvasNode = (
    <CanvasTool
      // @ts-ignore stupid hack
      crop={{ pageIdx: page }}
      urls={pageUrlsQuery.data.map((urls) => urls.url)}
      setPage={setPage}
    />
  );

  return (
    <Layout
      topNode={topNode}
      timelineNode={
        <TimelineWidget
          claim={claim}
          widgets={widgets}
          updatePage={updatePage}
        />
      }
      widgetsNode={widgetsNode}
      canvasNode={canvasNode}
    />
  );
}

export { PolicyReviewTool };
