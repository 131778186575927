import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';

function IsValidCheckAll() {
  const { control, setValue } = useFormContext();
  const [checked, setChecked] = useState(false);
  const fields = useWatch({ name: 'pages', control });

  useEffect(() => {
    const allChecked = fields.every((field) => field.isValid);
    setChecked(allChecked);
  }, [fields]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);

    fields.forEach((_, index: number) => {
      setValue(`pages.${index}.isValid`, isChecked, { shouldDirty: true });

      if (isChecked) {
        setValue(`pages.${index}.classification`, null, { shouldDirty: true });
      }
    });
  };

  return (
    <Checkbox
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      size="small"
      checked={checked}
      onChange={handleChange}
      sx={{
        p: '3px',
      }}
    />
  );
}

export { IsValidCheckAll };
