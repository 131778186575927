import { useTheme } from '@mui/material/styles';

function useChartBaseOption() {
  const theme = useTheme();

  return {
    baseDonutColor: theme.palette.grey[500],
    defaultColorRange: [
      theme.palette.chart.primary[0],
      theme.palette.chart.secondary[0],
      theme.palette.chart.yellow[0],
      theme.palette.chart.blue[0],
      theme.palette.chart.violet[0],
      theme.palette.chart.green[0],
      theme.palette.chart.red[0],
      theme.palette.secondary.main,
      theme.palette.success.main,
    ],
    hoverStrokeColor: theme.palette.grey[500],
    strokeColor: theme.palette.grey[50024],
    textColor: theme.palette.text.secondary,
  };
}

export { useChartBaseOption };
