import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { AppLogo } from 'components/AppLogo';

const RootStyle = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  paddingBottom: theme.spacing(10),
  paddingTop: theme.spacing(15),
}));

export default function Splash({ children }: { children: React.ReactNode }) {
  return (
    <RootStyle>
      <Container sx={{ textAlign: 'center' }}>
        <AppLogo />
        {children}
      </Container>
    </RootStyle>
  );
}
