import { useState } from 'react';

type UseBoolean = [boolean, VoidFunction, VoidFunction, VoidFunction];

function useBoolean(defaultValue = false): UseBoolean {
  const [value, setValue] = useState(defaultValue);

  function setTrue() {
    setValue(true);
  }

  function setFalse() {
    setValue(false);
  }

  function toggle() {
    setValue((x) => !x);
  }

  return [value, setTrue, setFalse, toggle];
}

export { useBoolean };
