import { Canvas as CanvasType, FabricImage, FabricObject } from 'fabric';
import { Point } from 'fabric';

import { BoundingBox } from './index.tsx';

export type Urls = { [key: string]: string } | string[];

export const SCALE_STEP = 0.1;
export const ZOOM_IN_THRESHOLD = 5;
export const ZOOM_OUT_THRESHOLD = 0.1;
export const ADDITIONAL_CROP_SIZE = 0.1; // 10%

export function calculateScaleFactor(
  { height, width }: { height: number; width: number },
  img: FabricImage,
  offset: number = 1 // default 90%
): number {
  const containerHeight = height;
  let scaleFactor = width / img.width;
  const scaledHeight = img.height * scaleFactor;

  // if the image is too tall, scale it down to fit the container height
  if (scaledHeight > containerHeight) {
    scaleFactor = containerHeight / img.height;
  }

  return scaleFactor * offset;
}

export function getImageList(urls: Urls | string[] | null) {
  return urls
    ? Object.keys(urls)
        .map((v) => parseInt(v))
        .sort()
    : [];
}

export function getNewImageList(
  urls: {
    [key: string]: string;
  }[]
) {
  return urls.map((_, index) => index);
}

export function getBoundingBox({ topLeft, bottomRight }: BoundingBox) {
  const [left, top] = topLeft;
  const [right, bottom] = bottomRight;

  return {
    bottom,
    left,
    right,
    top,
  };
}

export function getObjectAngle(object: FabricObject | undefined) {
  return object?.angle;
}

export function zoomToCrop(
  canvas: CanvasType,
  object: FabricObject,
  overrideZoom?: number
) {
  const oldZoom = canvas.getZoom();
  canvas.setZoom(1);
  const [, , , , x, y] = object.calcTransformMatrix();
  const zoom = canvas.getZoom();
  const vpw = (canvas.width || 0) / zoom;
  const vph = (canvas.height || 0) / zoom;
  const pan = new Point({ x: x + -(vpw / 2), y: y + -(vph / 2) });
  const point = new Point({ x: vpw / 2, y: vph / 2 });
  canvas.absolutePan(pan);

  let newZoom = oldZoom <= 1 ? 2 : oldZoom;

  if (overrideZoom) {
    newZoom = overrideZoom;
  }

  canvas.zoomToPoint(point, newZoom);
}

export function isPortrait(image: FabricObject) {
  return image.height > image.width;
}

export const getType = (obj: FabricObject) => obj.type.toLowerCase();
export const findImage = (obj: FabricObject) => obj.isType('image');
export const findGroup = (obj: FabricObject) => obj.isType('group');
export const findRect = (obj: FabricObject) => obj.isType('rect');
export const findPath = (obj: FabricObject) => obj.isType('path');
