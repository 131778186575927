import type { MouseEvent } from 'react';
import { ButtonWithTranslation } from 'components/with-translation.tsx';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import { UPLOAD_ICON } from 'constants/public-icons.ts';

interface MainUploadButtonProps {
  handleOpen: (event: MouseEvent<HTMLButtonElement>) => void;
  isUploadInProgress: boolean;
}

function MainUploadButton({
  handleOpen,
  isUploadInProgress,
}: MainUploadButtonProps) {
  return (
    <ButtonWithTranslation
      disabled={isUploadInProgress}
      i18nKey="common.uploadButton"
      variant="contained"
      startIcon={<SvgIconStyle src={UPLOAD_ICON} />}
      onClick={handleOpen}
    />
  );
}

export { MainUploadButton };
