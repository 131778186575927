import { useState } from 'react';
import { Trans } from 'react-i18next';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { DialogModal } from 'components/dialog/Modal/DialogModal.tsx';
import { renderComponent } from 'components/dialog/generators.tsx';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import { UPLOAD_ICON } from 'constants/public-icons.ts';
import {
  ButtonWithTranslation,
  DialogTitleWithTranslation,
  useTranslationRoot,
} from 'components/with-translation.tsx';
import { formatBytes } from 'utils/string.ts';

import { UploadMultiFile } from './UploadMultiFile.tsx';

interface ModalProps {
  files: File[];
  handleClose: VoidFunction;
  onUpload: (files: File[]) => void;
}

interface UploadModalProps extends ModalProps {
  handleClose: VoidFunction;
  isOpen: boolean;
}

const MAX_FILES = 5;
const MAX_SIZE = 1024 * 1024 * 1024 * 10; // 10 GB

function UploadModal({ isOpen, ...props }: UploadModalProps) {
  return (
    <DialogModal
      getDialog={{
        index() {
          return [
            renderComponent({
              Component: Modal,
              ...props,
            }),
          ];
        },
      }}
      handleClose={props.handleClose}
      isOpen={isOpen}
    />
  );
}

function Modal({ files: initialFiles, handleClose, onUpload }: ModalProps) {
  const { localNamespace } = useTranslationRoot();
  const [files, setFiles] = useState<File[]>(initialFiles ?? []);

  const handleDrop = (acceptedFiles: File[]) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  };

  const handleRemoveAll = () => {
    setFiles([]);
  };

  const handleRemove = (file: File) => {
    setFiles((prevFiles) => prevFiles.filter((f) => f !== file));
  };

  return (
    <Box
      sx={{
        width: 1,
      }}
    >
      <DialogTitleWithTranslation i18nKey="modal.title" />
      <DialogContent>
        <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
          <Trans
            i18nKey="modal.description"
            ns={localNamespace}
            tOptions={{
              maxFiles: MAX_FILES,
              maxSize: formatBytes(MAX_SIZE),
            }}
            components={{
              span: (
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ color: 'primary.main', fontWeight: 'bold' }}
                />
              ),
            }}
          />
        </Typography>
        <UploadMultiFile
          dropzoneProps={{
            disabled: files.length >= MAX_FILES,
            maxFiles: MAX_FILES - files.length,
            maxSize: MAX_SIZE,
            multiple: true,
            onDrop: handleDrop,
          }}
          files={files}
          onRemove={handleRemove}
        />
      </DialogContent>
      <DialogActions>
        {!!files.length && (
          <ButtonWithTranslation
            i18nKey="modal.removeAllButton"
            onClick={handleRemoveAll}
          />
        )}
        <ButtonWithTranslation
          i18nKey="common.submitButton"
          variant="contained"
          disabled={!files.length}
          onClick={() => {
            onUpload(files);
            handleClose();
          }}
          startIcon={<SvgIconStyle src={UPLOAD_ICON} />}
        />
      </DialogActions>
    </Box>
  );
}

export { UploadModal };
