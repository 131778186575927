import { useState } from 'react';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormState,
} from 'react-hook-form';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import type { PagesList } from 'types/Pages.ts';
import { LoadingButtonWithTranslation } from 'components/with-translation.tsx';
import { convertArrayToListAndDict } from 'utils/array.ts';

import { Item } from './Item.tsx';
import { TableColumnHead } from './TableColumnHead.tsx';

interface FormProps {
  pages: PagesList;
  submitReclassification: (
    data: {
      classification: string | null;
      pageId: string;
      isValid: boolean;
    }[],
    callback: () => void
  ) => void;
}

type FormValues = {
  pages: {
    classification: string | null;
    pageId: string;
    isValid: boolean;
  }[];
};

function Form({ pages, submitReclassification }: FormProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const methods = useForm<FormValues>({
    defaultValues: {
      // setting pageId instead to not override id in useFieldArray
      pages: pages.map(({ id }) => ({
        classification: null,
        pageId: id,
        isValid: false,
      })),
    },
  });
  const { control, handleSubmit, reset } = methods;
  const { isDirty, isValid } = useFormState({ control });
  const { fields } = useFieldArray({ control, name: 'pages' });
  const [, resources] = convertArrayToListAndDict(pages, 'id');

  const onSubmit = (data: FormValues) => {
    const { pages } = data;
    setIsSubmitting(() => true);

    submitReclassification(pages, () => {
      setIsSubmitting(() => false);
      reset();
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ minWidth: 500, mt: 3 }}>
              <Table>
                <TableColumnHead />
                <TableBody>
                  {fields.map((field, index) => (
                    <Item
                      key={field.id}
                      field={field}
                      index={index}
                      resources={resources}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Card>
        <Box sx={{ mt: 3, textAlign: 'right' }}>
          <LoadingButtonWithTranslation
            loading={isSubmitting}
            disabled={!isDirty && !isValid}
            type="submit"
            variant="contained"
            i18nKey="table.submitButton"
          />
        </Box>
      </form>
    </FormProvider>
  );
}

export { Form };
