import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { TypographyWithTranslation } from 'components/with-translation';
import { AI } from 'constants/public-icons';
import type { ClaimRecommendation } from 'types/Claims';

interface ReviewFormClaimRecommendationProps {
  claimRecommendation:
    | { [k: string]: string }[]
    | ClaimRecommendation
    | ClaimRecommendation[];
}

function ReviewFormClaimRecommendation({
  claimRecommendation,
}: ReviewFormClaimRecommendationProps) {
  const claimRecommendationArray = Array.isArray(claimRecommendation)
    ? claimRecommendation
    : [claimRecommendation];
  if (claimRecommendationArray.length > 0) {
    return (
      <Stack direction="row" align-items="center" spacing={1}>
        <Box
          sx={{
            alignItems: 'center',
            borderRadius: '50%',
            display: 'inline-flex',
            justifyContent: 'center',
            color: 'text.secondary',
          }}
        >
          <SvgIconStyle src={AI} />
        </Box>
        <Stack spacing={0}>
          <TypographyWithTranslation
            component="span"
            i18nKey="reviewTool.recommendation"
            variant="subtitle2"
          />
          <Typography
            component="span"
            variant="body2"
            sx={{
              fontSize: 14,
            }}
          >
            {claimRecommendationArray.map((obj, index) => {
              const separator =
                index === claimRecommendationArray.length - 1 ? '' : ', ';
              return `${Object.values(obj).join(', ')}${separator}`;
            })}
          </Typography>
        </Stack>
      </Stack>
    );
  }

  return null;
}

export { ReviewFormClaimRecommendation };
