import type { ReactNode } from 'react';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import { useLocales } from 'locales/useLocales';

type Props = {
  children: ReactNode;
};

function ThemeLocalisation({ children }: Props) {
  const defaultTheme = useTheme();
  const { currentLanguage } = useLocales();

  const theme = createTheme(defaultTheme, currentLanguage.systemValue);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export { ThemeLocalisation };
