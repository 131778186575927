export const ADMIN = 'ADMIN';
export const BANNED = 'BANNED';
export const HITL = 'HIL';
export const HITL_AND_MI = 'HIL_AND_MI';
export const MI = 'MI';
export const SUPER_ADMIN = 'SUPER_ADMIN';
export const SYSTEM = 'SYSTEM';
export const POV = 'POV';
export const CLAIM = 'CLAIM';
export const CLAIM_REVIEW = 'CLAIM_REVIEW';
export const DOCUMENT_ADMIN = 'DOCUMENT_ADMIN';
export const CLAIM_ADMIN = 'CLAIM_ADMIN';
export const CLIENT_ADMIN = 'CLIENT_ADMIN';
