import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { PAGE, QUERY, SEARCH_FIELD } from 'constants/route-keys';
import claimsRoute from 'pages/Claims/claims.route';
import { useIsSuperAdminRole } from 'state/queries/current-user';

import type { SearchFormValues } from './SearchForm';
import { SearchForm } from './SearchForm';

// this is so stupid, I don't know what the designer was thinking of
// we shouldn't have this search bar in the header, it should be in the claims page
function HeaderSearch() {
  const isSuperAdminQuery = useIsSuperAdminRole();
  const hasSuperAdminPermission =
    isSuperAdminQuery.isSuccess && isSuperAdminQuery.data;

  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();

  function handleSubmit(values: SearchFormValues) {
    const { searchClaimId, searchField } = values;

    if (pathname === claimsRoute.path) {
      const params = new URLSearchParams(search);

      if (searchClaimId) {
        params.set(QUERY, searchClaimId);
        params.set(PAGE, '0');
        if (searchField && hasSuperAdminPermission) {
          params.set(SEARCH_FIELD, searchField);
        }
      } else {
        // the user has deleted the searchClaimId
        params.delete(QUERY);
        params.delete(PAGE);
        params.delete(SEARCH_FIELD);
      }
      navigate(`${pathname}?${params.toString()}`);
    } else {
      const search = `?${QUERY}=${searchClaimId}`;
      const withSearchField = searchField
        ? `${search}&${SEARCH_FIELD}=${searchField}`
        : search;
      const to = claimsRoute.createPath({
        search: withSearchField,
      });
      navigate(to);
    }
  }

  return (
    <SearchForm
      onSubmit={handleSubmit}
      defaultValue={{
        searchClaimId: searchParam.get(QUERY) || '',
        searchField: searchParam.get(SEARCH_FIELD) || '',
      }}
      hasSuperAdminPermission={hasSuperAdminPermission}
    />
  );
}

export default HeaderSearch;
