export const sortOrder = [
  '**********************',
  'override_payout',
  'override_no_payout',

  '***********************',
  'payment_amount',
  'fraud_rule_1_result',
  'fraud_rule_2_result',
  'fraud_rule_3_result',
  'fraud_rule_4_result',
  'fraud_rule_5_result',
  'assessment_rule_1_result',
  'assessment_rule_2_result',
  'assessment_rule_3_result',
  'assessment_rule_4_result',
  'assessment_rule_5_result',

  '************************',
  'Insured_person_Kanji_name',
  'Insured_person_Date_of_birth',
  'Insured_person_Gender',
  'Disease_Name_of_the_disease_this_time',
  'disease_code',
  'disease_type',
  'has_surgery',
  'Surgery_date_1',
  'Surgery_name_1',
  'Disease_Date_of_first_medical_examination',
  'has_hospitalization',
  'Hospitalization_Start_date',
  'Hospitalization_End_date',
  'Hospitalization_Discharged_In_hospital_transferred_death_Discharged',

  '*************************',
  'insurance_type_name',
  'policy_number',
  'effective_date_of_coverage',
  'main_contract',
  'main_contract_insurance_type',
  'status_name',
  'has_fixed_benefit_clause_for_short_term_hospitalization',
  'daily_hospitalization_benefit',
  'max_num_days',
  'cumulative_limit_days',
  'policy_maintenance_cd',
  'policy_maintenance_cd_2',
  'transfer_cd',
  'transfer_date',
  'ab01_transfer_cd_1',
  'ab01_transfer_cd_2',
  'ab01_transfer_cd_3',
  'ab01_transfer_cd_4',

  '**************************',
  'claim_reasons',
  'hospitalization_days',
  'hospitalization_days_to_be_paid',
  'current_total_sickness_paid_days',
  'last_paid_payment_history_paid_days',
  'last_paid_payment_history_claim_period_end_date',
  'last_paid_payment_history_claim_reason_name',
  'last_paid_sickness_days_affecting_this_time',
  'interval_from_last_paid_sickness_Hospitalization_End_date',
  'other_contract_status_name',
  'other_contract_main_contract',
  'other_contract_policy_number',
  'other_contract_transfer_date',
  'other_contract_insured_person_name',
  'other_contract_insured_person_gender',
  'other_contract_insured_person_birth_date',
  'payment_amount_multiplier',
  'days_to_be_paid',
  '_days_to_be_paid',
  'last_paid_sickness_days',
  'last_paid_sickness_amount',
  'total_onetime_payment_days',
  'capped_total_onetime_payment_days',
  '_current_total_sickness_paid_amount',
  '_total_onetime_hospitalization_days',
  'last_paid_payment_history_cause_code',
  'last_paid_payment_history_paid_amount',
  'last_paid_sickness_hospitalization_days',
  'last_paid_payment_history_disease_code_icd10',
  'last_paid_sickness_hospitalization_days_affecting_this_time',

  // 'fraud_rule_1_table',
  // 'fraud_rule_2_table',
  // 'fraud_rule_3_table',
  // 'fraud_rule_4_table',
  // 'fraud_rule_5_table',
  // 'core_document_stages',
  // 'assessment_rule_1_table',
  // 'assessment_rule_2_table',
  // 'assessment_rule_3_table',
  // 'assessment_rule_4_table',
  // 'assessment_rule_5_table',
  // 'payment_amount_feature_table',
];
