import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import { CLOSE_ICON, FILE_EARMARK_ICON } from 'constants/public-icons.ts';
import { formatBytes } from 'utils/string.ts';

interface MultiFilePreviewProps {
  files: File[];
  onRemove: (file: File) => void;
}

function MultiFilePreview({ files, onRemove }: MultiFilePreviewProps) {
  const hasFiles = files.length > 0;

  return (
    <List disablePadding sx={{ ...(hasFiles && { my: 3 }) }}>
      {files.map((file, index) => {
        const { name, size } = file;
        return (
          <ListItem
            key={`${index}-${name}`}
            sx={{
              my: 1,
              px: 2,
              py: 0.75,
              borderRadius: 0.75,
              border: (theme) => `solid 1px ${theme.palette.divider}`,
            }}
          >
            <SvgIconStyle
              src={FILE_EARMARK_ICON}
              sx={{ color: 'text.secondary', height: 28, mr: 2, width: 28 }}
            />
            <ListItemText
              primary={name}
              secondary={formatBytes(size)}
              primaryTypographyProps={{ variant: 'subtitle2' }}
              secondaryTypographyProps={{ variant: 'caption' }}
              sx={{ flex: 1 }}
            />
            <IconButton edge="end" size="small" onClick={() => onRemove(file)}>
              <SvgIconStyle src={CLOSE_ICON} />
            </IconButton>
          </ListItem>
        );
      })}
    </List>
  );
}

export { MultiFilePreview };
