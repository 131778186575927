import type { ReactNode, ForwardedRef, ElementType } from 'react';
import type { AlertTitleProps } from '@mui/material/AlertTitle';
import type { ButtonProps } from '@mui/material/Button';
import type { CardHeaderProps } from '@mui/material/CardHeader';
import type { ChipProps } from '@mui/material/Chip';
import type { DialogContentTextProps } from '@mui/material/DialogContentText';
import type { DialogTitleProps } from '@mui/material/DialogTitle';
import type { FormHelperTextProps } from '@mui/material/FormHelperText';
import type { InputLabelProps } from '@mui/material/InputLabel';
import type { LinkProps } from '@mui/material/Link';
import type { MenuItemProps } from '@mui/material/MenuItem';
import type { StepLabelProps } from '@mui/material/StepLabel';
import type { TabProps } from '@mui/material/Tab';
import type { TableCellProps } from '@mui/material/TableCell';
import type { TypographyProps } from '@mui/material/Typography';
import type { LoadingButtonProps } from '@mui/lab/LoadingButton';
import type { Namespace, StringMap, TFuncReturn, TOptionsBase } from 'i18next';
import { createContext, forwardRef, useContext } from 'react';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import StepLabel from '@mui/material/StepLabel';
import Tab from '@mui/material/Tab';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';

type StringKey = string[] | string | TFuncReturn<any, any, any>;

interface TranslationProps {
  namespace?: Namespace;
  options?: (TOptionsBase & StringMap) | undefined;
}

interface AlertTitleWithTranslationProps
  extends AlertTitleProps,
    TranslationProps {
  i18nKey: StringKey;
}

interface ButtonWithTranslationProps extends ButtonProps, TranslationProps {
  component?: ElementType;
  i18nKey: StringKey;
  to?: string;
}

interface CardHeaderWithTranslationProps
  extends CardHeaderProps,
    TranslationProps {
  titleI18nKey: StringKey;
}

interface ChipWithTranslationProps extends ChipProps {
  labelI18nKey: StringKey;
  namespace?: Namespace;
}

interface DialogContentTextWithTranslationProps
  extends DialogContentTextProps,
    TranslationProps {
  i18nKey: StringKey;
}

interface DialogTitleWithTranslationProps
  extends DialogTitleProps,
    TranslationProps {
  i18nKey: StringKey;
  startAdornment?: ReactNode;
}

interface FormHelperTextWithTranslationProps
  extends FormHelperTextProps,
    TranslationProps {
  i18nKey: StringKey;
}

interface InputLabelWithTranslationProps
  extends InputLabelProps,
    TranslationProps {
  i18nKey: StringKey;
}
interface LinkWithTranslationProps extends LinkProps, TranslationProps {
  component?: ElementType;
  i18nKey: StringKey;
  to: string;
}

interface LoadingButtonWithTranslationProps
  extends LoadingButtonProps,
    TranslationProps {
  i18nKey: StringKey;
}

interface MenuItemWithTranslationProps extends MenuItemProps, TranslationProps {
  i18nKey: StringKey;
}

interface StepLabelWithTranslationProps
  extends StepLabelProps,
    TranslationProps {
  i18nKey: StringKey;
}

interface TabWithTranslationProps extends TabProps, TranslationProps {
  labelI18nKey: StringKey;
}

interface TableCellWithTranslationProps
  extends TableCellProps,
    TranslationProps {
  i18nKey: StringKey;
}

interface TypographyWithTranslationProps
  extends TypographyProps,
    TranslationProps {
  component?: ElementType;
  i18nKey: StringKey;
}

interface WithTranslationRootProps {
  children: ReactNode;
  namespace: Namespace;
}

const TranslationRootContext = createContext<Namespace>('common');

function WithTranslationRoot({
  children,
  namespace,
}: WithTranslationRootProps) {
  return (
    <TranslationRootContext.Provider value={namespace}>
      {children}
    </TranslationRootContext.Provider>
  );
}

function useTranslationRoot(namespace?: Namespace) {
  const ctxNamespace = useContext(TranslationRootContext);
  const ns = namespace ?? ctxNamespace;
  const translation = useTranslation(ns);

  return {
    ...translation,
    localNamespace: ns,
  };
}

function TypographyWithTranslation({
  i18nKey,
  namespace,
  options,
  ...props
}: TypographyWithTranslationProps) {
  const { t } = useTranslationRoot(namespace);
  // @ts-ignore
  return <Typography {...props}>{t(i18nKey, options)}</Typography>;
}

function AlertTitleWithTranslation({
  i18nKey,
  namespace,
  ...props
}: AlertTitleWithTranslationProps) {
  const { t } = useTranslationRoot(namespace);
  return <AlertTitle {...props}>{t(i18nKey)}</AlertTitle>;
}

function ButtonWithTranslation({
  i18nKey,
  namespace,
  ...props
}: ButtonWithTranslationProps) {
  const ctxNamespace = useContext(TranslationRootContext);
  const ns = namespace ?? ctxNamespace;
  const { t } = useTranslation(ns);
  return <Button {...props}>{t(i18nKey)}</Button>;
}

function CardHeaderWithTranslation({
  titleI18nKey,
  namespace,
  ...props
}: CardHeaderWithTranslationProps) {
  const { t } = useTranslationRoot(namespace);
  return <CardHeader {...props} title={t(titleI18nKey)} />;
}

function DialogContentTextWithTranslation({
  i18nKey,
  namespace,
  options,
  ...props
}: DialogContentTextWithTranslationProps) {
  const { t } = useTranslationRoot(namespace);
  return (
    // @ts-ignore
    <DialogContentText {...props}>{t(i18nKey, options)}</DialogContentText>
  );
}

function DialogTitleWithTranslation({
  i18nKey,
  namespace,
  options,
  startAdornment,
  ...props
}: DialogTitleWithTranslationProps) {
  const { t } = useTranslationRoot(namespace);

  return (
    <DialogTitle {...props}>
      <>
        {startAdornment}
        {
          // @ts-ignore
          t(i18nKey, options)
        }
      </>
    </DialogTitle>
  );
}

function FormHelperTextWithTranslation({
  i18nKey,
  namespace,
  ...props
}: FormHelperTextWithTranslationProps) {
  const { t } = useTranslationRoot(namespace);
  return <FormHelperText {...props}>{t(i18nKey)}</FormHelperText>;
}

function InputLabelWithTranslation({
  i18nKey,
  namespace,
  ...props
}: InputLabelWithTranslationProps) {
  const { t } = useTranslationRoot(namespace);
  return <InputLabel {...props}>{t(i18nKey)}</InputLabel>;
}

function MenuItemWithTranslation({
  i18nKey,
  namespace,
  ...props
}: MenuItemWithTranslationProps) {
  const { t } = useTranslationRoot(namespace);
  return <MenuItem {...props}>{t(i18nKey)}</MenuItem>;
}

function LinkWithTranslation({
  i18nKey,
  namespace,
  ...props
}: LinkWithTranslationProps) {
  const { t } = useTranslationRoot(namespace);
  return <Link {...props}>{t(i18nKey)}</Link>;
}

function LoadingButtonWithTranslation({
  i18nKey,
  namespace,
  options,
  ...props
}: LoadingButtonWithTranslationProps) {
  const { t } = useTranslationRoot(namespace);
  // @ts-ignore
  return <LoadingButton {...props}>{t(i18nKey, options)}</LoadingButton>;
}

function StepLabelWithTranslation({
  i18nKey,
  namespace,
  ...props
}: StepLabelWithTranslationProps) {
  const { t } = useTranslationRoot(namespace);
  return <StepLabel {...props}>{t(i18nKey)}</StepLabel>;
}

function TabWithTranslation({
  labelI18nKey,
  namespace,
  ...props
}: TabWithTranslationProps) {
  const { t } = useTranslationRoot(namespace);
  return <Tab {...props} label={t(labelI18nKey)} />;
}

function TableCellWithTranslation({
  i18nKey,
  namespace,
  options,
  ...props
}: TableCellWithTranslationProps) {
  const { t } = useTranslationRoot(namespace);
  // @ts-ignore
  return <TableCell {...props}>{t(i18nKey, options)}</TableCell>;
}

const ChipWithTranslation = forwardRef(function ChipWithTranslation(
  { labelI18nKey, namespace, ...props }: ChipWithTranslationProps,
  ref: ForwardedRef<any>
) {
  const { t } = useTranslationRoot(namespace);
  return <Chip label={t(labelI18nKey)} {...props} ref={ref} />;
});

export {
  AlertTitleWithTranslation,
  ButtonWithTranslation,
  CardHeaderWithTranslation,
  ChipWithTranslation,
  DialogContentTextWithTranslation,
  DialogTitleWithTranslation,
  FormHelperTextWithTranslation,
  InputLabelWithTranslation,
  LinkWithTranslation,
  LoadingButtonWithTranslation,
  MenuItemWithTranslation,
  StepLabelWithTranslation,
  TabWithTranslation,
  TableCellWithTranslation,
  TypographyWithTranslation,
  WithTranslationRoot,
  useTranslationRoot,
};
