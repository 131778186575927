import type {
  ConfigApiResponse,
  ConfigReasonsApiResponse,
} from 'api/transformers/types/config';
import type { Config } from 'types/Config';
import { toCamelCase } from 'utils/string';

export function transformConfigApiResponse(response: ConfigApiResponse) {
  return Object.entries(response).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [toCamelCase(key)]: value,
    };
  }, {} as Config);
}

export function transformConfigReasonsApiResponse(
  response: ConfigReasonsApiResponse
): ConfigReasonsApiResponse {
  return response;
}
