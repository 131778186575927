import Link from '@mui/material/Link';

interface PageLinkProps {
  page: number;
  updatePage: (page: number) => void;
}

function PageLink({ page, updatePage }: PageLinkProps) {
  return (
    <Link onClick={() => updatePage(Number(page))} sx={{ cursor: 'pointer' }}>
      [{page}]
    </Link>
  );
}

export { PageLink };
