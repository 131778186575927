import { useEffect } from 'react';
import { fetchAuthSession, signInWithRedirect } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import LoadingButton from '@mui/lab/LoadingButton';
import { Page } from 'components/Page';
import { AppLogo } from 'components/AppLogo';
import { LOGIN_PAGE } from 'constants/translation-keys';
import { useTranslationRoot } from 'components/with-translation';

const RootStyle = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  paddingBottom: theme.spacing(10),
  paddingTop: theme.spacing(15),
}));

export default function Login() {
  const { t } = useTranslationRoot(LOGIN_PAGE);
  const navigate = useNavigate();

  // if the user has somehow managed to make it back to /login page,
  // check if they are already authenticated and redirect them to the home page
  useEffect(() => {
    fetchAuthSession().then(({ tokens }) => {
      if (tokens) {
        navigate('/');
      }
    });
  }, []); // eslint-disable-line

  return (
    <Page i18nKeyTitle="meta.title" sx={{ height: 1 }} namespace={LOGIN_PAGE}>
      <RootStyle>
        <Container>
          <Card
            sx={{
              margin: 'auto',
              maxWidth: 564,
              px: 5,
              py: 7,
              textAlign: 'center',
            }}
          >
            <AppLogo />
            <LoadingButton
              onClick={() => signInWithRedirect()}
              fullWidth
              size="large"
              type="button"
              variant="contained"
              sx={{ marginTop: 3 }}
              data-testid="login-button"
            >
              {t('loginForm.login')}
            </LoadingButton>
          </Card>
        </Container>
      </RootStyle>
    </Page>
  );
}
