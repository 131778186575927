import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import {
  TypographyWithTranslation,
  useTranslationRoot,
} from 'components/with-translation.tsx';
import {
  COPY_ICON,
  PASTE_ICON,
  PLUS_ICON,
  TRASH_ICON,
} from 'constants/public-icons.ts';

import type { LineItemRow } from './types.ts';

interface LineItemsHeaderProps {
  addLineBelow: (index: number) => () => void;
  deleteLineItems: (row: LineItemRow) => () => void;
  index: number;
  pasteToLineItems: (row: LineItemRow) => void;
  row: LineItemRow;
  rowsLength: number;
  writeToClipboardRef: (row: LineItemRow) => void;
}

function LineItemsHeader({
  addLineBelow,
  deleteLineItems,
  index,
  pasteToLineItems,
  row,
  rowsLength,
  writeToClipboardRef,
}: LineItemsHeaderProps) {
  const { t } = useTranslationRoot();

  return (
    <Grid size={{ xs: 12 }}>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          alignItems: 'center',
        }}
      >
        <TypographyWithTranslation
          fontSize={12}
          fontWeight="bold"
          i18nKey="enrichmentTool.lineNumber"
          options={{ number: index + 1 }}
        />
        {rowsLength > 1 ? (
          <>
            <Tooltip title={t('enrichmentTool.tooltipAdd')}>
              <IconButton
                size="small"
                color="primary"
                onClick={addLineBelow(row.lineIdx)}
                data-testid="add-line-item"
              >
                <SvgIconStyle src={PLUS_ICON} height={18} width={18} />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('enrichmentTool.tooltipDelete')}>
              <IconButton
                size="small"
                color="error"
                onClick={deleteLineItems(row)}
                data-testid="delete-line-item"
              >
                <SvgIconStyle src={TRASH_ICON} height={18} width={18} />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('enrichmentTool.tooltipCopy')}>
              <IconButton size="small" onClick={() => writeToClipboardRef(row)}>
                <SvgIconStyle src={COPY_ICON} height={18} width={18} />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('enrichmentTool.tooltipPaste')}>
              <IconButton size="small" onClick={() => pasteToLineItems(row)}>
                <SvgIconStyle src={PASTE_ICON} height={18} width={18} />
              </IconButton>
            </Tooltip>
          </>
        ) : null}
      </Stack>
    </Grid>
  );
}

export { LineItemsHeader };
