import { useFormContext } from 'react-hook-form';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { ControllerWithTextField } from 'components/form/ControllerWithTextField';
import { InputLabelWithTranslation } from 'components/with-translation';
import { generateUuid } from 'utils/generate-uuid';

import { TabularData } from './TabularData';

const InputLabelStyled = styled(InputLabelWithTranslation)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: bold;
`;

function ClaimForm() {
  const { control, setValue } = useFormContext();

  return (
    <Card>
      <Stack
        spacing={2}
        sx={{
          p: 3,
        }}
      >
        <Stack spacing={1}>
          <InputLabelStyled
            htmlFor="client_claim_id"
            i18nKey="form.clientClaimId"
          />
          <Stack direction="row" spacing={2}>
            <ControllerWithTextField
              name="client_claim_id"
              id="client_claim_id"
              required
            />
            <Button
              sx={{
                whiteSpace: 'nowrap',
                color: 'main',
              }}
              onClick={() => {
                setValue('client_claim_id', generateUuid());
              }}
            >
              Refresh ID
            </Button>
          </Stack>
        </Stack>
        <TabularData control={control} name="properties" />
        <TabularData control={control} name="metadata" />
      </Stack>
    </Card>
  );
}

export { ClaimForm };
