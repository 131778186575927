import { useState } from 'react';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import type { Claim, ClaimFeature } from 'types/Claims.ts';
import type { ClaimSummaryWidget } from 'types/Widgets.ts';
import { unique } from 'utils/array.ts';
import { isJsonString } from 'utils/string.ts';

import { getTimeline } from './utils.ts';
import { TimelineIcon } from './TimelineIcon.tsx';
import { TypeSelect } from './TypeSelect.tsx';

interface TimelineWidgetProps {
  claim: Claim;
  updatePage: (page: number) => void;
  widgets: ClaimSummaryWidget[];
}

function TimelineWidget({ claim, updatePage, widgets }: TimelineWidgetProps) {
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);
  const [type, setType] = useState<string>('all');

  let timelineValue = '';
  const timelineWidget = widgets.find(getTimeline());

  if (timelineWidget) {
    timelineValue = (
      claim.features?.fields[timelineWidget.feature] as ClaimFeature
    )?.value;
  }

  if (timelineValue === '') {
    return null;
  }

  const timeline = timelineValue
    ? isJsonString(timelineValue)
      ? JSON.parse(timelineValue)
      : []
    : [];

  const handleClick = (pageNumber: number, index: number) => {
    updatePage(pageNumber);
    setActiveIndex(index);
  };

  const handleSetType = (value: string) => {
    setType(value);
  };

  if (!timeline.length) {
    return (
      <Typography variant="body2" sx={{ p: 1.5 }}>
        No timeline data.
      </Typography>
    );
  }

  const eventTypes = unique(
    timeline.map(({ event_type }) => event_type)
  ) as string[];

  return (
    <>
      <Typography variant="subtitle2" sx={{ px: 1.5, py: 1 }}>
        Timeline
      </Typography>
      <TypeSelect onChange={handleSetType} options={eventTypes} value={type} />
      <ClickAwayListener onClickAway={() => setActiveIndex(undefined)}>
        <Timeline
          sx={{
            height: 'calc(100% - 71px - 40px - 64px)',
            overflowY: 'auto',
            px: 1.5,
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {timeline
            .filter(({ event_type }) => {
              if (type.toLowerCase() === 'all') {
                return true;
              }

              return type.toLowerCase() !== 'all' && event_type === type;
            })
            .map(
              (
                { description, event_type, start_date, page_number },
                index: number
              ) => (
                <TimelineItem
                  key={`${event_type}-${index}`}
                  sx={{ cursor: 'pointer', minHeight: 'initial' }}
                  onClick={() => handleClick(page_number, index)}
                >
                  <TimelineSeparator>
                    <TimelineDot
                      color={activeIndex === index ? 'primary' : undefined}
                      sx={{ marginY: 0.5 }}
                    >
                      <TimelineIcon icon={event_type} />
                    </TimelineDot>
                    <TimelineConnector
                      sx={{
                        bgcolor:
                          activeIndex === index ? 'primary.main' : undefined,
                      }}
                    />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        fontSize: 12,
                      }}
                    >
                      {start_date}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: 12,
                        overflow: 'hidden',
                      }}
                    >
                      {description}
                    </Typography>
                    <Typography
                      color="primary"
                      variant="body2"
                      sx={{
                        fontSize: 12,
                        overflow: 'hidden',
                      }}
                    >
                      Page: {page_number}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              )
            )}
        </Timeline>
      </ClickAwayListener>
    </>
  );
}

export { TimelineWidget };
