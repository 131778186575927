import { Canvas as CanvasType, Point } from 'fabric';

import { ZOOM_IN_THRESHOLD, ZOOM_OUT_THRESHOLD } from './utils.ts';

function useEventListeners({ useCtrlZoom }: { useCtrlZoom?: boolean }) {
  const init = (canvas: CanvasType) => {
    canvas.on('mouse:wheel', function (opt) {
      if (useCtrlZoom && !opt.e.ctrlKey) {
        return;
      }

      const delta = opt.e.deltaY;
      let zoom = canvas.getZoom();

      zoom *= 0.999 ** delta;

      if (zoom > ZOOM_IN_THRESHOLD) {
        zoom = ZOOM_IN_THRESHOLD;
      }

      if (zoom < ZOOM_OUT_THRESHOLD) {
        zoom = ZOOM_OUT_THRESHOLD;
      }

      canvas.zoomToPoint(
        new Point({
          x: opt.e.offsetX,
          y: opt.e.offsetY,
        }),
        zoom
      );

      opt.e.preventDefault();
      opt.e.stopPropagation();
    });

    // /*
    //  the following event listeners are to stop the default fabric selection box behaviour
    //  */
    // canvas.on('mouse:down', function (this, opt) {
    //   const event = opt.e as MouseEvent;
    //
    //   this.isDragging = true;
    //   this.selection = false;
    //   this.lastPosX = event.x;
    //   this.lastPosY = event.y;
    // });
    //
    // canvas.on('mouse:move', function (this, opt) {
    //   if (this.isDragging) {
    //     const event = opt.e as MouseEvent;
    //     const vpt = this.viewportTransform;
    //     vpt[4] += event.x - this.lastPosX;
    //     vpt[5] += event.y - this.lastPosY;
    //     this.requestRenderAll();
    //     this.lastPosX = event.x;
    //     this.lastPosY = event.y;
    //   }
    // });
    //
    // canvas.on('mouse:up', function (this) {
    //   // on mouse up we want to recalculate new interaction
    //   // for all objects, so we call setViewportTransform
    //   this.setViewportTransform(this.viewportTransform);
    //   this.isDragging = false;
    //   this.selection = true;
    // });
  };

  return { init };
}

export { useEventListeners };
