import { useMemo } from 'react';
import Stack from '@mui/material/Stack';
import { HiddenInput } from 'components/form/HiddenInput';
import { isHighLevelItem, isLineLevelItem } from 'state/selectors/documents';
import {
  TypographyWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';
import { HEADER } from 'theme/layout';

import type { FormItemsProps, Resources } from './types';
import { hasDocumentTypeItem, INVALID, VALID } from './utils';
import { InputFieldNew } from './InputFieldNew';

type GroupedLineItems = { [key: string]: string[] };

// styling
const BUTTON_GROUP_HEIGHT = 85; // submit button
const FILTER_BAR_HEIGHT = 72;
const CLAIM_PANEL_HEADER = 48;

// hardcoding these because AXA want these disabled and there's no way for backend to configure this
const DISABLED_ITEMS = ['document_group', 'document_provider'];

function getLabelTranslation(isLineLevel: boolean, isMultiPage: boolean) {
  if (isLineLevel) {
    if (isMultiPage) {
      return 'form.lineItemInputLabelWithPage';
    }

    return 'form.lineItemInputLabel';
  }

  return '';
}

function groupLineItems(resources: Resources) {
  return (cur: GroupedLineItems, id: string) => {
    const item = resources[id];
    let obj = { ...cur };

    if (isLineLevelItem(item)) {
      // we need to handle multipage
      const lineIdx = String(item.lineIdx || 0);
      const pageIdx = String(item.pageIdx || 0);
      const key = `${pageIdx}-${lineIdx}`;

      if (!obj[key]) {
        obj = {
          ...obj,
          [key]: [],
        };
      }

      obj[key].push(id);

      return obj;
    }

    return cur;
  };
}

function getValidValueForGroupLineItems(resources: Resources) {
  return (cur: { [key: string]: boolean }, arr: string[]) => {
    // we want it so that we either show the Skip Checkbox
    // for the whole line item or not
    let valid = false;

    if (arr.every((id) => resources[id].valid)) {
      valid = true;
    }

    return {
      ...cur,
      ...arr.reduce((a, v) => ({ ...a, [v]: valid }), {}),
    };
  };
}

function FormItems({
  deleteLineItem,
  filterItemsBy,
  fromClaimView,
  hasInvalidItems,
  hasValidItems,
  isCheck,
  isDocumentTypeValid,
  isMultipage,
  list,
  ocrHistoryResource,
  resources,
  setCrop,
  updateIsCheckList,
}: FormItemsProps) {
  const { t } = useTranslationRoot();
  const highLevelItems = list.filter((id) => isHighLevelItem(resources[id]));

  // specific items to hide from the form
  const documentTypeId = list.find(hasDocumentTypeItem(resources));

  const normalItems = list.filter((id) => {
    return (
      !isHighLevelItem(resources[id]) && !hasDocumentTypeItem(resources)(id)
    );
  });
  const hasItems = !!normalItems.length;
  const lineItemsValidDict: { [key: string]: boolean } = useMemo(() => {
    const ids = list.reduce(groupLineItems(resources), {});

    return Object.values(ids).reduce(
      getValidValueForGroupLineItems(resources),
      {}
    );
  }, [list, resources]);

  return (
    <>
      {highLevelItems.map((id) => (
        <HiddenInput key={id} name={id} defaultValue={resources[id].value} />
      ))}
      {documentTypeId && (
        <HiddenInput
          name={documentTypeId}
          defaultValue={resources[documentTypeId].value}
        />
      )}
      <Stack
        spacing={3}
        sx={{
          p: 2,

          maxHeight: ({ spacing }) =>
            `calc(100vh - ${
              parseInt(spacing(3)) * 2 +
              BUTTON_GROUP_HEIGHT +
              FILTER_BAR_HEIGHT +
              (fromClaimView
                ? CLAIM_PANEL_HEADER - parseInt(spacing(2))
                : HEADER.MOBILE_HEIGHT)
            }px)`,

          overflowY: 'auto',
          position: 'relative',
        }}
      >
        {filterItemsBy === INVALID && !hasInvalidItems ? (
          <TypographyWithTranslation i18nKey="form.noItems" variant="body1" />
        ) : null}
        {filterItemsBy === VALID && !hasValidItems ? (
          <TypographyWithTranslation i18nKey="form.noItems" variant="body1" />
        ) : null}
        {hasItems &&
          normalItems.map((id) => {
            const item = resources[id];
            const isLineLevel = isLineLevelItem(item);
            const label = item.clientKey;
            const labelTranslation = getLabelTranslation(
              isLineLevel,
              isMultipage
            );
            const valid =
              (typeof item.valid === 'boolean' && item.valid) ||
              item.valid === null;
            const invalid = typeof item.valid === 'boolean' && !item.valid;
            const isVisuallyHidden =
              (filterItemsBy === INVALID && valid) ||
              (filterItemsBy === VALID && invalid);

            return (
              <InputFieldNew
                key={id}
                {...item}
                deleteLineItem={deleteLineItem}
                disabled={DISABLED_ITEMS.includes(item.sproutaiKey)}
                name={id}
                label={
                  isLineLevel
                    ? t(labelTranslation, {
                        pageIdx: item.pageIdx! + 1,
                        lineIdx: item.lineIdx! + 1,
                        label,
                      })
                    : label
                }
                isCheck={isCheck}
                isDocumentTypeValid={isDocumentTypeValid}
                isVisuallyHidden={isVisuallyHidden}
                setCrop={setCrop}
                updateIsCheckList={updateIsCheckList}
                isValidLineItems={
                  (isLineLevel && !lineItemsValidDict[id]) ||
                  (typeof item.valid === 'boolean' && !item.valid)
                }
                ocrHistoryResource={ocrHistoryResource}
              />
            );
          })}
      </Stack>
    </>
  );
}

export { FormItems };
