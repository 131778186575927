import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { useTranslationRoot } from 'components/with-translation';
import { InfoIcon } from 'theme/overrides/CustomIcons';

import type { OcrValueProps } from './types';

function OcrValue({ title }: OcrValueProps) {
  const { t } = useTranslationRoot();

  return (
    <Tooltip title={t('form.ocrValue', { value: title })} placement="top" arrow>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          height: 20,
          justifyContent: 'center',
          width: 20,
        }}
      >
        <InfoIcon sx={{ color: 'grey.500', width: 20, height: 20 }} />
      </Box>
    </Tooltip>
  );
}

export { OcrValue };
