import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import type { FileCanvas } from './types.ts';
import { EditingTools } from './EditingTools.tsx';
import { Controls } from './Controls.tsx';
import { Navigation } from './Navigation.tsx';
import { SubmitButton } from './SubmitButton.tsx';
import { getControls } from './controls-utils.ts';
import { Settings } from './Settings.tsx';

interface HeaderProps {
  canvas: FileCanvas;
  disableSubmitButton: boolean;
  getCanvas: () => FileCanvas;
  handleBrightnessChange: (value: number | number[]) => void;
  handleSkewXChange: (value: number | number[]) => void;
  handleSkewYChange: (value: number | number[]) => void;
  handleNextPage: () => void;
  handlePrevPage: () => void;
  isSubmitting: boolean;
  mode: 'crop' | 'redact';
  onCrop: () => void;
  onSubmit: () => void;
  page: number;
  total: number;
}

function Header({
  canvas,
  disableSubmitButton,
  getCanvas,
  handleBrightnessChange,
  handleSkewXChange,
  handleSkewYChange,
  handleNextPage,
  handlePrevPage,
  isSubmitting,
  mode,
  onCrop,
  onSubmit,
  page,
  total,
}: HeaderProps) {
  const {
    resetZoomAndPan,
    rotateImageClockwise,
    rotateImageCounterClockwise,
    zoomIn,
    zoomOut,
  } = getControls(getCanvas);

  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: 'space-between',
        p: 2,
      }}
    >
      <Stack direction="row" spacing={1}></Stack>
      <Stack direction="row" spacing={1}>
        <EditingTools
          canvas={canvas}
          getCanvas={getCanvas}
          handleCrop={onCrop}
          mode={mode}
        />
        <Divider orientation="vertical" flexItem />
        <Controls
          resetZoomAndPan={resetZoomAndPan}
          rotateImageClockwise={rotateImageClockwise}
          rotateImageCounterClockwise={rotateImageCounterClockwise}
          zoomIn={zoomIn}
          zoomOut={zoomOut}
        />
        <Divider orientation="vertical" flexItem />
        <Settings
          canvas={canvas}
          handleBrightnessChange={handleBrightnessChange}
          handleSkewXChange={handleSkewXChange}
          handleSkewYChange={handleSkewYChange}
        />
        <Divider orientation="vertical" flexItem />
        <Navigation
          handlePrevPage={handlePrevPage}
          handleNextPage={handleNextPage}
          page={page}
          total={total}
        />
        <SubmitButton
          disabled={disableSubmitButton}
          isSubmitting={isSubmitting}
          onSubmit={onSubmit}
        />
      </Stack>
    </Stack>
  );
}

export { Header };
