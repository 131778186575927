import type { LinearProgressProps } from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

import { useTranslationRoot } from './with-translation.tsx';

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number; label: string | number }
) {
  const { t } = useTranslationRoot();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{
            color: 'text.secondary',
          }}
        >
          {typeof props.label === 'string'
            ? t(props.label)
            : `${Math.round(props.label)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export { LinearProgressWithLabel };
