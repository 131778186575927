import { useEffect, useState } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
import SwaggerUI from 'swagger-ui-react';
import api, { makeApiLink } from 'api/api';
import { LoadingIcon } from 'components/icons/LoadingIcon';
import { Page } from 'components/Page';
import { WithTranslationRoot } from 'components/with-translation';
import { DOCS_PAGE } from 'constants/translation-keys';
import { pipe } from 'utils/pipe';

let didInit = false;

function Docs() {
  const [spec, setSpec] = useState<unknown | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [cssLoaded, setCssLoaded] = useState(false);

  useEffect(() => {
    if (!didInit) {
      api
        .get(makeApiLink('docs'))
        .then((response) => {
          setLoading(false);
          setSpec(response);
        })
        .catch(() => {
          setLoading(false);
        });

      import('swagger-ui-react/swagger-ui.css').then(() => {
        setCssLoaded(true);
      });

      didInit = true;
    }
  }, []);

  if (loading || !cssLoaded) {
    return <LoadingIcon />;
  }

  if (!spec) {
    throw new Error('Failed to load API documentation');
  }

  const adjustApiUrl = (req: Request) => {
    if (import.meta.env.DEV) {
      const url = new URL(req.url);
      url.pathname = `/api${url.pathname}`;

      return {
        ...req,
        url: url.toString(),
      };
    }

    return req;
  };

  const addAuthorizationHeader = async (req: Request) => {
    const { accessToken } = (await fetchAuthSession()).tokens ?? {};

    if (accessToken) {
      return {
        ...req,
        headers: {
          ...req.headers,
          Authorization: `Bearer ${accessToken.toString()}`,
        },
      };
    }

    return req;
  };

  return (
    <WithTranslationRoot namespace={DOCS_PAGE}>
      <Page i18nKeyTitle="meta.title">
        <SwaggerUI
          spec={spec}
          queryConfigEnabled={true}
          // @ts-ignore
          requestInterceptor={pipe(adjustApiUrl, addAuthorizationHeader)}
        />
      </Page>
    </WithTranslationRoot>
  );
}

export default Docs;

// import { useEffect, useState } from 'react';
// import api, { makeApiLink } from 'api/api';
// import { LoadingIcon } from 'components/icons/LoadingIcon';
// import { asyncInitScript } from 'utils/async-init-script';
// function Docs() {
//   const [spec, setSpec] = useState(undefined);
//   const [loading, setLoading] = useState(true);
//
//   useEffect(() => {
//     api
//       .get(makeApiLink('docs'))
//       .then((response) => {
//         setLoading(false);
//         setSpec(response);
//       })
//       .catch(() => {
//         setLoading(false);
//       });
//   }, []);
//
//   if (loading) {
//     return <LoadingIcon />;
//   }
//
//   if (!spec) {
//     throw new Error('Failed to load API documentation');
//   }
//
//   ensureRedocScriptInit((isSuccess) => {
//     if (isSuccess) {
//       window.Redoc.init(
//         spec,
//         { expandResponses: '200' },
//         document.getElementById('redoc-container')
//       );
//     } else {
//       throw new Error('Failed to load Redoc script');
//     }
//   });
//
//   return <div id="redoc-container" />;
// }
//
// function initRedocScript() {
//   asyncInitScript({
//     id: 'redoc-script',
//     src: 'https://cdn.jsdelivr.net/npm/redoc/bundles/redoc.standalone.js'
//   });
// }
//
// function ensureRedocScriptInit(
//   callback: (isSuccess: boolean) => void,
//   maxRetries = 20
// ) {
//   initRedocScript();
//
//   let counter = 0;
//
//   const interval = setInterval(() => {
//     if (window.Redoc) {
//       clearInterval(interval);
//       callback(true);
//     } else if (counter >= maxRetries) {
//       clearInterval(interval);
//       callback(false);
//     }
//     counter++;
//   }, 100);
// }
//
// export default Docs;
