import { enUS } from '@mui/material/locale';
import en_US from 'rsuite/locales/en_US';

type Language = {
  colors: string[];
  label: string;
  rsuiteLocale: any;
  systemValue: any;
  value: string;
};

const languagesBase: Language[] = [
  {
    colors: ['#c9072a', '#001b69'],
    label: 'English',
    rsuiteLocale: en_US,
    systemValue: enUS,
    value: 'en',
  },
];

export type { Language };
export default languagesBase;
