import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Controller, useFormContext } from 'react-hook-form';
import { generateUuid } from 'utils/generate-uuid';

import { TabularData } from './TabularData';

function UploadData() {
  const { control, setValue } = useFormContext();

  return (
    <Box>
      <Typography
        variant="h5"
        sx={{
          mt: 4,
          mb: 2,
        }}
      >
        Upload Data
      </Typography>
      <Stack spacing={2}>
        <Stack direction="row" spacing={2}>
          <Controller
            control={control}
            name="claim_id"
            render={({ field }) => (
              <TextField
                sx={{
                  width: '100%',
                }}
                label="Claim ID"
                variant="outlined"
                {...field}
              />
            )}
            rules={{ required: false }}
          />

          <Button
            sx={{
              whiteSpace: 'nowrap',
              color: 'main',
            }}
            onClick={() => {
              setValue('claim_id', generateUuid());
            }}
          >
            Refresh ID
          </Button>
        </Stack>
        <Controller
          control={control}
          name="client_claim_id"
          render={({ field }) => (
            <TextField label="Client Claim ID" variant="outlined" {...field} />
          )}
        />
        <Controller
          control={control}
          name="client_document_type"
          render={({ field }) => (
            <TextField
              label="Client Document Type"
              variant="outlined"
              {...field}
            />
          )}
        />
        <TabularData name="metadata" />

        <Button
          variant="contained"
          type="submit"
          sx={{
            maxWidth: '160px',
            width: '100%',
            marginLeft: 'auto',
            marginRight: 0,
            alignSelf: 'flex-end',
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  );
}

export { UploadData };
