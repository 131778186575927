import {
  LookupCodesApiResponse,
  LookupMappingsApiResponse,
} from 'api/transformers/types/lookup';
import { CLAIM_LOOKUP, CLAIM_LOOKUP_MAPPING } from 'constants/api-endpoints';
import { useGet } from 'utils/react-query';
import {
  transformLookupCodesResponse,
  transformLookupMappingsResponse,
} from 'api/transformers/lookup';
import type { LookupCodes, LookupMappings } from 'types/Lookup';

export function useGetLookup({
  searchText,
  searchKey,
}: {
  searchText: string;
  searchKey: string;
}) {
  const params = {
    search_text: searchText,
    search_key: searchKey,
  };

  return useGet<LookupCodesApiResponse, LookupCodes>({
    url: CLAIM_LOOKUP,
    params,

    select: transformLookupCodesResponse,
    enabled: !!searchText,
  });
}

export function useGetLookupMapping({
  claimId,
  searchText,
  searchKey,
  businessUnit,
  code,
}: {
  claimId: string;
  searchText: string;
  searchKey: string;
  businessUnit?: string;
  code?: string;
}) {
  const params = {
    claim_id: claimId,
    search_text: searchText,
    search_key: searchKey,
    business_unit: businessUnit ?? '',
    code: code ?? '',
  };

  function generateUrl() {
    /* Adding this specific rule for AdvanceCare line items search.
        Hardcoding temporarily for clarity until a more dynamic solution is implemented.
      */
    let url = CLAIM_LOOKUP_MAPPING;

    if (code) {
      url += `&code=${code}`;
    }

    if (businessUnit) {
      url += `&business_unit=${businessUnit}`;
    }

    return url;
  }

  return useGet<LookupMappingsApiResponse, LookupMappings>({
    url: generateUrl(),
    params,

    select: transformLookupMappingsResponse,
    enabled: !!searchText,
  });
}
