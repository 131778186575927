import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { AVATAR_ICON } from 'constants/public-icons';
import { IconButtonAnimate } from 'components/IconButtonAnimate';

interface OperatorProps {
  lastUpdatedBy: string | null;
}

function Operator({ lastUpdatedBy }: OperatorProps) {
  if (lastUpdatedBy) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Tooltip title={lastUpdatedBy}>
          <IconButtonAnimate isOpen={false}>
            <SvgIconStyle src={AVATAR_ICON} />
          </IconButtonAnimate>
        </Tooltip>
      </Box>
    );
  }

  return null;
}

export { Operator };
