import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import type { Claim } from 'types/Claims';
import type { Document } from 'types/Documents';
import { auditEventTranslation } from 'constants/claims';
import { TypographyWithTranslation } from 'components/with-translation';
import { useGetClaimDocuments } from 'state/queries/claims';
import { getIsDocumentAwaitingReview } from 'state/selectors/documents';
import { formatDocumentDate } from 'utils/date';
import { convertArrayToObject } from 'utils/array';

import type { FilesMap } from './types';
import { AttachmentV2 } from './AttachmentV2';

interface ClaimHistoryProps {
  claim: Claim;
  filesMap: FilesMap;
}

function ClaimHistoryV2({ claim, filesMap }: ClaimHistoryProps) {
  const { auditLog, id } = claim;
  const { data: documents, isSuccess } = useGetClaimDocuments(id);
  let documentsMap: { [key: string]: Document } = {};

  if (isSuccess) {
    documentsMap = convertArrayToObject(documents, 'id');
  }

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.missingOppositeContent}:before`]: {
          display: 'none',
        },
        padding: 0,
      }}
    >
      {auditLog?.map(({ createdOn, content, eventName }, index) => {
        const hasFileIds = Object.prototype.hasOwnProperty.call(
          content,
          'fileIds'
        );
        const hasFileId = Object.prototype.hasOwnProperty.call(
          content,
          'fileId'
        );
        const hasDocumentId = Object.prototype.hasOwnProperty.call(
          content,
          'documentId'
        );

        return (
          <TimelineItem key={index}>
            <TimelineSeparator>
              <TimelineDot color="primary" />
              {auditLog.length - 1 === index ? null : <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <TypographyWithTranslation
                variant="subtitle2"
                i18nKey={auditEventTranslation[eventName]}
              />
              <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                {formatDocumentDate(createdOn)}
              </Typography>
              <Box>
                {hasDocumentId ? (
                  <AttachmentV2
                    claimId={id}
                    documentId={content.documentId}
                    documentsMap={documentsMap}
                    hasHilStatus={
                      !!(
                        content.documentId &&
                        documentsMap[content.documentId] &&
                        getIsDocumentAwaitingReview(
                          documentsMap[content.documentId]
                        )
                      )
                    }
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 'bold',
                        color: ({ palette }) => palette.text.primary,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {content.documentId}
                    </Typography>
                  </AttachmentV2>
                ) : null}
                {hasFileId && filesMap[content.fileId!] ? (
                  <AttachmentV2 claimId={id}>
                    <Typography
                      variant="body2"
                      sx={{
                        color: ({ palette }) => palette.text.secondary,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {filesMap[content.fileId!].originalFileName}
                    </Typography>
                  </AttachmentV2>
                ) : null}
                {hasFileIds
                  ? content.fileIds!.map((fileId) => {
                      if (filesMap[content.fileId!]) {
                        return (
                          <AttachmentV2 key={fileId} claimId={id}>
                            <Typography
                              variant="body2"
                              sx={{
                                color: ({ palette }) => palette.text.secondary,
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {filesMap[content.fileId!].originalFileName}
                            </Typography>
                          </AttachmentV2>
                        );
                      }

                      return null;
                    })
                  : null}
              </Box>
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
}

export { ClaimHistoryV2 };
