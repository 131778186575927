import { useState } from 'react';

interface UseCarouselProps {
  noOfImages: number;
}

function wrap(min: number, max: number, v: number) {
  const rangeSize = max - min;
  return ((((v - min) % rangeSize) + rangeSize) % rangeSize) + min;
}

function useCarousel({ noOfImages }: UseCarouselProps) {
  const [containerHeight, setContainerHeight] = useState<number>();
  const [[page, forward], setCarouselState] = useState([0, 0]);

  function setHeight(clientHeight: number) {
    setContainerHeight(clientHeight);
  }

  function nextSlide() {
    paginate(1);
  }

  function previousSlide() {
    paginate(-1);
  }

  function paginate(newDirection: number) {
    setCarouselState([page + newDirection, newDirection]);
  }

  function setCarouselPage(newPage: number) {
    setCarouselState([newPage, forward]);
  }

  return {
    containerHeight,
    page: wrap(0, noOfImages, page),
    forward,
    setCarouselState,
    nextSlide,
    previousSlide,
    setHeight,
    setCarouselPage,
  };
}

export { useCarousel };
