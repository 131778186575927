export const RESOURCE_ID = 'resourceId';
export const ERROR_KEY = 'error';
export const PAGE_ID = 'pageId';
export const DOCUMENT_ID = 'documentId';

// ROUTE PARAMS
export const DOCUMENT_TYPE = 'documentType';
export const STAGE = 'stage';
export const ORDER = 'order';
export const QUERY = 'q';
export const FILTERS = 'filters';
export const SORT = 'sort';
export const PAGE = 'page';
export const ROWS_PER_PAGE = 'rowsPerPage';
export const SEARCH_FIELD = 'searchField';
