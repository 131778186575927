import type { Claim } from 'types/Claims';

import type { FilesMap } from './types';
import { ClaimHistoryV2 } from './ClaimHistoryV2';

interface AuditLogProps {
  claim: Claim;
  filesMap: FilesMap;
}

function AuditLogV2(props: AuditLogProps) {
  if (props.claim.auditLog.length) {
    return <ClaimHistoryV2 {...props} />;
  }

  return null;
}

export { AuditLogV2 };
