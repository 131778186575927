import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import type { FeedbackFormValues } from 'pages/Claim/types';
import {
  CardHeaderWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';
import { toast } from 'components/toast';
import { FeedbackForm } from 'pages/Claim/FeedbackForm';
import { postClaimFeedback } from 'state/queries/claims';

interface FeedbackProps {
  claimId: string;
}

function FeedbackV2({ claimId }: FeedbackProps) {
  const { t } = useTranslationRoot();
  function handleSubmit(values: FeedbackFormValues) {
    const { reason, description } = values;
    toast.promise(postClaimFeedback(claimId, reason, description), {
      loading: t('feedback.submitFeedbackLoading'),
      success: t('feedback.submitFeedbackSuccess'),
      error: (error) =>
        t('feedback.submitFeedbackFail', { errorMsg: error.detail }),
    });
  }

  return (
    <Card>
      <CardHeaderWithTranslation titleI18nKey="feedback.title" />
      <CardContent>
        <FeedbackForm onSubmit={handleSubmit} />
      </CardContent>
    </Card>
  );
}

export { FeedbackV2 };
