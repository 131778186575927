import type { MouseEvent, ReactNode } from 'react';
import type { IconButtonProps } from '@mui/material/IconButton';
import { styled, SxProps, Theme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { ARROW_DOWNWARD_ICON } from 'constants/public-icons.ts';

import {
  CardHeaderWithTranslation,
  useTranslationRoot,
} from './with-translation.tsx';
import { useBoolean } from './customHooks/useBoolean.ts';
import { SvgIconStyle } from './SvgIconStyle.tsx';

interface CollapsibleCardProps {
  children: ReactNode;
  initialExpanded?: boolean;
  sx?: SxProps<Theme>;
  titleI18nKey: string;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function CollapsibleCard({
  children,
  initialExpanded = true,
  titleI18nKey,
}: CollapsibleCardProps) {
  const { t } = useTranslationRoot();
  const [expanded, , , toggle] = useBoolean(initialExpanded);

  const handleExpandClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    toggle();
  };

  return (
    <Card>
      <CardHeaderWithTranslation
        titleI18nKey={titleI18nKey}
        action={
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label={t('common.showMore') as string}
          >
            <SvgIconStyle src={ARROW_DOWNWARD_ICON} />
          </ExpandMore>
        }
        sx={{
          cursor: 'pointer',
          pb: 3,
        }}
        onClick={handleExpandClick}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </Card>
  );
}

export { CollapsibleCard };
