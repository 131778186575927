import { useMemo } from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  TypographyWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';
import { toTitleCase } from 'utils/string';

import type { List, Resources } from './types';
import { getGroupedLineItems, initialiseItems } from './utils';
import { AccordionContainer } from './AccordionContainer';

interface BaseProps {
  list: List;
  isMultipage: boolean;
  resources: Resources;
}

type ItemsContentProps = BaseProps;

interface DocumentItemsProps extends BaseProps {
  heading: string;
}

function DocumentItems({
  heading,
  isMultipage,
  list,
  resources,
}: DocumentItemsProps) {
  return (
    <AccordionContainer
      childrenSpacing={2}
      defaultExpanded={true}
      heading={heading}
    >
      {list.map((id) => {
        const item = resources[id];

        return (
          <Stack
            key={id}
            sx={{
              gap: 0,
            }}
          >
            <TypographyWithTranslation
              variant="caption"
              fontSize={14}
              color="grey.500"
              i18nKey={
                isMultipage ? 'common.clientKeyWithPage' : 'common.clientKey'
              }
              options={{
                number: (item?.pageIdx || 0) + 1,
                clientKey: item.clientKey,
              }}
            />
            {item.value ? (
              <Typography variant="body1">{item.value}</Typography>
            ) : (
              <Typography variant="body1" sx={{ height: 24 }} />
            )}
          </Stack>
        );
      })}
    </AccordionContainer>
  );
}

function ItemDetails({ list, resources }: BaseProps) {
  return (
    <Stack
      direction="row"
      sx={{
        alignItems: 'center',
        flexWrap: 'wrap',
        pt: 2,
      }}
    >
      {list.map((id) => {
        const { clientKey, value } = resources[id];

        return (
          <Stack
            direction="row"
            key={id}
            spacing={0.5}
            sx={{
              ml: 1,
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontSize: 14, fontWeight: 'bold' }}
            >
              {toTitleCase(clientKey)}:
            </Typography>
            <Typography variant="body1" sx={{ fontSize: 14 }}>
              {value}
            </Typography>
          </Stack>
        );
      })}
    </Stack>
  );
}

function LineItems({
  heading,
  list,
  isMultipage,
  resources,
}: DocumentItemsProps) {
  const groupedLineItems = getGroupedLineItems(list, resources);

  return (
    <AccordionContainer
      childrenSpacing={2}
      defaultExpanded={true}
      heading={heading}
    >
      {Object.values(groupedLineItems).map((list, index) => {
        const lineIdx = resources[list[0]].lineIdx! + 1;
        const pageIdx = resources[list[0]].pageIdx! + 1;

        return (
          <Stack
            key={index}
            direction="row"
            spacing={2}
            sx={{
              alignItems: 'center',
            }}
          >
            <Stack
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                pt: 2,
              }}
            >
              <Box
                sx={{
                  backgroundColor: 'grey.300',
                  borderRadius: '50%',
                  display: 'block',
                  height: 20,
                  textAlign: 'center',
                  width: 20,
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: 12,
                    color: 'grey.500',
                    fontWeight: 'bold',
                    lineHeight: '20px',
                  }}
                >
                  {lineIdx}
                </Typography>
              </Box>
            </Stack>
            {!isMultipage ? (
              <Stack
                sx={{
                  justifyItems: 'center',
                  pt: 2,
                }}
              >
                <TypographyWithTranslation
                  i18nKey="common.pageNumber"
                  variant="body2"
                  options={{ number: pageIdx }}
                  sx={{ whiteSpace: 'nowrap' }}
                />
              </Stack>
            ) : null}
            <Box sx={{ flexGrow: 1 }}>
              <Box
                sx={{
                  borderTop: index > 0 ? 1 : 0,
                  borderColor: 'grey.300',
                }}
              >
                <ItemDetails
                  list={list}
                  isMultipage={isMultipage}
                  resources={resources}
                />
              </Box>
            </Box>
          </Stack>
        );
      })}
    </AccordionContainer>
  );
}

function ItemsContent({ list, isMultipage, resources }: ItemsContentProps) {
  const { t } = useTranslationRoot();
  const {
    unidentifiedItems,
    unidentifiedLineItems,
    identifiedItems,
    identifiedLineItems,
  } = useMemo(() => initialiseItems(list, resources), [list, resources]);

  if (list.length) {
    return (
      <Stack
        sx={{
          height: 1,
          overflowY: 'auto',
        }}
      >
        {unidentifiedItems.length ? (
          <DocumentItems
            heading={t('form.unidentifiedItems')}
            list={unidentifiedItems}
            resources={resources}
            isMultipage={isMultipage}
          />
        ) : null}
        {unidentifiedLineItems.length ? (
          <LineItems
            heading={t('form.unidentifiedLineItems')}
            list={unidentifiedLineItems}
            resources={resources}
            isMultipage={isMultipage}
          />
        ) : null}
        {identifiedItems.length ? (
          <DocumentItems
            heading={t('form.identifiedItems')}
            list={identifiedItems}
            resources={resources}
            isMultipage={isMultipage}
          />
        ) : null}
        {identifiedLineItems.length ? (
          <LineItems
            heading={t('form.identifiedLineItems')}
            list={identifiedLineItems}
            resources={resources}
            isMultipage={isMultipage}
          />
        ) : null}
      </Stack>
    );
  }

  return (
    <Alert severity="error" sx={{ m: 2 }}>
      <TypographyWithTranslation i18nKey="form.noItems" variant="body1" />
    </Alert>
  );
}

export { ItemsContent };
