import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  CardHeaderWithTranslation,
  TypographyWithTranslation,
} from 'components/with-translation';
import { round } from 'utils/numbers';

import type { Resources } from './types';
import { getMetric } from './utils';

interface HumanInTheLoopProps {
  list: string[];
  mainList: string[];
  isSuccess: boolean;
  resources: Resources;
}

const LIST = [
  {
    id: 'AvgOverallProcessingTime',
    text: 'humanInTheLoop.avgOverallProcessingTime',
  },
];

const MAIN_LIST = [
  {
    id: 'SumAvgDocumentsInHil',
    text: 'humanInTheLoop.sumAvgDocumentsInHIL',
  },
  {
    id: 'SumDocumentsSentToHil',
    text: 'humanInTheLoop.sumDocumentsSentToHIL',
  },
  {
    id: 'SumDocumentsFromHilToDone',
    text: 'humanInTheLoop.sumDocumentsFromHILToDone',
  },
];

// TODO: there's embedded list.length to temporarily hide data for now for Metlife
function HumanInTheLoop({ list, resources, isSuccess }: HumanInTheLoopProps) {
  return (
    <Card>
      <CardHeaderWithTranslation
        titleI18nKey="humanInTheLoop.heading"
        subheader={
          <Stack
            direction="row"
            sx={{
              justifyContent: 'space-between',
            }}
          >
            {list.length ? (
              <>
                <TypographyWithTranslation
                  color="grey.600"
                  variant="subtitle2"
                  i18nKey="humanInTheLoop.avgProcessingTime"
                />
                {list.length ? (
                  LIST.map(({ id }) => {
                    const { unit, value } = resources[id];
                    const { options, translation } = getMetric(
                      unit,
                      Number(value)
                    );

                    return (
                      <TypographyWithTranslation
                        key={id}
                        color="text.primary"
                        variant="subtitle2"
                        fontWeight="bold"
                        i18nKey={translation}
                        options={options}
                      />
                    );
                  })
                ) : isSuccess ? (
                  <TypographyWithTranslation
                    color="text.primary"
                    variant="subtitle2"
                    fontWeight="bold"
                    i18nKey="common.noData"
                  />
                ) : (
                  <Skeleton variant="rectangular" width={100} height={22} />
                )}
              </>
            ) : null}
          </Stack>
        }
        sx={{
          borderBottom: '1px solid',
          borderColor: 'divider',
          padding: 3,
        }}
      />
      <CardContent>
        {MAIN_LIST.map(({ id, text }) => {
          const item = resources[id];

          // TODO: should render markup if there's no item, but not now for Metlife
          if (item) {
            return (
              <Stack
                key={text}
                direction="row"
                sx={{
                  justifyContent: 'space-between',

                  '&:not(:first-of-type)': {
                    pt: 2,
                  },
                }}
              >
                <TypographyWithTranslation
                  color="grey.600"
                  variant="body2"
                  i18nKey={text}
                />
                {item ? (
                  <Typography variant="body2">
                    {round(Number(item.value))}
                  </Typography>
                ) : isSuccess ? (
                  <TypographyWithTranslation
                    variant="body2"
                    i18nKey="common.noData"
                  />
                ) : (
                  <Skeleton variant="rectangular" width={100} height={22} />
                )}
              </Stack>
            );
          }

          return null;
        })}
      </CardContent>
    </Card>
  );
}

export { HumanInTheLoop };
