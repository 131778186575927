import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  ButtonWithTranslation,
  TypographyWithTranslation,
  WithTranslationRoot,
} from 'components/with-translation';
import { COMMON } from 'constants/translation-keys';

interface GenericErrorFallbackProps {
  error: Error | null;
  resetError: () => void;
}

function GenericErrorFallback({
  error,
  resetError,
}: GenericErrorFallbackProps) {
  return (
    <WithTranslationRoot namespace={COMMON}>
      <Box role="alert" sx={{ p: 2 }}>
        <TypographyWithTranslation i18nKey="error" variant="body1" />
        <Typography component="pre" variant="body1">
          {error?.message}
        </Typography>
        <ButtonWithTranslation
          onClick={resetError}
          i18nKey="tryAgainButton"
          variant="contained"
          sx={{ mt: 2 }}
        />
      </Box>
    </WithTranslationRoot>
  );
}

export { GenericErrorFallback };
