import { useEffect } from 'react';
import { DialogModal } from 'components/dialog/Modal/DialogModal';
import { cancelButton, header, text } from 'components/dialog/generators';
import { WARNING_ICON } from 'constants/public-icons';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { useBoolean } from 'components/customHooks/useBoolean';

interface WarningDialogProps {
  email?: string;
  lastLockedBy?: string | null;
  locked?: boolean;
}

function WarningDialog({ email, lastLockedBy, locked }: WarningDialogProps) {
  const [isOpen, openModal, closeModal] = useBoolean();

  useEffect(function showLockedModal() {
    const isNotLockedByCurrentUser = locked && lastLockedBy !== email;

    if (isNotLockedByCurrentUser) {
      openModal();
    }
  }, []); // eslint-disable-line

  return (
    <DialogModal
      getDialog={{
        index() {
          return [
            header('warningModal.title', {
              i18nOptions: { email: lastLockedBy },
              startAdornment: (
                <SvgIconStyle
                  color="warning.main"
                  src={WARNING_ICON}
                  sx={{ mr: 1 }}
                />
              ),
            }),
            text('warningModal.message'),
            cancelButton({
              action: closeModal,
              icon: null,
              label: 'warningModal.closeButton',
            }),
          ];
        },
      }}
      handleClose={closeModal}
      isOpen={isOpen}
    />
  );
}

export { WarningDialog };
