import type { ReactNode } from 'react';
import { generateUuid } from 'utils/generate-uuid';

import { resolveValue } from './utils';
import { ActionType, dispatch } from './store';
import type {
  DefaultToastOptions,
  Toast,
  ToastOptions,
  ToastType,
  ValueOrFunction,
} from './types';

type Message = ReactNode;

function createToast(
  message: Message,
  type: ToastType = 'blank',
  opts?: ToastOptions
): Toast {
  return {
    ariaProps: {
      'aria-live': 'polite',
      role: 'status',
    },
    createdAt: Date.now(),
    id: generateUuid(),
    message,
    pauseDuration: 0,
    type,
    ...opts,
    visible: true,
  };
}

function createHandler(type?: ToastType) {
  return function handler(message: Message, opts?: ToastOptions) {
    const toast = createToast(message, type, opts);
    dispatch({ type: ActionType.UPSERT_TOAST, toast });

    return toast.id;
  };
}

const toast = (message: Message) => createHandler('blank')(message);
toast.error = createHandler('error');
toast.warning = createHandler('warning');
toast.success = createHandler('success');
toast.loading = createHandler('loading');
toast.custom = createHandler('custom');
toast.dismiss = (id?: string) =>
  dispatch({
    type: ActionType.DISMISS_TOAST,
    id,
  });
toast.remove = (id?: string) => dispatch({ type: ActionType.REMOVE_TOAST, id });

toast.promise = <T>(
  promise: Promise<T>,
  msgs: {
    loading: ReactNode;
    success: ValueOrFunction<ReactNode, T>;
    error: ValueOrFunction<ReactNode, any>;
  },
  opts?: DefaultToastOptions
) => {
  const id = toast.loading(msgs.loading, { ...opts, ...opts?.loading });

  promise
    .then((p) => {
      toast.success(resolveValue(msgs.success, p), {
        id,
        ...opts,
        ...opts?.success,
      });
      return p;
    })
    .catch((e) => {
      toast.error(resolveValue(msgs.error, e), {
        id,
        ...opts,
        ...opts?.error,
      });
    });

  return promise;
};

export { toast };
