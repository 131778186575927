import type { CSSProperties } from 'react';
import Box from '@mui/material/Box';

import type { ToastPosition } from './types';
import { useToast } from './use-toast';
import { ToastContent } from './ToastContent';
import { createRectRef } from './utils';

function getPositionStyle(
  position: ToastPosition,
  yOffset: number
): CSSProperties {
  const top = position.includes('top');
  const verticalStyle: CSSProperties = top ? { top: 0 } : { bottom: 0 };
  const horizontalStyle: CSSProperties = position.includes('center')
    ? {
        justifyContent: 'center',
      }
    : position.includes('right')
      ? {
          justifyContent: 'flex-end',
        }
      : {};

  return {
    transform: `translateY(${yOffset * (top ? 1 : -1)}px)`,
    transition: `all 230ms cubic-bezier(.21,1.02,.73,1)`,
    ...verticalStyle,
    ...horizontalStyle,
  };
}

interface ToasterProps {
  position?: ToastPosition;
}

export function Toaster({ position = 'top-center' }: ToasterProps) {
  const { handlers, toasts } = useToast();

  return (
    <Box
      sx={{
        bottom: ({ spacing }) => spacing(2),
        display: 'flex',
        flexDirection: 'column',
        left: ({ spacing }) => spacing(2),
        pointerEvents: 'none',
        position: 'fixed',
        right: ({ spacing }) => spacing(2),
        top: ({ spacing }) => spacing(2),
        zIndex: 'snackbar',
      }}
      onMouseEnter={handlers.startPause}
      onMouseLeave={handlers.endPause}
    >
      {toasts.map((toast) => {
        const toastPosition = toast.position || position;
        const yOffset = handlers.calculateOffset(toast, {
          defaultPosition: position,
        });
        const positionStyle = getPositionStyle(toastPosition, yOffset);
        const ref = toast.height
          ? undefined
          : createRectRef((rect) =>
              handlers.updateHeight(toast.id, rect.height)
            );

        return (
          <Box
            key={toast.id}
            ref={ref}
            style={positionStyle}
            sx={{
              display: 'flex',
              left: 0,
              position: 'absolute',
              right: 0,
              zIndex: toast.visible ? 'snackbar' : undefined,
              '& > *': {
                pointerEvents: toast.visible ? 'auto' : 'inherit',
              },
              ...positionStyle,
            }}
          >
            <ToastContent toast={toast} position={toastPosition} />
          </Box>
        );
      })}
    </Box>
  );
}
