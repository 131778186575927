import { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {
  ButtonWithTranslation,
  DialogContentTextWithTranslation,
  DialogTitleWithTranslation,
  WithTranslationRoot,
} from 'components/with-translation';
import { COMMON } from 'constants/translation-keys';
import { useBoolean } from 'components/customHooks/useBoolean';
import { getSessionTimestamp, logout } from 'pages/Site/logout';
import { generalConfig } from 'config';
// import loginRoute from 'pages/Login/login.route';

// const TIMEOUT_BUFFER = 10; // minutes

function AuthTimeoutWarning() {
  const [isOpen, openModal] = useBoolean();

  useEffect(() => {
    const [loginTime] = getSessionTimestamp();
    const currentTime = Date.now();
    const timeDifference = currentTime - (loginTime ?? 0);
    let delay = generalConfig.sessionExpirationWarningDelay - timeDifference;

    if (delay < 0) {
      delay = 0; // If the delay is negative, set it to 0
    }

    let timeoutId: NodeJS.Timeout | null = null;

    if (delay) {
      timeoutId = setTimeout(openModal, delay);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [openModal]);

  async function handleLogout() {
    await logout();
  }

  function handleClose(_event: Event, reason: string) {
    if (reason && reason === 'backdropClick') {
      return;
    }
  }

  return (
    <WithTranslationRoot namespace={COMMON}>
      <Dialog
        disableEscapeKeyDown
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="auth-timeout-dialog-title"
      >
        <DialogTitleWithTranslation
          id="auth-timeout-dialog-title"
          i18nKey="authTimeoutDialog.title"
        />
        <DialogContent>
          <DialogContentTextWithTranslation
            id="auth-timeout-dialog-description"
            i18nKey="authTimeoutDialog.description"
            sx={{
              pb: 5,
              pt: 2,
            }}
          />
        </DialogContent>
        <DialogActions
          sx={{ borderTop: '1px solid', borderTopColor: 'grey.50024' }}
        >
          <ButtonWithTranslation
            variant="contained"
            color="primary"
            i18nKey="authTimeoutDialog.loginButton"
            onClick={handleLogout}
          />
        </DialogActions>
      </Dialog>
    </WithTranslationRoot>
  );
}

export { AuthTimeoutWarning };
