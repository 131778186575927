import type {
  FiltersClaimsApiResponse,
  FiltersDocumentsApiResponse,
} from 'api/transformers/types/filters';
import type { FiltersClaims, FiltersDocuments } from 'types/Filters';
import { transformKeys } from 'utils/object';
import { toCamelCase } from 'utils/string';

export function transformFiltersClaimsResponse({
  filters,
}: FiltersClaimsApiResponse): FiltersClaims[] {
  return transformKeys(filters, toCamelCase);
}

export function transformFiltersDocumentsResponse({
  filters,
}: FiltersDocumentsApiResponse): FiltersDocuments[] {
  return transformKeys(filters, toCamelCase);
}
