import type { MouseEvent, ReactNode } from 'react';
import { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Collapse from '@mui/material/Collapse';
import Grow from '@mui/material/Grow';
import List from '@mui/material/List';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import type { CountPerStage } from 'types/Documents';
import {
  COMPLETED,
  CLOSED,
  ERROR,
  AWAITING_REVIEW,
  PROCESSING,
  REJECTED,
  REVIEW_IN_PROGRESS,
} from 'constants/document-stage';
import {
  AWAITING_ENRICHMENT as CLAIMS_AWAITING_ENRICHMENT,
  AWAITING_INFO as CLAIMS_AWAITING_INFO,
  AWAITING_OCR_RESULTS as CLAIMS_AWAITING_OCR_RESULTS,
  AWAITING_REVIEW as CLAIMS_AWAITING_REVIEW,
  CLOSED as CLAIMS_CLOSED,
  COMPLETED as CLAIMS_COMPLETED,
  ERROR as CLAIMS_ERROR,
  PROCESSING as CLAIMS_PROCESSING,
  REJECTED as CLAIMS_REJECTED,
} from 'constants/claims';
import { LoadingIcon } from 'components/icons/LoadingIcon';
import { LinkWithRef } from 'components/Link';
import {
  TypographyWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { DASHBOARD_PAGE } from 'constants/translation-keys';
import { ARROW_FORWARD_ICON } from 'constants/public-icons';
import claimsRoute from 'pages/Claims/claims.route';
import configurationRoute from 'pages/Configuration/configuration.route';
import fileUploadRoute from 'pages/FileUpload/file-upload.route.tsx';
import splitsRoute from 'pages/Splits/splits.route.tsx';
import classificationRoute from 'pages/Classification/classification.route.tsx';
import redactionsRoute from 'pages/Redactions/redactions.route.tsx';
import hitlRoute from 'pages/HitL/hitl.route';
import performanceRoute from 'pages/Performance/performance.route';
import performanceManageUsersRoute from 'pages/PerformanceManageUsers/performanceManageUsers.route';
import featureFlagsRoute from 'pages/FeatureFlags/featureFlags.route';
import createClaimRoute from 'pages/CreateClaim/createClaim.route';
import docsRoute from 'pages/Docs/docs.route';
import uploadDocumentRoute from 'pages/UploadDocument/uploadDocument.route';
import { useCurrentUserRole } from 'state/queries/current-user';
import { useGetDocumentsStagesCount } from 'state/queries/documents';
import { Counter } from 'components/Counter';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag';
import { PAGE, STAGE } from 'constants/route-keys';
import ROUTE_PERMISSIONS from 'constants/route-permissions';
import { CLAIM_REVIEW } from 'constants/roles';
import { ErrorIcon } from 'theme/overrides/CustomIcons';
import { convertArrayToObject } from 'utils/array';
import { pipe } from 'utils/pipe';

import { NavItemRoot, NavItemSub } from './NavItem';
import { ListSubheaderStyle, MiniNavItem } from './style';

interface NavListProps {
  isMiniNavbar?: boolean;
  removeDocuments: boolean;
  removeDocumentsClosedStage: boolean;
}

type BaseNav = {
  path: string;
  permission: string[];
  shortTitle: string;
  title: string;
  counter?: number;
  onClick?: VoidFunction;
};

export interface NavListChildrenProps extends BaseNav {
  children?: BaseNav[];
  endComponent?: (...args: any) => ReactNode;
  icon?: string;
}

interface NavListRootProps {
  list: NavListChildrenProps;

  [k: string]: any;
}

interface NavListSubProps {
  active: boolean;
  list: NavListChildrenProps;
}

function getActive(path: string, pathname: string, end = false) {
  return path ? !!matchPath({ path, end }, pathname) : false;
}

// get active based on STAGE search param
function getSearchParamActive(
  isActive: boolean,
  routePath: string,
  currentPath: string
) {
  if (isActive) {
    const current = new URL(`${window.location.origin}${currentPath}`);
    const route = new URL(`${window.location.origin}${routePath}`);
    const currentPathname = current.pathname;
    const routePathname = route.pathname;
    const currentStage = current.searchParams.get(STAGE);
    const routeStage = route.searchParams.get(STAGE);

    return currentPathname === routePathname && currentStage === routeStage;
  }

  return false;
}

function NavListSub({ active, list }: NavListSubProps) {
  const hasChildren = list.children;
  const { pathname, search } = useLocation();

  if (hasChildren) {
    const newLocation = new URL(`${window.location.origin}${list.path}`);
    const isActive = getActive(newLocation.pathname, pathname, false);

    return (
      <Collapse in={active} timeout="auto">
        <List component="div" disablePadding>
          {(list.children || []).map((item) => {
            const isSearchParamActive = getSearchParamActive(
              isActive,
              item.path,
              pathname + search
            );

            return (
              <NavItemSub
                key={item.title}
                item={item}
                active={!isActive || isSearchParamActive}
                endComponentProps={item.counter ? item.counter : ''}
                onClick={item?.onClick}
              />
            );
          })}
        </List>
      </Collapse>
    );
  }

  return <NavItemSub item={list} active={active} />;
}

function NavListRoot({ list, ...props }: NavListRootProps) {
  const location = useLocation();
  const { pathname } = location;
  const hasChildren = !!list.children?.length;
  const newLocation = new URL(`${window.location.origin}${list.path}`);
  const active = getActive(
    hasChildren ? newLocation.pathname : list.path,
    pathname
  );

  if (hasChildren) {
    return (
      <>
        <NavItemRoot item={list} open={active} {...props} />
        <Collapse in={active} timeout="auto">
          <List
            component="div"
            disablePadding
            data-testid={list.title.toLowerCase()}
          >
            <NavListSub active={active} list={list} />
          </List>
        </Collapse>
      </>
    );
  }

  return <NavItemRoot {...props} open={active} item={list} />;
}

function NavMiniRoot({ list }: NavListRootProps) {
  const { t } = useTranslationRoot();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isOpen = Boolean(anchorEl);
  const { icon, path, shortTitle, title } = list;
  const hasChildren = !!list.children?.length;

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    if (anchorEl !== e.currentTarget) {
      setAnchorEl(e.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <MiniNavItem
      // @ts-ignore
      activeClassName="isActiveRoot"
      component={LinkWithRef}
      key={title}
      to={path}
    >
      <ButtonBase
        id={`${title}-button`}
        aria-controls={isOpen ? `${title}-menu` : undefined}
        aria-haspopup={hasChildren ? 'true' : 'false'}
        aria-expanded={isOpen ? 'true' : undefined}
        onMouseEnter={handleClick}
        onMouseLeave={handleClose}
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          pb: 0.5,
          pt: 1,
          width: 1,
          zIndex: 1301,
        }}
      >
        <SvgIconStyle src={icon!} sx={{ mb: 0.5 }} />
        <TypographyWithTranslation
          i18nKey={shortTitle}
          variant="caption"
          sx={{
            display: 'block',
            maxWidth: 72,
            overflow: 'hidden',
            textAlign: 'center',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        />
        {hasChildren ? (
          <>
            <SvgIconStyle
              src={ARROW_FORWARD_ICON}
              sx={{
                height: 16,
                mb: 0.5,
                right: 4,
                position: 'absolute',
                top: 13,
                width: 16,
              }}
            />
            <Popper
              id={`${title}-menu`}
              open={isOpen}
              anchorEl={anchorEl}
              placement="right-start"
              transition
              sx={{ zIndex: ({ zIndex }) => zIndex.tooltip + 1 }}
            >
              {({ TransitionProps }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: 'left center',
                  }}
                >
                  <Paper elevation={3}>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList sx={{ p: 1 }}>
                        {list.children!.map((item) => (
                          <MenuItem
                            key={item.title}
                            onClick={handleClose}
                            component={LinkWithRef}
                            to={item.path}
                            sx={{
                              borderRadius: 1,
                              color: 'grey.600',
                              fontSize: 14,
                              textTransform: 'capitalize',
                              '&:hover': {
                                color: 'text.primary',
                              },
                            }}
                          >
                            {t(item.title)}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </>
        ) : null}
      </ButtonBase>
    </MiniNavItem>
  );
}

function Count({
  count,
  isLoading,
  isSuccess,
}: {
  count?: number;
  isLoading?: boolean;
  isSuccess?: boolean;
}) {
  if (isLoading) {
    return <LoadingIcon size={22} />;
  }

  if (isSuccess) {
    return <Counter count={count} />;
  }

  return null;
}

type Links = { items: NavListChildrenProps[]; subheader: string }[];

function genNavList({
  currentUserRole,
  documentsTotal,
  documentsLoading,
  documentsSuccess,
  documentStageMap,
  hasClaimsEnrichment,
  removeDocuments,
  removeDocumentsClosedStage,
  removeSplitsPage,
  removeClassificationPage,
  refetchDocumentCounts,
  removeRedactionPage,
}: {
  currentUserRole: string;
  documentsTotal: number;
  documentsLoading: boolean;
  documentsSuccess: boolean;
  documentStageMap: { [key: string]: CountPerStage } | null;
  hasClaimsEnrichment: boolean;
  removeDocuments: boolean;
  removeDocumentsClosedStage: boolean;
  refetchDocumentCounts: VoidFunction;
  removeSplitsPage: boolean;
  removeClassificationPage: boolean;
  removeRedactionPage: boolean;
}): Links {
  const claimsStageSubmenu = (hasClaimsEnrichment: boolean) => {
    const enrichmentLinks = [
      {
        label: 'dashboardNav.awaitingEnrichment',
        value: CLAIMS_AWAITING_ENRICHMENT,
      },
      {
        label: 'dashboardNav.awaitingOcrResults',
        value: CLAIMS_AWAITING_OCR_RESULTS,
      },
      {
        label: 'dashboardNav.awaitingReview',
        value: CLAIMS_AWAITING_REVIEW,
      },
    ];

    const links = [
      {
        label: 'dashboardNav.closed',
        value: CLAIMS_CLOSED,
      },
      {
        label: 'dashboardNav.completed',
        value: CLAIMS_COMPLETED,
      },
      {
        label: 'dashboardNav.error',
        value: CLAIMS_ERROR,
      },
      {
        label: 'dashboardNav.awaitingInfo',
        value: CLAIMS_AWAITING_INFO,
      },
      {
        label: 'dashboardNav.processing',
        value: CLAIMS_PROCESSING,
      },
      {
        label: 'dashboardNav.rejected',
        value: CLAIMS_REJECTED,
      },
    ];

    // TODO: remove when not featured flagged anymore
    return hasClaimsEnrichment ? enrichmentLinks.concat(links) : links;
  };
  const DOCUMENT_STAGE_SUBMENU = [
    {
      label: 'dashboardNav.awaitingReview',
      value: AWAITING_REVIEW,
    },
    {
      label: 'dashboardNav.reviewInProgress',
      value: REVIEW_IN_PROGRESS,
    },
    {
      label: 'dashboardNav.completed',
      value: COMPLETED,
    },
    {
      label: 'dashboardNav.closed',
      value: CLOSED,
    },
    {
      label: 'dashboardNav.processing',
      value: PROCESSING,
    },
    {
      label: 'dashboardNav.rejected',
      value: REJECTED,
    },
    {
      label: 'dashboardNav.error',
      value: ERROR,
    },
  ] as { label: string; value: string }[];
  const preserveSearchParams = (path: string, stage?: string) => {
    // need to preserve search params other than "stage"
    const searchParams = new URLSearchParams(location.search);

    if (window.location.pathname === path && stage) {
      // ensure we reset the page when changing stage
      if (searchParams.get(STAGE) !== stage) {
        searchParams.set(PAGE, '0');
      }
      searchParams.delete(STAGE);
      searchParams.set(STAGE, stage);
      return searchParams.toString();
    } else if (window.location.pathname === path && !stage) {
      searchParams.delete(STAGE);
      return searchParams.toString();
    } else if (window.location.pathname !== path && stage) {
      return new URLSearchParams({ [STAGE]: stage }).toString();
    }
  };
  const originalSidebarLinks = [
    {
      subheader: 'dashboardNav.clientDashboard',
      items: [
        {
          title: 'dashboardNav.claims',
          shortTitle: 'dashboardNavShort.claims',
          path: claimsRoute.createPath({
            search: preserveSearchParams(claimsRoute.path),
          }),
          icon: claimsRoute.icon,
          permission: claimsRoute.permission,
          children: claimsStageSubmenu(hasClaimsEnrichment).map((item) => ({
            title: item.label,
            shortTitle: item.label,
            path: claimsRoute.createPath({
              search: preserveSearchParams(claimsRoute.path, item.value),
            }),
            permission: claimsRoute.permission,
          })),
        },
        {
          title: 'dashboardNav.documents',
          shortTitle: 'dashboardNavShort.documents',
          path: hitlRoute.createPath({
            search: preserveSearchParams(hitlRoute.path),
          }),
          icon: hitlRoute.icon,
          permission: hitlRoute.permission,
          children: DOCUMENT_STAGE_SUBMENU.map((item) => ({
            title: item.label,
            shortTitle: item.label,
            path: hitlRoute.createPath({
              search: preserveSearchParams(hitlRoute.path, item.value),
            }),
            permission: hitlRoute.permission,
            onClick: refetchDocumentCounts,
          })),
        },
        {
          title: 'dashboardNav.performance',
          shortTitle: 'dashboardNavShort.performance',
          path: performanceRoute.createPath(),
          icon: performanceRoute.icon,
          permission: performanceRoute.permission,
          children: [
            {
              title: 'dashboardNav.userManagement',
              shortTitle: 'dashboardNav.userManagement',
              path: performanceManageUsersRoute.createPath(),
              permission: performanceManageUsersRoute.permission,
            },
          ],
        },
        {
          title: 'dashboardNav.splits',
          shortTitle: 'dashboardNavShort.splits',
          path: splitsRoute.createPath(),
          icon: splitsRoute.icon,
          permission: splitsRoute.permission,
        },
        {
          title: 'dashboardNav.classification',
          shortTitle: 'dashboardNavShort.classification',
          path: classificationRoute.createPath(),
          icon: classificationRoute.icon,
          permission: classificationRoute.permission,
        },
        {
          title: 'dashboardNav.redactions',
          shortTitle: 'dashboardNavShort.redactions',
          path: redactionsRoute.createPath(),
          icon: redactionsRoute.icon,
          permission: redactionsRoute.permission,
        },
      ],
    },
    {
      subheader: 'dashboardNav.clientPortal',
      items: [
        {
          title: 'dashboardNav.fileUpload',
          shortTitle: 'dashboardNavShort.fileUpload',
          path: fileUploadRoute.createPath(),
          icon: fileUploadRoute.icon,
          permission: fileUploadRoute.permission,
        },
      ],
    },
    {
      subheader: 'dashboardNav.sproutDev',
      items: [
        {
          title: 'dashboardNav.createClaim',
          shortTitle: 'dashboardNavShort.createClaim',
          path: createClaimRoute.createPath(),
          icon: createClaimRoute.icon,
          permission: createClaimRoute.permission,
        },
        {
          title: 'dashboardNav.uploadDocument',
          shortTitle: 'dashboardNavShort.uploadDocument',
          path: uploadDocumentRoute.createPath(),
          icon: uploadDocumentRoute.icon,
          permission: uploadDocumentRoute.permission,
        },
        {
          title: 'dashboardNav.featureFlags',
          shortTitle: 'dashboardNavShort.featureFlags',
          path: featureFlagsRoute.createPath(),
          icon: featureFlagsRoute.icon,
          permission: featureFlagsRoute.permission,
        },
        {
          title: 'dashboardNav.configuration',
          shortTitle: 'dashboardNavShort.configuration',
          path: configurationRoute.createPath(),
          icon: configurationRoute.icon,
          permission: configurationRoute.permission,
        },
        {
          title: 'Docs',
          shortTitle: 'Docs',
          path: docsRoute.createPath(),
          icon: docsRoute.icon,
          permission: docsRoute.permission,
        },
      ],
    },
  ];

  const removeItemsBasedOnRole = (links: Links) => {
    return [...links].reduce((acc, link) => {
      // filter out top level links
      const items = link.items.filter(({ permission }) =>
        permission.includes(currentUserRole)
      );

      // filter out children links
      const newItems = items.map((item) => {
        if (item.children) {
          return {
            ...item,
            children: item.children.filter(({ permission }) =>
              permission.includes(currentUserRole)
            ),
          };
        }

        return item;
      });

      if (items.length) {
        acc.push({ ...link, items: newItems });
      }

      return acc;
    }, [] as Links);
  };

  const hasDocumentsLevelLink = (links: Links) => {
    return links.some((link) =>
      link.items.some((item) => item.path.includes(hitlRoute.path))
    );
  };

  const removeSpecificLink = (links: Links, path: string) => {
    return [...links].map((links) => ({
      ...links,
      items: links.items.filter((link) => link.path !== path),
    }));
  };

  const removeDocumentsLink = (links: Links) => {
    const hasDocuments = hasDocumentsLevelLink(links);

    if (hasDocuments && removeDocuments) {
      return removeSpecificLink(links, hitlRoute.path);
    }

    return links;
  };

  const removeDocumentsClosedStageLink = (links: Links) => {
    const hasDocuments = hasDocumentsLevelLink(links);

    if (hasDocuments && removeDocumentsClosedStage) {
      return [...links].map((links) => ({
        ...links,
        items: links.items.map((link) => {
          if (link.path.includes(hitlRoute.path) && link.children?.length) {
            return {
              ...link,
              // example url: https://local.sprout.ai:8080/h?sort=DESC&page=0&stage=CLOSED
              // remove closed stage link from children
              children: link.children.filter((child) => {
                const childPathStage = new URL(
                  `${window.location.origin}${child.path}`
                ).searchParams.get(STAGE);

                return childPathStage !== CLOSED;
              }),
            };
          }

          return link;
        }),
      }));
    }

    return links;
  };

  const addDocumentsCount = (links: Links) => {
    const hasDocuments = hasDocumentsLevelLink(links);

    if (!hasDocuments && removeDocuments) {
      return links;
    }

    return [...links].map((links) => ({
      ...links,
      items: links.items.map((link) => {
        if (link.path.includes(hitlRoute.path)) {
          return {
            ...link,
            endComponent: () => (
              <Count
                count={documentsTotal}
                isLoading={documentsLoading}
                isSuccess={documentsSuccess}
              />
            ),
          };
        }

        return link;
      }),
    }));
  };

  const addDocumentStageCount = (links: Links) => {
    const hasDocuments = hasDocumentsLevelLink(links);

    if (!hasDocuments || removeDocuments || !documentStageMap) {
      return links;
    }

    return [...links].map((links) => ({
      ...links,
      items: links.items.map((link) => {
        if (link.path.includes(hitlRoute.path) && link.children?.length) {
          return {
            ...link,
            children: link.children.map((child) => {
              const url = new URL(`${window.location.origin}${child.path}`);
              const stage = url.searchParams.get(STAGE) as string;

              return {
                ...child,
                endComponent: () => (
                  <Count
                    count={documentStageMap[stage]?.count || 0}
                    isLoading={documentsLoading}
                    isSuccess={documentsSuccess}
                  />
                ),
              };
            }),
          };
        }

        return link;
      }),
    }));
  };

  const showClaimsReviewRoleOnlyLink = (links: Links) => {
    if (currentUserRole !== CLAIM_REVIEW) {
      return links;
    }

    const newLinks = [...links];
    const claimsLink = newLinks.findIndex((link) =>
      link.items.some((item) => item.path.includes(claimsRoute.path))
    );

    if (claimsLink > -1) {
      newLinks[claimsLink].items[0].path = claimsRoute.createPath({
        search: `?${STAGE}=${AWAITING_REVIEW}`,
      });
      newLinks[claimsLink].items[0].children = [];
    }

    return newLinks;
  };

  const removeSplits = (links: Links) => {
    if (removeSplitsPage) {
      return removeSpecificLink(links, splitsRoute.path);
    }

    return links;
  };

  const removeClassification = (links: Links) => {
    if (removeClassificationPage) {
      return removeSpecificLink(links, classificationRoute.path);
    }

    return links;
  };

  const removeRedaction = (links: Links) => {
    if (removeRedactionPage) {
      return removeSpecificLink(links, redactionsRoute.path);
    }

    return links;
  };

  return pipe(
    removeItemsBasedOnRole,
    removeDocumentsLink,
    removeDocumentsClosedStageLink,
    addDocumentsCount,
    addDocumentStageCount,
    showClaimsReviewRoleOnlyLink,
    removeSplits,
    removeClassification,
    removeRedaction
  )(originalSidebarLinks);
}

const userHasPermission = ({
  permissions,
  role,
}: {
  permissions: string[];
  role?: string;
}) => (role ? permissions.includes(role) : false);

function NavList({
  isMiniNavbar,
  removeDocuments,
  removeDocumentsClosedStage,
}: NavListProps) {
  const [searchParams] = useSearchParams();
  const documentTypeParams = searchParams.getAll('documentType');
  const filtersParams = searchParams.get('filters');
  const { t } = useTranslation(DASHBOARD_PAGE);
  const currentUserRoleQuery = useCurrentUserRole();
  const documentsStagesCount = useGetDocumentsStagesCount({
    documentTypes: documentTypeParams,
    enabled:
      !removeDocuments &&
      userHasPermission({
        permissions: ROUTE_PERMISSIONS.hitl,
        role: currentUserRoleQuery.data || undefined,
      }),
    filters: filtersParams,
  });
  const isClaimsEnrichmentAvailable = useFeatureFlag('enableClaimsEnrichment');
  const isSplitsPageEnabled = useFeatureFlag('enableSplitsPage');
  const isClassificationPageEnabled = useFeatureFlag(
    'enableClassificationPage'
  );
  const isRedactionPageEnabled = useFeatureFlag('enableRedactionPage');

  const sidebarLinks = useMemo(() => {
    if (currentUserRoleQuery.isPending || currentUserRoleQuery.isError) {
      return [];
    }

    const documentStageMap: { [key: string]: CountPerStage } | null =
      documentsStagesCount.isSuccess
        ? convertArrayToObject(documentsStagesCount.data.stages, 'stage')
        : null;

    return genNavList({
      currentUserRole: currentUserRoleQuery.data,
      documentsTotal: documentsStagesCount.data?.total || 0,
      documentsSuccess: documentsStagesCount.isSuccess,
      documentsLoading: documentsStagesCount.isPending,
      documentStageMap,
      hasClaimsEnrichment: isClaimsEnrichmentAvailable,
      removeDocuments,
      removeDocumentsClosedStage,
      refetchDocumentCounts: documentsStagesCount.refetch,
      removeSplitsPage: !isSplitsPageEnabled,
      removeClassificationPage: !isClassificationPageEnabled,
      removeRedactionPage: !isRedactionPageEnabled,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentUserRoleQuery.data,
    currentUserRoleQuery.isError,
    currentUserRoleQuery.isPending,
    documentsStagesCount.data?.stages,
    documentsStagesCount.data?.total,
    documentsStagesCount.isPending,
    documentsStagesCount.isSuccess,
    documentsStagesCount.refetch,
    isClaimsEnrichmentAvailable,
    location.search,
  ]);

  if (currentUserRoleQuery.isError) {
    return (
      <Stack
        sx={{
          alignItems: 'center',
          p: 3,
        }}
      >
        <ErrorIcon />
        <TypographyWithTranslation i18nKey="common.currentUserError" />
      </Stack>
    );
  }

  if (currentUserRoleQuery.isPending) {
    return <LoadingIcon size={22} />;
  }

  return (
    <Stack
      spacing={isMiniNavbar ? 0.5 : 0}
      sx={{
        alignItems: isMiniNavbar ? 'center' : 'flex-start',
        px: isMiniNavbar ? 0.5 : 0,
      }}
    >
      {sidebarLinks.map(({ subheader, items }, index) => {
        if (isMiniNavbar) {
          return (
            <Fragment key={subheader}>
              {index !== 0 && items.length ? (
                <Box
                  sx={{
                    backgroundColor: 'grey.50024',
                    mt: '12px !important',
                    height: '1px',
                    width: 24,
                  }}
                />
              ) : null}
              {items.map((list) => (
                <NavMiniRoot key={list.title} list={list} />
              ))}
            </Fragment>
          );
        }

        return (
          <List key={subheader} disablePadding sx={{ width: 1 }}>
            {items.length ? (
              <ListSubheaderStyle>{t(subheader)}</ListSubheaderStyle>
            ) : null}

            {items?.map((list) => <NavListRoot key={list.title} list={list} />)}
          </List>
        );
      })}
    </Stack>
  );
}

export { NavList };
