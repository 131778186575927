import { preloadImageLink } from './client.ts';

/**
 * Pre-warm the CDN cache for the images
 * Whilst the images are stored in our S3 buckets, they're now being served
 * via CloudFront. This means that the first time an image is requested, it
 * will be fetched from the S3 bucket and cached in CloudFront. This can
 * result in a delay in the image being displayed.
 * To mitigate this, we can pre-warm the cache by fetching the images from
 * the S3 bucket and storing them in the CloudFront cache.
 *
 * The happy path scenario:
 * HIL user has never seen the next document, therefore, pre-warm the image
 * cache for the next document.
 */
const cache = new Map<string, HTMLLinkElement>();

function fetchImage(url: string) {
  if (cache.has(url)) {
    return cache.get(url);
  }

  const element = preloadImageLink(url);

  if (element) {
    cache.set(url, element);
  }

  return element;
}

type Urls = { [key: string]: string } | string[] | string;

export function preWarmImageCache(urls: Urls) {
  if (Array.isArray(urls)) {
    return urls.map(fetchImage);
  } else if (typeof urls === 'object') {
    return Object.values(urls).map(fetchImage);
  }

  return fetchImage(urls);
}
