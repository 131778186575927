import { useEffect, useRef } from 'react';
import debounce from '@mui/utils/debounce';
import { generalConfig } from 'config';
import { FormProvider, useForm } from 'react-hook-form';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { useTranslationRoot } from 'components/with-translation';
import { ControllerWithTextField } from 'components/form/ControllerWithTextField';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { ControllerWithDropDown } from 'components/form/ControllerWithDropDown.tsx';
import { CLOSE_ICON, SEARCH_ICON } from 'constants/public-icons';

interface AdvancedSearchProps {
  defaultValue: string | null;
  handleSearch: ({
    value,
    searchField,
  }: {
    value: string | undefined;
    searchField?: string | null;
  }) => void;
  hasSuperAdminPermission: boolean;
  searchField?: string | null;
}

type FormValues = {
  searchById: string;
  searchField: string;
};

const SEARCH_ID = 'searchById';
const SEARCH_FIELD = 'searchField';
const CLIENT_DOCUMENT_ID = 'CLIENT_DOCUMENT_ID';
const DOCUMENT_ID = 'DOCUMENT_ID';

function AdvancedSearch({
  defaultValue,
  handleSearch,
  hasSuperAdminPermission,
  searchField,
}: AdvancedSearchProps) {
  const isInitialised = useRef(false);
  const { t } = useTranslationRoot();
  const menuItems = [
    { value: CLIENT_DOCUMENT_ID, label: t('search.clientDocumentId') },
    { value: DOCUMENT_ID, label: t('search.documentId') },
  ];

  const methods = useForm<FormValues>({
    defaultValues: {
      [SEARCH_ID]:
        new URLSearchParams(window.location.search).get('q') ||
        defaultValue ||
        '',
      [SEARCH_FIELD]: searchField || '',
    },
  });
  const { setValue, watch } = methods;

  useEffect(() => {
    const subscription = watch(
      debounce((data) => {
        const value = data[SEARCH_ID];
        const searchField = data[SEARCH_FIELD];
        handleSearch({ value, searchField });
      }, generalConfig.hilSearchWait)
    );

    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isInitialised.current && hasSuperAdminPermission && !searchField) {
    isInitialised.current = true;
    setValue(SEARCH_FIELD, 'CLIENT_DOCUMENT_ID');
  }

  const startAdornment = hasSuperAdminPermission ? (
    <InputAdornment position="start">
      <ControllerWithDropDown
        htmlFor="Search field selector"
        menuItems={menuItems}
        name={SEARCH_FIELD}
        label={t('search.labelWithSelect')}
        size="small"
        sx={{
          maxWidth: 146,
          minWidth: 146,
          '& .MuiInputBase-root': {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          },
        }}
      />
    </InputAdornment>
  ) : (
    <InputAdornment position="start">
      <SvgIconStyle src={SEARCH_ICON} />
    </InputAdornment>
  );

  return (
    <FormProvider {...methods}>
      <ControllerWithTextField
        label={t(
          hasSuperAdminPermission
            ? t('search.labelWithSelect')
            : t('search.label')
        )}
        InputProps={{
          startAdornment,
          endAdornment: watch(SEARCH_ID) && (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  setValue(SEARCH_ID, '');
                }}
                sx={{
                  transform: ({ spacing }) => `translateX(${spacing(1)})`,
                }}
              >
                <SvgIconStyle src={CLOSE_ICON} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        name={SEARCH_ID}
        variant="outlined"
        size="small"
        sx={{
          width: 1,
          '& .MuiInputBase-root.MuiOutlinedInput-root': hasSuperAdminPermission
            ? {
                pl: 0,
              }
            : {},
        }}
      />
    </FormProvider>
  );
}

export { AdvancedSearch };
