import type { Control } from 'react-hook-form';
import { useFieldArray, useFormContext } from 'react-hook-form';
import FormGroup from '@mui/material/FormGroup';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import { ControllerWithTextField } from 'components/form/ControllerWithTextField';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { TRASH_ICON } from 'constants/public-icons';
import {
  ButtonWithTranslation,
  TypographyWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';

import { CLAIM_DESCRIPTION_PROPERTY } from './utils';

const InputLabelStyled = styled(InputLabel)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: bold;
`;

function TabularData({
  control,
  name,
}: {
  control: Control<any>;
  name: string;
}) {
  const { t } = useTranslationRoot();
  const { getValues } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
    shouldUnregister: true,
  });

  const removeItem = (index: number) => () => {
    remove(index);
  };

  const addNewItem = () => {
    append({ key: '', value: '' });
  };

  return (
    <FormGroup>
      <Paper square={false}>
        <InputLabelStyled htmlFor={name} sx={{ textTransform: 'capitalize' }}>
          {name === 'metadata'
            ? (t('form.metadata') as string)
            : (t('form.properties') as string)}
        </InputLabelStyled>
        <Stack
          spacing={2}
          sx={{
            mt: 1,
            pl: 2,
          }}
        >
          <Stack direction="row" spacing={2}>
            <Box sx={{ flex: 1 }}>
              <label
                htmlFor={
                  name === 'metadata' ? 'metadata_key' : 'properties_key'
                }
              >
                <TypographyWithTranslation
                  i18nKey="form.key"
                  variant="subtitle2"
                  sx={{ color: 'text.secondary' }}
                />
              </label>
            </Box>
            <Box sx={{ flex: 1 }}>
              <label
                htmlFor={
                  name === 'metadata' ? 'metadata_value' : 'properties_value'
                }
              >
                <TypographyWithTranslation
                  i18nKey="form.value"
                  variant="subtitle2"
                  sx={{ color: 'text.secondary' }}
                />
              </label>
            </Box>
            <Box sx={{ flex: '0 0 48px', minWidth: 48 }} />
          </Stack>

          {fields.map(({ id }, index) => {
            const { key } = getValues(name)[index] || {};
            const isClaimDescription = key === CLAIM_DESCRIPTION_PROPERTY;
            return (
              <Stack
                direction="row"
                key={id}
                spacing={2}
                sx={{
                  alignItems: 'center',
                }}
              >
                <Stack direction="row" key={id} spacing={2} sx={{ flex: 1 }}>
                  <Box sx={{ flex: 1 }}>
                    <ControllerWithTextField
                      name={`${name}.${index}.key`}
                      defaultValue={isClaimDescription ? key : ''}
                      disabled={isClaimDescription}
                      id={
                        name === 'metadata' ? 'metadata_key' : 'properties_key'
                      }
                      required
                    />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <ControllerWithTextField
                      name={`${name}.${index}.value`}
                      type="text"
                      multiline={isClaimDescription}
                      id={
                        name === 'metadata'
                          ? 'metadata_value'
                          : 'properties_value'
                      }
                    />
                  </Box>
                </Stack>
                <Box sx={{ flex: '0 0 48px', minWidth: 48 }}>
                  <IconButton
                    size="large"
                    aria-label="delete item"
                    onClick={removeItem(index)}
                    color="error"
                  >
                    <SvgIconStyle src={TRASH_ICON} />
                  </IconButton>
                </Box>
              </Stack>
            );
          })}

          <Box>
            <ButtonWithTranslation
              i18nKey={
                name === 'metadata'
                  ? (t('form.addMetadataButton') as string)
                  : (t('form.addPropertyButton') as string)
              }
              variant="contained"
              color="secondary"
              onClick={addNewItem}
            />
          </Box>
        </Stack>
      </Paper>
    </FormGroup>
  );
}

export { TabularData };
