import { lazy } from 'react';
import { generatePath } from 'react-router-dom';
import { loadable } from 'components/loadable';
import { PAGE_ID, RESOURCE_ID } from 'constants/route-keys';
import ROUTE_PERMISSIONS from 'constants/route-permissions';
import { HITL_TOOL_PAGE } from 'constants/translation-keys';
import hitlGalleryRoute from 'pages/HitLGallery/hitlGallery.route';
import { initFabricSDK } from 'utils/init-fabric';

const path = `/h/:${RESOURCE_ID}`;
const HitLToolPage = loadable(lazy(() => import('./HitLTool')));

const historyPath = `/h/:${RESOURCE_ID}/history/:${PAGE_ID}`;
const hitlToolHistoryRoute = {
  path: historyPath,
  createPath: (resourceId: string, pageId?: string) =>
    generatePath(historyPath, { resourceId, pageId }),
  permission: ROUTE_PERMISSIONS.hitl,
  i18nNamespace: HITL_TOOL_PAGE,
  preload: () => {
    initFabricSDK();
    return import('./HitLTool');
  },
};

const hitlToolRoute = {
  path,
  createPath: (resourceId: string) => generatePath(path, { resourceId }),
  element: <HitLToolPage />,
  permission: ROUTE_PERMISSIONS.hitl,
  children: [hitlToolHistoryRoute, hitlGalleryRoute],
  i18nNamespace: HITL_TOOL_PAGE,
  preload: () => {
    initFabricSDK();
    return import('./HitLTool');
  },
};

export default hitlToolRoute;
export { hitlToolHistoryRoute };
