import type { ElementType } from 'react';
import { Suspense } from 'react';
import { LoadingScreen } from 'components/LoadingScreen';

export function loadable(Component: ElementType) {
  return function Loadable(props = {}) {
    return (
      <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
      </Suspense>
    );
  };
}
