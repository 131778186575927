import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { generalConfig } from 'config';

const isDev = process.env.NODE_ENV === 'development';

function DevTools() {
  return (
    <>
      {isDev && generalConfig.enableReactQueryDevtools ? (
        <ReactQueryDevtools
          initialIsOpen={false}
          buttonPosition="bottom-left"
        />
      ) : null}
    </>
  );
}

export { DevTools };
