import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { TypographyWithTranslation } from 'components/with-translation';
import type { ClaimsValidateResult } from 'types/Claims';

interface ValidationResultProps {
  validationResult: ClaimsValidateResult;
}

function ValidationResultV2({ validationResult }: ValidationResultProps) {
  const { title, result, ruleId } = validationResult;
  const passed = result === 'passed' || result === 'skipped';
  const fields = [
    { key: 'validationResult.ruleId', value: ruleId },
    { key: 'validationResult.title', value: title },
  ];

  return (
    <Stack direction="row">
      <Box sx={{ pr: 2 }}>
        <Box
          sx={{
            backgroundColor: passed ? 'success.main' : 'error.main',
            height: 1,
            width: '2px',
          }}
        />
      </Box>
      <Stack>
        {fields.map(({ key, value }) => (
          <Box key={key}>
            <TypographyWithTranslation
              component="span"
              variant="body1"
              fontWeight="bold"
              i18nKey={key}
            />
            <Typography component="span" variant="body1">{`: `}</Typography>
            <Typography component="span" variant="body1">
              {value}
            </Typography>
          </Box>
        ))}
      </Stack>
    </Stack>
  );
}

export { ValidationResultV2 };
