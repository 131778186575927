import { Controller, useFormContext } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { useTranslationRoot } from 'components/with-translation.tsx';
import { HiddenInput } from 'components/form/HiddenInput.tsx';
import { ControllerWithSingleCheckbox } from 'components/form/ControllerWithSingleCheckbox.tsx';
import { useGetDocumentTypes } from 'state/queries/documents';
import { unique } from 'utils/array.ts';

import { Thumbnail } from './Thumbnail.tsx';

interface ItemProps {
  index: number;
  field: {
    id: string;
    classification: string | null;
    pageId: string;
  };
  resources: {
    [key: string]: {
      id: string;
      claimId: string;
      clientClaimId: string;
      classification: null | {
        pageType: string;
      };
      pageUrl: string;
    };
  };
}

function Item({ index, field, resources }: ItemProps) {
  const { t } = useTranslationRoot();
  const { getValues, setValue } = useFormContext();
  const { data, isPending, isSuccess } = useGetDocumentTypes();
  const { pageId } = field;

  const genName = (name: string) => `pages.${index}.${name}`;
  const pageIdInputName = genName('pageId');
  const classificationInputName = genName('classification');
  const isValidInputName = genName('isValid');
  const displayId = pageId.substring(pageId.length - 6);

  if (resources[pageId] === undefined) {
    return null;
  }

  return (
    <TableRow
      id={pageId}
      sx={{
        verticalAlign: 'top',
        '&:last-child td, &:last-child th': { borderBottom: 0 },
      }}
    >
      <TableCell
        sx={{
          backgroundColor: 'background.default',
          borderRight: ({ palette }) => `3px solid ${palette.divider}`,
          position: 'sticky',
          left: 0,
        }}
      >
        {resources[pageId]?.pageUrl ? (
          <Thumbnail src={resources[pageId].pageUrl} />
        ) : (
          <Skeleton
            animation="wave"
            variant="rectangular"
            width="100%"
            height={30}
          />
        )}
      </TableCell>
      <TableCell
        sx={{
          width: 95,
        }}
      >
        <HiddenInput name={pageIdInputName} />
        {displayId}
      </TableCell>
      <TableCell>{resources[pageId].clientClaimId}</TableCell>
      <TableCell>
        {resources[pageId]?.classification?.pageType ??
          t('table.noClassification')}
      </TableCell>
      <TableCell>
        <Controller
          // rules={{ required: !watch(isValidInputName) }}
          render={({ field }) => (
            <Autocomplete
              {...field}
              loading={isPending}
              freeSolo
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('table.overrideClassificationLabel')}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isPending ? (
                          <CircularProgress color="inherit" size={16} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              options={
                isSuccess
                  ? unique(
                      data.documentTypes.map(
                        ({ documentGroup }) => documentGroup
                      )
                    )
                  : []
              }
              key={field.value}
              onChange={(_, data) => {
                field.onChange(data);

                if (getValues(isValidInputName)) {
                  setValue(isValidInputName, false);
                }
              }}
            />
          )}
          name={classificationInputName}
        />
      </TableCell>
      <TableCell>
        <ControllerWithSingleCheckbox
          // rules={{ required: !watch(classificationInputName) }}
          checkboxProps={{
            id: genName('isValid'),
            onChange: (_, value) => {
              if (value) {
                setValue(classificationInputName, null);
              }
            },
          }}
          name={genName('isValid')}
          sx={{
            mx: 0,
          }}
        />
      </TableCell>
      <TableCell />
    </TableRow>
  );
}

export { Item };
