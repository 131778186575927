import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { SAVE_ICON } from 'constants/public-icons';
import { LoadingButtonWithTranslation } from 'components/with-translation';

function SubmitButton({ isSubmitting }: { isSubmitting: boolean }) {
  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: 'flex-end',
      }}
    >
      <Box>
        <LoadingButtonWithTranslation
          color="success"
          variant="contained"
          loading={isSubmitting}
          loadingPosition="start"
          startIcon={<SvgIconStyle src={SAVE_ICON} />}
          type="submit"
          size="large"
          i18nKey="form.submitButton"
        />
      </Box>
    </Stack>
  );
}

export { SubmitButton };
