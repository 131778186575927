import { lazy } from 'react';
import { createPath } from 'react-router-dom';
import { loadable } from 'components/loadable';
import { REDACT_ICON } from 'constants/public-icons';
import ROUTE_PERMISSIONS from 'constants/route-permissions.ts';
import { REDACTIONS_PAGE } from 'constants/translation-keys.ts';
import redactionRoute from 'pages/Redaction/redaction.route.tsx';

import RedactionsLayout from './RedactionsLayout';

const path = '/r';
const RedactionsPage = loadable(lazy(() => import('./Redactions')));

const redactionsRoute = {
  path,
  createPath: (params?: { search?: string }) =>
    createPath({ pathname: path, ...params }),
  icon: REDACT_ICON,
  element: <RedactionsLayout />,
  permission: ROUTE_PERMISSIONS.redactions,
  children: [
    {
      element: <RedactionsPage />,
      index: true,
      permission: ROUTE_PERMISSIONS.redactions,
      i18nNamespace: REDACTIONS_PAGE,
    },
    redactionRoute,
  ],
};

export default redactionsRoute;
