import type { ReactNode } from 'react';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import dayjs from 'locales/dayjs';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';
import { styled, useTheme } from '@mui/material/styles';
import {
  ButtonWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';
import { convertArrayToObject } from 'utils/array';
import { throttle } from 'utils/throttle';
import type { BaseFilter } from 'types/Filters';
import { isValidISODateString } from 'utils/date';
import { useTooltip } from 'components/customHooks/useTooltip';
import { MenuPopover } from 'components/MenuPopover';

type DeleteFnProps = {
  facet: string;
  value: string;
};

type DeleteFn = ({ facet, value }: DeleteFnProps) => void;

type Filter = {
  deleteFn: DeleteFn;
  facet: string;
  label: ReactNode;
  value: string;
};

const Label = styled('span')(({ theme }) => ({
  color: theme.palette.text.disabled,
}));

const WIDTH_OFFSET = 100; // to account for more chips button and clear button

export interface AdvancedFilterChipsProps {
  filtersQueryData: BaseFilter[];
  filters: string | null;
  setFilters: (filters: string) => void;
  docTypeFilters?: string[];
  handleDeleteDocTypeFilter?: (filters: string) => void;
  updateDocumentsFiltersAndUrl?: (filters: string) => void;
}

function AdvancedFilterChips({
  handleDeleteDocTypeFilter,
  docTypeFilters,
  filtersQueryData,
  filters,
  setFilters,
  updateDocumentsFiltersAndUrl,
}: AdvancedFilterChipsProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const shadowContainerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslationRoot();
  const { spacing } = useTheme();
  const decodedFilters = decodeURIComponent(filters || '');
  const { element, handleClose, handleOpen, isOpen } = useTooltip();
  const [sliceBy, setSliceBy] = useState<undefined | number>(undefined);

  const recalculateSliceBy = useCallback(
    function recalculateSliceBy({
      children,
      containerWidth,
    }: {
      containerWidth: number;
      children: HTMLCollection;
    }) {
      const allowedContainerWidth = containerWidth - WIDTH_OFFSET;
      const chipWidths = Array.from(children).map((child) => child.clientWidth);
      // we want the number of chips from chipWidths that fit in the container,
      // so we need to sum the width of the chips until we reach the container width
      let sum = 0;
      let chipsCount = 0;

      for (let i = 0; i < chipWidths.length; i++) {
        sum += chipWidths[i] + parseInt(spacing(1));

        if (sum > allowedContainerWidth) {
          break;
        }

        chipsCount++;
      }

      // deduct by 1 to account for the "more" chip and "clear" button
      setSliceBy(() => chipsCount);
    },
    [spacing]
  );

  useLayoutEffect(() => {
    const shadowContainer = shadowContainerRef.current;
    const container = containerRef.current;

    if (container && shadowContainer && (docTypeFilters || filters)) {
      const containerWidth = container.clientWidth;
      const children = shadowContainer.children;

      recalculateSliceBy({ children, containerWidth });
    }
  }, [docTypeFilters, filters, recalculateSliceBy]);

  useEffect(() => {
    const container = containerRef.current;
    const shadowContainer = shadowContainerRef.current;

    if (container && shadowContainer) {
      const throttledResize = throttle((entries) => {
        setSliceBy(() => undefined);

        for (const entry of entries) {
          recalculateSliceBy({
            children: shadowContainer?.children,
            containerWidth: entry.contentRect.width,
          });
        }
      }, 500);

      const observer = new ResizeObserver(throttledResize);
      observer.observe(container);

      return () => observer.disconnect();
    }
  }, [containerRef, recalculateSliceBy]);

  function generateFilter({
    facet,
    label,
    value,
    deleteFn,
  }: {
    facet: string;
    value: string;
    deleteFn: DeleteFn;
    label: string;
  }) {
    return {
      deleteFn,
      facet: facet,
      label: (
        <>
          <Label>{facet}: </Label>
          {label}
        </>
      ),
      value,
    };
  }

  const filtersDocumentsDict = useMemo(() => {
    return convertArrayToObject(filtersQueryData, 'name');
  }, [filtersQueryData]);

  const allFilters = useMemo(() => {
    const getRange = (facet: string) =>
      filtersDocumentsDict[facet]?.range || false;
    const getMulti = (facet: string) =>
      filtersDocumentsDict[facet]?.multi || false;
    const splitByFacets = (str: string) => str.split(';');
    const splitByFacetAndValues = (str: string) => str.split('=');
    const splitByValues = (str: string, range: boolean, multi: boolean) => {
      if (range && !multi) {
        return [str];
      }

      if (range && multi) {
        return str.split(',').reduce((acc, val, i, arr) => {
          if (i % 2 === 0) {
            acc.push(`${val},${arr[i + 1]}`);
          }
          return acc;
        }, [] as string[]);
      }

      return str.split(',');
    };

    const readableDateFormat = (value: string) => dayjs(value).format('ll');

    const transformLabel = ({
      range,
      multi,
      value,
    }: {
      multi: boolean;
      range: boolean;
      value: string;
    }) => {
      if (range && !multi) {
        if (value.split(',').length < 2) {
          return value[0];
        }

        const [first, second] = value.split(',');

        if (!first && second) {
          return t('table.filterUpToNumber', { number: second });
        }

        if (first && !second) {
          return t('table.filterNumberAndAbove', { number: first });
        }

        // handle date range
        if (isValidISODateString(first)) {
          return `${readableDateFormat(first)}–${readableDateFormat(second)}`;
        }

        return `${first}–${second}`;
      }

      if (range && multi) {
        return value;
      }

      if (isValidISODateString(value)) {
        return readableDateFormat(value);
      }

      return value;
    };

    const deleteQueryStringFilter = ({ facet, value }: DeleteFnProps) => {
      const removeSelectedChip = (filter: string) => filter !== value;
      const removeFacetsWithEmptyArray = ([_key, values]: [string, string[]]) =>
        values.length;

      const splitFilters = splitByFacets(decodedFilters);

      const splitFacets = splitFilters.reduce(
        (acc, filter) => {
          const [facet, values] = splitByFacetAndValues(filter);
          const multi = getMulti(facet);
          const range = getRange(facet);

          return {
            ...acc,
            [facet]: splitByValues(values, range, multi),
          };
        },
        {} as Record<string, string[]>
      );

      const newFilters = {
        ...splitFacets,
        [facet]: splitFacets[facet]?.filter(removeSelectedChip),
      };

      const result = Object.entries(newFilters)
        .filter(removeFacetsWithEmptyArray)
        .reduce(
          (a, [key, values]) => ({ ...a, [key]: values }),
          {} as Record<string, string[]>
        );

      // convert result to query string
      const newQueryString = Object.keys(result)
        .map((facet) => `${facet}=${result[facet].join(',')}`)
        .join(';');

      setFilters(newQueryString);
    };

    const mappedFilters =
      docTypeFilters && handleDeleteDocTypeFilter
        ? docTypeFilters.map((filter) => ({
            deleteFn: ({ value }: DeleteFnProps) =>
              handleDeleteDocTypeFilter(value),
            facet: t('table.documentType'),
            label: filter,
            value: filter,
          }))
        : [];
    const docFilters = mappedFilters.map(generateFilter);

    const splitFilters = splitByFacets(decodedFilters);
    const facetAndValues = splitFilters.reduce((acc, filter) => {
      const [facet, value] = splitByFacetAndValues(filter);

      // check if facet is a range type and multi type filter
      const range = getRange(facet);
      const multi = getMulti(facet);

      const newValue = value ? splitByValues(value, range, multi) : [];
      const mappedValues = newValue.map((value) => ({
        deleteFn: deleteQueryStringFilter,
        facet,
        label: transformLabel({ value, range, multi }),
        value,
      }));

      return [...acc, ...mappedValues.map(generateFilter)];
    }, [] as Filter[]);

    return [...docFilters, ...facetAndValues];
  }, [
    decodedFilters,
    docTypeFilters,
    filtersDocumentsDict,
    handleDeleteDocTypeFilter,
    setFilters,
    t,
  ]);

  const hasFilters = allFilters.length > 0;
  const initialFilters =
    typeof sliceBy !== 'undefined' ? allFilters.slice(0, sliceBy) : allFilters;
  const otherFilters =
    typeof sliceBy !== 'undefined' ? allFilters.slice(sliceBy) : [];
  const hasMoreChips = otherFilters.length > 0;

  return (
    <>
      <Stack
        data-testid="filter-table-chips"
        direction="row"
        spacing={1}
        ref={shadowContainerRef}
        sx={{
          alignItems: 'center',
          visibility: 'hidden',
          position: 'absolute',
          width: 1,
        }}
      >
        {hasFilters &&
          allFilters.map(({ facet, label, value }) => (
            <Fade key={`${facet}-${value}`}>
              <Chip
                label={label}
                onDelete={() => {}}
                size="small"
                variant="outlined"
              />
            </Fade>
          ))}
      </Stack>
      <Stack
        direction="row"
        spacing={1}
        ref={containerRef}
        sx={{
          alignItems: 'center',
          opacity: typeof sliceBy === 'undefined' ? 0 : 1,
          overflow: 'hidden',
          width: 1,
        }}
      >
        <TransitionGroup component={null}>
          {hasFilters &&
            initialFilters.map(({ deleteFn, facet, label, value }) => (
              <Fade key={`${facet}-${value}`}>
                <Chip
                  label={label}
                  onDelete={() => deleteFn({ facet, value })}
                  size="small"
                  variant="outlined"
                  data-testid="filter-chip"
                />
              </Fade>
            ))}
        </TransitionGroup>
        {hasMoreChips && (
          <>
            <Chip
              clickable
              label={`+${otherFilters.length}`}
              size="small"
              variant="outlined"
              onClick={handleOpen}
            />
          </>
        )}

        {hasFilters && (
          <ButtonWithTranslation
            size="small"
            color="info"
            onClick={() => {
              setFilters('');
              if (updateDocumentsFiltersAndUrl) {
                updateDocumentsFiltersAndUrl('');
              }
            }}
            sx={{
              minWidth: 'initial',
            }}
            i18nKey="table.clearChipsButton"
            data-testid="clear-chips-button"
          />
        )}

        <MenuPopover
          open={isOpen}
          anchorEl={element}
          onClose={handleClose}
          sx={{
            mt: 1,
            ml: 1,
          }}
        >
          <Stack spacing={1}>
            {otherFilters.map(({ deleteFn, facet, label, value }, index) => (
              <Box key={`${facet}-${label}-${index}`}>
                <Chip
                  label={label}
                  onDelete={() => deleteFn({ facet, value })}
                  size="small"
                  variant="outlined"
                />
              </Box>
            ))}
          </Stack>
        </MenuPopover>
      </Stack>
    </>
  );
}

export { AdvancedFilterChips };
