import { Trans } from 'react-i18next';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { LazyImage } from 'components/Image';
import {
  TypographyWithTranslation,
  useTranslationRoot,
} from 'components/with-translation.tsx';
import { UPLOAD_FILE_ICON } from 'constants/public-icons.ts';

function BlockContent() {
  const { localNamespace } = useTranslationRoot();

  return (
    <Stack
      spacing={2}
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        width: 1,
        textAlign: 'center',
      }}
    >
      <Box
        sx={{
          width: 1,
          maxWidth: `220px`,
        }}
      >
        <LazyImage src={UPLOAD_FILE_ICON} />
      </Box>
      <Box sx={{ p: 3 }}>
        <TypographyWithTranslation
          i18nKey="modal.dropTitle"
          gutterBottom
          variant="h5"
        />

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          <Trans
            i18nKey="modal.dropDescription"
            ns={localNamespace}
            components={{
              span: (
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ color: 'primary.main', textDecoration: 'underline' }}
                />
              ),
            }}
          />
        </Typography>
      </Box>
    </Stack>
  );
}

export { BlockContent };
