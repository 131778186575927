import { DevTools } from 'components/DevTools';
import { ThemeColorPresets } from 'theme/ThemeColorPresets';
import { Router } from 'routes';

import ThemeProvider from './theme';
import { ThemeLocalisation } from './theme/ThemeLocalisation.tsx';

function App() {
  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <ThemeLocalisation>
          <Router />
          <DevTools />
        </ThemeLocalisation>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}

export default App;
