import { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useBoolean } from 'components/customHooks/useBoolean';
import {
  ButtonWithTranslation,
  DialogContentTextWithTranslation,
  DialogTitleWithTranslation,
  WithTranslationRoot,
} from 'components/with-translation';
import { HITL_TOOL_PAGE } from 'constants/translation-keys';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { WARNING_ICON } from 'constants/public-icons';

interface DocumentLockedDialogProps {
  clientDocumentType?: string | null;
  documentType?: string | null;
  isLockedByCurrentUser: boolean;
  lastLockedBy: string | null;
  locked: boolean;
  reasonCode: string | null;
}

const reasonDescTranslations: { [key: string]: string } = {
  DOCUMENT_MISMATCH: 'reasonCodeDialog.documentMismatch',
  UNKNOWN_DOCUMENT_TYPES: 'reasonCodeDialog.unknownDocumentTypes',
  MULTIPLE_DOCUMENT_TYPES: 'reasonCodeDialog.multipleDocumentTypes',
};

// TODO: deprecate this and use DocumentLockedDialog and also make one for warning of document type mismatch
function DocumentDialog({
  clientDocumentType,
  documentType,
  isLockedByCurrentUser,
  lastLockedBy,
  locked,
  reasonCode,
}: DocumentLockedDialogProps) {
  const [isOpen, openModal, closeModal] = useBoolean();

  useEffect(() => {
    if (!isLockedByCurrentUser && locked) {
      openModal();
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (reasonCode && !isOpen) {
      openModal();
    }
  }, [reasonCode]); // eslint-disable-line

  const titleKey = reasonCode
    ? 'reasonCodeDialog.title'
    : 'documentLockedDialog.title';
  const descriptionKey = reasonCode
    ? 'reasonCodeDialog.description'
    : 'documentLockedDialog.description';

  return (
    <WithTranslationRoot namespace={HITL_TOOL_PAGE}>
      <Dialog
        open={isOpen}
        onClose={closeModal}
        aria-labelledby="document-dialog-title"
      >
        <DialogTitleWithTranslation
          id="document-dialog-title"
          i18nKey={titleKey}
          options={{ email: lastLockedBy }}
          startAdornment={
            <SvgIconStyle
              color="warning.main"
              src={WARNING_ICON}
              sx={{ mr: 1 }}
            />
          }
          sx={{
            alignItems: 'center',
            display: 'flex',
          }}
        />
        <DialogContent>
          <DialogContentTextWithTranslation
            id="document-dialog-description"
            i18nKey={descriptionKey}
            options={{
              reasonCode,
              description: reasonCode
                ? reasonDescTranslations[reasonCode]
                : undefined,
            }}
          />
          {reasonCode ? (
            <DialogContentTextWithTranslation
              id="document-dialog-description"
              i18nKey={reasonDescTranslations[reasonCode]}
              options={{
                expectedDocumentType: clientDocumentType,
                receivedDocumentType: documentType,
              }}
              sx={{
                pb: 1,
                whiteSpace: 'pre-line',
              }}
            />
          ) : null}
        </DialogContent>
        <DialogActions>
          <ButtonWithTranslation
            variant="contained"
            color="primary"
            i18nKey="documentLockedDialog.closeButton"
            onClick={closeModal}
          />
        </DialogActions>
      </Dialog>
    </WithTranslationRoot>
  );
}

export { DocumentDialog };
