import type { ReactNode } from 'react';
import { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { HEADER } from 'theme/layout';

const ContentWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  position: 'sticky',
  width: '100%',
  md: {
    position: 'relative',
    top: 0,
  },
}));

interface StickyPanelProps {
  additionalHeight?: number | string;
  children: ReactNode;
  conditionForSticky: boolean;
}

function StickyPanel({
  additionalHeight = 0,
  children,
  conditionForSticky,
}: StickyPanelProps) {
  const containerRef = useRef<HTMLDivElement>();
  const wrapperRef = useRef<HTMLDivElement>();
  const contentRef = useRef<HTMLDivElement>();
  const topPositioned = useRef<boolean>(false);
  const initWindowScrollTop = useRef<number>();
  const isScrollDown = useRef<boolean>();

  function headersHeight() {
    return (
      Math.round(HEADER.DASHBOARD_DESKTOP_HEIGHT) +
      parseInt(additionalHeight as string)
    );
  }

  function setTopPositioned(value: boolean) {
    topPositioned.current = value;
  }

  function setInitTopStickyPosition() {
    setTopPositioned(false);
    setContainerStyle('display: block;');
    setWrapperStyle(`
      bottom: auto;
      height: auto;
      justify-content: flex-start;
      position: sticky;
      top: ${headersHeight()}px;
    `);
  }

  function setContainerStyle(value: string) {
    if (containerRef.current) {
      containerRef.current.style.cssText += value;
    }
  }

  function setWrapperStyle(value: string) {
    if (wrapperRef.current) {
      wrapperRef.current.style.cssText += value;
    }
  }

  function getContainerBBox() {
    return containerRef.current?.getBoundingClientRect();
  }

  function getWrapperBBox() {
    return wrapperRef.current?.getBoundingClientRect();
  }

  function getContentHeight() {
    return (
      contentRef.current &&
      Math.round(contentRef.current?.getBoundingClientRect().height)
    );
  }

  function getIsScrollDown() {
    const currentWindowScrollTop = window.scrollY || window.pageYOffset;
    // If scroll position hasn't changed, return previous isScrollDown value
    if (initWindowScrollTop.current !== currentWindowScrollTop) {
      isScrollDown.current =
        initWindowScrollTop.current! < currentWindowScrollTop;
    }

    initWindowScrollTop.current = currentWindowScrollTop;
    return isScrollDown.current;
  }

  function setTopStickyPosition() {
    topPositioned.current = true;
    const contentWrapperTop = Math.round(getWrapperBBox()!.top);
    const containerTop = Math.round(getContainerBBox()!.top);
    const containerPaddingTop = window.getComputedStyle(
      containerRef.current as Element,
      null
    ).paddingTop;

    // Prolongate the content wrapper height till the beginning of the container top.
    const contentWrapperHeight =
      Math.abs(containerTop - contentWrapperTop) +
      getContentHeight()! -
      parseInt(containerPaddingTop);
    const top = window.innerHeight - contentWrapperHeight;

    setContainerStyle('display: flex; align-items: flex-start;');
    setWrapperStyle(`
      position: sticky;
      top: ${top}px;
      justify-content: flex-end;
      bottom: auto;
      height: ${contentWrapperHeight}px`);
  }

  function setBottomStickyPosition() {
    topPositioned.current = false;
    const contentWrapperBottom = Math.round(getWrapperBBox()!.bottom);
    const containerBottom = Math.round(getContainerBBox()!.bottom);

    // Prolongate the content wrapper height till the end of the container bottom.
    const contentWrapperHeight =
      containerBottom - contentWrapperBottom + getContentHeight()!;
    const bottom =
      contentWrapperHeight - (window.innerHeight - headersHeight());

    setContainerStyle('display: flex; align-items: flex-end;');
    setWrapperStyle(`
      position: sticky;
      bottom: -${bottom}px;
      height: ${contentWrapperHeight}px;
      top: auto;
      justify-content: flex-start;`);
  }

  function updatePositionOnScroll() {
    if (getIsScrollDown()) {
      if (!topPositioned.current) {
        setTopStickyPosition();
      }
    } else {
      if (topPositioned.current) {
        setBottomStickyPosition();
      }
    }
  }

  function setInitPosition() {
    window.removeEventListener('scroll', updatePositionOnScroll);

    if (conditionForSticky) {
      setInitTopStickyPosition();

      if (wrapperRef.current?.style.position.indexOf('relative') === -1) {
        initWindowScrollTop.current = window.scrollY || window.pageYOffset;

        const containerHeight = Math.round(getContainerBBox()!.height);
        const contentHeight = getContentHeight() as number;

        const windowHeight = window.innerHeight;

        // If panel higher than list of items
        if (containerHeight < contentHeight) {
          setRelativePosition();
          // If panel higher than window height
        } else if (contentHeight >= windowHeight - headersHeight()) {
          window.addEventListener('scroll', updatePositionOnScroll);
        }
      }
    } else {
      setRelativePosition();
    }
  }

  function setRelativePosition() {
    if (containerRef.current) {
      setContainerStyle('display: block');
    }
    if (wrapperRef.current) {
      setWrapperStyle(
        'bottom: auto; height: auto; position: relative; top: 0;'
      );
    }
  }

  function removeEventListeners() {
    window.removeEventListener('resize', setInitPosition);
    window.removeEventListener('scroll', updatePositionOnScroll);
  }

  useEffect(() => {
    setInitPosition();
    window.addEventListener('resize', setInitPosition);

    return () => {
      removeEventListeners();
    };
  }, []); // eslint-disable-line

  return (
    <Box
      sx={{
        height: 1,
        position: 'relative',
      }}
      ref={containerRef}
    >
      <ContentWrapper ref={wrapperRef}>
        <Box ref={contentRef}>{children}</Box>
      </ContentWrapper>
    </Box>
  );
}

export { StickyPanel };
