import { useSearchParams } from 'react-router-dom';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ControllerWithDropDown } from 'components/form/ControllerWithDropDown';
import { generalConfig } from 'config';

import { UploadData } from './UploadData';

type ReuploadFormValues = {
  file_id: string;
  env: string;
  claim_id?: string;
  client_claim_id?: string;
  client_document_type?: string;
  metadata?: Record<string, string>[];
};

interface ReuploadDocumentFormProps {
  onSubmit: (data: ReuploadFormValues) => void;
}

function ReuploadDocumentForm({ onSubmit }: ReuploadDocumentFormProps) {
  const [searchParam] = useSearchParams();
  const isDevEnv =
    generalConfig.customer === 'dev' || generalConfig.customer === 'base';

  const methods = useForm<ReuploadFormValues>({
    defaultValues: {
      file_id: '',
      env: isDevEnv ? 'dev' : 'prod',
      claim_id: searchParam.get('claimId') || '',
      client_claim_id: '',
      client_document_type: '',
      metadata: [],
    },
  });

  const menuItems = isDevEnv
    ? [
        {
          label: 'Dev',
          value: 'dev',
        },
      ]
    : [
        {
          label: 'Prod',
          value: 'prod',
        },
        {
          label: 'Nonprod',
          value: 'nonprod',
        },
      ];

  const { control, handleSubmit } = methods;

  const handleFormSubmit = (data: ReuploadFormValues) => {
    onSubmit(data);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Stack spacing={2}>
          <Box>
            <Typography
              variant="h5"
              sx={{
                mb: 2,
              }}
            >
              Reupload Document
            </Typography>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                alignItems: 'center',
                mt: 2,
              }}
            >
              <Controller
                control={control}
                name="file_id"
                render={({ field }) => (
                  <TextField
                    data-testid="file-id"
                    label="File ID"
                    variant="outlined"
                    required
                    sx={{
                      width: '100%',
                    }}
                    {...field}
                  />
                )}
              />
              <ControllerWithDropDown
                label="Environment"
                name="env"
                menuItems={menuItems}
                htmlFor="env"
                sx={{
                  width: '160px',
                }}
              />
            </Stack>
          </Box>
          <UploadData />
        </Stack>
      </form>
    </FormProvider>
  );
}

export { ReuploadDocumentForm };
export type { ReuploadFormValues };
