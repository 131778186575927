import type { ReactNode } from 'react';
import { useLayoutEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import type { ClaimFeature } from 'types/Claims';
import {
  TypographyWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { EDIT_ICON } from 'constants/public-icons';
import { useLocales } from 'locales/useLocales';
import { LINES_KEYS, MAIN_LIST, makeLinesListKey } from 'constants/claims';

import { useResizer } from './useResizer';
import { useReviewToolContext } from './useReviewToolContext';
import {
  getDefaultValues,
  getHiddenFeature,
  getTranslatedFeatureKey,
  LABEL_WIDTH,
} from './review-tool-utils';
import { ReviewToolGallery } from './ReviewToolGallery';
import { ReviewFormHeader } from './ReviewFormHeader';
import { HIDDEN_BY_BOOLEAN } from './review-tool-dropdowns';
import { sortOrder } from './mlj-fields-order.ts';

type Column = {
  field: keyof ClaimFeature;
  headerName: string;

  startAdornment?: ReactNode;
  width?: number | string;
};

const baseColumnStyles = {
  alignItems: 'center',
  display: 'flex',
  height: 36,
  overflow: 'hidden',

  fontSize: 12,
  whiteSpace: 'nowrap',

  px: 1,
  py: 0,
};

type FormValues = Record<string, string | boolean>;

interface ReviewFieldWrapperProps {
  children: ReactNode;
  displayLabel: string;
  id: string;
  isHidden?: boolean;
  order?: number;
}

function ReviewFieldWrapper({
  children,
  displayLabel,
  id,
  isHidden = false,
  order,
}: ReviewFieldWrapperProps) {
  return (
    <Stack
      direction="row"
      spacing={0.5}
      sx={{
        alignItems: 'flex-start',
        px: 1,
        display: isHidden ? 'none' : 'flex',
        order,
      }}
    >
      <Typography
        variant="body2"
        id={id}
        sx={{
          color: 'grey.500',
          flex: `0 0 ${LABEL_WIDTH}px`,
        }}
      >
        {displayLabel}
      </Typography>
      {children}
    </Stack>
  );
}

function ReviewReadOnlyLineItemsWrapper({
  lineItemsType,
  children,
}: {
  lineItemsType?: string;
  children: ReactNode;
}) {
  return (
    <Paper>
      <Box
        sx={{
          my: 2,
        }}
      >
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 1,
          }}
        >
          {lineItemsType ? (
            <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
              {lineItemsType}
            </Typography>
          ) : (
            <TypographyWithTranslation
              i18nKey="reviewTool.lineItems"
              sx={{ fontSize: 14, fontWeight: 'bold' }}
            />
          )}
        </Stack>
        {children}
      </Box>
    </Paper>
  );
}
function ReviewReadOnlyForm() {
  const fieldsRef = useRef<HTMLDivElement>(null);

  const { currentLanguage } = useLocales();
  const { config, state } = useReviewToolContext();

  const { initialEnrichmentFormWidth, wrapperRef } = useResizer();

  const { t } = useTranslationRoot();
  const methods = useForm<FormValues>({
    defaultValues: getDefaultValues(state),
  });

  useLayoutEffect(() => {
    if (fieldsRef.current) {
      const rect = fieldsRef.current.getBoundingClientRect();
      // @ts-ignore
      fieldsRef.current.style = `height: calc(100vh - ${rect.top}px - 24px)`;
    }
  }, []);

  const { lists, resources } = state;
  const docLevelItems = lists[MAIN_LIST];
  const linesLevelItems = lists[LINES_KEYS];

  const columns: Column[] = [
    {
      field: 'key',
      headerName: t('enrichmentTool.clientKey'),
      width: `${LABEL_WIDTH}px`,
    },
    {
      field: 'value',
      headerName: t('enrichmentTool.value'),
      startAdornment: <SvgIconStyle src={EDIT_ICON} height={17} width={17} />,
    },
  ];

  const renderRows = () => {
    return docLevelItems.map((id) => {
      const item = resources[id];
      const { value, key } = item;

      if (HIDDEN_BY_BOOLEAN[key]) {
        return;
      }

      // TODO: MLJ manbearpig hack................
      const mljHackKeys = {
        '**********************': 'customLabel.decision',
        '***********************': 'customLabel.results',
        '************************': 'customLabel.dataFromSubmittedDocuments',
        '*************************': 'customLabel.dataFromClientDb',
        '**************************': 'customLabel.feature',
      };
      if (key.includes('*')) {
        return (
          <Box key={item.key} sx={{ order: sortOrder.indexOf(key) }}>
            <TypographyWithTranslation
              i18nKey={mljHackKeys[key]}
              variant="subtitle2"
              sx={{
                p: 1,
              }}
            />
          </Box>
        );
      }

      const isHiddenFeature = getHiddenFeature(config);
      const isHidden = isHiddenFeature(key);

      const translateFeatureKey = getTranslatedFeatureKey({
        config,
        currentLanguage,
      });
      const displayLabel = translateFeatureKey(key);

      // apply sort order with css order:x according to sortOrder array
      return (
        <ReviewFieldWrapper
          key={id}
          id={key}
          displayLabel={displayLabel}
          isHidden={isHidden}
          order={sortOrder.indexOf(key)}
        >
          <Typography variant="body2">{value}</Typography>
        </ReviewFieldWrapper>
      );
    });
  };

  const renderLineItems = () => {
    if (linesLevelItems.length) {
      return linesLevelItems.map((name) => {
        const lineItemIds = lists[makeLinesListKey(name)];

        return (
          <ReviewReadOnlyLineItemsWrapper key={name} lineItemsType={name}>
            {lineItemIds.map((id, lineIndex) => {
              const item = lists[id];

              return (
                <Stack
                  key={id}
                  spacing={2}
                  sx={{
                    px: 2,
                  }}
                >
                  <Stack spacing={1} direction="column">
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{
                        alignItems: 'center',
                        height: 28,
                      }}
                    >
                      <TypographyWithTranslation
                        fontSize={12}
                        fontWeight="bold"
                        i18nKey="reviewTool.lineNumber"
                        options={{ number: lineIndex + 1 }}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={1}
                      useFlexGap
                      sx={{
                        flexWrap: 'wrap',
                      }}
                    >
                      {item.map((name) => {
                        const { key, value } = resources[name];
                        return (
                          <Stack
                            key={key}
                            sx={{
                              flexDirection: 'column',
                              gap: 0.5,
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                color: 'grey.500',
                                fontSize: 14,
                              }}
                            >
                              {key}
                            </Typography>
                            <Typography variant="body2">{value}</Typography>
                          </Stack>
                        );
                      })}
                    </Stack>
                  </Stack>
                </Stack>
              );
            })}
          </ReviewReadOnlyLineItemsWrapper>
        );
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <form>
        <Stack sx={{ backgroundColor: 'background.default' }}>
          <ReviewFormHeader />

          <Stack direction={{ md: 'row' }} ref={wrapperRef}>
            <Stack
              sx={{
                order: { xs: 2, md: 1 },
                borderRight: ({ palette }) => `1px solid ${palette.divider}`,
                width: { xs: '100%', md: Number(initialEnrichmentFormWidth) },
              }}
            >
              <Box
                sx={{
                  backgroundColor: 'background.neutral',
                  minHeight: 40,
                  position: 'relative',
                  overflow: 'initial',
                  px: 1,
                  '&:before': {
                    // little accent to show a little box to represent resizing
                    content: '""',
                    backgroundColor: 'grey.500',
                    display: 'block',
                    height: 20,
                    width: 3,

                    position: 'absolute',
                    right: -2,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 1,
                  },
                }}
              />
              <Stack
                component="section"
                ref={fieldsRef}
                sx={{
                  overflowY: 'auto',
                }}
              >
                <Stack direction="row">
                  {columns.map((column) => (
                    <Box
                      key={column.field}
                      sx={{
                        ...baseColumnStyles,
                        fontWeight: 'bold',
                        flex: column.width ? `0 0 ${column.width}` : '1 1 auto',
                      }}
                    >
                      <TypographyWithTranslation
                        i18nKey={column.headerName}
                        sx={{ fontSize: 12, fontWeight: 'bold' }}
                      />
                    </Box>
                  ))}
                </Stack>
                <Stack
                  component="section"
                  sx={{
                    gap: 2,
                  }}
                >
                  {renderRows()}
                </Stack>
                <Stack
                  component="section"
                  sx={{
                    gap: 2,
                  }}
                >
                  {renderLineItems()}
                </Stack>
              </Stack>
            </Stack>

            <ReviewToolGallery
              initialEnrichmentFormWidth={initialEnrichmentFormWidth}
            />
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
}

export { ReviewReadOnlyForm };
