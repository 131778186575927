import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';

function RedactionsLayout() {
  return (
    <Box sx={{ px: 2 }}>
      <Outlet />
    </Box>
  );
}

export default RedactionsLayout;
