import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';

import { HitLContextProvider } from './hitl-context';

function HitLLayout() {
  return (
    <HitLContextProvider>
      <Box sx={{ px: 2 }}>
        <Outlet />
      </Box>
    </HitLContextProvider>
  );
}

export default HitLLayout;
