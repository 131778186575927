import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import { DateRangePickerV2 } from 'components/DateRangePickerV2.tsx';

interface DateRangeFilterProps {
  name: string;
}

function DateRangeFilter({ name }: DateRangeFilterProps) {
  const { getValues, setValue } = useFormContext();
  const formValue = getValues(name);
  const startDate = formValue?.[0]
    ? dayjs(formValue?.[0]).utc(true).toDate()
    : undefined;
  const endDate = formValue?.[1]
    ? dayjs(formValue?.[1]).utc(true).toDate()
    : undefined;
  const [state, setState] = useState<{ startDate?: Date; endDate?: Date }>({
    startDate,
    endDate,
  });

  function updateStartDate(value: Date) {
    setState((state) => ({
      ...state,
      startDate: value,
    }));
  }

  function updateEndDate(value: Date) {
    setState((state) => ({
      ...state,
      endDate: value,
    }));
  }

  function onOk(date: Date[]) {
    setValue(name, [
      dayjs(date[0]).toISOString(),
      dayjs(date[1]).toISOString(),
    ]);
  }

  return (
    <DateRangePickerV2
      onOk={onOk}
      updateStartDate={updateStartDate}
      updateEndDate={updateEndDate}
      size="sm"
      startDate={state.startDate as Date}
      endDate={state.endDate as Date}
    />
  );
}

export { DateRangeFilter };
