import { Controller, useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';

interface DatePickerProps {
  name: string;
}

function DatePicker({ name }: DatePickerProps) {
  const { control } = useFormContext();

  return (
    <Controller
      render={({ field }) => {
        const { onChange, value, ...rest } = field;

        return (
          <TextField
            {...rest}
            value={dayjs.utc(value as string).format('YYYY-MM-DD')}
            onChange={(e) => {
              onChange(dayjs.utc(e.target.value).toISOString());
            }}
            type="date"
            fullWidth
            size="small"
          />
        );
      }}
      name={name}
      control={control}
    />
  );
}

export { DatePicker };
