import { Controller, useFormContext } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

import { LABEL_WIDTH } from './review-tool-utils';

interface ReviewFormMultiselectProps {
  item?: any;
  displayLabel?: string;
  optionsList?: string[];
}

function ReviewFormMultiselect({
  item,
  displayLabel,
  optionsList = [],
}: ReviewFormMultiselectProps) {
  const { key, value } = item;
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      defaultValue={value}
      name={key}
      render={({ field: { onChange, value }, fieldState }) => (
        <FormControl
          fullWidth
          error={Boolean(fieldState.error)}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: '36px',
            'label + div': {
              marginTop: 0,
            },
          }}
        >
          <InputLabel
            id={`${key}-label`}
            sx={{
              position: 'relative',
              transform: 'none',
              translate: 'none',
              maxWidth: `${LABEL_WIDTH}px`,
              height: '36px',
              flex: `0 0 ${LABEL_WIDTH}px`,
              fontSize: '12px',
              lineHeight: '36px',
              color: 'text.primary',
              '&.Mui-focused': {
                color: 'text.primary',
              },
            }}
          >
            {displayLabel}
          </InputLabel>
          <Select
            multiple
            labelId={`${key}-label`}
            id={key}
            value={value.length > 1 ? value.split(', ') : value.split('')}
            onChange={(event) => {
              onChange(event.target.value.join(', ') as string);
            }}
            inputProps={{
              'aria-label': key,
              sx: {
                fontSize: 12,
              },
            }}
            input={<Input />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={{
              MenuListProps: {
                dense: true,
              },
              PaperProps: {
                style: {
                  maxHeight: '75vh',
                },
              },
            }}
            sx={{
              maxWidth: `calc(100% - ${LABEL_WIDTH}px)`,
              overflow: 'hidden',
            }}
          >
            {optionsList.map((option) => (
              <MenuItem key={option} value={option}>
                <Checkbox
                  checked={
                    value
                      .split(', ')
                      .findIndex((item: string) => item === option) > -1
                  }
                  size="small"
                />
                <ListItemText primary={option} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
}

export { ReviewFormMultiselect };
