import type { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { useBoolean } from 'components/customHooks/useBoolean';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import { SvgIconStyle } from 'components/SvgIconStyle';
import {
  ButtonWithTranslation,
  TypographyWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';
import { CLOSE_ICON, TRASH_ICON } from 'constants/public-icons';
import { generateUuid } from 'utils/generate-uuid';

interface PreviewProps {
  index: number;
  error: ProgressEvent<EventTarget> | undefined;
  file: File;
  metadata?: Record<string, string>[];
  progress: number;
  setFiles: Dispatch<SetStateAction<any[]>>;
  thumbnail: string;
}

type State = {
  id: string;
  key: string;
  value: string;
};

function Preview({
  index,
  error,
  file,
  metadata,
  progress,
  setFiles,
  thumbnail,
}: PreviewProps) {
  const { t } = useTranslationRoot();
  const [isOpen, openDrawer, closeDrawer] = useBoolean();
  const [fields, setFields] = useState<State[]>(
    metadata?.length
      ? metadata.map((data) => ({
          id: generateUuid(),
          key: data.key,
          value: data.value,
        }))
      : []
  );

  const addNewItem = () => {
    setFields((prevFields) => [
      ...prevFields,
      {
        id: generateUuid(),
        key: '',
        value: '',
      },
    ]);
  };

  const removeItem = (index: number) => () => {
    setFields((prevFields) => {
      const newFields = [...prevFields];
      newFields.splice(index, 1);
      return newFields;
    });
  };

  const onChange =
    (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;

      setFields((prevFields) => {
        const newFields = [...prevFields];
        newFields[index][name as 'key' | 'value'] = value;
        return newFields;
      });
    };

  const onDocumentTypeChange =
    (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      setFiles((prevFiles) => {
        const newFiles = [...prevFiles];
        newFiles[index].clientDocumentType = value;
        return newFiles;
      });
    };

  const removeFile = () => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
  };

  const onSubmit = () => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles[index].metadata = fields.map(({ key, value }) => ({
        key,
        value,
      }));

      return newFiles;
    });

    closeDrawer();
  };

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          alignItems: 'flex-start',
        }}
      >
        <Card
          key={index}
          variant="outlined"
          elevation={0}
          sx={{
            alignItems: 'flex-start',
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
          }}
        >
          <CardContent sx={{ flex: 1 }}>
            <Stack spacing={2}>
              {progress > 0 ? (
                <LinearProgress
                  variant="determinate"
                  color="success"
                  value={progress}
                  sx={{ height: 10 }}
                />
              ) : null}
              {error ? (
                <LinearProgress
                  variant="determinate"
                  color="error"
                  value={100}
                  sx={{ height: 10 }}
                />
              ) : null}
              <Typography
                variant="subtitle2"
                sx={{
                  display: '-webkit-box',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                }}
              >
                {file.name}
              </Typography>
              <TextField
                label={t('form.documentType')}
                name="clientDocumentType"
                variant="outlined"
                onChange={onDocumentTypeChange(index)}
              />
              <Metadata metadata={metadata} />
              <Box>
                <ButtonWithTranslation
                  i18nKey="form.setMetadataButton"
                  variant="contained"
                  onClick={openDrawer}
                />
              </Box>
            </Stack>
          </CardContent>
          <Box sx={{ p: 1 }}>
            <Box
              sx={{
                borderRadius: 2,
                overflow: 'hidden',
                position: 'relative',
              }}
            >
              <CardMedia
                component="img"
                sx={{ width: 140, height: 180 }}
                image={thumbnail}
                alt=""
              />
            </Box>
          </Box>
        </Card>
        <Box>
          <IconButton
            size="large"
            aria-label={t('form.removeDocumentButton') as string}
            onClick={removeFile}
            color="error"
          >
            <SvgIconStyle src={TRASH_ICON} />
          </IconButton>
        </Box>
      </Stack>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={closeDrawer}
        PaperProps={{ elevation: 0, sx: { maxWidth: 608, width: 1 } }}
        ModalProps={{
          keepMounted: false,
        }}
      >
        <Box
          role="presentation"
          sx={{
            minWidth: 300,
            maxWidth: 608,
            width: '100%',
            position: 'relative',
          }}
        >
          <IconButton
            aria-label="close drawer"
            onClick={closeDrawer}
            sx={{
              position: 'absolute',
              right: ({ spacing }) => spacing(2),
              top: ({ spacing }) => spacing(2.5),
            }}
          >
            <SvgIconStyle src={CLOSE_ICON} />
          </IconButton>
          <Box
            component="header"
            sx={{
              p: 3,
            }}
          >
            <TypographyWithTranslation
              i18nKey="setMetadataDialog.title"
              variant="h5"
              options={{ filename: file.name }}
            />
          </Box>

          <InternalTabularData
            addNewItem={addNewItem}
            removeItem={removeItem}
            fields={fields}
            onChange={onChange}
          />

          <Stack sx={{ p: 3, pt: 0 }} spacing={2}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <ButtonWithTranslation
                variant="contained"
                color="primary"
                onClick={() => onSubmit()}
                i18nKey="setMetadataDialog.submitButton"
              />
            </Box>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
}

interface MetadataProps {
  metadata?: Record<string, string>[];
}

function Metadata({ metadata }: MetadataProps) {
  if (!metadata?.length) return null;

  return (
    <Stack spacing={1}>
      <TypographyWithTranslation
        variant="subtitle1"
        sx={{ color: 'text.primary' }}
        i18nKey="form.metadata"
      />
      <Box
        sx={{
          pl: 2,
        }}
      >
        <Stack direction="row" spacing={2}>
          <Box sx={{ flex: 1 }}>
            <TypographyWithTranslation
              i18nKey="form.key"
              variant="subtitle2"
              sx={{ color: 'text.primary' }}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <TypographyWithTranslation
              i18nKey="form.value"
              variant="subtitle2"
              sx={{ color: 'text.primary' }}
            />
          </Box>
        </Stack>
        {metadata.map(({ key, value }, index) => (
          <Stack key={index} direction="row" spacing={2}>
            <Box sx={{ flex: 1 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {key}
              </Typography>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {value}
              </Typography>
            </Box>
          </Stack>
        ))}
      </Box>
    </Stack>
  );
}

interface InternalTabularDataProps {
  addNewItem: () => void;
  onChange: (index: number) => (event: ChangeEvent<HTMLInputElement>) => void;
  removeItem: (index: number) => () => void;
  fields: State[];
}

function InternalTabularData({
  addNewItem,
  fields,
  onChange,
  removeItem,
}: InternalTabularDataProps) {
  const { t } = useTranslationRoot();
  return (
    <FormGroup>
      <Paper square={false}>
        <Stack
          spacing={2}
          sx={{
            mt: 1,
            pl: 2,
          }}
        >
          <Stack direction="row" spacing={2}>
            <Box sx={{ flex: 1 }}>
              <TypographyWithTranslation
                i18nKey="form.key"
                variant="subtitle2"
                sx={{ color: 'text.secondary' }}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <TypographyWithTranslation
                i18nKey="form.value"
                variant="subtitle2"
                sx={{ color: 'text.secondary' }}
              />
            </Box>
            <Box sx={{ flex: '0 0 48px', minWidth: 48 }} />
          </Stack>

          {fields.map(({ id, key, value }, index) => (
            <Stack
              direction="row"
              key={id}
              spacing={2}
              sx={{
                alignItems: 'center',
              }}
            >
              <Stack direction="row" key={id} spacing={2} sx={{ flex: 1 }}>
                <Box sx={{ flex: 1 }}>
                  <TextField
                    name="key"
                    onChange={onChange(index)}
                    value={key}
                    fullWidth
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <TextField
                    name="value"
                    onChange={onChange(index)}
                    value={value}
                    fullWidth
                  />
                </Box>
              </Stack>
              <Box sx={{ flex: '0 0 48px', minWidth: 48 }}>
                <IconButton
                  size="large"
                  aria-label={
                    t('setMetadataDialog.removeMetadataButton') as string
                  }
                  onClick={removeItem(index)}
                  color="error"
                >
                  <SvgIconStyle src={TRASH_ICON} />
                </IconButton>
              </Box>
            </Stack>
          ))}

          <Box>
            <ButtonWithTranslation
              i18nKey="setMetadataDialog.addMetadataButton"
              variant="contained"
              color="secondary"
              onClick={addNewItem}
            />
          </Box>
        </Stack>
      </Paper>
    </FormGroup>
  );
}

export { Preview };
