import { FabricObject, Path } from 'fabric';
import type { BoundingBox, Cropping, Redaction } from 'api/image-correction.ts';

import type { FileCanvas } from './types.ts';
import { findImage, findPath, generateRedactPath } from './utils.ts';

type RedactionWithSproutaiKey = Redaction & { sproutai_key?: string };

type Crop = {
  snapshot: string;
  cropping?: Cropping;
  redactions?: RedactionWithSproutaiKey[];
};

export type Correction =
  | {
      hasBeenCropped: true;
      pageId: string;
      snapshot?: string;
      redactions?: RedactionWithSproutaiKey[];
      crops?: Crop[];
    }
  | {
      hasBeenCropped: false;
      pageId: string;
      snapshot: string;
      redactions?: RedactionWithSproutaiKey[];
      crops?: Crop[];
    };

type WriteToCorrections = {
  canvas: FileCanvas;
  cropObject?: FabricObject;
  // supply snapsnot string manually without canvas for cropping
  overrideSnapshot?: string;
  page: number;
  pageId: string;
  isCrop?: boolean;
  subPage?: number;
};

const corrections = new Map<number, Correction>();

export function getCorrections() {
  return corrections;
}

export function clearCorrections() {
  corrections.clear();
}

export function appendSuffixToPageId(pageId: string) {
  return `${pageId}_b`;
}

export function appendCropSuffixToPageId(pageId: string, page: number) {
  return `${pageId}_${page}`;
}

export function appendSubIndexToPageId(pageId: string, subIndex: number) {
  // if pageId ends with underscore and number then do nothing
  if (String(pageId).match(/_\d+$/)) {
    return pageId;
  }

  return `${pageId}_${subIndex}`;
}

function takeSnapshot({
  canvas,
  imageObj,
}: {
  canvas: FileCanvas;
  imageObj: FabricObject | undefined;
}): Promise<string> {
  return new Promise((resolve, reject) => {
    if (imageObj) {
      const image = new Image();
      image.src = canvas.toDataURL({
        left: (canvas.getWidth() - imageObj.width * imageObj.scaleX) / 2,
        top: (canvas.getHeight() - imageObj.height * imageObj.scaleY) / 2,
        width: imageObj.width * imageObj.scaleX,
        height: imageObj.height * imageObj.scaleY,

        format: 'jpeg',
        multiplier: imageObj.width / (imageObj.width * imageObj.scaleX),
      });

      image.onload = () => {
        resolve(image.src);
      };

      image.onerror = (error) => {
        reject(error);
      };
    } else {
      reject(new Error('No image object found'));
    }
  });
}

export function dataURItoBlob(dataURI: string) {
  const binary = atob(dataURI.split(',')[1]);
  const array: number[] = [];

  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }

  return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
}

function formatRedactsToApi({
  pageId,
  redactObjects,
}: {
  pageId: string;
  redactObjects: (FabricObject & { sproutaiKey?: string })[];
}) {
  return redactObjects.map((obj) => {
    const { angle, top, left, width, height } = obj;
    const ceilLeft = Math.ceil(left);
    const ceilTop = Math.ceil(top);

    return {
      sproutai_key: obj?.sproutaiKey || undefined,
      bounding_box: {
        angle: Math.ceil(angle),
        top_left: [ceilLeft, ceilTop],
        top_right: [Math.ceil(left + width), ceilTop],
        bottom_left: [ceilLeft, Math.ceil(top + height)],
        bottom_right: [Math.ceil(left + width), Math.ceil(top + height)],
      } as BoundingBox,
      metadata: {
        old_page_id: pageId,
        new_page_id: appendSuffixToPageId(pageId),
      },
    };
  });
}

function formatCropToApi({
  object,
  pageId,
  subPage,
}: {
  object: FabricObject;
  pageId: string;
  subPage: number;
}) {
  const { angle, top, left, width, height } = object;
  const ceilLeft = Math.ceil(left);
  const ceilTop = Math.ceil(top);

  return {
    bounding_box: {
      angle: Math.ceil(angle),
      top_left: [ceilLeft, ceilTop],
      top_right: [Math.ceil(left + width), ceilTop],
      bottom_left: [ceilLeft, Math.ceil(top + height)],
      bottom_right: [Math.ceil(left + width), Math.ceil(top + height)],
    } as BoundingBox,
    metadata: {
      old_page_id: pageId,
      new_page_id: appendCropSuffixToPageId(pageId, subPage),
    },
  };
}

export function getRedactObjects(canvas: FileCanvas) {
  return canvas.getObjects().filter(findPath);
}

export async function writeToCorrections({
  canvas,
  cropObject,
  isCrop,
  overrideSnapshot,
  page,
  pageId,
  subPage,
}: WriteToCorrections) {
  const imageObj = canvas.getObjects().find(findImage);

  if (!imageObj) {
    return;
  }

  const angle = imageObj.get('angle');

  if (angle % 360 !== 0) {
    imageObj.rotate(0);
    canvas.fire('object:rotating');
  }

  let snapshotData: string = '';

  if (!overrideSnapshot) {
    try {
      snapshotData = await takeSnapshot({
        canvas,
        imageObj,
      });
    } catch (error) {
      console.error(error);
      return;
    }
  }

  if (isCrop && typeof subPage === 'number' && cropObject) {
    const oldCorrection = corrections.get(Number(page));
    const croppingPayload = formatCropToApi({
      pageId,
      object: cropObject,
      subPage,
    });

    corrections.set(Number(page), {
      ...oldCorrection,
      hasBeenCropped: true,
      pageId,
      crops: [
        ...(oldCorrection?.crops || []),
        {
          snapshot: overrideSnapshot || snapshotData,
          cropping: croppingPayload,
        },
      ],
    });
  }

  const redactObjects = getRedactObjects(canvas);

  if (redactObjects.length) {
    const redacts = formatRedactsToApi({
      pageId,
      redactObjects,
    });

    corrections.set(page, {
      hasBeenCropped: false,
      pageId,
      snapshot: snapshotData,
      redactions: redacts,
    });
  }

  return Promise.resolve();
}

export function restoreRedactsFromCorrections({
  canvas,
  page,
  subPage,
}: {
  canvas: FileCanvas;
  page: number;
  subPage?: number;
}) {
  const redactObjects =
    typeof subPage === 'undefined'
      ? corrections.get(page)?.redactions
      : corrections.get(page)?.crops?.[subPage]?.redactions;

  if (redactObjects?.length) {
    redactObjects.forEach((obj) => {
      const { angle, top_left, bottom_right } = obj.bounding_box;
      const width = bottom_right[0] - top_left[0];
      const height = bottom_right[1] - top_left[1];
      const path = new Path(generateRedactPath({ width, height }), {
        angle,
        left: top_left[0],
        top: top_left[1],
        width,
        height,
        fill: 'black',
        stroke: 'grey',
        border: 'none',
        uniformScaling: false,
        cornerColor: 'blue',
        transparentCorners: false,
        cornerStrokeColor: 'white',
        cornerStyle: 'circle',
        originX: 'center',
        originY: 'center',
        sproutaiKey:
          (obj as { sproutai_key?: string })?.sproutai_key || undefined,
      });

      canvas.add(path);
    });
  }
}
