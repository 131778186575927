import { lazy, Suspense } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import { useResponsive } from 'components/customHooks/useResponsive';
import { LoadingIcon } from 'components/icons/LoadingIcon';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { MENU_ICON } from 'constants/public-icons';
import claimsRoute from 'pages/Claims/claims.route';
import claimRoute from 'pages/Claim/claim.route';
import { HEADER, NAVBAR } from 'theme/layout';

import { AccountTooltip } from './AccountTooltip';
import { LanguageTooltip } from './LanguageTooltip';

interface DashboardHeaderProps {
  isMiniNavBarOpen: boolean;
  onOpenSidebar: VoidFunction;
}

const HeaderSearch = lazy(() => import('./HeaderSearch'));

function DashboardHeader({
  isMiniNavBarOpen,
  onOpenSidebar,
}: DashboardHeaderProps) {
  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'lg');

  return (
    <Box
      sx={{
        height: HEADER.MOBILE_HEIGHT,
        position: 'relative',
      }}
    >
      <AppBar
        color="inherit"
        sx={{
          boxShadow: 'none',
          height: HEADER.MOBILE_HEIGHT,
          width: {
            lg: isMiniNavBarOpen
              ? `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`
              : `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
          },
          zIndex: ({ zIndex }) => zIndex.appBar + 1,
        }}
      >
        <Toolbar
          sx={{
            minHeight: '100% !important',
            gap: 0.5,
          }}
        >
          {!isDesktop ? (
            <IconButton
              onClick={onOpenSidebar}
              sx={{ color: 'text.primary', mr: 1 }}
            >
              <SvgIconStyle src={MENU_ICON} />
            </IconButton>
          ) : null}
          {matchPath(claimRoute.path, pathname) ||
          matchPath(claimsRoute.path, pathname) ? (
            <Suspense fallback={<LoadingIcon />}>
              <HeaderSearch />
            </Suspense>
          ) : null}
          <Box sx={{ flexGrow: 1 }} />
          <Stack
            direction="row"
            spacing={{ xs: 0.5, sm: 1.5 }}
            sx={{
              alignItems: 'center',
            }}
          >
            <LanguageTooltip />
            <AccountTooltip />
          </Stack>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export { DashboardHeader };
