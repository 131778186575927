import type { ChangeEventHandler } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import {
  DialogContentTextWithTranslation,
  DialogTitleWithTranslation,
  LoadingButtonWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';
import { CLOSE_ICON, SQUARE_MINUS_ICON } from 'constants/public-icons';
import { SvgIconStyle } from 'components/SvgIconStyle';

interface RejectClaimDialogProps {
  isOpen: boolean;
  closeModal: VoidFunction;
  handleChange: ChangeEventHandler<HTMLInputElement>;
  reason: string;
  onReject: VoidFunction;
  isSubmitting: boolean;
}

function RejectClaimDialog({
  isOpen,
  closeModal,
  handleChange,
  reason,
  onReject,
  isSubmitting,
}: RejectClaimDialogProps) {
  const { t } = useTranslationRoot();

  return (
    <Dialog
      data-testid="reject-claim-dialog"
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="reject-dialog-title"
    >
      <DialogTitleWithTranslation
        id="reject-dialog-title"
        i18nKey="rejectDialog.title"
      />
      <DialogContent>
        <DialogContentTextWithTranslation
          id="reject-dialog-description"
          sx={{ mb: 2 }}
          i18nKey="rejectDialog.message"
        />
        <Stack spacing={3}>
          <TextField
            id="reason-freetext"
            label={t('rejectDialog.label')}
            variant="outlined"
            autoFocus
            required
            onChange={handleChange}
            value={reason}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButtonWithTranslation
          disabled={!reason}
          variant="contained"
          color="error"
          startIcon={
            <SvgIconStyle height={17} src={SQUARE_MINUS_ICON} width={17} />
          }
          onClick={onReject}
          loading={isSubmitting}
          autoFocus
          i18nKey="rejectDialog.submitButton"
        />
        <LoadingButtonWithTranslation
          variant="outlined"
          color="inherit"
          startIcon={<SvgIconStyle height={17} src={CLOSE_ICON} width={17} />}
          onClick={closeModal}
          i18nKey="rejectDialog.cancelButton"
        />
      </DialogActions>
    </Dialog>
  );
}
export { RejectClaimDialog };
