import type { WheelEvent } from 'react';
import type { TextFieldProps } from '@mui/material/TextField';
import { Controller, useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import merge from 'lodash.merge';
import { useTranslationRoot } from 'components/with-translation';

interface ControllerWithTextFieldV2Props {
  name: string;
  onChangeHandler?: any;
  textfieldProps?: TextFieldProps;
}

function ControllerWithTextFieldV2({
  name,
  onChangeHandler = () => {},
  textfieldProps,
}: ControllerWithTextFieldV2Props) {
  const { t } = useTranslationRoot();
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => {
        const { error, invalid } = fieldState;

        return (
          <TextField
            {...merge({}, field, textfieldProps)}
            aria-invalid={invalid}
            fullWidth
            helperText={error?.message && t(error.message)}
            onKeyPress={onChangeHandler}
            onKeyUp={(event) => {
              if (event.key === 'Backspace' || event.key === 'Delete') {
                onChangeHandler();
              }
            }}
            onWheel={
              // stop scrolling on number fields to prevent value from
              // incrementing or decrementing
              textfieldProps?.type === 'number'
                ? (e: WheelEvent<HTMLDivElement>) =>
                    (e.target as HTMLElement).blur()
                : undefined
            }
          />
        );
      }}
    />
  );
}

export { ControllerWithTextFieldV2 };
export type { ControllerWithTextFieldV2Props };
