import type { ReactNode } from 'react';
import { useEffect } from 'react';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag.ts';
import { PageView } from 'analytics/PageView.tsx';
import { useAuthenticationContext } from 'pages/Site/useAuthenticationContext.tsx';
import {
  useCurrentUserEmail,
  useCurrentUserFullName,
} from 'state/queries/current-user.ts';

posthog.init(import.meta.env.VITE_POSTHOG_KEY || window.__env__.POSTHOG_KEY, {
  api_host: import.meta.env.VITE_POSTHOG_HOST || window.__env__.POSTHOG_HOST,
  person_profiles: 'identified_only',
});

interface PostHogConfigProps {
  children: ReactNode;
}

let didInit = false;

function PostHogConfig({ children }: PostHogConfigProps) {
  const allowGTM = useFeatureFlag('enableGTM');
  const { user } = useAuthenticationContext();
  const { data: email } = useCurrentUserEmail();
  const { data: name } = useCurrentUserFullName();

  useEffect(() => {
    if (email && name && user && !didInit) {
      // Identify sends an event, so you want may want to limit how often you call it
      posthog?.identify(user.username);
      posthog?.group('company', import.meta.env.VITE_CUSTOMER);
      didInit = true;
    }
  }, [email, name, user]);

  if (allowGTM) {
    return (
      <PostHogProvider client={posthog}>
        {children}
        <PageView />
      </PostHogProvider>
    );
  }

  return <>{children}</>;
}

export { PostHogConfig };
