import { useContext } from 'react';
import { FeatureFlagsContext } from 'components/FeatureFlags';

function useFeatureFlag(featureName: string): boolean {
  const context = useContext(FeatureFlagsContext);

  if (context === undefined && import.meta.env.DEV) {
    console.warn('useFeatureFlag must be used within a FeatureFlagsProvider');
    return false;
  }

  if (Object.prototype.hasOwnProperty.call(context, featureName)) {
    return context[featureName];
  }

  if (import.meta.env.MODE === 'development') {
    console.warn(`${featureName} does not exist.`);
  }

  return false;
}

export { useFeatureFlag };
