export function round(num: number, decimalPlaces = 0): number {
  //SO: https://stackoverflow.com/a/48764436/9202873
  if (num < 0) {
    return -round(-num, decimalPlaces);
  }

  const p = Math.pow(10, decimalPlaces);
  const n = num * p;
  const f = n - Math.floor(n);
  const e = Number.EPSILON * n;

  // Determine whether this fraction is a midpoint value.
  return f >= 0.5 - e ? Math.ceil(n) / p : Math.floor(n) / p;
}

export function toFixed(num: number, fractionDigit = 4) {
  return num % 1 ? num.toFixed(fractionDigit) : num;
}
