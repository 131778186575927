import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';
import type { LineLevelContent } from 'types/Documents.ts';
import {
  isLineLevelItem,
  isNotLineLevelItem,
} from 'state/selectors/documents.ts';
import { generateUuid } from 'utils/generate-uuid.ts';

import type {
  DocumentContentWithId,
  DocumentLevelContentWithId,
  LineContentWithId,
  LineItemIdsAndResources,
  LineItemRow,
  Resources,
} from './types.ts';
import { DocumentItems } from './DocumentItems.tsx';
import { LineItemsWrapper } from './LineItemsWrapper.tsx';
import { LineItems } from './LineItems.tsx';
import { useEnrichmentToolContext } from './useEnrichmentToolContext.tsx';
import { generateLineItems, getGroupedLineItems } from './utils.ts';

interface FieldsProps {
  hasLineItems: boolean;
  items: DocumentContentWithId[];
  list: string[];
}

function Fields({ hasLineItems, items, list }: FieldsProps) {
  const { unregister, getValues, setValue } = useFormContext();
  const { state, setState } = useEnrichmentToolContext();
  const { columns, rows } = generateLineItems(items);

  const groupedAllLineItems = useMemo(() => {
    const items = Object.values(state.fields);
    const lines = items.filter(isLineLevelItem);
    return getGroupedLineItems(lines);
  }, [state.fields]);

  const prepareNewLineItems = (newLineIdx?: number) => {
    const exampleRow = [
      ...Object.values(groupedAllLineItems),
    ].pop() as DocumentLevelContentWithId[];
    const highestLineIdx = Object.values(groupedAllLineItems).reduce(
      (acc, row) => {
        const lineIdx = row[0].lineIdx as number;
        return lineIdx > acc ? lineIdx : acc;
      },
      0
    );

    return exampleRow.reduce(
      (acc, item) => {
        const id = generateUuid();
        const lineIdx = newLineIdx || highestLineIdx + 1;
        return {
          ...acc,
          ids: [...acc.ids, id],
          resources: {
            ...acc.resources,
            [id]: {
              ...item,
              deleted: false,
              id,
              lineIdx,
              source: 'HIL',
              valid:
                item.sproutaiKey === 'item_specialty' ||
                item.sproutaiKey === 'item_state_contribution_flag' ||
                item.sproutaiKey === 'item_state_contribution',
              value: '',
            },
          },
        } as LineItemIdsAndResources;
      },
      {
        ids: [],
        resources: {},
      } as LineItemIdsAndResources
    );
  };

  const getExistingFormValues = () => {
    // having to do this to stop the user from losing their previously typed
    // data before adding/removing line items
    const existingFormValues = getValues();

    return Object.keys(existingFormValues).reduce(
      (acc, key) => {
        acc[key] = {
          ...state.fields[key],
          value: existingFormValues[key] as string,
        };

        return acc;
      },
      { ...state.fields }
    );
  };

  const setEmptyValues = ({ ids, resources }: LineItemIdsAndResources) => {
    ids.forEach((id) => {
      const item = resources[id];

      if (item.valueType === 'bool') {
        setValue(id, false);
      } else {
        setValue(id, '');
      }
    });
  };

  const addLineItems = useCallback(
    () => {
      const existingFields = getExistingFormValues();
      const { ids, resources } = prepareNewLineItems();

      setEmptyValues({ ids, resources });

      setState((prevState) => ({
        ...prevState,
        lists: {
          ...prevState.lists,
          [prevState.fieldsListId]: [
            ...prevState.lists[prevState.fieldsListId],
            ...ids,
          ],
        },
        fields: {
          ...prevState.fields,
          ...existingFields,
          ...resources,
        },
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.fields]
  );

  const deleteLineItems = (row: LineItemRow) => {
    return () => {
      const ids = Object.values(row.keyToId) as string[];
      unregister(ids);

      const list = state.lists[state.fieldsListId];
      const filteredList = list.filter((id) => !ids.includes(id));

      // also might as well updates fields object at the same time
      const newFields = ids.reduce((acc, id) => {
        acc[id] = {
          ...state.fields[id],
          deleted: true,
        };
        return acc;
      }, {} as Resources);

      // having to do this to stop the user from losing their previously typed
      // data before adding/removing line items
      const existingFormValues = getValues();
      const existingFields = Object.keys(existingFormValues).reduce(
        (acc, key) => {
          acc[key] = {
            ...state.fields[key],
            value: existingFormValues[key],
          };

          return acc;
        },
        { ...state.fields }
      );

      setState((prevState) => ({
        ...prevState,
        lists: {
          ...prevState.lists,
          [prevState.fieldsListId]: filteredList,
        },
        fields: {
          ...prevState.fields,
          ...existingFields,
          ...newFields,
        },
      }));

      Object.keys(existingFormValues).forEach((key) => {
        setValue(key, existingFormValues[key]);
      });
    };
  };

  const deleteAllLineItems = () => {
    const list = state.lists[state.fieldsListId];
    const filteredList = list.filter((id) => {
      const item = state.fields[id];

      if (isNotLineLevelItem(item)) {
        return id;
      }
    });
    const lineItemsList = list.filter((id) => {
      const item = state.fields[id];

      if (isLineLevelItem(item)) {
        return id;
      }
    });

    unregister(lineItemsList);
    // also might as well updates fields object at the same time
    const newFields = lineItemsList.reduce((acc, id) => {
      acc[id] = {
        ...state.fields[id],
        deleted: true,
      };
      return acc;
    }, {} as Resources);

    // having to do this to stop the user from losing their previously typed
    // data before adding/removing line items
    const existingFormValues = getValues();
    const existingFields = Object.keys(existingFormValues).reduce(
      (acc, key) => {
        acc[key] = {
          ...state.fields[key],
          value: existingFormValues[key],
        };

        return acc;
      },
      { ...state.fields }
    );

    // prepare new line items
    const { ids: newLineItemIds, resources: newLineItemFields } =
      prepareNewLineItems();
    setEmptyValues({ ids: newLineItemIds, resources: newLineItemFields });

    setState((prevState) => ({
      ...prevState,
      lists: {
        ...prevState.lists,
        [prevState.fieldsListId]: [...filteredList, ...newLineItemIds],
      },
      fields: {
        ...prevState.fields,
        ...existingFields,
        ...newFields,
        ...newLineItemFields,
      },
    }));

    Object.keys(existingFormValues).forEach((key) => {
      setValue(key, existingFormValues[key]);
    });
  };

  const addLineBelow = (index: number) => () => {
    const nextIndex = index + 1;
    const lineItemsList = Object.keys(state.fields).filter((id) => {
      const item = state.fields[id];

      if (isLineLevelItem(item)) {
        return id;
      }
    });

    // check if the next lineIdx already exists
    const hasNextLineIdx = lineItemsList.some((id) => {
      const item = state.fields[id];

      return item.lineIdx === nextIndex;
    });

    // if yes then increment the lineIdx of all the line items below the next lineIdx
    if (hasNextLineIdx) {
      const oldFields = lineItemsList.reduce((acc, id) => {
        const item = state.fields[id] as LineLevelContent & { id: string };

        if (item.lineIdx >= nextIndex) {
          acc[id] = {
            ...item,
            lineIdx: item.lineIdx + 1,
          };
        }

        return acc;
      }, {} as Resources);
      const { ids, resources } = prepareNewLineItems(nextIndex);
      const newFields = {
        ...state.fields,
        ...oldFields,
        ...resources,
      };
      const oldList = [...state.lists[state.fieldsListId], ...ids];
      const newNonLineItemIdsList = oldList.filter((id) => {
        const item = newFields[id] as LineContentWithId;
        return item.lineIdx === null;
      });
      const newLineItemIdsList = oldList.filter((id) => {
        const item = newFields[id] as LineContentWithId;
        return item.lineIdx !== null;
      });
      const sortedLineItemsList = newLineItemIdsList.sort((a, b) => {
        const itemA = newFields[a] as LineContentWithId;
        const itemB = newFields[b] as LineContentWithId;

        if (itemA.lineIdx === null && itemB.lineIdx === null) return 0;
        if (itemA.lineIdx === null) return -1;
        if (itemB.lineIdx === null) return 1;
        return itemA.lineIdx - itemB.lineIdx;
      });

      setState((prevState) => ({
        ...prevState,
        lists: {
          ...prevState.lists,
          [prevState.fieldsListId]: [
            ...newNonLineItemIdsList,
            ...sortedLineItemsList,
          ],
        },
        fields: newFields,
      }));
    } else {
      // normal add lines
      addLineItems();
    }
  };

  const documentItems = items.filter(isNotLineLevelItem);
  //clear the policynumber field when client memid is manually entered ON KEYUP
  const policyNumberId =
    list.find((id) => state.fields[id].sproutaiKey === 'policy_number') ?? '';

  const node = (
    <DocumentItems items={documentItems} policyNumberId={policyNumberId} />
  );

  return hasLineItems ? (
    <LineItemsWrapper
      addLineItems={addLineItems}
      deleteAllLineItems={deleteAllLineItems}
      fields={node}
      lineItems={
        <LineItems
          addLineBelow={addLineBelow}
          columns={columns}
          deleteLineItems={deleteLineItems}
          rows={rows}
        />
      }
    />
  ) : (
    <Box
      sx={{
        width: 1,
      }}
    >
      {node}
    </Box>
  );
}

export { Fields };
