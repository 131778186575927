import {
  useLayoutEffect,
  useRef,
  type KeyboardEvent,
  type ReactNode,
} from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import merge from 'lodash.merge';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import type {
  ClaimFeature,
  ClaimFeatures,
  ClaimsValidateResults,
  Fields,
  LinesFeature,
} from 'types/Claims';
import {
  TypographyWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';
import { AWAITING_REVIEW, LINES_KEYS } from 'constants/claims';
import { MAIN_LIST } from 'constants/claims';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { EDIT_ICON } from 'constants/public-icons';
import { useLocales } from 'locales/useLocales';
import { ControllerWithSingleCheckbox } from 'components/form/ControllerWithSingleCheckbox';
import type { Config } from 'types/Config';
import { validateClaimReview } from 'state/queries/claims';
import { toast } from 'components/toast';
import debounce from '@mui/utils/debounce';
import {
  sendAnalyticEvent,
  updateWindowPerformanceObject,
  getEventTimestamp,
} from 'analytics/utils';
import {
  REVIEW_SCREEN_VALIDATION_END,
  REVIEW_SCREEN_VALIDATION_START,
} from 'analytics/events';

import { useResizer } from './useResizer';
import { useReviewToolContext } from './useReviewToolContext';
import {
  LABEL_WIDTH,
  getCheckboxFeature,
  getDefaultValues,
  getDropdownFeature,
  getHiddenFeature,
  getMultiselectFeature,
  getMultiselectOptions,
  getTranslatedFeatureKey,
  getUneditableFeature,
} from './review-tool-utils';
import { ReviewToolGallery } from './ReviewToolGallery';
import { ReviewFormLineItems } from './ReviewFormLineItems';
import { ReviewFormDropdown } from './ReviewFormDropdown';
import { ReviewFormHeader } from './ReviewFormHeader';
import { ReviewFormMultiselect } from './ReviewFormMultiselect';
import { HIDDEN_BY_BOOLEAN } from './review-tool-dropdowns';
import { sortOrder } from './mlj-fields-order.ts';

type Column = {
  field: keyof ClaimFeature;
  headerName: string;

  startAdornment?: ReactNode;
  width?: number | string;
};

const baseColumnStyles = {
  alignItems: 'center',
  display: 'flex',
  height: 36,
  overflow: 'hidden',

  fontSize: 12,
  whiteSpace: 'nowrap',

  px: 1,
  py: 0,
};

interface ReviewFormProps {
  onSubmit: (features: ClaimFeatures) => void;
}

type FormMainValues = Record<string, string | boolean>;
type FormLinesValues = Record<string, Record<string, string | boolean>[]>;
type FormValues = Record<string, FormMainValues | FormLinesValues>;

interface ReviewFieldWrapperProps {
  children: ReactNode;
  displayLabel: string;
  id: string;
  isHidden?: boolean;
  hasOwnLabel?: boolean;
  order?: number;
}

function ReviewFieldWithWatch({
  children,
  ...props
}: {
  children: ReactNode;
} & ReviewFieldWrapperProps) {
  const { control } = useFormContext();
  const { id } = props;
  const watchedValue = useWatch({
    control: control,
    name: HIDDEN_BY_BOOLEAN[id],
  });

  return (
    <ReviewFieldWrapper {...props} isHidden={!watchedValue}>
      {children}
    </ReviewFieldWrapper>
  );
}

function ReviewFieldWrapper({
  children,
  displayLabel,
  id,
  isHidden = false,
  hasOwnLabel = false,
  order,
}: ReviewFieldWrapperProps) {
  return (
    <Stack
      direction="row"
      sx={{
        alignItems: 'flex-start',
        px: 1,
        display: isHidden ? 'none' : 'flex',
        minHeight: '36px',
        order,
      }}
    >
      {!hasOwnLabel ? (
        <InputLabel
          htmlFor={id}
          sx={{
            flex: `0 0 ${LABEL_WIDTH}px`,
            fontSize: 12,
            lineHeight: 1.5,
            color: 'text.primary',
          }}
        >
          {displayLabel}
        </InputLabel>
      ) : null}
      {children}
    </Stack>
  );
}

function ReviewForm({ onSubmit }: ReviewFormProps) {
  const theme = useTheme();
  const { currentLanguage } = useLocales();
  const { config, claim, state, toggleIsSubmitting } = useReviewToolContext();
  const { initialEnrichmentFormWidth, wrapperRef } = useResizer();
  const { t } = useTranslationRoot();
  const methods = useForm<FormValues>({
    defaultValues: getDefaultValues(state),
  });
  const { lists, resources } = state;
  const docLevelItems = lists[MAIN_LIST];
  const linesLevelItems = lists[LINES_KEYS];
  const containerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const PADDING = Number(theme.spacing(2).slice(0, -2));
    function updateMaxHeight() {
      if (containerRef.current) {
        const container = containerRef.current;
        const rect = container.getBoundingClientRect();
        if (window.innerWidth >= theme.breakpoints.values.md) {
          container.style.maxHeight = `${window.innerHeight - rect.top - PADDING}px`;
        } else {
          container.style.maxHeight = '';
        }
      }
    }

    const debounceUpdateMaxHeight = debounce(updateMaxHeight, 400);

    debounceUpdateMaxHeight();
    window.addEventListener('resize', debounceUpdateMaxHeight);

    return () => {
      window.removeEventListener('resize', debounceUpdateMaxHeight);
    };
  }, [theme]);

  function transformFeatureKeyToStateValue(value: string | boolean) {
    if (typeof value === 'boolean' && value) {
      return 'True';
    } else if (typeof value === 'boolean' && !value) {
      return 'False';
    }
    return value;
  }

  function isFieldHiddenByBoolean(key: string) {
    return (
      !!HIDDEN_BY_BOOLEAN[key] && !methods.getValues(HIDDEN_BY_BOOLEAN[key])
    );
  }

  function getAllFailedRules(response: ClaimsValidateResults) {
    const { results } = response;
    return results.filter(({ result }) => result === 'failed');
  }

  function getIsAwaitingReview(response: ClaimsValidateResults) {
    const { stage } = response;
    return stage === AWAITING_REVIEW;
  }

  function getAllValidationErrorMsg(response: ClaimsValidateResults) {
    const rules = getAllFailedRules(response);
    return rules.map(({ title }) => title).join(' ');
  }

  function getFeatureKeys(response: ClaimsValidateResults) {
    const rules = getAllFailedRules(response);

    if (rules.length) {
      return rules.map(({ featureKeys }) => featureKeys).flat();
    }

    return [];
  }

  async function handleFormSubmit(features: FormValues) {
    updateWindowPerformanceObject(REVIEW_SCREEN_VALIDATION_START);
    toggleIsSubmitting();

    const linesKeys = lists[LINES_KEYS];

    function getFields(
      values: FormValues,
      condition: (key: string) => boolean
    ) {
      const v = { ...values };

      return Object.entries(v).reduce((acc, [key, value]) => {
        if (condition(key)) {
          return {
            ...acc,
            [key]: value,
          };
        } else {
          return acc;
        }
      }, {});
    }

    function transformEntry(acc: any, [key, value]: [string, any]) {
      return {
        ...acc,
        [key]: {
          key,
          value: isFieldHiddenByBoolean(key)
            ? ''
            : transformFeatureKeyToStateValue(value),
        },
      };
    }

    function transformFormValuesToState(features: FormValues) {
      const mainFields = getFields(
        features,
        (key) => !linesKeys.includes(key)
      ) as FormMainValues;
      const formLines = getFields(features, (key) =>
        linesKeys.includes(key)
      ) as FormLinesValues;

      const fields = Object.entries(mainFields).reduce(
        transformEntry,
        {}
      ) as Fields;

      const lines = Object.entries(formLines).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: value.map((item) =>
            Object.entries(item).reduce(transformEntry, {})
          ),
        }),
        {}
      ) as LinesFeature;

      return {
        fields,
        lines,
      };
    }

    const transformedFeatures = transformFormValuesToState(features);

    try {
      const validationResult = await validateClaimReview({
        id: claim.id,
        features: transformedFeatures,
      });
      sendAnalyticEvent({
        event: REVIEW_SCREEN_VALIDATION_END,
        performance:
          (Date.now() - getEventTimestamp(REVIEW_SCREEN_VALIDATION_START)) /
          1000,
        claimId: claim.id,
      });

      const isAwaitingReview = getIsAwaitingReview(validationResult);

      if (!isAwaitingReview) {
        onSubmit(transformedFeatures);
      } else {
        toast.error(
          t('reviewTool.validateChangesFail', {
            errorMsg: getAllValidationErrorMsg(validationResult),
          })
        );

        const featureKeys = getFeatureKeys(validationResult);
        featureKeys.forEach((key) => {
          methods.setError(key, { type: 'manual', message: 'Invalid' });
        });
      }
    } catch (error: any) {
      const errorMsg = typeof error === 'string' ? error : error.reason_message;
      toast.error(
        t('reviewTool.validateChangesFail', {
          errorMsg,
        })
      );
    }
  }

  // Keyboard shortcuts
  const handleKeyDown = (event: KeyboardEvent<HTMLFormElement>) => {
    // event.keyCode might be deprecated in other browsers
    // Enter key
    if (event.keyCode === 13 || event.key === 'Enter') {
      event.preventDefault();
    }

    // CTRL key and Enter key press together then submit
    if (event.ctrlKey && (event.keyCode === 13 || event.key === 'Enter')) {
      event.preventDefault();
      methods.handleSubmit(handleFormSubmit)();
    }
  };

  const columns: Column[] = [
    {
      field: 'key',
      headerName: t('enrichmentTool.clientKey'),
      width: `${LABEL_WIDTH}px`,
    },
    {
      field: 'value',
      headerName: t('enrichmentTool.value'),
      startAdornment: <SvgIconStyle src={EDIT_ICON} height={17} width={17} />,
    },
  ];

  const renderFields = () => {
    return docLevelItems.map((id) => {
      const item = resources[id];
      const key = item.key;
      // visual sort order
      const order = sortOrder.indexOf(key);

      const translateFeatureKey = getTranslatedFeatureKey({
        config,
        currentLanguage,
      });
      const displayLabel = translateFeatureKey(key);

      if (config) {
        const isSubmittingFeature = getUneditableFeature(config);
        const isHiddenFeature = getHiddenFeature(config);
        const disabled = isSubmittingFeature(key);
        const isHidden = isHiddenFeature(key);
        const isDropdownFeature = getDropdownFeature(config);
        const isCheckboxFeature = getCheckboxFeature(config);
        const isMultiselectFeature = getMultiselectFeature(config);

        // TODO: MLJ manbearpig hack................
        const mljHackKeys = {
          '**********************': 'customLabel.decision',
          '***********************': 'customLabel.results',
          '************************': 'customLabel.dataFromSubmittedDocuments',
          '*************************': 'customLabel.dataFromClientDb',
          '**************************': 'customLabel.feature',
        };
        if (key.includes('*')) {
          return (
            <Box key={item.key} sx={{ order }}>
              <TypographyWithTranslation
                i18nKey={mljHackKeys[key]}
                variant="subtitle2"
                sx={{
                  p: 1,
                }}
              />
            </Box>
          );
        }

        if (isDropdownFeature(key)) {
          return (
            <ReviewFieldWrapper
              key={key}
              id={key}
              displayLabel={displayLabel}
              order={order}
            >
              <ReviewFormDropdown key={key} name={key} testid={key} />
            </ReviewFieldWrapper>
          );
        }

        if (isCheckboxFeature(key)) {
          return (
            <ReviewFieldWrapper
              key={key}
              id={key}
              displayLabel={displayLabel}
              order={order}
            >
              <ControllerWithSingleCheckbox
                checkboxProps={{
                  'aria-label': key,
                  size: 'small',
                  id: key,
                }}
                name={key}
                sx={{
                  alignItems: 'flex-start',
                  mx: 0,
                  '& .MuiFormControlLabel-label': {
                    color: 'grey.500',
                    fontSize: 12,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  },
                }}
              />
            </ReviewFieldWrapper>
          );
        }

        if (isMultiselectFeature(key)) {
          const optionsList = getMultiselectOptions(
            config,
            key as keyof Config
          );

          return (
            <ReviewFieldWithWatch
              key={key}
              id={key}
              displayLabel={displayLabel}
              hasOwnLabel
              order={order}
            >
              <ReviewFormMultiselect
                item={item}
                displayLabel={displayLabel}
                optionsList={optionsList}
              />
            </ReviewFieldWithWatch>
          );
        }

        const textField = (
          <Controller
            name={item.key}
            control={methods.control}
            render={({ field, fieldState }) => (
              <TextField
                {...merge(
                  field,
                  {
                    'aria-label': item.key,
                  },
                  {
                    inputProps: {
                      sx: { fontSize: 12, lineHeight: 1.5 },
                      id: item.key,
                      'aria-label': item.key,
                    },
                    InputLabelProps: { shrink: false, htmlFor: item.key },
                  }
                )}
                disabled={disabled}
                hidden={isHidden}
                multiline
                maxRows={16}
                fullWidth
                hiddenLabel
                size="small"
                variant="standard"
                error={Boolean(fieldState.error)}
              />
            )}
          />
        );

        /* we are specifically handling a string type field as part of our
           'hide/show field based on a related boolean field' hack. */
        return !HIDDEN_BY_BOOLEAN[key] ? (
          <ReviewFieldWrapper
            key={id}
            id={key}
            displayLabel={displayLabel}
            isHidden={isHidden}
            order={order}
          >
            {textField}
          </ReviewFieldWrapper>
        ) : (
          <ReviewFieldWithWatch key={key} id={key} displayLabel={displayLabel}>
            {textField}
          </ReviewFieldWithWatch>
        );
      }

      return (
        <ReviewFieldWithWatch
          key={key}
          id={key}
          displayLabel={displayLabel}
          order={order}
        >
          <Controller
            name={item.key}
            control={methods.control}
            render={({ field, fieldState }) => (
              <TextField
                {...merge(
                  field,
                  {
                    'aria-label': item.key,
                  },
                  {
                    inputProps: {
                      sx: { fontSize: 12, lineHeight: 1.5 },
                      'aria-label': item.key,
                    },
                    InputLabelProps: { shrink: false },
                  }
                )}
                fullWidth
                multiline
                maxRows={16}
                hiddenLabel
                size="small"
                variant="standard"
                error={Boolean(fieldState.error)}
              />
            )}
          />
        </ReviewFieldWithWatch>
      );
    });
  };

  const renderLineItems = () =>
    linesLevelItems
      ? linesLevelItems.map((key) => (
          <Paper key={key}>
            <ReviewFormLineItems name={key} />
          </Paper>
        ))
      : null;

  return docLevelItems ? (
    <FormProvider {...methods}>
      <form
        data-testid="review-form"
        onSubmit={methods.handleSubmit(handleFormSubmit)}
        onKeyDown={handleKeyDown}
      >
        <Stack
          sx={{ backgroundColor: 'background.default', overflow: 'hidden' }}
          ref={containerRef}
          data-testid="review-form-container"
        >
          <ReviewFormHeader />
          <Stack
            direction={{ md: 'row' }}
            ref={wrapperRef}
            sx={{
              maxHeight: { md: '100%' },
              overflow: {
                md: 'hidden',
              },
            }}
          >
            <Stack
              sx={{
                order: { xs: 2, md: 1 },
                borderRight: ({ palette }) => `1px solid ${palette.divider}`,
                width: { xs: '100%', md: Number(initialEnrichmentFormWidth) },
                height: { xs: 1, md: 'auto' },
                overflowY: 'auto',
                overflowX: 'hidden',
              }}
            >
              <Box
                sx={{
                  backgroundColor: 'background.neutral',
                  minHeight: 40,
                  position: 'relative',
                  overflow: 'initial',
                  px: 1,
                  '&:before': {
                    // little accent to show a little box to represent resizing
                    content: '""',
                    backgroundColor: 'grey.500',
                    display: 'block',
                    height: 20,
                    width: 3,

                    position: 'absolute',
                    right: -2,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 1,
                  },
                }}
              />
              <Stack
                component="section"
                sx={{
                  h: 1,
                  overflowY: 'auto',
                }}
              >
                <Stack direction="row">
                  {columns.map((column) => (
                    <Box
                      key={column.field}
                      sx={{
                        ...baseColumnStyles,
                        fontWeight: 'bold',
                        flex: column.width ? `0 0 ${column.width}` : '1 1 auto',
                      }}
                    >
                      <TypographyWithTranslation
                        i18nKey={column.headerName}
                        sx={{ fontSize: 12, fontWeight: 'bold' }}
                      />
                    </Box>
                  ))}
                </Stack>
                <Stack component="section">{renderFields()}</Stack>
                <Stack component="section">{renderLineItems()}</Stack>
              </Stack>
            </Stack>
            <ReviewToolGallery
              initialEnrichmentFormWidth={initialEnrichmentFormWidth}
            />
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  ) : (
    <Box
      sx={{
        p: 2,
      }}
    >
      <TypographyWithTranslation i18nKey="reviewTool.noFields" />
    </Box>
  );
}

export { ReviewForm };
