import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import HttpApi from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';
import { generalConfig } from 'config';

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      backends: [
        HttpApi,
        resourcesToBackend(
          (lng: string, ns: string) => import(`./${lng}/${ns}.json`)
        ),
      ],
      backendOptions: [
        {
          loadPath: `/locales/${generalConfig.customer}/{{lng}}/{{ns}}.json`,
        },
      ],
    },
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    load: 'languageOnly',
    // users are most likely to land on the dashboard page first after login
    ns: ['common', 'dashboard-page'],
  });

export default i18n;
