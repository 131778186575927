import {
  AppConfigConfigApiResponse,
  AppConfigConfigVersionsApiResponse,
  AppConfigDeploymentsApiResponse,
} from 'api/transformers/types/appconfig';
import {
  AppConfigConfig,
  AppConfigConfigVersions,
  AppConfigDeployments,
} from 'types/AppConfig';
import api, { makeApiLink } from 'api/api';
import { useOptimisticMutation } from 'utils/react-query';
import {
  APP_CONFIG_NAME,
  APP_CONFIGS,
  APP_CONFIG_DEPLOY,
  APP_CONFIG_VERSIONS,
  APP_CONFIG_DEPLOYMENTS,
  APP_YAMLCONFIG,
  APP_YAMLCONFIG_NAME,
} from 'constants/api-endpoints';
import { useGet } from 'utils/react-query';
import {
  selectAppConfigConfig,
  selectAppConfigConfigVersions,
  selectAppConfigDeployments,
} from 'state/selectors/appconfig.ts';
import { pathToUrl } from 'utils/url.ts';
import { useQueryClient } from '@tanstack/react-query';

export function useGetAppConfigs() {
  return useGet<AppConfigConfigApiResponse, AppConfigConfig>({
    url: APP_CONFIGS,

    select: selectAppConfigConfig,
    prefix: APP_CONFIGS,
  });
}

export function useGetAppConfig({
  name,
  version,
}: {
  name: string;
  version: string;
}) {
  return useGet<{ [key: string]: string }, { [key: string]: string }>({
    url: `${APP_CONFIG_NAME}\\?version=:version`,
    params: { config_name: name, version },

    prefix: APP_CONFIGS,
  });
}

export function useGetConfigVersions(configName: string) {
  return useGet<AppConfigConfigVersionsApiResponse, AppConfigConfigVersions>({
    url: APP_CONFIG_VERSIONS,
    params: { config_name: configName },

    select: selectAppConfigConfigVersions,
    prefix: APP_CONFIGS,
  });
}

function updateAppConfig({ body, name }) {
  return api.put(
    makeApiLink(pathToUrl(APP_CONFIG_NAME, { config_name: name })),
    JSON.stringify(body)
  );
}

export function useUpdateAppConfig({
  name,
  version,
}: {
  name: string;
  version: string;
}) {
  const queryClient = useQueryClient();

  return useOptimisticMutation({
    fn: updateAppConfig,
    url: `${APP_CONFIG_NAME}\\?version=:version`,
    params: { config_name: name, version },
    meta: {
      errorMessage: 'Failed to save config',
      loadingMessage: 'Creating config',
      successMessage: 'Config created',
    },
    prefix: APP_CONFIGS,
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: [APP_CONFIGS] });
    },
  });
}

function updateYamlAppConfig({ body, name }) {
  return api.put(
    makeApiLink(pathToUrl(APP_YAMLCONFIG_NAME, { config_name: name })),
    body,
    { headers: new Headers({ 'Content-Type': 'application/x-yaml' }) }
  );
}

export function useUpdateYamlAppConfig({
  name,
  version,
}: {
  name: string;
  version: string;
}) {
  const queryClient = useQueryClient();

  return useOptimisticMutation({
    fn: updateYamlAppConfig,
    url: `${APP_YAMLCONFIG_NAME}\\?version=:version`,
    params: { config_name: name, version },
    meta: {
      errorMessage: 'Failed to save config',
      loadingMessage: 'Creating config',
      successMessage: 'Config created',
    },
    prefix: APP_YAMLCONFIG,
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: [APP_YAMLCONFIG] });
    },
  });
}

function deployAppConfig({
  name,
  version,
  strategyName,
}: {
  name: string;
  version: string;
  strategyName: string;
}) {
  return api.put(
    makeApiLink(
      pathToUrl(APP_CONFIG_DEPLOY, {
        config_name: name,
        version,
        strategyName,
      })
    )
  );
}

export function useDeployAppConfig() {
  const queryClient = useQueryClient();

  return useOptimisticMutation({
    fn: deployAppConfig,
    url: APP_CONFIG_DEPLOY,
    meta: {
      errorMessage: 'Failed to deploy config',
      loadingMessage: 'Deploying config',
      successMessage: 'Config deployed',
    },
    prefix: APP_CONFIGS,
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: [APP_CONFIGS] });
    },
  });
}

export function useGetAppConfigDeployments(configName: string) {
  return useGet<AppConfigDeploymentsApiResponse, AppConfigDeployments>({
    url: APP_CONFIG_DEPLOYMENTS,
    params: { config_name: configName },
    prefix: APP_CONFIGS,

    select: selectAppConfigDeployments,
  });
}
