import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { Correction } from 'components/ImageEditingTool/state.ts';
import { TypographyWithTranslation } from 'components/with-translation.tsx';
import redactionsRoute from 'pages/Redactions/redactions.route.tsx';
import { ImageEditingTool } from 'components/ImageEditingTool';
import { Loading } from 'components/ImageEditingTool/Loading.tsx';
import { SkeletonWrapper } from 'components/ImageEditingTool/SkeletonWrapper.tsx';
import { toast } from 'components/toast';
import { useTranslationRoot } from 'components/with-translation.tsx';
import { useCurrentUserEmail } from 'state/queries/current-user.ts';
import {
  PostPagesRedact,
  postPagesRedact,
  useGetPage,
  unlockPage,
  useGetPageUrl,
  usePageLock,
} from 'state/queries/pages.ts';

interface LandingProps {
  id: string;
}

function Landing({ id }: LandingProps) {
  const navigate = useNavigate();
  const { t } = useTranslationRoot();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const init = useRef(false);
  const hasSubmitted = useRef(false);

  // queries
  const pageQuery = useGetPage(id);
  const pageUrlQuery = useGetPageUrl(id);
  const pageLockQuery = usePageLock();
  const currentUserQuery = useCurrentUserEmail();

  // unlock page mechanism on unmount
  useEffect(() => {
    if (pageQuery.data && currentUserQuery.data) {
      const { lastLockedBy, locked } = pageQuery.data;
      const currentUserEmail = currentUserQuery.data;

      return () => {
        if (
          !hasSubmitted.current &&
          locked &&
          lastLockedBy === currentUserEmail
        ) {
          void unlockPage(id);
        }
      };
    }
  }, [currentUserQuery.data, pageQuery.data, id]);

  if (pageQuery.isError || pageUrlQuery.isError) {
    return (
      <SkeletonWrapper>
        <TypographyWithTranslation i18nKey="common.failedToLoadPage" />
      </SkeletonWrapper>
    );
  }

  if (pageQuery.isPending || pageUrlQuery.isPending) {
    return <Loading />;
  }

  if (!pageQuery.data || !pageUrlQuery.data?.length) {
    return (
      <SkeletonWrapper>
        <TypographyWithTranslation i18nKey="common.noPagesToShow" />
      </SkeletonWrapper>
    );
  }

  if (!init.current && !pageQuery.data.locked) {
    pageLockQuery.mutate(id);
    init.current = true;
  }

  const handleSubmit = async (corrections: Map<number, Correction>) => {
    setIsSubmitting(true);

    const toastId = toast.loading(t('editor.submitting'));
    const promises: { id: string; body: PostPagesRedact }[] = [];
    let newFields = pageQuery.data.content || [];

    corrections.forEach(({ redactions }) => {
      const hasBeenRedacted = redactions?.length;

      if (hasBeenRedacted) {
        const { fields, manualRedactions } = redactions.reduce(
          (acc, { bounding_box, sproutai_key }) => {
            // handle manual redactions in this loop
            if (sproutai_key) {
              return acc;
            }

            acc.manualRedactions.push({
              topLeft: bounding_box.top_left,
              topRight: bounding_box.top_right,
              bottomLeft: bounding_box.bottom_left,
              bottomRight: bounding_box.bottom_right,
            });
            return acc;
          },
          {
            fields: newFields,
            manualRedactions: [] as {
              topLeft: [number, number];
              topRight: [number, number];
              bottomLeft: [number, number];
              bottomRight: [number, number];
            }[],
          }
        );

        if (manualRedactions.length) {
          promises.push({
            id: redactions[0].metadata.old_page_id,
            body: {
              fields,
              manualRedactions: manualRedactions,
            },
          });
        }

        const redactionsWithSproutaiKey = redactions.filter(
          ({ sproutai_key }) => sproutai_key
        );

        redactionsWithSproutaiKey.forEach(({ bounding_box, sproutai_key }) => {
          newFields = newFields.map((field) => {
            if (field.sproutaiKey === sproutai_key) {
              return {
                ...field,
                bounding_box: {
                  topLeft: bounding_box.top_left,
                  topRight: bounding_box.top_right,
                  bottomLeft: bounding_box.bottom_left,
                  bottomRight: bounding_box.bottom_right,
                },
              };
            }
            return field;
          });
        });
      }
    });

    let request = Promise.all(promises.map(postPagesRedact));

    if (!promises.length) {
      request = postPagesRedact({
        id,
        body: { fields: newFields, manualRedactions: [] },
      });
    }

    toast.promise(request, {
      loading: t('editor.submitting'),
      success: () => {
        setIsSubmitting(false);
        toast.dismiss(toastId);
        hasSubmitted.current = true;
        navigate(redactionsRoute.createPath());
        return t('editor.submitSuccess');
      },
      error: () => {
        setIsSubmitting(false);
        toast.dismiss(toastId);
        return t('editor.submitError');
      },
    });
  };

  const handleNoChanges = () => {
    toast.promise(
      postPagesRedact({
        id,
        body: { fields: pageQuery.data.content || [], manualRedactions: [] },
      }),
      {
        loading: t('editor.submitting'),
        success: () => {
          hasSubmitted.current = true;
          navigate(redactionsRoute.createPath());
          return t('editor.submitSuccess');
        },
        error: () => t('editor.submitError'),
      }
    );
  };

  const hasRedactions = !!pageQuery.data.content?.length;
  const existingRedactions = hasRedactions
    ? (pageQuery.data.content
        ?.map(({ boundingBox, sproutaiKey, redacted }) => {
          if (redacted) {
            return {
              boundingBox,
              identifier: sproutaiKey,
            };
          }
          return null;
        })
        .filter(Boolean) as {
        boundingBox: {
          bottomLeft: [number, number];
          bottomRight: [number, number];
          topLeft: [number, number];
          topRight: [number, number];
        };
        identifier: string;
      }[])
    : [];

  return (
    <ImageEditingTool
      disableSubmitButton={false}
      isSubmitting={isSubmitting}
      urls={[
        {
          pageId: pageQuery.data.id,
          pageUrl: pageUrlQuery.data,
        },
      ]}
      onNoChanges={handleNoChanges}
      onSubmit={handleSubmit}
      mode="redact"
      prevRedactions={existingRedactions}
    />
  );
}

export { Landing };
