import { useEffect, useState } from 'react';
import { DialogModal } from 'components/dialog/Modal/DialogModal.tsx';
import { actionButton, header, text } from 'components/dialog/generators';

interface LockedModalProps {
  locked: boolean;
  lockedByAnotherUser: boolean;
  user: string | null;
}

function LockedModal({ locked, lockedByAnotherUser, user }: LockedModalProps) {
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (locked && lockedByAnotherUser) {
      setIsOpen(true);
    }
  }, [locked, lockedByAnotherUser]);

  return (
    <DialogModal
      isOpen={isOpen}
      handleClose={closeModal}
      getDialog={{
        index: () => [
          header('lockedModal.title', { i18nOptions: { user } }),
          text('lockedModal.description'),
          actionButton({
            action: closeModal,
            icon: null,
            label: 'lockedModal.closeButton',
          }),
        ],
      }}
    />
  );
}

export { LockedModal };
