import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import api from 'api/api.ts';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import { CircleCheck } from 'components/icons/CircleCheck.tsx';
import { ButtonWithTranslation } from 'components/with-translation.tsx';
import { LinearProgressWithLabel } from 'components/LinearProgressWithLabel.tsx';
import { FILE_EARMARK_ICON, RETRY_ICON } from 'constants/public-icons.ts';
import { formatBytes } from 'utils/string.ts';

interface UploadProgressTileProps {
  uploader: ReturnType<typeof api.multipartUpload>;
  file: File;
  id: string;
  retryFileUpload: (id: string) => void;
  uploadProgress: boolean | number;
}

function UploadProgressTile({
  uploader,
  file,
  id,
  retryFileUpload,
  uploadProgress,
}: UploadProgressTileProps) {
  return (
    <Stack
      spacing={1}
      sx={{
        my: 1,
        px: 2,
        py: 0.75,
        borderRadius: 0.75,
        border: (theme) => `solid 1px ${theme.palette.divider}`,
      }}
    >
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
        }}
      >
        <SvgIconStyle
          src={FILE_EARMARK_ICON}
          sx={{
            color: 'text.secondary',
            height: 28,
            mr: 2,
            width: 28,
          }}
        />
        <ListItemText
          primary={file.name}
          secondary={formatBytes(file.size)}
          primaryTypographyProps={{ variant: 'subtitle2' }}
          secondaryTypographyProps={{ variant: 'caption' }}
          sx={{ flex: 1 }}
        />
        {uploadProgress === 100 && (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              width: 24,
            }}
          >
            <CircleCheck />
          </Box>
        )}
        {typeof uploadProgress === 'boolean' && !uploadProgress && (
          <ButtonWithTranslation
            color="info"
            i18nKey="common.retryButton"
            size="small"
            startIcon={<SvgIconStyle src={RETRY_ICON} />}
            variant="contained"
            onClick={() => retryFileUpload(id)}
          />
        )}
        {typeof uploadProgress === 'number' && uploadProgress < 100 && (
          <ButtonWithTranslation
            i18nKey="common.abortButton"
            variant="outlined"
            color="error"
            size="small"
            onClick={() => uploader.abort()}
          />
        )}
      </Stack>
      <LinearProgressWithLabel
        color={typeof uploadProgress === 'boolean' ? 'error' : 'primary'}
        value={typeof uploadProgress === 'boolean' ? 0 : uploadProgress}
        label={
          typeof uploadProgress === 'boolean' ? 'common.fail' : uploadProgress
        }
        sx={{ flex: '0 0 100%', height: 10 }}
      />
    </Stack>
  );
}

export { UploadProgressTile };
