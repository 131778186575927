import type { ReactNode, SyntheticEvent } from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Typography from '@mui/material/Typography';
import { useGetFeatureFlagsCategories } from 'state/queries/feature-flags';
import { LoadingIcon } from 'components/icons/LoadingIcon';
import { Page } from 'components/Page';
import { FEATURE_FLAGS_PAGE } from 'constants/translation-keys';

import { List } from './List.tsx';

function FeatureFlagsPageV2() {
  const { isPending, isError, data } = useGetFeatureFlagsCategories();

  const [tab, setTab] = useState('0');

  const handleChange = (_: SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const Root = ({ children }: { children: ReactNode }) => (
    <Page i18nKeyTitle="meta.title" namespace={FEATURE_FLAGS_PAGE}>
      <Container
        maxWidth="md"
        sx={{
          mr: 'auto',
          ml: 'auto',
        }}
      >
        <Typography variant="h4" sx={{ mb: 2 }}>
          Feature Flags
        </Typography>
        {children}
      </Container>
    </Page>
  );

  return (
    <Root>
      {isPending ? (
        <LoadingIcon />
      ) : isError ? (
        <Typography variant="body1">Failed to get categories.</Typography>
      ) : data.categories.length ? (
        <TabContext value={tab}>
          <Box
            sx={{
              mb: 2,
            }}
          >
            <TabList onChange={handleChange}>
              {data.categories.map((category, index) => (
                <Tab key={category} label={category} value={String(index)} />
              ))}
            </TabList>
          </Box>
          {data.categories.map((category, index) => (
            <TabPanel key={`${category}-panel`} value={String(index)}>
              <List name={category} />
            </TabPanel>
          ))}
        </TabContext>
      ) : (
        <Typography>Categories is empty.</Typography>
      )}
    </Root>
  );
}

export default FeatureFlagsPageV2;
