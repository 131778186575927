import type { ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import { useTranslationRoot } from 'components/with-translation';

interface ControllerWithMultiCheckboxProps {
  name: string;
  namespace?: string;
  options?: { label: string; value: any; endAdornment?: ReactNode }[];
}

function ControllerWithMultiCheckbox({
  name,
  namespace,
  options,
  ...props
}: ControllerWithMultiCheckboxProps) {
  const { control } = useFormContext();
  const { t } = useTranslationRoot(namespace);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        function onSelected(option: string) {
          return field.value.includes(option)
            ? field.value.filter((value: string) => value !== option)
            : [...field.value, option];
        }

        return (
          <FormGroup>
            {options?.length
              ? options.map((option) => (
                  <Stack
                    key={option.value}
                    direction="row"
                    sx={{
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value.includes(option.value)}
                          onChange={() =>
                            field.onChange(onSelected(option.value))
                          }
                        />
                      }
                      label={t(option.label)}
                      {...props}
                    />
                    {option?.endAdornment}
                  </Stack>
                ))
              : null}
          </FormGroup>
        );
      }}
    />
  );
}

export { ControllerWithMultiCheckbox };
