import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  TableCellWithTranslation,
  useTranslationRoot,
} from 'components/with-translation.tsx';

import { IsValidCheckAll } from './IsValidCheckAll.tsx';

interface TableColumnHeadProps {
  hasIsValidCheckbox?: boolean;
}

function TableColumnHead({ hasIsValidCheckbox = true }: TableColumnHeadProps) {
  const { t } = useTranslationRoot();

  return (
    <TableHead>
      <TableRow>
        <TableCellWithTranslation
          i18nKey="table.page"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
            borderRight: ({ palette }) =>
              `3px solid ${palette.background.default} !important`,
            left: 0,
            minWidth: 180,
            position: 'sticky',
            width: ({ spacing }) =>
              140 + parseInt(spacing(2)) + parseInt(spacing(3)),
            whiteSpace: 'nowrap',
          }}
        />
        <TableCellWithTranslation
          i18nKey="table.pageId"
          sx={{
            whiteSpace: 'nowrap',
          }}
        />
        <TableCellWithTranslation
          i18nKey="table.clientClaimId"
          sx={{
            whiteSpace: 'nowrap',
          }}
        />
        <TableCellWithTranslation
          i18nKey="table.classification"
          sx={{ whiteSpace: 'nowrap' }}
        />
        <TableCellWithTranslation
          i18nKey="table.overrideClassification"
          sx={{ whiteSpace: 'nowrap' }}
        />
        <TableCell
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            whiteSpace: 'nowrap',
          }}
        >
          {t('table.valid')}
          {hasIsValidCheckbox && <IsValidCheckAll />}
        </TableCell>
        <TableCell sx={{ width: 'auto' }} />
      </TableRow>
    </TableHead>
  );
}

export { TableColumnHead };
