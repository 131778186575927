import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import type { FilterTypeDict } from 'types/Filters.ts';
import { DOCUMENT_TYPE } from 'constants/route-keys.ts';
import { unique } from 'utils/array.ts';
import { useGetDocumentsTypesDocumentTypes } from 'state/queries/documents.ts';

function useDocumentTypeFilter() {
  const [searchParams] = useSearchParams();
  const documentTypesQuery = useGetDocumentsTypesDocumentTypes();
  const documentTypes = useMemo(() => {
    const { data, isError, isPending } = documentTypesQuery;

    if (isError || isPending) {
      return {
        list: [],
        dict: {},
      };
    }

    return data.reduce(
      (acc, { documentGroup }) => {
        return {
          list: [...acc.list, documentGroup],
          dict: {
            ...acc.dict,
            [documentGroup]: {
              label: documentGroup,
              value: documentGroup,
            },
          },
        };
      },
      {
        list: [] as string[],
        dict: {} as FilterTypeDict,
      }
    );
  }, [documentTypesQuery]);

  const options = unique(documentTypes.list)
    .map((id) => documentTypes.dict[id])
    .filter(({ value }) => value);
  const filters = searchParams.getAll(DOCUMENT_TYPE);

  return {
    label: 'documentType',
    options,
    filters,
    defaultValues: { documentType: filters },
  };
}

export { useDocumentTypeFilter };
