import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
  accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { Claim } from 'types/Claims.ts';
import type { ClaimSummaryWidget } from 'types/Widgets.ts';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import { ARROW_FORWARD_ICON } from 'constants/public-icons.ts';
import { not } from 'utils/not.ts';
import { isJsonString } from 'utils/string.ts';

import { getRecommendationsWidgets, getTimeline } from './utils.ts';
import { PageLink } from './PageLink.tsx';

interface WidgetsProps {
  claim: Claim;
  updatePage: (page: number) => void;
  widgets: ClaimSummaryWidget[];
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  borderRadius: '0 !important',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  boxShadow: 'none !important',
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <SvgIconStyle src={ARROW_FORWARD_ICON} height={16} width={16} />
    }
    {...props}
  />
))(({ theme }) => ({
  minHeight: 40,
  marginY: 0,
  paddingLeft: theme.spacing(1),
  flexDirection: 'row-reverse',
  [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
    {
      transform: 'rotate(90deg)',
    },
  [`& .${accordionSummaryClasses.content}`]: {
    marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles('dark', {
    backgroundColor: 'rgba(255, 255, 255, .05)',
  }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  paddingTop: 0,
}));

function Widgets({ claim, updatePage, widgets }: WidgetsProps) {
  const recommendationWidgets = widgets.filter(getRecommendationsWidgets);
  const removedTimeline = recommendationWidgets.filter(not(getTimeline()));
  const filteredWidgets = removedTimeline.filter(({ feature }) => {
    // @ts-ignore
    const value = claim.features?.fields[feature]?.value;
    return !!value;
  });

  if (!filteredWidgets.length) {
    return (
      <Typography variant="body2" sx={{ p: 1.5 }}>
        No data.
      </Typography>
    );
  }

  return (
    <Box sx={{ height: 'calc(100% - 71px)', overflowY: 'auto' }}>
      {filteredWidgets.map(({ collapsed, feature, title }, index: number) => {
        // @ts-ignore assuming there is value because it's prefiltered
        const value = claim.features?.fields[feature]?.value;
        const isJson = isJsonString(value);
        const arr = isJson ? JSON.parse(value) : [];

        return (
          <Accordion key={`${title}-${index}`} defaultExpanded={!collapsed}>
            <AccordionSummary
              id={title}
              sx={{
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              <Typography variant="subtitle2">{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                {isJson
                  ? arr.map(({ content, page_numbers }, arrIndex) => {
                      const pageNumber = Array.isArray(page_numbers)
                        ? page_numbers
                        : [page_numbers];

                      return (
                        <span key={`${index}-${arrIndex}`}>
                          {pageNumber.map((page, linkIndex) => {
                            return (
                              <PageLink
                                key={`link-${index}-${arrIndex}-${linkIndex}`}
                                page={page}
                                updatePage={updatePage}
                              />
                            );
                          })}
                          {content}
                        </span>
                      );
                    })
                  : replaceWithClickableElement(updatePage)(value)}
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
}

const replaceWithClickableElement =
  (updatePage: (page: number) => void) => (text: string) => {
    const regex = /\[(\d+(?:\s*,\s*\d+)*)\]/g;

    return text.split(regex).map((part, index) => {
      if (index % 2 === 1) {
        const arr = part.split(',').map((num) => Number(num.trim()));
        return arr.map((num, idx) => (
          <PageLink
            key={`${index}-${idx}`}
            page={num}
            updatePage={updatePage}
          />
        ));
      }
      return part;
    });
  };

export { Widgets };
