import Rollbar from 'rollbar';
import { generalConfig } from 'config';

const rollbarConfig: Rollbar.Configuration = {
  // required
  accessToken: generalConfig.rollbarAccessToken,
  environment: generalConfig.rollbarEnvironment,
  captureUncaught: true,
  captureUnhandledRejections: true,

  // managing sensitive data
  captureIp: 'anonymize' as const,
  captureEmail: false,
  captureUsername: false,
  scrubRequestBody: true,
  scrubTelemetryInputs: true,
  autoInstrument: {
    network: true,
    log: false,
    dom: true,
    navigation: true,
    connectivity: true,
  },

  // optional
  codeVersion: generalConfig.platformVersion,
  payload: {
    client: {
      javascript: {
        code_version: generalConfig.platformVersion,
        source_map_enabled: true,
      },
    },
  },
  enabled: import.meta.env.PROD, // disable rollbar in dev mode
};

const rollbar = new Rollbar(rollbarConfig);

export default rollbar;
