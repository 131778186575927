import type { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import type { AppConfigConfigVersions } from 'types/AppConfig.ts';

const HTML_FOR = 'config-versions-dropdown';

interface VersionSelectorProps {
  handleChange: (value: string) => void;
  value: string;
  versions: AppConfigConfigVersions;
}

function VersionSelector({
  handleChange,
  value,
  versions,
}: VersionSelectorProps) {
  const onChange = (e: SelectChangeEvent) => {
    handleChange(e.target.value);
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel id={HTML_FOR}>Versions</InputLabel>
      <Select
        label="Versions"
        labelId={HTML_FOR}
        onChange={onChange}
        value={value}
        data-testid="versions-dropdown"
      >
        {versions.map(({ version }, index) => (
          <MenuItem
            key={index}
            value={parseInt(version)}
            data-testid={HTML_FOR}
          >
            {version}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export { VersionSelector };
