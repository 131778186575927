import type { ClaimFeature } from 'types/Claims';
import type { Config } from 'types/Config';
import type { Language } from 'locales/languages-base';
import { LINES_KEYS, MAIN_LIST, makeLinesListKey } from 'constants/claims';
import { convertArrayToListAndDict } from 'utils/array';
import { toTitleCase } from 'utils/string';

type List = string[];
type Resources = Record<string, ClaimFeature>;
type BaseProps = {
  lists: Record<string, List>;
  resources: Resources;
};
export type FormValues = Record<string, any>;
type TranslatedKeysProps = {
  config: Config;
  currentLanguage: Language;
};

export const FULLSCREEN_ZINDEX = 9000;
export const LABEL_WIDTH = 160;

export function normaliseData(features: ClaimFeature[]) {
  const [lists, resources] = convertArrayToListAndDict<ClaimFeature>(
    features,
    'key'
  );

  return { lists, resources };
}

export function getDefaultValues({ lists, resources }: BaseProps) {
  function transformValue(value: string) {
    if (value === 'True' || value === 'False') {
      return value === 'True';
    }
    return value;
  }

  //check if lists[MAIN_LIST] is null or an empty object
  const defaultValuesMain = lists[MAIN_LIST]
    ? lists[MAIN_LIST].reduce((acc, curr) => {
        return {
          ...acc,
          [curr]: transformValue(resources[curr].value),
        };
      }, {})
    : {};

  const defaultLines = lists[LINES_KEYS]?.length
    ? lists[LINES_KEYS].reduce(
        (acc, key) => {
          const linesListKey = makeLinesListKey(key);
          const item = lists[linesListKey].map((id) => {
            return lists[id].reduce((acc, item) => {
              const key = resources[item].key;
              const value = resources[item].value;

              return {
                ...acc,
                [key]: value,
              };
            }, {});
          });

          return { ...acc, [key]: item };
        },
        {} as Record<string, Record<string, ClaimFeature>[]>
      )
    : {};

  return { ...defaultValuesMain, ...defaultLines };
}

export function getTranslatedFeatureKey({
  config,
  currentLanguage,
}: TranslatedKeysProps) {
  return (key: string) => {
    if (config?.featureTranslations) {
      const { featureTranslations } = config;
      const featureTranslation = featureTranslations[currentLanguage.value];
      return featureTranslation?.[key] || key;
    }
    return toTitleCase(key);
  };
}

export function generateLineName(name: string, index: number, key: string) {
  return `${name}.${index}.${key}`;
}

function transformArrayOfStringsToDict(array: string[]) {
  return array.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: true,
    }),
    {} as Record<string, boolean>
  );
}

function getUneditableFeatures(config: Config) {
  return config.uneditableFeatures || [];
}

export function getUneditableFeature(config: Config): (key: string) => boolean {
  const uneditableFeatures = getUneditableFeatures(config);
  const dictionary = transformArrayOfStringsToDict(uneditableFeatures);

  return (key: string) => dictionary[key] ?? false;
}

function getDropdownFeatures(config: Config) {
  return config.lookupFeatures || [];
}

export function getDropdownFeature(config: Config): (key: string) => boolean {
  const lookupFeatures = getDropdownFeatures(config);
  const lookupFeaturesDict = transformArrayOfStringsToDict(lookupFeatures);

  return (key: string) => lookupFeaturesDict[key] ?? false;
}

function getCheckboxFeatures(config: Config) {
  return config.checkboxFeatures || [];
}

export function getCheckboxFeature(config: Config): (key: string) => boolean {
  const booleanFeatures = getCheckboxFeatures(config);
  const booleanFeaturesDict = transformArrayOfStringsToDict(booleanFeatures);

  return (key: string) => booleanFeaturesDict[key] ?? false;
}

function getMultiselectFeatures(config: Config) {
  return config.multiselectFeatures || [];
}

export function transformToBoolean(value: string) {
  return value === 'True';
}

export function getIsBooleanFeature(value: string) {
  return value === 'True' || value === 'False';
}

export function getMultiselectFeature(
  config: Config
): (key: string) => boolean {
  const multiselectFeatures = getMultiselectFeatures(config);
  const multiselectFeaturesDict =
    transformArrayOfStringsToDict(multiselectFeatures);

  return (key: string) => multiselectFeaturesDict[key] ?? false;
}

export function getMultiselectOptions(config: Config, searchKey: keyof Config) {
  return (config[searchKey] ?? []) as string[];
}

function getHiddenFeatures(config: Config) {
  return config.hiddenFeatures || [];
}

export function getHiddenFeature(config: Config): (key: string) => boolean {
  const hiddenFeatures = getHiddenFeatures(config);
  const hiddenFeaturesDict = transformArrayOfStringsToDict(hiddenFeatures);

  return (key: string) => hiddenFeaturesDict[key] ?? false;
}
