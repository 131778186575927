import type { ReactNode } from 'react';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

import { Wrapper } from './Wrapper.tsx';

interface SkeletonWrapperProps {
  children: ReactNode;
}

function SkeletonWrapper({ children }: SkeletonWrapperProps) {
  return (
    <Wrapper>
      <div style={{ height: '63px' }}>
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            p: 2,
          }}
        >
          <Stack direction="row" spacing={1}></Stack>
          <Stack direction="row" spacing={1}>
            <Skeleton
              animation="wave"
              variant="rounded"
              width={550}
              height={34}
            />
          </Stack>
        </Stack>
      </div>
      <Stack
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'grey.200',
          width: '100%',
          height: '100%',
        }}
      >
        {children}
      </Stack>
    </Wrapper>
  );
}

export { SkeletonWrapper };
