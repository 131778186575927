import { useEffect, useRef, useState } from 'react';
import type { MutableRefObject } from 'react';

const EVENT = 'resize';

function useRect<T extends Element>(
  delay = 300
): [DOMRect | undefined, MutableRefObject<T | null>] {
  const ref = useRef<T>(null);
  const timeout = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  const [rect, setRect] = useState<DOMRect>();

  function setRef() {
    setRect(ref.current?.getBoundingClientRect());
  }

  function withDebounce() {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(setRef, delay);
  }

  useEffect(() => {
    setRef();
    window.addEventListener(EVENT, withDebounce);
    return () => window.removeEventListener(EVENT, withDebounce);
  }, []); // eslint-disable-line

  return [rect, ref];
}

export { useRect };
