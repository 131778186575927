import { SvgIconStyle } from 'components/SvgIconStyle';
import { DialogTooltip } from 'components/dialog/Tooltip/DialogTooltip';
import {
  cancelButton,
  copyButton,
  deleteButton,
  unlockButton,
  header,
  text,
} from 'components/dialog/generators';
import { THREE_DOTS_ICON } from 'constants/public-icons';
import { useArchiveClaim, useUnlockClaim } from 'state/queries/claims';
import { copyToClipboard } from 'utils/client';

interface ClientTooltipProps {
  id: string;
}

const ARCHIVE = 'archive';
const COPY = 'copy';
const UNLOCK = 'unlock';

function TableTooltip({ id }: ClientTooltipProps) {
  const archiveClaimAction = useArchiveClaim({
    id,
    meta: {
      errorMessage: 'Failed to archive claim',
      loadingMessage: 'Archiving claim',
      successMessage: 'Claim archived',
    },
  });

  const unlockClaimAction = useUnlockClaim(id);

  return (
    <DialogTooltip
      getDialog={{
        index({ changePage, closeTooltip }) {
          return [
            copyButton({
              action() {
                copyToClipboard(id);
                changePage(COPY);
                setTimeout(closeTooltip, 2000);
              },
              label: 'tooltip.copy',
            }),
            unlockButton({
              action() {
                changePage(UNLOCK);
              },
              label: 'tooltip.unlock',
            }),
            deleteButton({
              action() {
                changePage(ARCHIVE);
              },
              label: 'tooltip.archive',
            }),
          ];
        },
        [ARCHIVE]: ({ closeTooltip }) => [
          header('tooltip.confirmArchive'),
          deleteButton({
            action() {
              archiveClaimAction.mutate({ id });
              closeTooltip();
            },
            label: 'tooltip.archive',
          }),
          cancelButton({
            action() {
              closeTooltip();
            },
            namespace: '',
          }),
        ],
        [UNLOCK]: ({ closeTooltip }) => [
          header('tooltip.unlock'),
          unlockButton({
            action() {
              unlockClaimAction.mutate({ id, force: true });
              closeTooltip();
            },
            label: 'tooltip.unlock',
          }),
          cancelButton({
            action() {
              closeTooltip();
            },
            namespace: '',
          }),
        ],
        [COPY]: () => [text('tooltip.copySuccess')],
      }}
      testId={`settings-${id}`}
    >
      <SvgIconStyle height={20} width={20} src={THREE_DOTS_ICON} />
    </DialogTooltip>
  );
}

export { TableTooltip };
