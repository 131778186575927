import TableRow from '@mui/material/TableRow';
import { TableCellWithTranslation } from 'components/with-translation';
import type { StringMap, TOptionsBase } from 'i18next';

interface NoItemsProps {
  columnLength: number;
  noItemsLabeli18nKey: string;
  options?: TOptionsBase & StringMap;
}

function NoItems({ columnLength, noItemsLabeli18nKey, options }: NoItemsProps) {
  return (
    <TableRow>
      <TableCellWithTranslation
        colSpan={columnLength}
        i18nKey={noItemsLabeli18nKey}
        options={options}
      />
    </TableRow>
  );
}

export { NoItems };
