import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { TypographyWithTranslation } from 'components/with-translation';

interface StatusMessageProps {
  lastUpdatedBy?: string | null;
  message: string | null;
  titleI18nKey: string;
}

function StatusMessage({
  lastUpdatedBy,
  message,
  titleI18nKey,
}: StatusMessageProps) {
  if (message) {
    return (
      <Box sx={{ p: 2 }}>
        <TypographyWithTranslation
          i18nKey={titleI18nKey}
          variant="subtitle1"
          sx={{
            fontSize: 12,
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}
        />
        <Typography variant="body2">{message}</Typography>
        {lastUpdatedBy ? (
          <TypographyWithTranslation
            i18nKey="common.lastUpdatedBy"
            variant="body2"
            options={{ lastUpdatedBy }}
          />
        ) : null}
      </Box>
    );
  }

  return null;
}

export { StatusMessage };
