import type { MouseEvent } from 'react';
import { useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';

interface ThumbnailProps {
  src: string;
}

function Thumbnail({ src }: ThumbnailProps) {
  const prevRect = useRef<DOMRect | undefined>(undefined);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Box
        component="img"
        role="button"
        src={src}
        alt=""
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{
          height: 140,
          backgroundColor: 'common.black',
          cursor: 'pointer',
          objectFit: 'contain',
          width: 140,
        }}
      />
      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: 'none' }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        slotProps={{
          paper: () => {
            if (!prevRect.current) {
              prevRect.current = anchorEl?.getBoundingClientRect();
            }

            return {
              sx: {
                maxWidth: `calc(100vw - ${prevRect.current?.right}px - 32px)`,
              },
            };
          },
        }}
      >
        <Box
          component="img"
          src={src}
          alt=""
          sx={{
            maxWidth: 1,
            maxHeight: `calc(100vh - 32px)`,
          }}
        />
      </Popover>
    </>
  );
}

export { Thumbnail };
