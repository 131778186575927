import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { TypographyWithTranslation } from 'components/with-translation';

interface ReviewFormClaimTypeProps {
  claimType?: string;
}

function ReviewFormClaimType({ claimType }: ReviewFormClaimTypeProps) {
  if (claimType) {
    return (
      <Stack spacing={0}>
        <TypographyWithTranslation
          component="span"
          i18nKey="enrichmentTool.claimType"
          variant="subtitle2"
        />
        <Typography
          component="span"
          variant="body2"
          sx={{
            fontSize: 14,
          }}
        >
          {claimType}
        </Typography>
      </Stack>
    );
  }

  return null;
}

export { ReviewFormClaimType };
