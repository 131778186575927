import 'dayjs/locale/pt';
import { enUS, ptPT } from '@mui/material/locale';
import pt_BR from 'rsuite/locales/pt_BR';
import en_US from 'rsuite/esm/locales/en_US';

const languagesAdvcarePtNonprod = [
  {
    colors: ['#c9072a', '#001b69'],
    label: 'English',
    rsuiteLocale: en_US,
    systemValue: enUS,
    value: 'en',
  },
  {
    colors: ['#006600', '#ff0000'],
    label: 'Português',
    rsuiteLocale: pt_BR,
    systemValue: ptPT,
    value: 'pt',
  },
];

export default languagesAdvcarePtNonprod;
