import type {
  ConfigApiResponse,
  ConfigReasonsApiResponse,
} from 'api/transformers/types/config.ts';
import type { Config, ConfigDocuments } from 'types/Config';
import { CONFIG, CONFIG_REASONS } from 'constants/api-endpoints';
import { useGet } from 'utils/react-query';
import { transformConfigApiResponse } from 'api/transformers/config';
import { selectConfigDocumentRejectReasons } from 'state/selectors/config.ts';

export function useGetConfig(name: string) {
  return useGet<ConfigApiResponse, Config>({
    url: CONFIG,
    params: { name },

    select: transformConfigApiResponse,
  });
}

export function useGetConfigDocumentRejectReasons(language: string) {
  return useGet<ConfigReasonsApiResponse, ConfigDocuments>({
    url: CONFIG_REASONS,
    params: {
      language,
    },

    select: selectConfigDocumentRejectReasons,
  });
}
