import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import visuallyHidden from '@mui/utils/visuallyHidden';

import { UploadData } from './UploadData';

type FormValues = {
  claim_id?: string;
  client_claim_id?: string;
  client_document_type?: string;
  document: string;
  metadata?: Record<string, string>[];
};

interface UploadDocumentFormProps {
  onSubmit: (data: FormValues) => void;
}

function UploadDocumentForm({ onSubmit }: UploadDocumentFormProps) {
  const [searchParam] = useSearchParams();

  const methods = useForm<FormValues>({
    defaultValues: {
      claim_id: searchParam.get('claimId') || '',
      client_claim_id: '',
      client_document_type: '',
      metadata: [],
      document: '',
    },
  });

  const { handleSubmit, register } = methods;

  const [fileName, setFileName] = useState('');

  const handleFormSubmit = (data: FormValues) => {
    onSubmit(data);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Stack spacing={2}>
          <Box>
            <Typography
              variant="h5"
              sx={{
                mb: 2,
              }}
            >
              Document
            </Typography>
            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
              }}
            >
              <Button
                variant="contained"
                component="label"
                sx={{
                  width: '160px',
                  marginRight: 2,
                }}
              >
                Upload Document
                <input
                  accept="image/*,.pdf"
                  hidden
                  type="file"
                  {...register('document', {
                    required: true,
                    onChange: (e) => {
                      const document = Array.from(
                        e.target.files as FileList
                      )[0];
                      setFileName(document.name);
                    },
                  })}
                  style={visuallyHidden}
                />
              </Button>
              <Typography variant="subtitle2">
                {fileName ? fileName : 'No files selected'}
              </Typography>
            </Stack>
          </Box>
          <UploadData />
        </Stack>
      </form>
    </FormProvider>
  );
}

export { UploadDocumentForm };
export type { FormValues };
