import { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useFormContext } from 'react-hook-form';
import { useBoolean } from 'components/customHooks/useBoolean';
import { TypographyWithTranslation } from 'components/with-translation';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag';

import type { HitLToolbarProps } from './types';
import { DocumentTypeModal } from './DocumentTypeModal';
import { DocumentTypeDropdown } from './DocumentTypeDropdown';

function HitLToolbar({
  document,
  documentTypeItemId,
  getResource,
  hasAwaitingReviewStage,
  onSubmit,
}: HitLToolbarProps) {
  const showClientDocumentChanger = useFeatureFlag(
    'enableHILDocumentTypeChanger'
  );

  const { getValues } = useFormContext();
  const item = getResource(documentTypeItemId);
  /**
   * some disgusting local logic here
   * if valid is true or null, then it's valid
   * this impacts how the document type changer is enabled/disabled and also
   * whether the items in the form in editable or not
   */
  const isValid = item?.valid || item?.valid === null;
  const defaultValue =
    documentTypeItemId && isValid ? getValues(documentTypeItemId) : '';
  const [docType, setDocType] = useState<string>(defaultValue || '');
  const [isOpen, openModal, closeModal] = useBoolean();

  // TODO: remove this when not needed
  if (!showClientDocumentChanger) {
    return null;
  }

  return (
    <>
      {document.clientDocumentType && hasAwaitingReviewStage ? (
        <Box
          sx={{
            position: 'relative',
            flexBasis: '50%',
            minWidth: 150,
          }}
        >
          <TypographyWithTranslation
            i18nKey="form.receivedDocumentType"
            variant="caption"
            sx={{
              position: 'absolute',
              top: -25,
              color: 'grey.500',
              whiteSpace: 'nowrap',
            }}
          />
          <Typography
            sx={{
              fontWeight: 'bold',
              color: 'primary.main',
            }}
          >
            {document.clientDocumentType}
          </Typography>
        </Box>
      ) : null}
      {hasAwaitingReviewStage && documentTypeItemId ? (
        <Box
          sx={{
            flexBasis: '50%',
            minWidth: 150,
          }}
        >
          <DocumentTypeModal
            closeModal={closeModal}
            isOpen={isOpen}
            onCancel={() => {
              setDocType(defaultValue);
            }}
            onConfirm={() => {
              onSubmit(docType);
            }}
          />
          <DocumentTypeDropdown
            value={docType}
            handleChange={(event) => {
              event.preventDefault();
              openModal();
              setDocType(event.target.value as string);
            }}
            valid={isValid}
          />
        </Box>
      ) : null}
    </>
  );
}

export { HitLToolbar };
