import type { SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';

import { TimelineIcon } from './TimelineIcon';

interface VerticalTimelineWidgetProps {
  sx?: SxProps<Theme>;
  value: string;
}

function VerticalTimelineWidget({ sx, value }: VerticalTimelineWidgetProps) {
  let timelineContent = [];

  try {
    if (value.length) {
      timelineContent = JSON.parse(value);
    } else {
      throw new Error('No value');
    }
  } catch (e) {
    timelineContent = [];
    console.warn('Error parsing timeline content', e as Error);
  }

  return (
    <Timeline
      sx={{
        ...sx,
        '& .MuiTimelineItem-root::before': {
          display: 'none',
        },
      }}
    >
      {timelineContent.map((item, index) => {
        const { start_date, end_date, event_type, description } = item;

        return (
          <TimelineItem key={index}>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineIcon icon={event_type} />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 'bold',
                  color: 'text.secondary',
                  whiteSpace: 'nowrap',
                }}
              >
                {start_date}
                {end_date && ` – ${end_date}`}
              </Typography>

              <Typography variant="body2">{description}</Typography>
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
}

export { VerticalTimelineWidget };
