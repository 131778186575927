import { useTranslation } from 'react-i18next';
import { DASHBOARD_PAGE } from 'constants/translation-keys';
import { Label } from 'components/Label';

function Counter({ count }: { count: number | undefined }) {
  const { t } = useTranslation(DASHBOARD_PAGE);

  return typeof count === 'number' ? (
    <Label
      color="error"
      variant="filled"
      sx={{ backgroundColor: 'error.light' }}
    >
      {count >= 1000000 ? t('dashboardNav.millionDocuments') : count}
    </Label>
  ) : null;
}

export { Counter };
