import { useEffect, useState } from 'react';

import type { DefaultToastOptions, Toast, ToastType } from './types';

const TOAST_LIMIT = 20;

export enum ActionType {
  ADD_TOAST = 'ADD_TOAST',
  UPDATE_TOAST = 'UPDATE_TOAST',
  UPSERT_TOAST = 'UPSERT_TOAST',
  DISMISS_TOAST = 'DISMISS_TOAST',
  REMOVE_TOAST = 'REMOVE_TOAST',
  START_PAUSE = 'START_PAUSE',
  END_PAUSE = 'END_PAUSE',
}

type Action =
  | {
      type: ActionType.ADD_TOAST;
      toast: Toast;
    }
  | {
      type: ActionType.UPSERT_TOAST;
      toast: Toast;
    }
  | {
      type: ActionType.UPDATE_TOAST;
      toast: Partial<Toast>;
    }
  | {
      type: ActionType.DISMISS_TOAST;
      id?: string;
    }
  | {
      type: ActionType.REMOVE_TOAST;
      id?: string;
    }
  | {
      type: ActionType.START_PAUSE;
      time: number;
    }
  | {
      type: ActionType.END_PAUSE;
      time: number;
    };

interface State {
  toasts: Toast[];
  pausedAt?: number;
}
const listeners: ((state: State) => void)[] = [];

let memoryState: State = { toasts: [], pausedAt: undefined };

const toastTimeouts = new Map<Toast['id'], ReturnType<typeof setTimeout>>();

function addToRemoveQueue(id: string) {
  if (toastTimeouts.has(id)) {
    return;
  }

  const timeout = setTimeout(() => {
    toastTimeouts.delete(id);
    dispatch({
      type: ActionType.REMOVE_TOAST,
      id,
    });
  }, 1000);

  toastTimeouts.set(id, timeout);
}

function clearFromRemoveQueue(toastId: string) {
  const timeout = toastTimeouts.get(toastId);
  if (timeout) {
    clearTimeout(timeout);
  }
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.ADD_TOAST:
      return {
        ...state,
        toasts: [action.toast, ...state.toasts].slice(0, TOAST_LIMIT),
      };

    case ActionType.UPDATE_TOAST:
      //  ! Side effects !
      if (action.toast.id) {
        clearFromRemoveQueue(action.toast.id);
      }

      return {
        ...state,
        toasts: state.toasts.map((t) =>
          t.id === action.toast.id ? { ...t, ...action.toast } : t
        ),
      };

    case ActionType.UPSERT_TOAST:
      return state.toasts.find((t) => t.id === action.toast.id)
        ? reducer(state, { type: ActionType.UPDATE_TOAST, toast: action.toast })
        : reducer(state, { type: ActionType.ADD_TOAST, toast: action.toast });

    case ActionType.DISMISS_TOAST:
      // ! Side effects ! - This could be execrated into a dismissToast() action, but I'll keep it here for simplicity
      if (action.id) {
        addToRemoveQueue(action.id);
      } else {
        state.toasts.forEach((toast) => {
          addToRemoveQueue(toast.id);
        });
      }

      return {
        ...state,
        toasts: state.toasts.map((t) =>
          t.id === action.id || action.id === undefined
            ? {
                ...t,
                visible: false,
              }
            : t
        ),
      };
    case ActionType.REMOVE_TOAST:
      if (action.id === undefined) {
        return {
          ...state,
          toasts: [],
        };
      }
      return {
        ...state,
        toasts: state.toasts.filter((t) => t.id !== action.id),
      };

    case ActionType.START_PAUSE:
      return {
        ...state,
        pausedAt: action.time,
      };

    case ActionType.END_PAUSE:
      return {
        ...state,
        pausedAt: undefined,
        toasts: state.toasts.map((t) => ({
          ...t,
          pauseDuration:
            t.pauseDuration + (action.time - (state.pausedAt || 0)),
        })),
      };
  }
}

export function dispatch(action: Action) {
  memoryState = reducer(memoryState, action);
  listeners.forEach((listener) => listener(memoryState));
}

const defaultTimeouts: {
  [key in ToastType]: number;
} = {
  blank: 4000,
  error: 4000,
  warning: 4000,
  success: 2000,
  loading: Infinity,
  custom: 4000,
};

export function useStore(toastOptions: DefaultToastOptions = {}): State {
  const [state, setState] = useState<State>(memoryState);

  useEffect(() => {
    listeners.push(setState);

    return () => {
      const index = listeners.indexOf(setState);
      if (index > -1) {
        listeners.splice(index, 1);
      }
    };
  }, [state]);

  const mergedToasts = state.toasts.map((t) => ({
    ...toastOptions,
    ...toastOptions[t.type],
    ...t,
    duration:
      t.duration ||
      toastOptions[t.type]?.duration ||
      toastOptions?.duration ||
      defaultTimeouts[t.type],
    style: {
      ...toastOptions.style,
      ...toastOptions[t.type]?.style,
      ...t.style,
    },
  }));

  return {
    ...state,
    toasts: mergedToasts,
  };
}
