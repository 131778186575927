import type { ReactNode } from 'react';
import type { Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import type { Document } from 'types/Documents';
import { LazyImage } from 'components/Image';
import { LinkWithRef } from 'components/Link';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { useBoolean } from 'components/customHooks/useBoolean';
import { Label } from 'components/Label';
import {
  ButtonWithTranslation,
  CardHeaderWithTranslation,
  TableCellWithTranslation,
  TypographyWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';
import {
  CLOSE_ICON,
  FILE_EARMARK_ICON,
  HUMAN_IN_THE_LOOP_ICON,
} from 'constants/public-icons';
import { ARROW_FORWARD_ICON } from 'constants/public-icons';
import {
  STAGE_LABEL_COLOR_MAP,
  STAGE_TRANSLATION_MAP,
} from 'constants/document-stage';
import claimHilToolRoute from 'pages/ClaimHILTool/claimHilTool.route';
import { formatDocumentDate } from 'utils/date';

import { SubHeaderItem } from './SubHeaderItem';

interface AttachmentProps {
  children: ReactNode;
  claimId: string;
  documentId?: string;
  documentsMap?: { [key: string]: Document };
  hasHilStatus?: boolean;
  hasDoneStatus?: boolean;
  stage?: string;
}

function borderStyle({ palette }: Theme) {
  return `1px solid ${palette.grey[300]}`;
}

function AttachmentV2({
  children,
  claimId,
  documentId,
  documentsMap,
  hasHilStatus,
  hasDoneStatus,
  stage,
}: AttachmentProps) {
  const { t } = useTranslationRoot();
  const [isOpen, openDrawer, closeDrawer] = useBoolean();
  const markup = (
    <Stack
      direction="row"
      sx={{
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 1,
        width: 1,
      }}
    >
      <SvgIconStyle
        src={documentId ? HUMAN_IN_THE_LOOP_ICON : FILE_EARMARK_ICON}
        sx={{ color: ({ palette }) => palette.text.secondary }}
      />
      {children}
    </Stack>
  );

  if (hasHilStatus && documentId) {
    const document = documentsMap ? documentsMap[documentId] : ({} as Document);
    const s = document?.stage || stage;

    return (
      <Button
        component={LinkWithRef}
        endIcon={
          s && (
            <Label
              color={STAGE_LABEL_COLOR_MAP[s]}
              sx={{ fontSize: '12px !important' }}
              i18nKey={s ? STAGE_TRANSLATION_MAP[s] : ''}
            />
          )
        }
        to={claimHilToolRoute.createPath(claimId, documentId) as string}
      >
        {markup}
      </Button>
    );
  }

  if (documentsMap && documentId) {
    const document = documentsMap[documentId];

    return (
      <>
        <Button
          onClick={openDrawer}
          endIcon={
            stage ? (
              <Label
                color={STAGE_LABEL_COLOR_MAP[stage]}
                sx={{ fontSize: '12px !important' }}
                i18nKey={STAGE_TRANSLATION_MAP[stage]}
              />
            ) : null
          }
        >
          {markup}
        </Button>
        <Drawer
          anchor="right"
          open={isOpen}
          onClose={closeDrawer}
          PaperProps={{ sx: { maxWidth: 'lg', width: '100%' } }}
        >
          <Box role="presentation">
            <Box sx={{ height: 1, maxHeight: '106px' }}>
              <Stack
                component="header"
                direction="row"
                spacing={3}
                sx={{
                  alignItems: 'flex-end',
                  padding: 3,
                  backgroundColor: 'background.default',
                  position: 'fixed',
                  maxWidth: 'lg',
                  width: 1,
                  zIndex: 1,
                }}
              >
                <SubHeaderItem titleI18Key="drawer.claimId">
                  <Typography
                    component="span"
                    sx={{
                      fontWeight: 'bold',
                    }}
                  >
                    {claimId}
                  </Typography>
                </SubHeaderItem>
                {hasDoneStatus ? (
                  <Box
                    sx={{
                      mr: 9,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <ButtonWithTranslation
                      component={LinkWithRef}
                      variant="outlined"
                      to={
                        claimHilToolRoute.createPath(
                          claimId,
                          documentId
                        ) as string
                      }
                      sx={{
                        whiteSpace: 'nowrap',
                      }}
                      i18nKey="drawer.toDocumentHistory"
                      endIcon={<SvgIconStyle src={ARROW_FORWARD_ICON} />}
                    />
                  </Box>
                ) : null}
              </Stack>
              <IconButton
                aria-label={t('drawer.closeButtonText') as string}
                onClick={closeDrawer}
                sx={{
                  position: 'fixed',
                  right: ({ spacing }) => spacing(3),
                  top: ({ spacing }) => spacing(3),
                  zIndex: 1,
                }}
              >
                <SvgIconStyle src={CLOSE_ICON} />
              </IconButton>
            </Box>
            <Grid container spacing={1}>
              <Grid size={7}>
                {document?.urls ? (
                  <>
                    {Object.values(document?.urls).map((url) => (
                      <LazyImage key={url} alt="" src={url} width={697} />
                    ))}
                  </>
                ) : (
                  <Box sx={{ px: 4 }}>
                    <TypographyWithTranslation i18nKey="drawer.noImage" />
                  </Box>
                )}
              </Grid>
              <Grid size={5}>
                <Card
                  sx={{ position: 'sticky', top: ({ spacing }) => spacing(1) }}
                >
                  <CardHeaderWithTranslation titleI18nKey="drawer.extractedValues" />
                  <Stack
                    direction={{ md: 'row' }}
                    spacing={{ xs: 2, md: 1 }}
                    sx={{
                      mt: 3,
                    }}
                  >
                    {document?.lastUpdatedBy ? (
                      <SubHeaderItem
                        titleI18Key="drawer.lastUpdatedBy"
                        sx={{
                          px: 3,
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{ whiteSpace: 'nowrap' }}
                        >
                          {document?.lastUpdatedBy}
                        </Typography>
                      </SubHeaderItem>
                    ) : null}

                    <SubHeaderItem
                      titleI18Key="drawer.lastUpdatedOn"
                      sx={{
                        borderLeft: borderStyle,
                        px: 3,
                      }}
                    >
                      <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                        {formatDocumentDate(document?.lastUpdatedOn)}
                      </Typography>
                    </SubHeaderItem>
                  </Stack>

                  <TableContainer>
                    <Table sx={{ mt: 3 }}>
                      <TableHead>
                        <TableRow>
                          <TableCellWithTranslation i18nKey="drawer.formalKey" />
                          <TableCellWithTranslation i18nKey="drawer.text" />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {document?.content ? (
                          document.content.map(
                            ({ sproutaiKey, value }, index) => {
                              return (
                                <TableRow key={`${sproutaiKey}_${index}`}>
                                  <TableCell>{sproutaiKey}</TableCell>
                                  <TableCell>{value}</TableCell>
                                </TableRow>
                              );
                            }
                          )
                        ) : (
                          <TableRow>
                            <TableCellWithTranslation
                              i18nKey="drawer.noContent"
                              rowSpan={2}
                            />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Drawer>
      </>
    );
  }

  return <Box sx={{ p: 1 }}>{markup}</Box>;
}

export { AttachmentV2 };
