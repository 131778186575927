import { useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { SvgIconStyle } from 'components/SvgIconStyle';
import {
  ButtonWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';
import { EDIT_ICON, TRASH_ICON } from 'constants/public-icons';
import { HITL_TOOL_PAGE } from 'constants/translation-keys';
import { toTitleCase } from 'utils/string';

import type {
  ItemDetailsProps,
  ItemFormProps,
  LineItemAccordionProps,
  LineItemProps,
} from './types';
import { AccordionContainer } from './AccordionContainer';
import { InputField } from './InputField';
import { generateSchema, getDefaultValues, getGroupedLineItems } from './utils';

function ItemForm({
  closeEditMode,
  handleSubmit,
  isCheck,
  isUnidentifiedItems,
  list,
  resources,
  setCrop,
  updateIsCheckList,
}: ItemFormProps) {
  const { t } = useTranslationRoot(HITL_TOOL_PAGE);

  return (
    <Stack spacing={2} sx={{ pt: 1 }}>
      {list.map((id) => {
        const item = resources[id];

        return (
          <InputField
            key={id}
            name={id}
            label={t('form.page', {
              number: item.pageIdx,
              label: toTitleCase(item.clientKey),
            })}
            isUnidentifiedItem={isUnidentifiedItems}
            isCheck={isCheck}
            setCrop={setCrop}
            updateIsCheckList={updateIsCheckList}
            {...item}
          />
        );
      })}
      <Stack direction="row" spacing={1}>
        <ButtonWithTranslation
          i18nKey="form.saveChangesButton"
          variant="outlined"
          type="button"
          onClick={handleSubmit}
        />
        <ButtonWithTranslation
          variant="outlined"
          color="inherit"
          type="button"
          sx={{ color: 'grey.500' }}
          onClick={closeEditMode}
          i18nKey="form.cancelButton"
        />
      </Stack>
    </Stack>
  );
}

function ItemDetails({
  onDeleteLine,
  list,
  openEditMode,
  resources,
}: ItemDetailsProps) {
  const { t } = useTranslation(HITL_TOOL_PAGE);
  const { getValues } = useFormContext();

  return (
    <>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
        }}
      >
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
            flexWrap: 'wrap',
            pt: 2,
          }}
        >
          {list.map((id) => {
            const { clientKey, deleted } = resources[id];

            return (
              <Stack
                direction="row"
                key={id}
                spacing={0.5}
                sx={{
                  ml: 1,
                  textDecoration: deleted ? 'line-through' : 'none',
                  textDecorationThickness: deleted ? '2px' : 'auto',
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {toTitleCase(clientKey)}:
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontSize: 14, wordBreak: 'break-all' }}
                >
                  {getValues(id)}
                </Typography>
              </Stack>
            );
          })}
        </Stack>

        <Stack>
          <Tooltip title={t('form.editLineItem') as string}>
            <IconButton onClick={openEditMode}>
              <SvgIconStyle height={15} width={15} src={EDIT_ICON} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('form.deleteLineItem') as string}>
            <IconButton onClick={() => onDeleteLine(list)}>
              <SvgIconStyle height={15} width={15} src={TRASH_ICON} />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    </>
  );
}

function LineItem({
  deleteLineItem,
  index,
  isCheck,
  isUnidentifiedItems,
  lineIdx,
  list,
  lockDocument,
  resources,
  setCrop,
  updateIsCheckList,
}: LineItemProps) {
  const [isEditMode, setEditMode] = useState(false);
  const [isSaved, setSaved] = useState(false);

  // this is from the parent form context
  const { setValue } = useFormContext();

  // new child useForm construct
  const schema = generateSchema(list, resources);
  const methods = useForm({
    defaultValues: getDefaultValues(list, resources),
    resolver: yupResolver(schema),
  });
  const isAllEmpty = list.every((id) => !resources[id].value);

  function updateValues() {
    const values = methods.getValues();

    Object.entries(values).forEach(([key, value]) => {
      setValue(key, value, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  }

  function revertValues() {
    const values: { [key: string]: string } = methods.getValues();

    list.forEach((id) => {
      // @ts-ignore
      methods.setValue(id, values[id] || resources[id].value, {
        shouldDirty: false,
        shouldTouch: false,
        shouldValidate: false,
      });
    });
  }

  function openEditMode() {
    setEditMode(true);
    lockDocument();
  }

  function closeEditMode() {
    revertValues();
    setEditMode(false);
    lockDocument();
  }

  function onSubmit() {
    updateValues();
    setSaved(true);
    closeEditMode();
  }

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        alignItems: 'center',
      }}
    >
      <Stack
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          pt: 2,
        }}
      >
        <Box
          sx={{
            backgroundColor:
              isSaved || !isUnidentifiedItems
                ? 'success.main'
                : isAllEmpty
                  ? 'error.main'
                  : 'grey.300',
            borderRadius: '50%',
            display: 'block',
            height: 20,
            textAlign: 'center',
            width: 20,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: 12,

              color: isAllEmpty
                ? 'common.white'
                : isSaved || !isUnidentifiedItems
                  ? 'common.white'
                  : 'grey.500',

              fontWeight: 'bold',
              lineHeight: '20px',
            }}
          >
            {lineIdx + 1}
          </Typography>
        </Box>
      </Stack>
      <Box sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            borderTop: index > 0 ? 1 : 0,
            borderColor: 'grey.300',
          }}
        >
          <FormProvider {...methods}>
            <TransitionGroup>
              {isEditMode && (
                <Collapse orientation="vertical">
                  <ItemForm
                    closeEditMode={closeEditMode}
                    handleSubmit={methods.handleSubmit(onSubmit)}
                    list={list}
                    resources={resources}
                    isCheck={isCheck}
                    isUnidentifiedItems={isUnidentifiedItems}
                    setCrop={setCrop}
                    updateIsCheckList={updateIsCheckList}
                  />
                </Collapse>
              )}

              {!isEditMode && (
                <Collapse orientation="vertical">
                  <ItemDetails
                    list={list}
                    resources={resources}
                    openEditMode={openEditMode}
                    onDeleteLine={deleteLineItem}
                  />
                </Collapse>
              )}
            </TransitionGroup>
          </FormProvider>
        </Box>
      </Box>
    </Stack>
  );
}

function LineItemAccordion({
  addLineItem,
  defaultExpanded = true,
  deleteLineItem,
  heading,
  isCheck,
  isUnidentifiedItems = true,
  list,
  lockDocument,
  resources,
  setCrop,
  updateIsCheckList,
}: LineItemAccordionProps) {
  const hasItems = !!list.length;

  const groupedLineItems = getGroupedLineItems(list, resources);

  if (hasItems) {
    return (
      <AccordionContainer
        defaultExpanded={defaultExpanded}
        heading={heading}
        childrenSpacing={1}
      >
        {Object.values(groupedLineItems).map((list, index) => (
          <LineItem
            deleteLineItem={deleteLineItem}
            key={`${list[0]}${index}`}
            isCheck={isCheck}
            index={index}
            isUnidentifiedItems={isUnidentifiedItems}
            lineIdx={resources[list[0]].lineIdx!}
            list={list}
            lockDocument={lockDocument}
            resources={resources}
            setCrop={setCrop}
            updateIsCheckList={updateIsCheckList}
          />
        ))}
        {isUnidentifiedItems ? (
          <Box sx={{ display: 'flex', pt: 2 }}>
            <ButtonWithTranslation
              variant="contained"
              i18nKey="form.addLineItemButton"
              sx={{ margin: '0 auto' }}
              onClick={addLineItem}
            />
          </Box>
        ) : null}
      </AccordionContainer>
    );
  }

  return null;
}

export { LineItemAccordion };
