import type { CSSProperties } from 'react';
import { generalConfig } from 'config';
import { getIconPath } from 'constants/public-icons';

interface AppLogoProps {
  height?: number;
  style?: CSSProperties;
  width?: number;
}

function AppLogo({ height = 70, style, width = 219 }: AppLogoProps) {
  const customer =
    generalConfig.customer === 'dev' ? 'base' : generalConfig.customer;
  const logo = getIconPath(`logo-${customer}.svg`);

  return (
    <span
      data-testid="app-logo"
      style={{
        background: `url(${logo}) no-repeat center / contain`,
        display: 'inline-block',
        height,
        width,
        ...style,
      }}
    />
  );
}

function AppLogoIcon({ height = 40, width = 40 }: AppLogoProps) {
  const logo = getIconPath(`brand-icon.svg`);

  return (
    <span
      data-testid="app-logo"
      style={{
        background: `url(${logo}) no-repeat center / contain`,
        display: 'inline-block',
        height,
        width,
      }}
    />
  );
}

export { AppLogo, AppLogoIcon };
