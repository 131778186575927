import type { ReactNode } from 'react';
import Card from '@mui/material/Card';
import { HEADER } from 'theme/layout.ts';

interface WrapperProps {
  children: ReactNode;
}

function Wrapper({ children }: WrapperProps) {
  return (
    <Card
      sx={{
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        height: `calc(100vh - ${HEADER.MAIN_DESKTOP_HEIGHT}px - 24px)`,
      }}
    >
      {children}
    </Card>
  );
}

export { Wrapper };
