import { round } from 'utils/numbers';
import { secondsConverter } from 'utils/date';

export function getMetric(unit: string, value: number) {
  let translation;
  let options: {
    days?: number;
    hours?: number;
    minutes?: number;
    seconds?: number;
    value?: number;
  } = {};

  if (unit === 'Percent') {
    translation = 'units.percent';
    options.value = round(Number(value), 2) * 100;
  } else if (unit === 'Seconds') {
    const duration = Number(value);
    const { days, hours, minutes, seconds } = secondsConverter(duration);

    options = {
      seconds,
      minutes,
      hours,
      days,
    };

    if (duration < 60) {
      translation = 'units.seconds';
    } else if (duration >= 60 && duration < 3600) {
      translation = 'units.minutes';
    } else if (duration >= 3600 && duration < 86400) {
      translation = 'units.hours';
    } else {
      translation = 'units.days';
    }
  } else {
    translation = 'units.count';
    options.value = Number(value);
  }

  return { options, translation };
}
