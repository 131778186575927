import type { ReactNode } from 'react';
import { createContext } from 'react';
import { CategoryReadFeatureFlagsData } from 'types/FeatureFlags';
import { useGetFeatureFlagsCategoryRead } from 'state/queries/feature-flags';
import { SomethingWentWrong } from 'pages/Site/SomethingWentWrong.tsx';
import Splash from 'pages/Site/Splash.tsx';
import rollbar from 'rollbar-config.ts';

import {
  TypographyWithTranslation,
  useTranslationRoot,
} from './with-translation.tsx';

type FeatureMap = {
  [featureName: string]: boolean;
};

const FeatureFlagsContext = createContext<FeatureMap>({});

function convertArrayToObject(array: CategoryReadFeatureFlagsData) {
  return array.reduce((obj, item) => {
    return { ...obj, [item.key]: item.value };
  }, {});
}

// TODO: refactor out escapeHatch
function FeatureFlagsProvider({
  children,
  features: escapeHatch,
}: {
  children: ReactNode;
  features?: FeatureMap;
}) {
  const { t } = useTranslationRoot();
  const { data, error, isPending, isError } = useGetFeatureFlagsCategoryRead();

  if (isPending) {
    return (
      <Splash>
        <TypographyWithTranslation i18nKey="initialisingApp" />
      </Splash>
    );
  }

  if (isError && !data?.length) {
    return (
      <SomethingWentWrong
        error={error}
        additionalMessage={t('failedToFetchFeatureFlags') as string}
      />
    );
  }

  if (isError && data?.length) {
    rollbar.warn('Failed to fetch subsequent feature flags', error);
  }

  const features = data.length ? convertArrayToObject(data) : escapeHatch;

  return (
    <FeatureFlagsContext.Provider value={features || {}}>
      {children}
    </FeatureFlagsContext.Provider>
  );
}

export { FeatureFlagsContext, FeatureFlagsProvider };
