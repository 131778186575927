import { createSelector } from 'reselect';
import { transformUsersResponse } from 'api/transformers/users.ts';

import { selectState } from './utils.ts';

export const selectUsersResponse = createSelector(
  selectState,
  transformUsersResponse
);

export const selectUsersData = createSelector(
  selectUsersResponse,
  (response) => response.data
);

export const selectUsersDataLength = createSelector(
  selectUsersResponse,
  (response) => response.data.length
);

export const selectUsersTotal = createSelector(
  selectUsersResponse,
  (response) => response.total
);
