import 'dayjs/locale/es';
import { enUS, esES } from '@mui/material/locale';
import en_US from 'rsuite/esm/locales/en_US';
import es_ES from 'rsuite/esm/locales/es_ES';

const languagesMetlifeCl = [
  {
    colors: ['#c9072a', '#001b69'],
    label: 'English',
    rsuiteLocale: en_US,
    systemValue: enUS,
    value: 'en',
  },
  {
    colors: ['#af0d12', '#fabe00', '#af0d12'],
    label: 'Español',
    rsuiteLocale: es_ES,
    systemValue: esES,
    value: 'es',
  },
];

export default languagesMetlifeCl;
