import type { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTranslationRoot } from 'components/with-translation';
import { LoadingIcon } from 'components/icons/LoadingIcon';
import { useGetDocumentTypes } from 'state/queries/documents';

interface DocumentTypeDropdownProps {
  handleChange: (event: SelectChangeEvent<string>) => void;
  valid: boolean;
  value?: string;
}

function DocumentTypeDropdown({
  handleChange,
  valid,
  value,
}: DocumentTypeDropdownProps) {
  const { t } = useTranslationRoot();
  const { data, isPending, isSuccess } = useGetDocumentTypes();

  if (isPending) {
    return <LoadingIcon />;
  }

  if (isSuccess) {
    const { documentTypes } = data;
    const menuItems = documentTypes.map(({ documentType }) => ({
      label: documentType,
      value: documentType,
    }));

    return (
      <FormControl fullWidth error={!valid}>
        <InputLabel id="document-type-filter">
          {t('form.identifiedDocumentType')}
        </InputLabel>
        <Select
          label={t('form.identifiedDocumentType')}
          labelId="document-type-filter"
          onChange={handleChange}
          value={value}
        >
          {menuItems.map(({ label, value }, index) => (
            <MenuItem key={index} value={value}>
              {t(label)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  return null;
}

export { DocumentTypeDropdown };
