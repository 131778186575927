import { lazy } from 'react';
import { loadable } from 'components/loadable';

const HealthPage = loadable(lazy(() => import('./Health')));

const healthRoute = {
  path: '/health',
  element: <HealthPage />,
};

export default healthRoute;
