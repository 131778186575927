// TODO: hardcoding this for now until backend knows how to handle this
export const DROPDOWN_FEATURES = [
  'client_tax_id',
  'policy_client_tax_id',
  'provider_tax_id',
  'reason_id',
  'referrer_provider',
  'self_funding_tax_id',
  'doubts',
];

export const HIDDEN_BY_BOOLEAN: Record<string, string> = {
  doubts: 'treat_manually',
  self_funding_tax_id: 'pre_reimbursed',
};

export const DROPDOWN_LINE_ITEM_KEYS = ['item_code', 'item_description'];

export const DOUBLE_WIDTH_LINE_ITEM_KEYS = ['service_description'];

export function getIsDescription(searchKey: string) {
  return searchKey === 'description';
}

export function getLookupMappingCode(name: string) {
  return `${getLineItemPrefix(name)}code`;
}

export function getLineItemPrefix(name: string) {
  return `${name.substring(0, name.lastIndexOf('.') + 1)}item_`;
}
