import type { ChangeEvent } from 'react';
import { useState } from 'react';
import { useTranslationRoot } from 'components/with-translation.tsx';
import { useBoolean } from 'components/customHooks/useBoolean.ts';
import { useRejectClaim } from 'state/queries/claims.ts';

function useRejectDialog(claimId: string, navigateToNextContext: () => void) {
  const { t } = useTranslationRoot();
  const [isOpen, openDialog, closeDialog] = useBoolean();
  const [reason, setReason] = useState<string>('');
  const rejectClaim = useRejectClaim({
    meta: {
      loadingMessage: t('rejectDialog.submitLoading') as string,
      successMessage: t('rejectDialog.submitSuccess') as string,
      errorMessage: t('rejectDialog.submitFail') as string,
    },
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setReason(event.target.value);
  };

  const handleReject = () => {
    rejectClaim.mutate(
      { id: claimId, reason },
      {
        onSuccess: () => {
          navigateToNextContext();
        },
      }
    );
  };

  return {
    closeDialog,
    handleChange,
    handleReject,
    isOpen,
    isSubmitting: rejectClaim.isPending,
    openDialog,
    reason,
  };
}

export { useRejectDialog };
