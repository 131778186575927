import type { DocumentContent } from 'types/Documents.ts';
import { PROVIDER, REIMBURSEMENT } from 'constants/claims.ts';
import { BOOL } from 'constants/value-type.ts';
import { isLineLevelItem } from 'state/selectors/documents.ts';

import type { DocumentContentWithId } from './types.ts';
import {
  DISABLED_DOCUMENT_ITEMS,
  DROPDOWN_ITEMS,
  FORCEFULLY_HIDDEN_FIELDS,
  FORCEFULLY_HIDDEN_LINE_ITEMS,
  HARDCODED_DROPDOWN_ITEMS,
  HARDCODED_MULTISELECT_ITEMS,
  lineItemDropdownFields,
  REIMBURSEMENT_FIELDS,
  PROVIDER_FIELDS,
  PROVIDER_FORCEFULLY_HIDDEN_FIELDS,
} from './constants.ts';

export function isDisabledDocumentItems({ sproutaiKey }: DocumentContent) {
  return DISABLED_DOCUMENT_ITEMS.includes(sproutaiKey);
}

export function isDropdownItems({ sproutaiKey }: DocumentContent) {
  return DROPDOWN_ITEMS.includes(sproutaiKey);
}

export function isHardcodedDropdownItems({ sproutaiKey }: DocumentContent) {
  return HARDCODED_DROPDOWN_ITEMS.includes(sproutaiKey);
}

export function isHardcodedMultiselectItems({ sproutaiKey }: DocumentContent) {
  return HARDCODED_MULTISELECT_ITEMS.includes(sproutaiKey);
}

// TODO: remove this function once we have a better way to handle this
export function isClientMemIdItem({ sproutaiKey }: DocumentContent) {
  return sproutaiKey === 'client_memid';
}

function isForcefullyHiddenLineItem(item: DocumentContentWithId) {
  return FORCEFULLY_HIDDEN_LINE_ITEMS.includes(item.sproutaiKey);
}

export function isLineItemDropdownType(item: DocumentContent) {
  return lineItemDropdownFields.includes(item.sproutaiKey);
}

export function getGroupedLineItems(items: DocumentContentWithId[]) {
  return items.reduce(
    (groups, item) => {
      const lineIndex = String(item.lineIdx);

      if (!groups[lineIndex]) {
        groups[lineIndex] = [];
      }

      groups[lineIndex].push(item);

      return groups;
    },
    {} as Record<string, DocumentContentWithId[]>
  );
}

export function removeDeletedGroupLineItems(
  items: Record<string, DocumentContentWithId[]>
) {
  return Object.entries(items).reduce(
    (acc, [key, value]) => {
      if (value.length && value.some((item) => item.deleted)) {
        return acc;
      }

      return {
        ...acc,
        [key]: value,
      };
    },
    {} as Record<string, DocumentContentWithId[]>
  );
}

export function generateTranslatedLabel(item: DocumentContentWithId) {
  return `enrichmentFields.${item.sproutaiKey}`;
}

export function generateLineItems(items: DocumentContentWithId[]) {
  const lineItems = items.filter(isLineLevelItem);

  // group line items by line index
  const groupedLineItems = getGroupedLineItems(lineItems);
  const filteredGroupLineItems = removeDeletedGroupLineItems(groupedLineItems);
  const length = Object.keys(filteredGroupLineItems).length;
  const [first] = Object.values(filteredGroupLineItems);

  const columns = length
    ? first.map((item) => {
        const base = {
          field: item.sproutaiKey,
          headerName: generateTranslatedLabel(item),
          type: 'string',
          hidden: isForcefullyHiddenLineItem(item),
        };

        if (isLineItemDropdownType(item)) {
          return {
            ...base,
            type: 'singleSelect',
          };
        }

        if (item.valueType === BOOL) {
          return {
            ...base,
            type: 'boolean',
          };
        }

        return base;
      })
    : [];

  const rows = Object.entries(filteredGroupLineItems).map(
    ([lineIndex, items]) => {
      const row = items.reduce(
        (acc, item) => {
          acc[item.sproutaiKey] = item.value;
          acc['keyToId'][item.sproutaiKey] = item.id;
          return acc;
        },
        { keyToId: {} } as Record<string, any>
      );

      return {
        ...row,
        lineIdx: parseInt(lineIndex),
      } as { [key: string]: any; lineIdx: number };
    }
  );

  return { columns, rows };
}

export const isProviderClaimType = (claimType: string) =>
  claimType.toLowerCase() === PROVIDER;
export const isReimbursementClaimType = (claimType: string) =>
  claimType.toLowerCase() === REIMBURSEMENT;

export function isForcefullyHiddenItem(claimType: string) {
  return (item: DocumentContent): boolean => {
    const hiddenFields = isProviderClaimType(claimType)
      ? PROVIDER_FORCEFULLY_HIDDEN_FIELDS
      : FORCEFULLY_HIDDEN_FIELDS;

    return hiddenFields.includes(item.sproutaiKey);
  };
}

export function isHiddenByClaimType(claimType: string) {
  return (item: DocumentContent): boolean => {
    if (isReimbursementClaimType(claimType)) {
      return REIMBURSEMENT_FIELDS.includes(item.sproutaiKey);
    }

    if (isProviderClaimType(claimType)) {
      return PROVIDER_FIELDS.includes(item.sproutaiKey);
    }

    return false;
  };
}
