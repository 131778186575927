import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Divider from '@mui/material/Divider';
import { menuItemClasses } from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useTooltip } from 'components/customHooks/useTooltip';
import { IconButtonAnimate } from 'components/IconButtonAnimate';
import { Avatar } from 'components/Avatar';
import { createAvatar } from 'utils/create-avatar';
import { MenuPopover } from 'components/MenuPopover';
import {
  ButtonWithTranslation,
  MenuItemWithTranslation,
  TypographyWithTranslation,
} from 'components/with-translation';
import { useSettings } from 'components/customHooks/useSettings';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag';
import { DARK, LIGHT } from 'constants/theme';
import { MOON_ICON, SUN_ICON } from 'constants/public-icons';
import { DASHBOARD_PAGE } from 'constants/translation-keys';
import {
  useCurrentUserEmail,
  useCurrentUserFirstName,
  useCurrentUserFullName,
} from 'state/queries/current-user';
import { LogoutButton } from 'pages/Dashboard/LogoutButton';
import { ensureBeamerInit } from 'utils/init-beamer';
import { generalConfig } from 'config';
import { useLocales } from 'locales/useLocales';

function AccountTooltip() {
  const fullNameQuery = useCurrentUserFullName();
  const firstNameQuery = useCurrentUserFirstName();
  const emailQuery = useCurrentUserEmail();
  const { onChangeMode } = useSettings();
  const { element, handleClose, handleOpen, isOpen } = useTooltip();
  const enableBeamer = useFeatureFlag('enableBeamer');
  const { currentLanguage } = useLocales();

  if (enableBeamer && isOpen) {
    ensureBeamerInit((isSuccess, parameters) => {
      if (isSuccess) {
        window.Beamer.update({
          ...parameters,
          language: currentLanguage.value,
        });
      }
    });
  }

  return (
    <>
      <IconButtonAnimate isOpen={isOpen} onClick={handleOpen}>
        {firstNameQuery.isSuccess && emailQuery.isSuccess ? (
          <Avatar
            alt={fullNameQuery.isSuccess ? fullNameQuery.data : ''}
            color={
              emailQuery.data
                ? 'default'
                : createAvatar(firstNameQuery.data).color
            }
            src={''}
          >
            {createAvatar(firstNameQuery.data).name}
          </Avatar>
        ) : null}
      </IconButtonAnimate>

      <MenuPopover
        anchorEl={element}
        onClose={handleClose}
        open={isOpen}
        sx={{
          p: 0,
          mt: 1,
          ml: 0.25,
          [`& .${menuItemClasses.root}`]: {
            borderRadius: 0.75,
            typography: 'body2',
          },
        }}
      >
        {fullNameQuery.isSuccess && emailQuery.isSuccess ? (
          <Box sx={{ my: 1.5, px: 2.5 }}>
            <Typography variant="subtitle1" noWrap>
              {fullNameQuery.data}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {emailQuery.data}
            </Typography>
          </Box>
        ) : null}

        <Divider />

        <Box sx={{ my: 1.5, px: 2.5 }}>
          <TypographyWithTranslation
            i18nKey="accountTooltip.mode"
            variant="body2"
            sx={{ mb: 1.5 }}
            namespace={DASHBOARD_PAGE}
          />

          <ButtonGroup variant="outlined" aria-label="Change theme mode">
            <ButtonWithTranslation
              i18nKey="accountTooltip.light"
              onClick={() => onChangeMode(LIGHT)}
              startIcon={<SvgIconStyle src={SUN_ICON} />}
              namespace={DASHBOARD_PAGE}
            />
            <ButtonWithTranslation
              i18nKey="accountTooltip.dark"
              onClick={() => onChangeMode(DARK)}
              startIcon={<SvgIconStyle src={MOON_ICON} />}
              namespace={DASHBOARD_PAGE}
            />
          </ButtonGroup>
        </Box>

        <Divider />

        {enableBeamer && (
          <>
            <MenuItemWithTranslation
              i18nKey="accountTooltip.releaseNotes"
              namespace={DASHBOARD_PAGE}
              id={generalConfig.beamerElementId}
              sx={{ borderRadius: 1, m: 1, px: 2, py: 1, typography: 'body2' }}
            />

            <Divider />
          </>
        )}

        <LogoutButton />
      </MenuPopover>
    </>
  );
}

export { AccountTooltip };
