import type { ClaimObservation } from 'types/Claims.ts';
import type { DocumentContent, DocumentValueType } from 'types/Documents.ts';
import { generateUuid } from 'utils/generate-uuid.ts';
import { convertArrayToListAndDict } from 'utils/array.ts';
import { ObservationType } from 'constants/claims.ts';
import { pipe } from 'utils/pipe.ts';
import { BOOL, FLOAT, INT } from 'constants/value-type.ts';

import type {
  DocumentContentWithId,
  NormalisedData,
  NormalisedState,
  Resources,
} from './types.ts';
import { isDropdownItems } from './utils.ts';

function makeFieldsList(id: string) {
  return `${id}-fields`;
}

export function unmakeFieldsList(listId: string) {
  return listId.replace('-fields', '');
}

function makeEnrichmentList(id: string) {
  return `${id}-enrichment`;
}

export function normaliseData(
  observations: ClaimObservation[],
  clientClaimId: string | null
) {
  const data: NormalisedData = {
    businessUnit: '',
    dropdownLevelMap: {},
    enrichmentListId: '',
    fieldsListId: '',
    lists: {
      observations: [],
    },
    enrichment: {},
    fields: {},
    observations: {},
    groupedEnrichmentLevels: {},
    urls: {},
  };

  const addIdToItem = (item: DocumentContent) => ({
    ...item,
    id: generateUuid(),
  });

  const getContentFieldsWithIds = (fields: DocumentContent[]) => {
    const fieldsWithIds = fields.map(addIdToItem);
    return convertArrayToListAndDict<DocumentContentWithId>(
      fieldsWithIds,
      'id'
    ) as [string[], Resources];
  };

  const getEnrichmentType = (observation: ClaimObservation) =>
    observation.type === ObservationType.ENRICHMENT;

  const getDocumentType = (observation: ClaimObservation) =>
    observation.type === ObservationType.DOCUMENT;

  const getPropertiesType = (observation: ClaimObservation) =>
    observation.type === ObservationType.PROPERTIES;

  const getDocumentGroupItem = (item: DocumentContent) =>
    item.sproutaiKey === 'document_group';

  const getBusinessUnitItem = (item: DocumentContent) =>
    item.sproutaiKey === 'business_unit';

  const getFeature = (item: DocumentContent) => item.value === 'feature';

  const normaliseObservations = ({ state, obs }: NormalisedState) => ({
    state: obs.reduce((acc, observation) => {
      acc.lists['observations'] = [...acc.lists.observations, observation.id];
      acc.observations[observation.id] = observation;

      return acc;
    }, state),
    obs,
  });

  const normaliseFields = ({ state, obs }: NormalisedState) => ({
    state: obs.reduce((acc, observation) => {
      if (getDocumentType(observation)) {
        const documentGroup =
          observation.content.fields.find(getDocumentGroupItem);

        if (documentGroup && getFeature(documentGroup)) {
          const listId = makeFieldsList(observation.id);

          if (!acc.fieldsListId) {
            acc.fieldsListId = listId;
          }

          const [list, resources] = getContentFieldsWithIds(
            observation.content.fields
          );
          acc.lists[listId] = list;
          acc.fields = {
            ...acc.fields,
            ...resources,
          };
          acc.urls = observation.content.urls;
        }
      }

      return acc;
    }, state),
    obs,
  });

  const normaliseEnrichment = ({ state, obs }: NormalisedState) => ({
    state: obs.reduce((acc, observation) => {
      if (getEnrichmentType(observation)) {
        const listId = makeEnrichmentList(observation.id);

        if (!acc.enrichmentListId) {
          acc.enrichmentListId = listId;
        }

        const [list, resources] = getContentFieldsWithIds(
          observation.content.fields
        );
        acc.lists[listId] = list;
        acc.enrichment = {
          ...acc.enrichment,
          ...resources,
        };
      }

      return acc;
    }, state),
    obs,
  });

  const groupEnrichment = ({ state, obs }: NormalisedState) => {
    const { enrichmentListId, enrichment, fields, fieldsListId, lists } = state;
    const enrichmentItems = Object.keys(enrichment).length
      ? lists[enrichmentListId].map((id) => enrichment[id])
      : [];

    const groupedEnrichmentLevels = enrichmentItems.reduce(
      (groups, item) => {
        const lineIndex = String(item.lineIdx);

        if (!groups[item.level]) {
          groups[item.level] = {};
        }

        if (!groups[item.level][lineIndex]) {
          groups[item.level][lineIndex] = {};
        }

        const fieldId = lists[fieldsListId].find((id) => {
          // TODO: remove this once we have a better way to handle this
          // adding client_policy_number to the groupedEnrichmentLevels
          if (item.sproutaiKey === 'client_policy_number') {
            return fields[id].sproutaiKey === 'policy_number';
          }

          return fields[id].sproutaiKey === item.sproutaiKey;
        });

        if (fieldId) {
          groups[item.level][lineIndex] = {
            ...groups[item.level][lineIndex],
            [fieldId]: item,
          };
        }

        return groups;
      },
      {} as Record<
        string,
        Record<string, Record<string, DocumentContentWithId>>
      >
    );

    return { state: { ...state, groupedEnrichmentLevels }, obs };
  };

  const populateFieldsWithEnrichedData = ({ state, obs }: NormalisedState) => {
    const {
      enrichment,
      enrichmentListId,
      fields,
      fieldsListId,
      groupedEnrichmentLevels,
      lists,
    } = state;

    if (Object.keys(enrichment).length !== 0) {
      const fieldsList = lists[fieldsListId];

      fieldsList.forEach((id) => {
        const field = fields[id];

        if (isDropdownItems(field)) {
          const { sproutaiKey, value } = field;
          const enrichmentId = lists[enrichmentListId].find(
            (enrichmentId) =>
              enrichment[enrichmentId].sproutaiKey === sproutaiKey &&
              enrichment[enrichmentId].value === value
          );

          if (enrichmentId && enrichment[enrichmentId]) {
            const { level, lineIdx } = enrichment[enrichmentId];
            const group = groupedEnrichmentLevels[level]?.[String(lineIdx)];
            // check if the group has related enrichment data
            const groupHasRelatedEnrichmentData = Object.keys(group).some(
              (key) => group[key].value === field.value
            );

            if (group) {
              if (!state.dropdownLevelMap?.[id]) {
                state.dropdownLevelMap = {
                  ...state.dropdownLevelMap,
                  [id]: level,
                };
              }

              Object.keys(group).forEach((key) => {
                const enrichedData = group[key];

                if (state.fields[key]) {
                  state.fields[key].value = groupHasRelatedEnrichmentData
                    ? enrichedData.value
                    : state.fields[key].value;
                }
              });
            }
          } else {
            const enrichmentId = lists[enrichmentListId].find(
              (enrichmentId) =>
                enrichment[enrichmentId].sproutaiKey === sproutaiKey
            );

            if (enrichmentId && enrichment[enrichmentId]) {
              const { level, lineIdx } = enrichment[enrichmentId];
              const group = groupedEnrichmentLevels[level]?.[String(lineIdx)];

              if (group) {
                if (!state.dropdownLevelMap?.[id]) {
                  state.dropdownLevelMap = {
                    ...state.dropdownLevelMap,
                    [id]: level,
                  };
                }
              }
            }
          }
        }
      });
    }

    return { state, obs };
  };

  const populateControlNumberFieldWithClientClaimId = ({
    state,
    obs,
  }: NormalisedState) => {
    const newState = { ...state };
    const { fields, fieldsListId, lists } = state;
    const fieldsList = lists[fieldsListId];
    const controlNumberFieldId = fieldsList.find(
      (id) => fields[id].sproutaiKey === 'control_number'
    );

    if (controlNumberFieldId && clientClaimId) {
      newState.fields[controlNumberFieldId].value = clientClaimId;
    }

    return { state: newState, obs };
  };

  //TODO: comment out this function for now
  /*
  const populatePolicyNumberValueFromEnrichment = ({
    state,
    obs
  }: NormalisedState) => {
    const newState = { ...state };
    const { enrichment, enrichmentListId, fields, fieldsListId, lists } =
      newState;
    const enrichmentPolicyNumberId = lists[enrichmentListId].find(
      (id) => enrichment[id].sproutaiKey === 'policy_number'
    );
    // TODO: find a better way to handle this
    // geting client_policy_number from enrichment
    const enrichmentClientPolicyNumberId = lists[enrichmentListId].find(
      (id) => enrichment[id].sproutaiKey === 'client_policy_number'
    );

    const fieldPolicyNumberId = lists[fieldsListId].find(
      (id) => fields[id].sproutaiKey === 'policy_number'
    );

    if (
      enrichmentPolicyNumberId &&
      fieldPolicyNumberId &&
      enrichmentClientPolicyNumberId
    ) {
      newState.fields[fieldPolicyNumberId].value =
        enrichment[enrichmentClientPolicyNumberId].value;
    }

    return {
      state: newState,
      obs
    };
  };
  */

  // const populateProviderNameValueFromEnrichment = ({
  //   state,
  //   obs
  // }: NormalisedState) => {
  //   const newState = { ...state };
  //   const { enrichment, enrichmentListId, fields, fieldsListId, lists } =
  //     newState;
  //   const enrichmentProviderNameId = lists[enrichmentListId].find(
  //     (id) => enrichment[id].sproutaiKey === 'provider_name'
  //   );
  //   const fieldProviderNameId = lists[fieldsListId].find(
  //     (id) => fields[id].sproutaiKey === 'provider_name'
  //   );
  //
  //   if (enrichmentProviderNameId && fieldProviderNameId) {
  //     newState.fields[fieldProviderNameId].value =
  //       enrichment[enrichmentProviderNameId].value;
  //   }
  //
  //   return {
  //     state: newState,
  //     obs
  //   };
  // };

  const getBusinessUnitFromPropertiesObservation = ({
    state,
    obs,
  }: NormalisedState) => ({
    state: obs.reduce((acc, observation) => {
      if (getPropertiesType(observation)) {
        const businessUnitItem =
          observation.content.fields.find(getBusinessUnitItem);

        if (businessUnitItem) {
          acc.businessUnit = businessUnitItem.value;
        }
      }

      return acc;
    }, state),
    obs,
  });

  const result = pipe<NormalisedState>(
    normaliseObservations,
    normaliseFields,
    normaliseEnrichment,
    groupEnrichment,
    populateFieldsWithEnrichedData,
    populateControlNumberFieldWithClientClaimId,
    getBusinessUnitFromPropertiesObservation
  )({
    state: data,
    obs: observations,
  });

  return result.state;
}

export function transformValueFromStateToForm(valueType: DocumentValueType) {
  return (value: string) => {
    switch (valueType) {
      case BOOL:
        return value === 'True';
      case INT:
      case FLOAT:
        return parseInt(value, 10);
      default:
        return value ?? '';
    }
  };
}

export function transformValueFromFormToState(valueType: DocumentValueType) {
  return (value: string | boolean | number | null) => {
    switch (valueType) {
      case BOOL:
        return value ? 'True' : 'False';
      case INT:
      case FLOAT:
        return String(value);
      default:
        return value;
    }
  };
}

export function getDefaultValues({
  fieldsListId,
  lists,
  fields,
}: NormalisedData) {
  const fieldsList = lists[fieldsListId];
  return fieldsList.reduce(
    (acc, id) => {
      const field = fields[id];

      if (field) {
        const transformer = transformValueFromStateToForm(field.valueType);
        acc[field.id] = transformer(field.value);
      }

      return acc;
    },
    {} as Record<string, string | boolean | number | null>
  );
}
