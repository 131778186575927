import type { FileCanvas } from './types.ts';
import { writeToCorrections } from './state.ts';
import { customEvent } from './events.ts';

function keyboardEvents(canvas: FileCanvas) {
  const deleteSelectedObject = async (event: KeyboardEvent) => {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      const activeObject = canvas.getActiveObject();

      if (activeObject && activeObject.type !== 'image') {
        canvas.remove(activeObject);

        if (typeof canvas.page === 'number') {
          await writeToCorrections({
            canvas,
            page: canvas.page,
            pageId: canvas.pageId,
          });
        }
      }
    }
  };

  document.addEventListener('keydown', deleteSelectedObject);

  canvas.on(customEvent.TOOL_UNMOUNT, () => {
    document.removeEventListener('keydown', deleteSelectedObject);
  });
}

export { keyboardEvents };
