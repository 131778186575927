import type { MouseEvent } from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import { useTranslationRoot } from 'components/with-translation.tsx';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import {
  CROP_ICON,
  REDACT_ICON,
  SELECTION_ICON,
} from 'constants/public-icons.ts';
import { COMMON } from 'constants/translation-keys.ts';

import type { FileCanvas } from './types.ts';
import { customEvent } from './events.ts';
import { useRedactionTool } from './use-redaction-tool.ts';
import { useSelectionTool } from './use-selection-tool.ts';

interface EditingProps {
  canvas: FileCanvas;
  getCanvas: () => FileCanvas;
  handleCrop: () => void;
  mode: 'crop' | 'redact';
}

type State = {
  isRedactionActive: boolean;
  isSelectionActive: boolean;
  hasRectangularSelection: boolean;
};

const ICON_SIZE = 18;

function EditingTools({ getCanvas, handleCrop, mode }: EditingProps) {
  const { t } = useTranslationRoot(COMMON);
  const [tool, setTool] = useState<string | null>(null);
  const [
    { hasRectangularSelection, isRedactionActive, isSelectionActive },
    setState,
  ] = useState<State>({
    isRedactionActive: false,
    isSelectionActive: false,
    hasRectangularSelection: false,
  });
  const [isDisabled, setIsDisabled] = useState(false);

  const toggleTools = (
    _event: MouseEvent<HTMLElement>,
    newTool: string | null
  ) => {
    if (newTool?.includes('crop')) {
      // do nothing
    } else {
      setTool(newTool);
    }
  };

  const toggleSelection = () => {
    setState((prevState) => ({
      ...prevState,
      hasRectangularSelection: false,
      isSelectionActive: !prevState.isSelectionActive,
      isRedactionActive: false,
    }));
  };

  const toggleRedact = () => {
    setState((prevState) => ({
      ...prevState,
      hasRectangularSelection: false,
      isSelectionActive: false,
      isRedactionActive: !prevState.isRedactionActive,
    }));
  };

  const onCrop = () => {
    if (isSelectionActive) {
      handleCrop();
    }
  };

  // list for canvas.page changes
  useEffect(() => {
    const canvas = getCanvas();

    if (canvas) {
      canvas.on(customEvent.PAGE_UPDATED, () => {
        if (typeof canvas.page === 'string') {
          setState((prevState) => ({
            ...prevState,
            isRedactionActive: false,
            isSelectionActive: false,
            hasRectangularSelection: false,
          }));
          setTool(null);
          setIsDisabled(true);
        } else {
          setIsDisabled(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useRedactionTool({ getCanvas, isActive: isRedactionActive });
  useSelectionTool({
    getCanvas,
    isActive: isSelectionActive,
    onSelectionReady: (ready) =>
      setState((prevState) => ({
        ...prevState,
        hasRectangularSelection: ready,
      })),
  });

  return (
    <>
      <ToggleButtonGroup
        color="standard"
        size="small"
        value={tool}
        onChange={toggleTools}
        exclusive
        aria-label="Tools"
        sx={{
          '& > .MuiToggleButton-root.Mui-selected': {
            color: 'info.main',
          },
        }}
      >
        {mode === 'crop' && (
          <Tooltip title={t('canvas.selection')}>
            <ToggleButton
              disabled={isDisabled}
              value="selection"
              onClick={toggleSelection}
            >
              <SvgIconStyle
                src={SELECTION_ICON}
                height={ICON_SIZE}
                width={ICON_SIZE}
              />
            </ToggleButton>
          </Tooltip>
        )}
        {mode === 'crop' && (
          <Tooltip title={t('canvas.crop')}>
            <Box component="span" sx={{ display: 'flex' }}>
              <ToggleButton
                value="crop"
                disabled={isDisabled || !hasRectangularSelection}
                onClick={onCrop}
              >
                <SvgIconStyle
                  src={CROP_ICON}
                  height={ICON_SIZE}
                  width={ICON_SIZE}
                />
              </ToggleButton>
            </Box>
          </Tooltip>
        )}
        {mode === 'redact' && (
          <Tooltip title={t('canvas.redact')}>
            <ToggleButton
              disabled={isDisabled}
              value="redact"
              onClick={toggleRedact}
            >
              <SvgIconStyle
                src={REDACT_ICON}
                height={ICON_SIZE}
                width={ICON_SIZE}
              />
            </ToggleButton>
          </Tooltip>
        )}
      </ToggleButtonGroup>
    </>
  );
}

export { EditingTools };
