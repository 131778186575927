import type { PaletteMode } from '@mui/material';

const lightMode = {
  primary: {
    light: '#0000b8',
    main: '#00008F',
    dark: '#000066',
  },
  secondary: {
    light: '#FF4751',
    main: '#FF1721',
    dark: '#FF1F2A',
  },
  chart: {
    primary: ['#00003d', '#000066', '#00008F', '#0000b8'],
    secondary: ['#F5000C', '#FF1F2A', '#FF1721', '#ff4751'],
    blue: ['#3E64A3', '#4975ba', '#95ddf4', '#fff0e1'],
    yellow: ['#fdd835', '#ffeb3b', '#fceb55', '#fef075'],
    green: ['#00c64a', '#00ab2d', '#008000', '#005000'],
    red: ['#c0802e', '#c0632e', '#c0442e', '#a9412f'],
  },
};

const darkMode = {
  primary: {
    light: '#FFFFFF',
    main: '#ece3e3',
    dark: '#d3d3da',
    contrastText: '#000000',
  },
  secondary: {
    light: '#FF4751',
    main: '#FF1721',
    dark: '#FF1F2A',
  },
  chart: {
    primary: ['#00003d', '#000066', '#00008F', '#0000b8'],
    secondary: ['#F5000C', '#FF1F2A', '#FF1721', '#ff4751'],
    blue: ['#3E64A3', '#4975ba', '#95ddf4', '#fff0e1'],
    yellow: ['#fdd835', '#ffeb3b', '#fceb55', '#fef075'],
    green: ['#00c64a', '#00ab2d', '#008000', '#005000'],
    red: ['#c0802e', '#c0632e', '#c0442e', '#a9412f'],
  },
};

const axajp = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === 'light' ? lightMode : darkMode),
  },
});

export default axajp;
