import type { ClaimRecommendation } from 'types/Claims';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useTranslationRoot } from 'components/with-translation';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { REFRESH_ICON } from 'constants/public-icons';
import {
  ErrorIcon,
  SuccessIcon,
  WarningIcon,
} from 'theme/overrides/CustomIcons';

interface TrafficLightProps {
  clientRecommendation: ClaimRecommendation | ClaimRecommendation[];
  hasRefetchButton: boolean;
  isRefetching: boolean;
  refetchClaim: VoidFunction;
}

function getStatusTranslation(status: string) {
  const s = Number(status);

  if (s >= 0 && s < 1) {
    return 'activityStatus.accept';
  } else if (s >= 1 && s < 4) {
    return 'activityStatus.requiresReview';
  }

  return 'activityStatus.error';
}

export function getBackgroundColor(status: number) {
  if (status >= 0 && status < 1) {
    return 'success.lighter';
  } else if (status >= 1 && status < 4) {
    return 'warning.lighter';
  }

  return 'error.lighter';
}

export function getFontColor(status: number) {
  if (status >= 0 && status < 1) {
    return 'success.dark';
  } else if (status >= 1 && status < 4) {
    return 'warning.dark';
  }

  return 'error.dark';
}

function getStatusIcon(status: number) {
  if (status >= 0 && status < 1) {
    return SuccessIcon;
  } else if (status >= 1 && status < 4) {
    return WarningIcon;
  }

  return ErrorIcon;
}

function TrafficLightV2({
  clientRecommendation,
  hasRefetchButton,
  isRefetching,
  refetchClaim,
}: TrafficLightProps) {
  const { t } = useTranslationRoot();
  const recommendations = Array.isArray(clientRecommendation)
    ? clientRecommendation
    : [clientRecommendation];

  if (!recommendations.length) {
    return null;
  }
  const StatusIcon = getStatusIcon(Number(recommendations[0].status));

  return (
    <Stack
      sx={{
        alignItems: 'center',
        backgroundColor: getBackgroundColor(Number(recommendations[0].status)),
        borderRadius: 2,
        color: getFontColor(Number(recommendations[0].status)),
        justifyItems: 'center',
        position: 'relative',
        py: 5,
      }}
    >
      {hasRefetchButton ? (
        <Tooltip title={t('common.refetchButton')}>
          <LoadingButton
            loading={isRefetching}
            sx={{
              height: 64,
              position: 'absolute',
              top: 0,
              right: 0,
            }}
            onClick={refetchClaim}
          >
            <SvgIconStyle src={REFRESH_ICON} />
          </LoadingButton>
        </Tooltip>
      ) : null}
      <Box
        sx={{
          alignItems: 'center',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Tooltip
          arrow
          title={
            <>
              {recommendations.map(({ status, statusDetail }, index) => (
                <Stack key={index}>
                  <Typography variant="subtitle1">
                    {t('common.status')}: {status}
                  </Typography>
                  <Typography variant="body2">{statusDetail}</Typography>
                </Stack>
              ))}
            </>
          }
          disableInteractive
        >
          <span>
            <Button
              variant="text"
              color="inherit"
              disabled
              sx={{ color: 'inherit !important' }}
            >
              <StatusIcon sx={{ height: 64, width: 64 }} />
            </Button>
          </span>
        </Tooltip>
      </Box>
      <Typography variant="h3">
        {t(getStatusTranslation(recommendations[0].status))}
      </Typography>
    </Stack>
  );
}

export { TrafficLightV2 };
