import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { useTranslationRoot } from 'components/with-translation';
import { round } from 'utils/numbers';

import type { Resources, TranslationDict } from './types';
import { VerticalBarChart } from './VerticalBarChart';

interface DocumentPerformanceProps {
  list: string[];
  resources: Resources;
}

const translationDict: TranslationDict = {
  SumDocumentsAccepted: 'documentPerformance.sumDocumentsAccepted',
  SumDocumentsPresented: 'documentPerformance.sumDocumentsPresented',
  SumDocumentsProcessedFirstTime:
    'documentPerformance.sumDocumentsProcessedFirstTime',
};

function DocumentPerformance({ list, resources }: DocumentPerformanceProps) {
  const { t } = useTranslationRoot();

  const { data, legend } = list.reduce<{
    data: { name: string; value: number }[];
    legend: string[];
  }>(
    (acc, id) => {
      const { name, value } = resources[id];
      const data = round(Number(value), 1);
      const legend = t(translationDict[name]);

      return {
        data: [...acc.data, { name, value: data }],
        legend: [...acc.legend, legend],
      };
    },
    {
      data: [],
      legend: [],
    }
  );

  // TODO: bar chart doesn't redraw bars when new data is fetched
  return (
    <Card>
      <CardHeader title={t('documentPerformance.heading')} sx={{ mb: 8 }} />
      <VerticalBarChart data={data} legend={legend} />
    </Card>
  );
}

export { DocumentPerformance };
