import api, { makeApiLink } from 'api/api';

const INITIALISE_MULTIPART_UPLOAD_ENDPOINT = 'files/start_upload';
const GET_PRESIGNED_URLS_ENDPOINT = 'files/presigned_url_for_part';
const FINALISE_UPLOAD_ENDPOINT = 'files/complete_upload';
const ABORT_UPLOAD = 'files/abort_upload';

type Props = {
  chunkSize?: number;
  threadsQuantity?: number;
  file: File;
  fileName: string;
};

type ErrorFn = (error: Error | undefined | string) => void;
type FileKey = string | null;
type FileId = string | null;
type Part = { url: string; partNumber: number };
type PartList = Part[];
type ProgressFn = (props: {
  sent: number;
  total: number;
  percentage: number;
}) => void;
type UploadedPart = { etag: string; part_no: number };
type UploadedPartList = UploadedPart[];

const DEFAULT_PART_SIZE = 100; // MB

// adapted from: https://github.com/pilovm/multithreaded-uploader/blob/master/frontend/uploader.js
class FilesMultipart {
  constructor(options: Props) {
    // this must be bigger than or equal to 5MB,
    // otherwise AWS will respond with:
    // "Your proposed upload is smaller than the minimum allowed size"
    this.chunkSize = options.chunkSize || 1024 * 1024 * DEFAULT_PART_SIZE;
    // number of parallel uploads
    this.threadsQuantity = Math.min(options.threadsQuantity || 5, 15);
    this.file = options.file;
    this.fileName = options.fileName;
  }

  chunkSize: number;
  // number of parallel uploads
  threadsQuantity: number;
  file: File;
  fileName: string;
  aborted = false;
  uploadedSize = 0;
  progressCache: { [key: string]: number } = {};
  activeConnections: { [key: string]: { abort: VoidFunction } } = {};
  parts: PartList = [];
  uploadedParts: UploadedPartList = [];
  fileId: FileId = null;
  fileKey: FileKey = null;
  onProgressFn: ProgressFn = () => {};
  onErrorFn: ErrorFn = () => {};

  public start() {
    this.initialise();
  }

  private isEncoded(value: string) {
    return value !== decodeURIComponent(value);
  }

  private getInitialiseMultipartUpload(objectName: string) {
    const isEncoded = this.isEncoded(objectName);
    const encodedObjectName = isEncoded
      ? objectName
      : encodeURIComponent(objectName);

    return api.get<{ key: string; upload_id: string }>(
      makeApiLink(
        `${INITIALISE_MULTIPART_UPLOAD_ENDPOINT}?object_name=${encodedObjectName}`
      )
    );
  }

  private generateUrlForPresigning(
    key: FileKey,
    uploadId: FileId,
    partNo: number
  ) {
    const isEncoded = this.isEncoded(key as string);
    const encodedKey = isEncoded ? key : encodeURIComponent(key as string);

    return makeApiLink(
      `${GET_PRESIGNED_URLS_ENDPOINT}?key=${encodedKey}&upload_id=${uploadId}&part_no=${partNo}`
    );
  }

  private getPresignedUrls(url: string) {
    return api.get<string>(url);
  }

  private finaliseUpload(uploadId: FileId) {
    return api.post(
      makeApiLink(`${FINALISE_UPLOAD_ENDPOINT}?upload_id=${uploadId}`),
      {
        key: this.fileKey,
        parts: this.uploadedParts.sort((a, b) => a.part_no - b.part_no),
      }
    );
  }

  private abortUpload(uploadId: FileId, key: FileKey) {
    const isEncoded = this.isEncoded(key as string);
    const encodedKey = isEncoded ? key : encodeURIComponent(key as string);
    return api.post(makeApiLink(`${ABORT_UPLOAD}?upload_id=${uploadId}`), {
      key: encodedKey,
    });
  }

  private async initialise() {
    try {
      // adding the file extension (if present) to fileName
      const fileName = this.fileName;
      // const ext = this.file.name.split('.').pop();
      // if (ext) {
      //   fileName += `.${ext}`;
      // }

      const { key, upload_id } =
        await this.getInitialiseMultipartUpload(fileName);

      this.fileId = upload_id;
      this.fileKey = key;

      // retrieving the pre-signed URLs
      const numberOfparts = Math.ceil(this.file.size / this.chunkSize);
      const urls: string[] = [];

      for (let i = 1; i < numberOfparts + 1; i++) {
        urls.push(this.generateUrlForPresigning(this.fileKey, this.fileId, i));
      }

      // const signedUrls = await Promise.all(urls);
      const partSignedUrlList = urls.map((url, index) => {
        return {
          url,
          partNumber: index + 1,
        };
      });

      this.parts.push(...partSignedUrlList);
      this.sendNext();
    } catch (error: any) {
      await this.complete(error);
    }
  }

  private sendNext() {
    const activeConnections = Object.keys(this.activeConnections).length;

    if (activeConnections >= this.threadsQuantity) {
      return;
    }

    if (!this.parts.length) {
      if (!activeConnections) {
        this.complete();
      }

      return;
    }

    const part = this.parts.pop();

    if (this.file && part) {
      const sentSize = (part.partNumber - 1) * this.chunkSize;
      const chunk = this.file.slice(sentSize, sentSize + this.chunkSize);

      const sendChunkStarted = () => {
        this.sendNext();
      };

      this.sendChunk(chunk, part, sendChunkStarted)
        .then(() => {
          this.sendNext();
        })
        .catch((error) => {
          this.parts.push(part);

          this.complete(error);
        });
    }
  }

  private async complete(error?: Error) {
    if (error && !this.aborted) {
      this.onErrorFn(error);
      return;
    }

    if (error) {
      this.onErrorFn(error);
      return;
    }

    try {
      return await this.sendCompleteRequest();
    } catch (error: any) {
      this.onErrorFn(error);
    }
  }

  private async sendCompleteRequest() {
    try {
      await this.finaliseUpload(this.fileId);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  }

  private sendChunk(chunk: Blob, part: Part, sendChunkStarted: VoidFunction) {
    return new Promise<void>((resolve, reject) => {
      this.upload(chunk, part, sendChunkStarted)
        .then((status) => {
          if (status !== 200) {
            reject(new Error('Failed chunk upload'));
            return;
          }

          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private handleProgress = (part: string, event: ProgressEvent) => {
    if (this.file) {
      if (
        event.type === 'progress' ||
        event.type === 'error' ||
        event.type === 'abort'
      ) {
        this.progressCache[part] = event.loaded;
      }

      if (event.type === 'uploaded') {
        this.uploadedSize += this.progressCache[part] || 0;
        delete this.progressCache[part];
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((memo, id) => memo + this.progressCache[id], 0);

      const sent = Math.min(this.uploadedSize + inProgress, this.file.size);

      const total = this.file.size;

      const percentage = Math.round((sent / total) * 100);

      this.onProgressFn({
        sent: sent,
        total: total,
        percentage: percentage,
      });
    }
  };

  private upload(file: Blob, part: Part, sendChunkStarted: VoidFunction) {
    // uploading each part with its pre-signed URL
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      if (this.fileId && this.fileKey) {
        const signedUrl = await this.getPresignedUrls(part.url);
        const xhr = (this.activeConnections[part.partNumber - 1] =
          new XMLHttpRequest());

        sendChunkStarted();

        const progressListener = this.handleProgress.bind(
          this,
          String(part.partNumber - 1)
        );

        xhr.upload.addEventListener('progress', progressListener);

        xhr.addEventListener('error', progressListener);
        xhr.addEventListener('abort', progressListener);
        xhr.addEventListener('loadend', progressListener);

        xhr.open('PUT', signedUrl);

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            const ETag = xhr.getResponseHeader('ETag');

            if (ETag) {
              const uploadedPart = {
                etag: ETag.replaceAll('"', ''),
                part_no: part.partNumber,
              };

              this.uploadedParts.push(uploadedPart);

              resolve(xhr.status);
              delete this.activeConnections[part.partNumber - 1];
            }
          }
        };

        xhr.onerror = (error) => {
          // console.log('xhr onerror ERROR');
          reject(error);
          delete this.activeConnections[part.partNumber - 1];
        };

        xhr.onabort = () => {
          reject(new Error('Upload aborted by user'));
          delete this.activeConnections[part.partNumber - 1];
        };

        xhr.send(file);
      }
    });
  }

  public onProgress(onProgress: ProgressFn) {
    this.onProgressFn = onProgress;
    return this;
  }

  public onError(onError: ErrorFn) {
    this.onErrorFn = onError;
    return this;
  }

  public abort() {
    Object.keys(this.activeConnections)
      .map(Number)
      .forEach((id) => {
        this.activeConnections[id].abort();
      });

    void this.abortUpload(this.fileId, this.fileKey);

    this.aborted = true;
  }
}

export { FilesMultipart };
