import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { formatDocumentDate } from 'utils/date.ts';

interface HeaderSummaryProps {
  clientClaimId: string | null;
  lastUpdatedOn: string;
}

function HeaderSummary({ clientClaimId, lastUpdatedOn }: HeaderSummaryProps) {
  return (
    <Stack sx={{ flex: '0 0 auto', p: 1.5 }}>
      <Typography variant="h6">{clientClaimId}</Typography>
      <Stack direction="row" spacing={1}>
        <Typography variant="body2" color="textSecondary" sx={{ fontSize: 12 }}>
          Last updated:{' '}
        </Typography>
        <Typography variant="body2" color="textPrimary" sx={{ fontSize: 12 }}>
          {formatDocumentDate(lastUpdatedOn)}
        </Typography>
      </Stack>
    </Stack>
  );
}

export { HeaderSummary };
