import type { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslationRoot } from 'components/with-translation';
import { toCamelCase } from 'utils/string';

interface CategoryProps {
  children: ReactNode;
  name: string;
}

function Category({ children, name }: CategoryProps) {
  const { t } = useTranslationRoot();

  return (
    <Stack
      sx={{
        height: 1,
      }}
    >
      <Typography
        variant="subtitle2"
        sx={{
          fontWeight: 'bold',
          textTransform: 'capitalize',
          px: 2,
        }}
      >
        {t(`tableCustom.${toCamelCase(name)}`)}
      </Typography>
      <Box
        sx={{
          maxHeight: '60vh',
          minHeight: '5vh',
          overflowY: 'auto',
          pt: 2,
          px: 2,
        }}
      >
        {children}
      </Box>
    </Stack>
  );
}

export { Category };
