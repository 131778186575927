import { useTranslation } from 'react-i18next';
import ListItemText from '@mui/material/ListItemText';
import { LinkWithRef } from 'components/Link';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { DotIcon } from 'components/icons/DotIcon';
import {
  ARROW_DOWNWARD_ICON,
  ARROW_FORWARD_ICON,
} from 'constants/public-icons';
import { DASHBOARD_PAGE } from 'constants/translation-keys';

import type { NavListChildrenProps } from './NavList';
import { ListItemIconStyle, ListItemStyle, ListItemTextStyle } from './style';

interface NavItemRootProps {
  endComponentProps?: any;
  item: NavListChildrenProps;
  open: boolean;
}

interface NavItemSubProps {
  active: boolean;
  item: NavListChildrenProps;
  onOpen?: VoidFunction;
  endComponentProps?: any;
  onClick?: VoidFunction;
}

interface ArrowIconProps {
  open: boolean;
}

function NavItemRoot({
  item,
  open = false,
  endComponentProps,
}: NavItemRootProps) {
  const { t } = useTranslation(DASHBOARD_PAGE);
  const { children, endComponent, icon, path, title } = item;

  return (
    <ListItemStyle
      component={LinkWithRef}
      to={path}
      activeClassName="isActiveRoot"
      state={{
        prevPathname: location.pathname,
      }}
    >
      {icon ? (
        <ListItemIconStyle>
          <SvgIconStyle src={icon} />
        </ListItemIconStyle>
      ) : null}
      <ListItemTextStyle disableTypography primary={t(title)} />
      {endComponent ? endComponent(endComponentProps) : null}
      {children?.length ? <ArrowIcon open={open} /> : null}
    </ListItemStyle>
  );
}

function NavItemSub({
  active = false,
  item,
  onOpen,
  endComponentProps,
  onClick,
}: NavItemSubProps) {
  const { t } = useTranslation(DASHBOARD_PAGE);
  const { children, path, title, endComponent } = item;

  const renderContent = (
    <>
      <ListItemIconStyle>
        <DotIcon active={active} />
      </ListItemIconStyle>
      <ListItemText disableTypography primary={t(title)} />
      {endComponent ? endComponent(endComponentProps) : null}
    </>
  );

  if (children) {
    return (
      <ListItemStyle activeSub={active} onClick={onOpen} subItem>
        {renderContent}
      </ListItemStyle>
    );
  }

  return (
    <ListItemStyle
      activeSub={active}
      component={LinkWithRef}
      to={path}
      subItem
      onClick={onClick}
      data-testid={path}
      state={{
        prevPathname: location.pathname,
      }}
    >
      {renderContent}
    </ListItemStyle>
  );
}

function ArrowIcon({ open }: ArrowIconProps) {
  const src = open ? ARROW_DOWNWARD_ICON : ARROW_FORWARD_ICON;

  return <SvgIconStyle src={src} height={16} width={16} sx={{ ml: 1 }} />;
}

export { NavItemRoot, NavItemSub };
