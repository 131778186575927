import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import type { ClaimValidationResult } from 'types/Claims';
import { SvgIconStyle } from 'components/SvgIconStyle';
import {
  CHECK_FILL_ICON,
  CLOSE_CIRCLE_FILL_ICON,
  CLOSE_ICON,
} from 'constants/public-icons';
import {
  ButtonWithTranslation,
  TypographyWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';
import { useBoolean } from 'components/customHooks/useBoolean';

import { ValidationResult } from './ValidationResult';

interface EnrichmentValidationResultsProps {
  validationResults: ClaimValidationResult[] | null;
}

const StartIcon = styled(SvgIconStyle)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

function EnrichmentValidationResults({
  validationResults,
}: EnrichmentValidationResultsProps) {
  const { t } = useTranslationRoot();
  const [isOpen, openDrawer, closeDrawer] = useBoolean();
  const hasValidationResults =
    validationResults && validationResults?.length > 0;
  const isAllValidationResultsPassed = hasValidationResults
    ? validationResults.every(({ passed }) => passed)
    : false;

  if (!hasValidationResults) {
    return null;
  }

  return (
    <>
      <ButtonWithTranslation
        color={isAllValidationResultsPassed ? 'success' : 'error'}
        i18nKey="enrichmentTool.validationResultsButton"
        onClick={openDrawer}
        size="small"
        startIcon={
          <StartIcon
            height={17}
            src={
              isAllValidationResultsPassed
                ? CHECK_FILL_ICON
                : CLOSE_CIRCLE_FILL_ICON
            }
            width={17}
          />
        }
        variant="text"
        sx={{
          whiteSpace: 'nowrap',
        }}
      />
      <Drawer anchor="right" open={isOpen} onClose={closeDrawer}>
        <Box role="presentation" sx={{ minWidth: 300, position: 'relative' }}>
          <IconButton
            aria-label={t('drawer.closeButtonText') as string}
            onClick={closeDrawer}
            sx={{
              position: 'absolute',
              right: ({ spacing }) => spacing(2),
              top: ({ spacing }) => spacing(2.5),
            }}
          >
            <SvgIconStyle src={CLOSE_ICON} />
          </IconButton>
          <Box
            component="header"
            sx={{
              p: 3,
            }}
          >
            <TypographyWithTranslation
              i18nKey="enrichmentTool.validationResultsTitle"
              variant="h5"
            />
          </Box>
          <Stack sx={{ maxWidth: 500, p: 3, pt: 0 }} spacing={2}>
            {validationResults.map((validationResult) => (
              <ValidationResult
                key={validationResult.ruleId}
                validationResult={validationResult}
              />
            ))}
          </Stack>
        </Box>
      </Drawer>
    </>
  );
}

export { EnrichmentValidationResults };
