import type { DropzoneProps } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { BlockContent } from './BlockContent.tsx';
import { RejectedFiles } from './RejectedFiles.tsx';
import { MultiFilePreview } from './MultiFilePreview.tsx';

interface UploadMultiFileProps {
  dropzoneProps: DropzoneProps;
  error?: boolean;
  files: File[];
  helperText?: string;
  onRemove: (file: File) => void;
}

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

function UploadMultiFile({
  dropzoneProps,
  error,
  files,
  helperText,
  onRemove,
}: UploadMultiFileProps) {
  const {
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
  } = useDropzone({
    ...dropzoneProps,
  });

  return (
    <Box sx={{ width: 1 }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            backgroundColor: 'error.lighter',
          }),
          ...(dropzoneProps.disabled && {
            cursor: 'not-allowed !important',
            opacity: '0.5 !important',
          }),
        }}
      >
        <input {...getInputProps()} />
        <BlockContent />
      </DropZoneStyle>

      {fileRejections.length > 0 && (
        <RejectedFiles fileRejections={fileRejections} />
      )}

      <MultiFilePreview files={files} onRemove={onRemove} />

      {helperText && helperText}
    </Box>
  );
}

export { UploadMultiFile };
