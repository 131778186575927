import type { SelectChangeEvent } from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { LoadingIcon } from 'components/icons/LoadingIcon';
import { TypographyWithTranslation } from 'components/with-translation';
import { HITL_TOOL_PAGE } from 'constants/translation-keys';
import { useGetConfigDocumentRejectReasons } from 'state/queries/config';
import { useLocales } from 'locales/useLocales';

const HTML_FOR = 'reject-reason-dropdown';

interface RejectReasonDropDownProps {
  error: {
    invalid: boolean;
    message?: string;
  };
  onChange?: (e: SelectChangeEvent) => void;
  value?: string;
}

function RejectReasonDropDown({
  error,
  onChange,
  value,
}: RejectReasonDropDownProps) {
  const { t } = useTranslation(HITL_TOOL_PAGE);
  const { currentLanguage } = useLocales();
  const {
    data: reasons,
    isPending,
    isError,
  } = useGetConfigDocumentRejectReasons(currentLanguage.value);

  if (isPending) {
    return <LoadingIcon />;
  }

  if (isError) {
    return (
      <TypographyWithTranslation
        variant="body1"
        i18nKey="rejectReason.failedToLoad"
      />
    );
  }

  return (
    <FormControl fullWidth>
      <InputLabel id={HTML_FOR}>{t('rejectReason.label')}</InputLabel>
      <Select
        error={error.invalid}
        aria-invalid={error.invalid}
        label={t('rejectReason.label')}
        labelId={HTML_FOR}
        onChange={onChange}
        value={value}
        data-testid="reject-reason-dropdown"
      >
        {Object.keys(reasons).map((reason, index) => (
          <MenuItem key={index} value={reason} data-testid={HTML_FOR}>
            {reasons[reason]}
          </MenuItem>
        ))}
      </Select>
      {error?.message ? (
        <FormHelperText error>{t(error.message)}</FormHelperText>
      ) : null}
    </FormControl>
  );
}

export { RejectReasonDropDown };
