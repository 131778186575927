function gtmScript(gtmId: string) {
  return {
    script: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${gtmId}');`,
    src: `https://www.googletagmanager.com/ns.html?id=${gtmId}`,
  };
}

export function initGtm(gtmId: string) {
  if (gtmId) {
    const { script, src } = gtmScript(gtmId);
    const gtmScriptEl = document.createElement('script');
    gtmScriptEl.innerHTML = script;
    document.head.insertBefore(gtmScriptEl, document.head.childNodes[0]);

    const iframe = document.createElement('iframe');
    iframe.height = '0';
    iframe.width = '0';
    // @ts-expect-error
    iframe.style = 'display:none;visibility:hidden';
    iframe.src = src;
    const noscript = document.createElement('noscript');
    noscript.appendChild(iframe);
    document.body.insertAdjacentElement('afterbegin', noscript);
  }
}
