import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { createContext, useState } from 'react';
import type { Claim } from 'types/Claims.ts';
import type { CurrentUser } from 'types/CurrentUser.ts';

import type { ContextState } from './types.ts';

interface EnrichmentToolContextProviderProps {
  children: ReactNode;
  claim: Claim;
  currentUser: CurrentUser;
  navigateToNextContext?: () => void;
}

const defaultState = {
  businessUnit: '',
  dropdownLevelMap: {},
  enrichment: {},
  fields: {},
  observations: {},
  lists: {},
  fieldsListId: '',
  enrichmentListId: '',
  groupedEnrichmentLevels: {},
  urls: {},
};

export const EnrichmentToolContext = createContext<{
  businessUnit: string;
  claim: Claim;
  claimType: string;
  currentUser: CurrentUser;
  state: ContextState;
  setState: Dispatch<SetStateAction<ContextState>>;
  navigateToNextContext: () => void;
}>({
  businessUnit: '',
  claim: {} as Claim,
  claimType: '',
  currentUser: {} as CurrentUser,
  state: {
    __originalState: defaultState,
    ...defaultState,
  },
  setState: () => {},
  navigateToNextContext: () => {},
});

export function EnrichmentToolContextProvider({
  children,
  claim,
  currentUser,
  navigateToNextContext,
}: EnrichmentToolContextProviderProps) {
  const [state, setState] = useState({
    __originalState: defaultState,
    ...defaultState,
  });

  const businessUnit = (claim.properties?.businessUnit || '') as string;
  const claimType = claim.properties?.claimType || '';

  return (
    <EnrichmentToolContext.Provider
      value={{
        businessUnit,
        claim,
        claimType,
        currentUser,
        state,
        setState,
        navigateToNextContext: navigateToNextContext || (() => {}),
      }}
    >
      {children}
    </EnrichmentToolContext.Provider>
  );
}
