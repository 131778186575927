import type { RadioProps } from '@mui/material/Radio';
import type { Namespace } from 'i18next';
import type { ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import { useTranslationRoot } from 'components/with-translation';

export interface ControllerWithRadioGroupProps extends RadioProps {
  getOptionLabel?: string[];
  InputProps?: {
    inputAdornment: ReactNode;
  };
  label?: string;
  name: string;
  namespace?: Namespace;
  radioOptions?: string[];
  radioProps?: NonNullable<unknown>;
  [key: string]: any;
}

function ControllerWithRadioGroup({
  disabled,
  getOptionLabel,
  label,
  name,
  namespace,
  radioProps,
  radioOptions = ['true', 'false'],
  ...props
}: ControllerWithRadioGroupProps) {
  const { t } = useTranslationRoot(namespace);
  const { control } = useFormContext();
  const { InputProps, error, ...rest } = props;

  return (
    <Controller
      control={control}
      name={name}
      disabled={disabled}
      render={({ field, fieldState: { error } }) => (
        <Stack spacing={1}>
          <Stack
            direction="row"
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              height: 56,
              position: 'relative',
            }}
          >
            <FormControl
              error={props.error}
              disabled={field.disabled}
              variant="standard"
            >
              {label ? (
                <FormLabel sx={{ color: 'grey.500', fontSize: 12 }}>
                  {label}
                </FormLabel>
              ) : null}
              {InputProps?.inputAdornment}
              <RadioGroup {...field} row>
                {radioOptions.map((option, index) => (
                  <FormControlLabel
                    key={option}
                    value={option}
                    control={<Radio {...rest} {...radioProps} />}
                    label={
                      getOptionLabel?.length
                        ? (t(getOptionLabel[index]) as string)
                        : option
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Stack>
          {error ? (
            <FormHelperText error sx={{ px: 2 }}>
              {error.message}
            </FormHelperText>
          ) : null}
        </Stack>
      )}
    />
  );
}

export { ControllerWithRadioGroup };
