import { createSelector } from 'reselect';
import {
  transformFileResponse,
  transformFilesResponse,
} from 'api/transformers/files.ts';
import { AWAITING_REVIEW } from 'constants/file-stage.ts';

import { selectState } from './utils.ts';

export const selectFilesResponse = createSelector(
  selectState,
  transformFilesResponse
);

export const selectFilesData = createSelector(
  selectFilesResponse,
  (response) => response.data
);

export const selectFilesTotal = createSelector(
  selectFilesResponse,
  (response) => response.total
);

export const selectFileResponse = createSelector(
  selectState,
  transformFileResponse
);

export const selectFileUrls = createSelector(
  selectFileResponse,
  (response) => response.urls
);

const selectFilesMetadata = createSelector(
  selectFilesResponse,
  (response) => response.metadata
);

export const selectFilesMetadataSortedColumns = createSelector(
  selectFilesMetadata,
  (metadata) => metadata?.sortedColumns || []
);

export const selectFileStageIsAwaitingReview = createSelector(
  ({ stage }) => stage,
  (stage) => stage === AWAITING_REVIEW
);
