import type { Theme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Drawer(theme: Theme) {
  return {
    MuiDrawer: {
      styleOverrides: {
        modal: {
          '&[role="presentation"]': {
            '& .MuiDrawer-paperAnchorLeft': {
              boxShadow: `8px 24px 24px 12px ${alpha(
                theme.palette.grey[900],
                0.16
              )}`,
              ...theme.applyStyles('dark', {
                boxShadow: `8px 24px 24px 12px ${alpha(
                  theme.palette.grey[900],
                  0.48
                )}`,
              }),
            },
            '& .MuiDrawer-paperAnchorRight': {
              boxShadow: `-8px 24px 24px 12px ${alpha(
                theme.palette.grey[900],
                0.16
              )}`,
              ...theme.applyStyles('dark', {
                boxShadow: `-8px 24px 24px 12px ${alpha(
                  theme.palette.grey[900],
                  0.48
                )}`,
              }),
            },
          },
        },
      },
    },
  };
}
