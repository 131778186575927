import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { TypographyWithTranslation } from 'components/with-translation';
import type { ClaimValidationResult } from 'types/Claims';

interface ValidationResultProps {
  validationResult: ClaimValidationResult;
}

function ValidationResult({ validationResult }: ValidationResultProps) {
  const { message, passed, ruleId, ruleTitle } = validationResult;
  const fields = [
    { key: 'validationResult.ruleId', value: ruleId },
    { key: 'validationResult.ruleTitle', value: ruleTitle },
    {
      key: 'validationResult.passed',
      value: String(passed),
      sx: {
        textTransform: 'capitalize',
      },
    },
    { key: 'validationResult.message', value: message },
  ];

  return (
    <Stack direction="row">
      <Box sx={{ pr: 2 }}>
        <Box
          sx={{
            backgroundColor: passed ? 'success.main' : 'error.main',
            height: 1,
            width: '2px',
          }}
        />
      </Box>
      <Stack>
        {fields.map(({ key, value, sx }) => (
          <Box key={key}>
            <TypographyWithTranslation
              component="span"
              variant="body1"
              fontWeight="bold"
              i18nKey={key}
            />
            <Typography component="span" variant="body1">{`: `}</Typography>
            <Typography component="span" variant="body1" sx={sx}>
              {value}
            </Typography>
          </Box>
        ))}
      </Stack>
    </Stack>
  );
}

export { ValidationResult };
