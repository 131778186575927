import type { MouseEvent } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import * as d3 from 'd3';
import { useRect } from 'components/customHooks/useRect';
import { toFixed } from 'utils/numbers';

import { useChartBaseOption } from './useChartBaseOption';
import { useChartInteractivityHandlers } from './useChartInteractivityHandlers';
import { ChartTooltip } from './ChartTooltip';

interface BarChartProps {
  colors?: string[];
  containerHeight?: number;
  containerWidth?: number;
  data: {
    color?: string;
    name: string;
    value: number;
  }[];
  margin?: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  ratio?: number;
}

type BarDimensions = {
  x: number;
  y: number;
  width: number;
  height: number;
};

interface BarProps extends BarDimensions {
  fill: string;
  index: number;
  onClick: () => void;
  onMouseLeave: (e: MouseEvent) => void;
  onMouseMove?: (e: MouseEvent) => void;
  opacity?: number;
  selected: number | undefined;
}

const AXIS_ADJUSTMENT = 5;
const BAND_HEIGHT = 40;
const BAND_PADDING = 0.7;
const FONT_SIZE = 12;
const PADDING_LEFT = 10;
const Y_TICK_WIDTH = 10;

function drawCubicCurve(
  dx1: number,
  dy1: number,
  dx2: number,
  dy2: number,
  dx: number,
  dy: number
) {
  return `${toFixed(dx1)} ${toFixed(dy1)} ${toFixed(dx2)} ${toFixed(
    dy2
  )} ${toFixed(dx)} ${toFixed(dy)}`;
}
function drawRect(width: number, height: number) {
  const radius = height / 2;
  const halfRadius = radius / 2;

  // there's a few of these width ? x : 0
  // purely for animating the cubic curve
  const topRight = drawCubicCurve(
    width ? width - halfRadius : 0,
    0,
    width,
    width ? halfRadius : 0,
    width,
    width ? radius : 0
  );
  const bottomRight = drawCubicCurve(
    width,
    width ? height - halfRadius : 0,
    width ? width - halfRadius : 0,
    height,
    width ? width - radius : 0,
    height
  );

  const v = toFixed(radius);
  const h = toFixed(width - radius);

  return `M0 0 H${h} C${topRight} V${v} C${bottomRight} H0 V0 Z`;
}

function Bar({
  fill: propFill,
  index,
  onClick,
  onMouseMove,
  onMouseLeave,
  opacity,
  selected,
  x,
  y,
  width,
  height,
}: BarProps) {
  function getPathDefinition(width: number, height: number, initial = false) {
    return drawRect(initial ? 0 : width, height);
  }

  const { strokeColor } = useChartBaseOption();
  const pathRef = useRef<SVGPathElement>(null);
  const [{ fill, pathDefinition }, setState] = useState({
    fill: 'transparent',
    pathDefinition: getPathDefinition(width, height, true),
  });
  const isSelected = selected === index;

  useEffect(
    function initialAnimation() {
      const path = d3.select(pathRef.current);
      const pathDefinition = getPathDefinition(width, height);

      path
        .style('fill', propFill)
        .transition()
        .delay(index * 75) // can use arbitrary number of duration / data.length
        .ease(d3.easeBackOut)
        .duration(450)
        .attr('d', pathDefinition)
        .on('end', () => {
          setState(() => ({
            pathDefinition,
            fill: propFill,
          }));
        });

      return () => {
        path.interrupt();
      };
    },
    [width] // eslint-disable-line
  );

  return (
    <path
      ref={pathRef}
      d={pathDefinition}
      fill={fill}
      fillOpacity={isSelected ? 1 : typeof selected !== 'number' ? 1 : 0.6}
      onClick={onClick}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      opacity={opacity}
      stroke={isSelected ? strokeColor : 'none'}
      strokeWidth={2}
      transform={`translate(${x}, ${y})`}
    />
  );
}

function BarChart({
  colors,
  data,
  margin = {
    top: 10,
    right: 10,
    bottom: 20,
    left: 350,
  },
  ratio = 1,
}: BarChartProps) {
  const [yAxisWidth, setYAxisWidth] = useState<undefined | number>(undefined);
  const [containerRect, containerRef] = useRect();
  const { defaultColorRange, strokeColor, textColor } = useChartBaseOption();
  const {
    handleChartOnClick,
    handleChartMouseEnter,
    handleChartMouseLeave,
    isHovering,
    open,
    parentRef,
    popover,
    selected,
  } = useChartInteractivityHandlers();

  const marginLeft = Math.ceil(yAxisWidth || 0) + PADDING_LEFT;
  const height = data.length * BAND_HEIGHT;
  const width = containerRect?.width || ratio * 1; // default to 1:1 ratio
  const colorRange = colors || defaultColorRange;
  const maxValue = d3.max(data, ({ value }) => value) as number;
  const containerWidth = width - marginLeft - margin.right;
  const containerHeight = height - margin.bottom - margin.top;
  const linearDomain = [0, maxValue || 1000];
  const scaleDomain = data.map(({ name }) => name);
  const xRange = [0, containerWidth];
  const yRange = [0, containerHeight];

  const linearScale = d3.scaleLinear(linearDomain, xRange);
  const bandScale = d3.scaleBand(scaleDomain, yRange).padding(BAND_PADDING);

  const yGridlines = d3
    .axisLeft(bandScale)
    .tickSize(containerWidth)
    .tickFormat(null);

  function getYGridlines(ref: SVGGElement) {
    d3.select(ref)
      .call(yGridlines)
      .call((g) => g.selectAll('.domain').attr('display', 'none'))
      .call((g) =>
        g
          .selectAll('.tick line')
          .attr('stroke-dasharray', 3)
          .attr('stroke', strokeColor)
          .attr('stroke-linecap', 'butt')
          .attr('pointer-events', 'none')
      )
      .call((g) => g.selectAll('.tick text').attr('display', 'none'));
  }

  function getXAxis(ref: SVGGElement) {
    const xAxis = d3.axisBottom(linearScale).tickSizeOuter(0).ticks(6);

    d3.select(ref)
      .call(xAxis)
      .call((g) =>
        g
          .selectAll('.domain')
          .attr('stroke-dasharray', 4)
          .attr('stroke', strokeColor)
      )
      .call((g) => g.selectAll('.tick line').attr('stroke', 'none'))
      .call((g) =>
        g
          .selectAll('.tick text')
          .attr('font-size', FONT_SIZE)
          .attr('x', -Y_TICK_WIDTH)
          .attr('fill', textColor)
          .attr('transform', `translate(${Y_TICK_WIDTH},0)`)
      );
  }

  function getYAxis(ref: SVGGElement) {
    const xAxis = d3.axisLeft(bandScale);

    d3.select(ref)
      .call(xAxis)
      .call((g) => g.selectAll('.domain').attr('stroke', 'none'))
      .call((g) => g.selectAll('.tick line').attr('stroke', 'none'))
      .call((g) =>
        g
          .selectAll('.tick text')
          .attr('font-size', FONT_SIZE)
          .attr('x', -Y_TICK_WIDTH)
          .attr('fill', textColor)
      )
      .call(() => {
        if (ref) {
          const rect = ref.getBoundingClientRect();
          setYAxisWidth(rect.width);
        }
      });
  }

  const bars = useMemo(
    () =>
      data.map((d, i) => ({
        data: d,
        x: linearScale(0),
        y: bandScale(d.name) || 0,
        width: linearScale(d.value),
        height: bandScale.bandwidth(),
        fill: d.color || colorRange[i],
      })),
    [bandScale, colorRange, data, linearScale]
  );

  return (
    <Box ref={containerRef} sx={{ position: 'relative' }}>
      <Box ref={parentRef} sx={{ position: 'relative' }}>
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
          <g transform={`translate(${marginLeft - Y_TICK_WIDTH}, 0)`}>
            <g
              ref={getYGridlines}
              transform={`translate(${containerWidth}, -${AXIS_ADJUSTMENT})`}
            />
            <g
              transform={`translate(0, ${margin.top})`}
              style={{ cursor: 'pointer' }}
            >
              {bars.map((bar, i) => (
                <Bar
                  key={bar.data.name}
                  {...bar}
                  index={i}
                  onClick={handleChartOnClick(i)}
                  onMouseMove={handleChartMouseEnter(i)}
                  onMouseLeave={handleChartMouseLeave}
                  opacity={isHovering(i) ? 0.2 : undefined}
                  selected={selected}
                />
              ))}
            </g>

            <g
              ref={getXAxis}
              transform={`translate(0, ${height - margin.bottom - 10})`}
            />
            <g ref={getYAxis} transform={`translate(0, ${margin.top})`} />
          </g>
        </svg>
      </Box>

      {data.length ? (
        <ChartTooltip
          left={popover.left}
          top={popover.top}
          horizontalAlignment="center"
          verticalAlignment="bottom"
          sx={{
            backgroundColor: popover.fill,
            color: 'common.white',
            opacity: Number(open),
          }}
        >
          {String(data[popover.index].value)}
        </ChartTooltip>
      ) : null}
    </Box>
  );
}

export { BarChart };
