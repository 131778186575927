import type { LookupCodes, LookupMappings } from 'types/Lookup';

import type {
  LookupCodeApiResponse,
  LookupCodesApiResponse,
  LookupMappingApiResponse,
  LookupMappingsApiResponse,
} from './types/lookup';

function transformLookupCodeResponse(item: LookupCodeApiResponse) {
  //we want to keep the keys in snake case, because the feature keys are in snake case
  return {
    ...item,
  };
}

export function transformLookupCodesResponse({
  lookup_codes,
}: LookupCodesApiResponse): LookupCodes {
  return lookup_codes.map(transformLookupCodeResponse);
}

function transformLookupMappingResponse(item: LookupMappingApiResponse) {
  return item;
}

export function transformLookupMappingsResponse({
  mappings,
}: LookupMappingsApiResponse): LookupMappings {
  return mappings.map(transformLookupMappingResponse);
}
