import type { Theme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import type { Metrics } from 'types/Metrics';
import { CardHeaderWithTranslation } from 'components/with-translation';
import { round } from 'utils/numbers';

import type { Resources } from './types';
import { ProgressChart } from './ProgressChart';
import { Claims } from './Claims';

interface ClaimsPerformanceProps {
  isPending: boolean;
  list: string[];
  resources: Resources;
  totalList: string[];
}

type ClaimsMetrics = {
  color: (theme: Theme) => string;
  id: string;
  name: string;
  quantity: number;
  value: number;
}[];

const CLAIMS: ClaimsMetrics = [
  {
    color: ({ palette }) => palette.chart.green[0],
    id: 'SumClaimsDoneLT24h',
    name: 'claimsPerformance.sumClaimsDoneLT24h',
    quantity: 100,
    value: 0,
  },
  {
    color: ({ palette }) => palette.chart.yellow[0],
    id: 'SumClaimsDoneLT5d',
    name: 'claimsPerformance.sumClaimsDoneLT5d',
    quantity: 100,
    value: 0,
  },
  {
    color: ({ palette }) => palette.chart.red[0],
    id: 'SumClaimsDoneGT5d',
    name: 'claimsPerformance.sumClaimsDoneGT5d',
    quantity: 100,
    value: 0,
  },
];

// TODO: most likely not needed?
const ARBITRARY_ANIMATION_DELAY = 150;

function ClaimsPerformance({
  isPending,
  list,
  resources,
  totalList,
}: ClaimsPerformanceProps) {
  const [claims, setClaims] = useState<ClaimsMetrics>(CLAIMS);
  const [otherMetrics, setOtherMetrics] = useState<Metrics>();

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (list.length) {
      const total = list.reduce(
        (acc, id) => acc + Number(resources[id].value),
        0
      );

      claims.map((metric) => {
        const item = resources[metric.id];
        const newMetric = {
          ...metric,
        };

        if (item) {
          newMetric.value = round((Number(item.value) / total) * 100, 2);
        }

        return newMetric;
      });

      timeoutId = setTimeout(() => {
        setClaims((state) =>
          state.map((metric) => {
            const item = resources[metric.id];
            const newMetric = {
              ...metric,
            };

            if (item) {
              newMetric.value = round((Number(item.value) / total) * 100, 2);
            }

            return newMetric;
          })
        );
      }, ARBITRARY_ANIMATION_DELAY);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [list, resources]); // eslint-disable-line

  useEffect(() => {
    if (totalList.length) {
      setOtherMetrics(totalList.map((id) => resources[id]));
    }
  }, [resources, totalList]);

  return (
    <Card>
      <CardHeaderWithTranslation titleI18nKey="claimsPerformance.heading" />
      <CardContent>
        <Stack spacing={6} direction={{ xs: 'column', sm: 'row' }}>
          <ProgressChart data={claims} />
          <Claims data={otherMetrics} isPending={isPending} />
        </Stack>
      </CardContent>
    </Card>
  );
}

export { ClaimsPerformance };
