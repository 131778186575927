import { MenuItemWithTranslation } from 'components/with-translation';
import { DASHBOARD_PAGE } from 'constants/translation-keys';
import { logout } from 'pages/Site/logout';

function LogoutButton() {
  return (
    <MenuItemWithTranslation
      i18nKey="accountTooltip.logout"
      namespace={DASHBOARD_PAGE}
      onClick={logout}
      sx={{ borderRadius: 1, m: 1, px: 2, py: 1, typography: 'body2' }}
    />
  );
}

export { LogoutButton };
