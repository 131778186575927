import type { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { LinkWithRef } from 'components/Link';
import { useTranslationRoot } from 'components/with-translation';
import claimRoute from 'pages/Claim/claim.route';
import type { Document } from 'types/Documents';

import { MetadataTooltip } from './MetadataTooltip';

interface GoBackToClaimProps {
  claimId: string | null;
}
interface DocumentToolbarProps {
  children?: ReactNode;
  document: Document;
  fromClaimView?: boolean;
}

function GoBackToClaim({ claimId }: GoBackToClaimProps) {
  const { t } = useTranslationRoot();

  if (claimId) {
    return (
      <Button
        component={LinkWithRef}
        to={claimRoute.createPath(claimId)}
        variant="outlined"
        sx={{
          whiteSpace: 'nowrap',
        }}
      >
        {t('common.goBackToClaim')}
      </Button>
    );
  }

  return null;
}

function DocumentToolbar({
  children,
  document,
  fromClaimView,
}: DocumentToolbarProps) {
  return (
    <Stack
      sx={{
        position: 'sticky',
        top: fromClaimView ? 0 : 60,
        zIndex: ({ zIndex }) => zIndex.appBar,
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: ({ palette }) => palette.background.paper,
          borderBottom: ({ palette }) => `1px solid ${palette.divider}`,
          height: 85,
          px: 2,
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <MetadataTooltip document={document} />
          {children}
        </Stack>
        {!fromClaimView ? (
          <Box>
            <GoBackToClaim claimId={document.claimId} />
          </Box>
        ) : null}
      </Stack>
    </Stack>
  );
}

export { DocumentToolbar };
