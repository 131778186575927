import { lazy } from 'react';
import { generatePath } from 'react-router-dom';
import { loadable } from 'components/loadable';
import ROUTE_PERMISSIONS from 'constants/route-permissions';
import { API_ICON } from 'constants/public-icons';
import { DOCS_PAGE } from 'constants/translation-keys';
import { ProtectedRoute } from 'pages/Site/ProtectedRoute';

const path = '/docs';
const DocsPage = loadable(lazy(() => import('./Docs')));

const docsRoute = {
  path,
  createPath: () => generatePath(path),
  element: (
    <ProtectedRoute>
      <DocsPage />
    </ProtectedRoute>
  ),
  icon: API_ICON,
  permission: ROUTE_PERMISSIONS.docs,
  i18nNamespace: DOCS_PAGE,
};

export default docsRoute;
