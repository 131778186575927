import { lazy } from 'react';
import { loadable } from 'components/loadable';

const Page404 = loadable(
  lazy(() => import(/* webpackChunkName: "page-404" */ './Page404'))
);

const page404route = {
  path: '*',
  element: <Page404 />,
};

export default page404route;
