import posthog from 'posthog-js';
import { signOut } from 'aws-amplify/auth';
import { appBC } from 'components/customHooks/useBroadcastChannel';
import rollbar from 'rollbar-config';
import { generalConfig } from 'config';

function getSessionTimestamp(): [number | undefined, string | undefined] {
  const sessionTimestamp = localStorage.getItem(
    generalConfig.sessionTimestampKey
  );

  if (sessionTimestamp) {
    const [loginTime, userId] = sessionTimestamp.split('.');
    return [parseInt(loginTime ?? '0', 10), userId];
  }

  return [undefined, undefined];
}

async function logout() {
  appBC.postMessage('signOut');

  if (posthog) {
    posthog.reset();
  }

  try {
    await signOut({ global: true });
  } catch (error) {
    rollbar.warn('Logout failed', error as unknown as Error);
  }
}

export { getSessionTimestamp, logout };
