import { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import type { NavLinkProps } from 'react-router-dom';

interface Props extends NavLinkProps {
  activeClassName?: string;
  className?: any;
  disabled?: boolean;
  to: string;
}

const LinkWithRef = forwardRef<any, Props>(function LinkWithRef(
  { activeClassName, ...props },
  ref
) {
  return (
    <RouterLink
      ref={ref}
      {...props}
      className={({ isActive }) =>
        [props.className, isActive ? activeClassName : null]
          .filter(Boolean)
          .join(' ')
      }
    />
  );
});

export { LinkWithRef };
