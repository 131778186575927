import { useLayoutEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { resizer } from 'utils/client';
import debounce from '@mui/utils/debounce';
import { generalConfig } from 'config';

function useResizer() {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const initialEnrichmentFormWidth =
    localStorage.getItem(generalConfig.claimEnrichmentFormWidthKey) || 800;

  // enable enrichment form to be resizable
  useLayoutEffect(() => {
    if (wrapperRef.current) {
      resizer(wrapperRef.current, Number(theme.breakpoints.values.md));
    }
  }, [theme.breakpoints.values.md]);

  function setColumnWidth() {
    const width = window.innerWidth;
    // 900 because that's the md breakpoint
    if (width < 900 && wrapperRef.current) {
      Array.from(wrapperRef.current?.children).forEach((box) => {
        (box as HTMLElement).style.width = '100%';
      });
    }
  }

  useLayoutEffect(() => {
    const callback = debounce(() => setColumnWidth(), 200);

    if (wrapperRef.current) {
      setColumnWidth();
      window.addEventListener('resize', callback);
    }

    return () => {
      window.removeEventListener('resize', callback);
    };
  }, []);

  // save width of enrichment form to local storage
  useLayoutEffect(() => {
    const config = { attributes: true };
    const observer = new MutationObserver((mutationList) => {
      for (const mutation of mutationList) {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'style'
        ) {
          localStorage.setItem(
            generalConfig.claimEnrichmentFormWidthKey,
            String((mutation.target as HTMLElement).clientWidth)
          );
        }
      }
    });

    if (wrapperRef.current) {
      const node = wrapperRef.current.firstChild as HTMLElement;
      observer.observe(node, config);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return {
    initialEnrichmentFormWidth,
    wrapperRef,
  };
}

export { useResizer };
