export const DISABLED_DOCUMENT_ITEMS = [
  'control_number',
  'client_name',
  'provider_name',
  'policy_number',
];
export const DROPDOWN_ITEMS = [
  'client_tax_id',
  'policy_client_tax_id',
  'provider_tax_id',
  'reason_id',
];
export const HARDCODED_DROPDOWN_ITEMS = [
  'referrer_provider',
  'self_funding_tax_id',
];
export const HARDCODED_MULTISELECT_ITEMS = ['doubts'];

export const baseTableStyles = {
  fontSize: 12,
  height: 36,
  overflow: 'hidden',
  px: 1,
  py: 0,
  whiteSpace: 'nowrap',
};
export const HIDDEN_BY_BOOLEAN: Record<string, string> = {
  doubts: 'treat_manually',
  self_funding_tax_id: 'pre_reimbursed',
};
export const FORCEFULLY_HIDDEN_LINE_ITEMS = [
  'item_specialty',
  'item_state_contribution',
  'item_description_ocr',
];
export const lineItemDropdownFields = ['item_code', 'item_description'];

// Don't add line items in here as it'll break the updating of
// related line items
export const FORCEFULLY_HIDDEN_FIELDS = [
  'document_group',
  'document_provider',
  'document_type',
  'has_medical_prescription',
  'total_state_contribution',
];
export const PROVIDER_FORCEFULLY_HIDDEN_FIELDS = [
  ...FORCEFULLY_HIDDEN_FIELDS,
  'referrer_provider',
];
export const REIMBURSEMENT_FIELDS = [
  'credit_note_number',
  'credit_note_date',
  'credit_note_total_value',
];
export const PROVIDER_FIELDS = [
  'client_tax_id',
  'client_memid',
  'client_multichannel',
  'policy_number',
  'provider_name',
  'treat_manually',
  'self_funding_tax_id',
  'doubts',
];
