import { useState, useEffect, useMemo, useRef, useLayoutEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { toTitleCase } from 'utils/string';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { ARROW_DOWNWARD_ICON } from 'constants/public-icons';
import { useTheme } from '@mui/material/styles';
import { useTranslationRoot } from 'components/with-translation';
import { COMMON } from 'constants/translation-keys';
import { getOsKey } from 'utils/client';

interface GalleryCollapsibleThumbnailsProps {
  imageUrls: {
    [key: string]: string;
  }[];
  handleClick: (index: number) => void;
  page: number;
  thumbnailsContainerWidth?: string;
}

type GroupedUrls = {
  [key: string]: number[];
};

function GalleryCollapsibleThumbnails({
  thumbnailsContainerWidth = '100px',
  imageUrls,
  page,
  handleClick,
}: GalleryCollapsibleThumbnailsProps) {
  const containerRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslationRoot(COMMON);
  const groupedUrls = useMemo(
    () =>
      imageUrls.reduce<GroupedUrls>((acc, item, index) => {
        const { documentGroup } = item;

        if (!acc[documentGroup]) {
          acc[documentGroup] = [];
        }

        acc[documentGroup].push(index);

        return acc;
      }, {}),
    [imageUrls]
  );

  const [expandedPanels, setExpandedPanels] = useState<string[]>([]);

  const { palette } = useTheme();

  useEffect(() => {
    Object.keys(groupedUrls).forEach((documentGroup) => {
      if (groupedUrls[documentGroup].includes(page)) {
        setExpandedPanels([documentGroup]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useLayoutEffect(() => {
    if (containerRef.current) {
      const container = containerRef.current;
      const rect = container.getBoundingClientRect();

      container.style.maxHeight = `${window.innerHeight - rect.top}px`;
    }
  }, []);

  const handlePanelClick = (panel: string) => {
    if (expandedPanels.includes(panel)) {
      setExpandedPanels(expandedPanels.filter((item) => item !== panel));
    } else {
      setExpandedPanels([...expandedPanels, panel]);
    }
  };

  const key = getOsKey();

  return (
    <Stack
      direction="column"
      sx={{
        position: 'sticky',
        top: '60px',
        display: 'flex',
        flexBasis: thumbnailsContainerWidth,
        overflow: 'hidden',
        flexShrink: 0,
        height: 1,
        width: 1,
      }}
      ref={containerRef}
    >
      <Stack
        direction="row"
        sx={{
          justifyContent: 'center',
        }}
      >
        <Tooltip
          title={t('canvas.shortcutKeyPrev', {
            key,
            arrow: '↑',
          })}
        >
          <IconButton
            sx={{ transform: 'rotate(180deg)' }}
            onClick={() => handleClick(page - 1)}
          >
            <SvgIconStyle src={ARROW_DOWNWARD_ICON} />
          </IconButton>
        </Tooltip>
      </Stack>
      <Stack
        direction="column"
        sx={{
          position: 'relative',
          overflowY: 'scroll',
          width: '100%',
          height: 'auto',
        }}
      >
        <Stack
          direction="column"
          sx={{
            position: 'relative',
            width: 1,
            transform: '',
            transition: 'max-height 0.3s ease-in-out',
          }}
        >
          {Object.keys(groupedUrls).map((documentGroup, index) => {
            const urlsIndexes = groupedUrls[documentGroup];
            const isOpen = expandedPanels.includes(documentGroup);

            return (
              <Accordion
                key={index}
                disableGutters
                sx={{
                  '&.Mui-expanded': {
                    boxShadow: 'none',
                    mb: 2,

                    '&:before': {
                      display: 'block',
                      opacity: 1,
                    },
                    '& + &.Mui-expanded:before': {
                      display: 'block',
                    },
                  },

                  '&:before': {
                    display: 'block',
                    opacity: 1,
                    backgroundColor: palette.grey[500_56],
                  },
                }}
                onChange={() => handlePanelClick(documentGroup)}
                expanded={isOpen}
              >
                <AccordionSummary
                  expandIcon={
                    <SvgIconStyle width={12} src={ARROW_DOWNWARD_ICON} />
                  }
                  sx={{ pl: 0, pr: 1, py: 0 }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 700,
                      fontSize: 12,
                    }}
                  >
                    {documentGroup ? toTitleCase(documentGroup) : ''}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    overflow: 'visible',
                    maxWidth: `${thumbnailsContainerWidth}px`,
                    '&.MuiAccordionDetails-root': {
                      boxShadow: 'none',
                      p: 0,
                      px: 1,
                    },
                  }}
                >
                  <Stack
                    direction="column"
                    spacing={2}
                    sx={{
                      alignItems: 'center',
                      p: 1,
                      width: 1,
                    }}
                  >
                    {urlsIndexes.map((item, index) => (
                      <Box
                        key={index}
                        sx={{
                          borderRadius: 1,
                          boxShadow: ({ shadows }) => shadows[5],
                          outline: `2px solid`,
                          outlineColor:
                            item === page
                              ? ({ palette }) => palette.primary.main
                              : 'transparent',
                          cursor: 'pointer',
                          width: 1,
                          height: 0,
                          paddingTop: '100%',
                          position: 'relative',
                          overflow: 'hidden',
                          transition:
                            'outline-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                          '&:hover': {
                            boxShadow: ({ shadows }) => shadows[3],
                          },
                        }}
                      >
                        <Box
                          sx={{
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                          }}
                        >
                          <img
                            alt=""
                            src={imageUrls[item].url}
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'contain',
                              objectPosition: 'center',
                            }}
                            onClick={() => handleClick(item)}
                          />
                        </Box>
                      </Box>
                    ))}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Stack>
      </Stack>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'center',
        }}
      >
        <Tooltip
          title={t('canvas.shortcutKeyNext', {
            key,
            arrow: '↓',
          })}
        >
          <IconButton
            onClick={() => {
              handleClick(page + 1);
            }}
          >
            <SvgIconStyle src={ARROW_DOWNWARD_ICON} />
          </IconButton>
        </Tooltip>
      </Stack>
    </Stack>
  );
}

export { GalleryCollapsibleThumbnails };
