import type { BoxProps } from '@mui/material/Box';
import Box from '@mui/material/Box';
import { LazyImage } from 'components/Image';
import { ILLUSTRATION_404_ICON } from 'constants/public-icons';

function PageNotFoundIllustration({ ...other }: BoxProps) {
  return (
    <Box {...other}>
      <LazyImage
        src={ILLUSTRATION_404_ICON}
        height={260}
        width={347}
        sx={{
          height: '100%',
          maxHeight: 260,
          maxWidth: 347,
          mx: 'auto',
          width: '100%',
        }}
      />
    </Box>
  );
}

export { PageNotFoundIllustration };
