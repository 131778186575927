import type { ChangeEvent } from 'react';
import debounce from '@mui/utils/debounce';
import { useSearchParams } from 'react-router-dom';
import type { Order } from 'constants/sort';
import { generalConfig } from 'config.ts';
import {
  DOCUMENT_TYPE,
  FILTERS,
  PAGE,
  ROWS_PER_PAGE,
  SORT,
} from 'constants/route-keys.ts';
import { ASC, DESC } from 'constants/sort';

const queue = new Map();

const clearQueue = () => {
  queue.clear();
};

const flushQueue = (
  updateFn: (key: string, value: string | string[]) => void,
  executeFn: () => void
) => {
  if (queue.size) {
    queue.forEach((value, key) => {
      updateFn(key, value);
    });
    executeFn();
    clearQueue();
  }
};

const rateLimitedFlushQueue = debounce(flushQueue, 0);

const addToQueue = (
  key: string,
  value: string | string[],
  updateFn: (key: string, value: string | string[]) => void,
  executeFn: () => void
) => {
  queue.set(key, value);
  rateLimitedFlushQueue(updateFn, executeFn);
};

export interface InitialProps {
  initialFilters?: string;
  initialPage?: string;
  initialRowsPerPage?: string;
  initialSort?: Order;
}

interface AdvancedTablePaginationReturn {
  page: number;
  rowsPerPage: number;
  sort: Order;
  filters: string | undefined;
  deleteFilterAndUrl: (filter: string) => void;
  deleteDocumentTypeFilterAndUrl: (filter: string) => void;
  handlePageChange: (_event: unknown, newPage: number) => void;
  handleRowsPerPageChange: (event: ChangeEvent<HTMLInputElement>) => void;
  setFilters: (filters: string) => void;
  toggleSort: () => void;
  updateFiltersAndUrl: (filters: string) => void;
  updateDocumentTypeFiltersAndUrl: (filters: string) => void;
  updatePage: (newPage: number) => void;
}

function useAdvancedTablePagination({
  initialFilters,
  initialPage,
  initialRowsPerPage,
  initialSort,
}: InitialProps): AdvancedTablePaginationReturn {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get(PAGE);
  const rowsPerPageParam = searchParams.get(ROWS_PER_PAGE);
  const sortParam = searchParams.get(SORT);

  const page = Number(
    initialPage
      ? initialPage
      : pageParam
        ? +pageParam
        : generalConfig.defaultPaginationStart
  );
  const rowsPerPage = Number(
    initialRowsPerPage
      ? initialRowsPerPage
      : rowsPerPageParam
        ? +rowsPerPageParam
        : generalConfig.defaultPaginationSize
  );
  const sort = initialSort
    ? initialSort
    : ((sortParam ? sortParam : generalConfig.defaultTableSort) as Order);

  const updateParams = (key: string, value: string | string[]) => {
    const params = new URLSearchParams(searchParams);
    addToQueue(
      key,
      value,
      (k, v) => {
        if (typeof v === 'string' && v) {
          params.set(k, v);
        } else if (Array.isArray(v) && v.length) {
          params.delete(k);
          v.forEach((val) => params.append(k, val));
        } else if (Array.isArray(v) && !v.length) {
          params.delete(k);
        } else {
          params.delete(k);
        }
      },
      () => {
        setSearchParams(params);
      }
    );
  };

  const updatePage = (newPage: number) => {
    updateParams(PAGE, newPage.toString());
  };

  const handlePageChange = (_event: unknown, newPage: number) => {
    updatePage(newPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    updatePage(0);
    updateParams(ROWS_PER_PAGE, event.target.value.toString());
  };

  const toggleSort = () => {
    updateParams(SORT, sort === ASC ? DESC : ASC);
  };

  const setFilters = (filters: string) => {
    updateParams(FILTERS, filters);
  };

  const updateFiltersAndUrl = (filters: string) => {
    updatePage(0);
    updateParams(FILTERS, filters);
  };

  const updateDocumentTypeFiltersAndUrl = (filters: string) => {
    updatePage(0);
    updateParams(DOCUMENT_TYPE, filters.length ? filters.split(',') : []);
  };

  const deleteDocumentTypeFilterAndUrl = (filter: string) => {
    const documentTypeFilters = searchParams.getAll(DOCUMENT_TYPE);
    const newFilters = documentTypeFilters.filter((f) => f !== filter);
    updateDocumentTypeFiltersAndUrl(newFilters.join(','));
  };

  const deleteFilterAndUrl = (filter: string) => {
    if (!initialFilters) return;
    const initialFiltersArray = initialFilters.split(';');
    const newFilters = initialFiltersArray.filter((f) => f !== filter);
    updateFiltersAndUrl(newFilters.join(','));
  };

  return {
    page,
    rowsPerPage,
    sort,
    filters: initialFilters,
    deleteFilterAndUrl,
    deleteDocumentTypeFilterAndUrl,
    handlePageChange,
    handleRowsPerPageChange,
    setFilters,
    toggleSort,
    updateFiltersAndUrl,
    updateDocumentTypeFiltersAndUrl,
    updatePage,
  };
}

export { useAdvancedTablePagination };
