import Stack from '@mui/material/Stack';
import {
  TypographyWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';
import type { Theme } from '@mui/material/styles';

import { CircularProgressWithLabel } from './CircularProgressWithLabel';

export interface OcrCardProps {
  color: (theme: Theme) => string;
  heading: string;
  label: string;
  summary: string;
  value: number;
  options: any;
  isSuccess: boolean;
}

function OcrCard({
  color,
  heading,
  label,
  summary,
  value,
  options,
  isSuccess,
}: OcrCardProps) {
  const { t } = useTranslationRoot();
  const tranlatedLabel = t(label, options) as unknown as string;

  return (
    <Stack
      direction="row"
      spacing={3}
      key={heading}
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        width: 1,
        px: 2,
        py: 5,

        '@media print': {
          width: '50%',
        },
      }}
    >
      <CircularProgressWithLabel
        color={color}
        isSuccess={isSuccess}
        label={tranlatedLabel}
        size={80}
        thickness={4}
        value={value}
      />
      <div>
        <TypographyWithTranslation
          variant="h4"
          sx={{ mb: 0.5 }}
          i18nKey={heading}
        />
        <TypographyWithTranslation
          variant="body2"
          sx={{ opacity: 0.72 }}
          i18nKey={summary}
        />
      </div>
    </Stack>
  );
}

export { OcrCard };
