import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import { menuItemClasses } from '@mui/material/MenuItem';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import { MenuPopover } from 'components/MenuPopover';
import { useTooltip } from 'components/customHooks/useTooltip.ts';
import {
  BRIGHTNESS_HIGH_ICON,
  BRIGHTNESS_LOW_ICON,
  SKEW_X_ICON,
  SKEW_Y_ICON,
  TWOTONE_SETTINGS_ICON,
} from 'constants/public-icons.ts';

import type { FileCanvas } from './types.ts';
import { customEvent } from './events.ts';

interface SettingsProps {
  canvas: FileCanvas;
  handleBrightnessChange: (value: number | number[]) => void;
  handleSkewXChange: (value: number | number[]) => void;
  handleSkewYChange: (value: number | number[]) => void;
}

const ICON_SIZE = 18;

function Settings({
  canvas,
  handleBrightnessChange,
  handleSkewXChange,
  handleSkewYChange,
}: SettingsProps) {
  const { element, handleClose, handleOpen, isOpen } = useTooltip();
  const [brightness, setBrightness] = useState(50);
  const [skewX, setSkewX] = useState(50);
  const [skewY, setSkewY] = useState(50);

  const onBrightness = (_: Event, newValue: number | number[]) => {
    setBrightness(newValue as number);
    handleBrightnessChange(newValue);
  };

  const onSkewX = (_: Event, newValue: number | number[]) => {
    setSkewX(newValue as number);
    handleSkewXChange(newValue);
  };

  const onSkewY = (_: Event, newValue: number | number[]) => {
    setSkewY(newValue as number);
    handleSkewYChange(newValue);
  };

  canvas.on(customEvent.IMAGE_ADDED, () => {
    setBrightness(50);
    setSkewX(50);
    setSkewY(50);
  });

  return (
    <>
      <ButtonGroup color="inherit" variant="contained" size="small">
        <Button onClick={handleOpen}>
          <SvgIconStyle
            src={TWOTONE_SETTINGS_ICON}
            height={ICON_SIZE}
            width={ICON_SIZE}
          />
        </Button>
      </ButtonGroup>
      <MenuPopover
        anchorEl={element}
        onClose={handleClose}
        open={isOpen}
        sx={{
          p: 0,
          mt: 1,
          ml: 0.25,
          [`& .${menuItemClasses.root}`]: {
            borderRadius: 0.75,
            typography: 'body2',
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 1.5, width: 200 }}>
          <Stack
            spacing={2}
            direction="row"
            sx={{
              alignItems: 'center',
              mb: 1,
            }}
          >
            <SvgIconStyle src={BRIGHTNESS_LOW_ICON} />
            <Slider
              aria-label="Brightness"
              value={brightness}
              onChange={onBrightness}
              valueLabelDisplay="auto"
            />
            <SvgIconStyle src={BRIGHTNESS_HIGH_ICON} />
          </Stack>
          <Stack
            spacing={2}
            direction="row"
            sx={{
              alignItems: 'center',
              mb: 1,
            }}
          >
            <SvgIconStyle src={SKEW_X_ICON} />
            <Slider
              aria-label="SkewX"
              value={skewX}
              onChange={onSkewX}
              valueLabelDisplay="auto"
            />
            <SvgIconStyle
              src={SKEW_X_ICON}
              sx={{ transform: 'rotate(180deg)' }}
            />
          </Stack>
          <Stack
            spacing={2}
            direction="row"
            sx={{
              alignItems: 'center',
              mb: 1,
            }}
          >
            <SvgIconStyle src={SKEW_Y_ICON} />
            <Slider
              aria-label="SkewX"
              value={skewY}
              onChange={onSkewY}
              valueLabelDisplay="auto"
            />
            <SvgIconStyle
              src={SKEW_Y_ICON}
              sx={{ transform: 'rotate(180deg)' }}
            />
          </Stack>
        </Box>
      </MenuPopover>
    </>
  );
}

export { Settings };
