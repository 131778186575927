import { useNavigate } from 'react-router-dom';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {
  TypographyWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { ARROW_FORWARD_ICON } from 'constants/public-icons';
import { formatDocumentDate } from 'utils/date';
import hitlToolRoute, {
  hitlToolHistoryRoute,
} from 'pages/HitLTool/hitlTool.route';

interface AuditTrailProps {
  currentHistory: string | undefined;
  fromClaimView?: boolean;
  historical: boolean;
  historySize: number;
  id: string;
  isVisible: boolean;
  lastUpdatedBy: string | null;
  lastUpdatedOn: string | null;
  updateHistory: (history: string | undefined) => void;
}

function AuditTrail({
  currentHistory,
  fromClaimView,
  historical,
  historySize,
  id,
  isVisible,
  lastUpdatedBy,
  lastUpdatedOn,
  updateHistory,
}: AuditTrailProps) {
  const { t } = useTranslationRoot();
  const navigate = useNavigate();
  const offsetHistorySize = historySize - 1;

  const navigationLogic =
    (page: number, direction: 1 | -1) =>
    (
      latestHistoryFn?: VoidFunction,
      navHistoryFn?: (nextPage: number) => void
    ) => {
      if (page === offsetHistorySize) {
        updateHistory(undefined);
        latestHistoryFn && latestHistoryFn();
      } else {
        const nextPage = currentHistory
          ? direction === -1
            ? parseInt(currentHistory) - 1
            : parseInt(currentHistory) + 1
          : page;
        updateHistory(String(nextPage));
        navHistoryFn && navHistoryFn(nextPage);
      }
    };

  const navigateHistory = (page: number, direction: 1 | -1) => {
    if (fromClaimView) {
      navigationLogic(
        page,
        direction
      )(() => navigate(hitlToolRoute.createPath(id), { replace: true }));
    } else {
      navigationLogic(page, direction)(
        () => navigate(hitlToolRoute.createPath(id), { replace: true }),
        (nextPage: number) =>
          navigate(hitlToolHistoryRoute.createPath(id, String(nextPage)), {
            replace: true,
          })
      );
    }
  };

  if (isVisible) {
    return (
      <Grid
        container
        sx={{
          justifyContent: 'space-between',
          borderBottom: ({ palette }) => `1px solid ${palette.divider}`,
          height: 85,
          p: 2,
        }}
      >
        <Grid size={6}>
          <TypographyWithTranslation
            i18nKey="audit.title"
            variant="subtitle1"
            sx={{
              fontSize: 12,
              fontWeight: 'bold',
              textTransform: 'uppercase',
            }}
          />
        </Grid>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'end',
          }}
          size={6}
        >
          <ButtonGroup
            variant="text"
            sx={{
              transform: 'translateY(-5px)',
            }}
          >
            <IconButton
              aria-label={t('audit.previous') as string}
              size="small"
              onClick={() => {
                historySize > 1 && navigateHistory(offsetHistorySize - 1, -1);
              }}
              disabled={
                offsetHistorySize === 0 || parseInt(currentHistory!) < 1
              }
            >
              <SvgIconStyle
                height={20}
                width={20}
                src={ARROW_FORWARD_ICON}
                sx={{ transform: 'rotate(180deg)' }}
              />
            </IconButton>
            <IconButton
              aria-label={t('audit.next') as string}
              size="small"
              onClick={() => {
                historical &&
                  currentHistory &&
                  navigateHistory(parseInt(currentHistory) + 1, 1);
              }}
              disabled={
                !historical ||
                !currentHistory ||
                (historical && parseInt(currentHistory) === offsetHistorySize)
              }
            >
              <SvgIconStyle height={20} width={20} src={ARROW_FORWARD_ICON} />
            </IconButton>
          </ButtonGroup>
        </Grid>
        <Grid
          size={6}
          sx={{
            justifySelf: 'end',
          }}
        >
          {lastUpdatedOn ? (
            <Typography variant="body1" sx={{ fontSize: 12 }}>
              {formatDocumentDate(new Date(lastUpdatedOn).toISOString())}
            </Typography>
          ) : null}
        </Grid>
        <Grid
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'end',
          }}
          size={6}
        >
          {lastUpdatedBy ? (
            <Typography variant="body1" sx={{ fontSize: 12 }}>
              {lastUpdatedBy}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
    );
  }

  return null;
}

export { AuditTrail };
