import en_US from 'rsuite/locales/en_US';
import { enUS } from '@mui/material/locale';

const languagesMetlifeUS = [
  {
    colors: ['#c9072a', '#001b69'],
    label: 'English',
    rsuiteLocale: en_US,
    systemValue: enUS,
    value: 'en',
  },
];

export default languagesMetlifeUS;
