import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import { useTranslationRoot } from 'components/with-translation.tsx';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import {
  MINUS_ICON,
  PLUS_ICON,
  REFRESH_ICON,
  ZOOM_RESET_ICON,
} from 'constants/public-icons.ts';
import { COMMON } from 'constants/translation-keys.ts';

interface ControlsProps {
  resetZoomAndPan: () => void;
  rotateImageClockwise: () => void;
  rotateImageCounterClockwise: () => void;
  zoomIn: () => void;
  zoomOut: () => void;
}

const ICON_SIZE = 18;

function Controls({
  resetZoomAndPan,
  rotateImageClockwise,
  rotateImageCounterClockwise,
  zoomIn,
  zoomOut,
}: ControlsProps) {
  const { t } = useTranslationRoot(COMMON);

  return (
    <ButtonGroup color="inherit" variant="contained" size="small">
      <Tooltip title={t('canvas.resetZoomAndPan')}>
        <Button onClick={resetZoomAndPan}>
          <SvgIconStyle
            src={ZOOM_RESET_ICON}
            height={ICON_SIZE}
            width={ICON_SIZE}
          />
        </Button>
      </Tooltip>

      <Tooltip title={t('canvas.zoomIn')}>
        <Button onClick={zoomIn}>
          <SvgIconStyle src={PLUS_ICON} height={ICON_SIZE} width={ICON_SIZE} />
        </Button>
      </Tooltip>

      <Tooltip title={t('canvas.zoomOut')}>
        <Button onClick={zoomOut}>
          <SvgIconStyle src={MINUS_ICON} height={ICON_SIZE} width={ICON_SIZE} />
        </Button>
      </Tooltip>

      <Tooltip title={t('canvas.rotateLeft')}>
        <Button onClick={rotateImageCounterClockwise}>
          <SvgIconStyle
            src={REFRESH_ICON}
            sx={{ transform: 'scaleX(-1)' }}
            height={ICON_SIZE}
            width={ICON_SIZE}
          />
        </Button>
      </Tooltip>

      <Tooltip title={t('canvas.rotateRight')}>
        <Button onClick={rotateImageClockwise}>
          <SvgIconStyle
            src={REFRESH_ICON}
            height={ICON_SIZE}
            width={ICON_SIZE}
          />
        </Button>
      </Tooltip>
    </ButtonGroup>
  );
}

export { Controls };
