import { useState } from 'react';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import api from 'api/api.ts';
import {
  TypographyWithTranslation,
  WithTranslationRoot,
} from 'components/with-translation.tsx';
import { Page } from 'components/Page';
import { useModal } from 'components/customHooks/useModal.ts';
import { FILE_UPLOAD_PAGE } from 'constants/translation-keys.ts';
import { generateUuid } from 'utils/generate-uuid.ts';

import { UploadModal } from './UploadModal.tsx';
import { MainUploadButton } from './MainUploadButton.tsx';
import { UploadProgressTile } from './UploadProgressTile.tsx';

type AttachedMedia = {
  file: File;
  id: string;
  type: string;
  uploader: ReturnType<typeof api.multipartUpload>;
  uploadProgress: boolean | number;
};

type State = {
  isUploadInProgress: boolean;
  attachedMedia: { [k: string]: AttachedMedia };
  attachedMediaIds: string[];
};

export default function FileUpload() {
  const { handleClose, handleOpen, isOpen } = useModal();
  const [state, setState] = useState<State>({
    isUploadInProgress: false,
    attachedMedia: {},
    attachedMediaIds: [],
  });

  const addMedia = (value: AttachedMedia) => {
    setState((oldState) => ({
      ...oldState,
      isUploadInProgress: true,
      attachedMedia: {
        ...oldState.attachedMedia,
        [value.id]: {
          ...oldState.attachedMedia[value.id],
          ...value,
        },
      },
      attachedMediaIds: [...oldState.attachedMediaIds, value.id].filter(
        (elem, pos, arr) => arr.indexOf(elem) === pos
      ),
    }));
  };

  const updateMedia = ({
    id,
    uploadProgress,
  }: {
    id: string;
    uploadProgress: number | boolean;
  }) => {
    setState((oldState) => ({
      ...oldState,
      isUploadInProgress:
        typeof uploadProgress === 'number' ? uploadProgress !== 100 : false,
      attachedMedia: {
        ...oldState.attachedMedia,
        [id]: {
          ...oldState.attachedMedia[id],
          uploadProgress,
        },
      },
    }));
  };

  const fileHandler = ({ id, file }: { id: string; file: File }) => {
    let percentage: number | undefined = undefined;
    const uploader = api.multipartUpload(file.name, file);

    addMedia({
      file,
      id,
      type: file.type,
      uploadProgress: 0,
      uploader,
    });

    uploader
      .onProgress(({ percentage: newPercentage }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;

          updateMedia({
            id,
            uploadProgress: percentage,
          });
        }
      })
      .onError(() => {
        updateMedia({ id, uploadProgress: false });
      });

    uploader.start();
  };

  const addNewFiles = (newFiles: File[]) => {
    newFiles.forEach((file: File) => {
      const id = generateUuid();
      fileHandler({ id, file });
    });
  };

  const handleUpload = (files: File[]) => {
    addNewFiles(files);
  };

  const retryFileUpload = (id: string) => {
    const { file } = state.attachedMedia[id];
    fileHandler({ id, file });
  };

  const filterUploadedFiles = (id: string) =>
    state.attachedMedia[id].uploadProgress !== 100;

  const getFileFromState = (id: string) => state.attachedMedia[id].file;

  return (
    <WithTranslationRoot namespace={FILE_UPLOAD_PAGE}>
      <Page i18nKeyTitle="meta.title">
        <Container maxWidth="lg">
          <Stack direction="row" component="header" spacing={3} sx={{ mb: 5 }}>
            <TypographyWithTranslation i18nKey="common.title" variant="h4" />
            <MainUploadButton
              handleOpen={handleOpen}
              isUploadInProgress={state.isUploadInProgress}
            />
          </Stack>
          <Stack>
            {state.attachedMediaIds.map((id) => (
              <UploadProgressTile
                key={id}
                {...state.attachedMedia[id]}
                id={id}
                retryFileUpload={retryFileUpload}
              />
            ))}
          </Stack>
        </Container>
      </Page>
      <UploadModal
        files={state.attachedMediaIds
          .filter(filterUploadedFiles)
          .map(getFileFromState)}
        handleClose={handleClose}
        isOpen={isOpen}
        onUpload={handleUpload}
      />
    </WithTranslationRoot>
  );
}
