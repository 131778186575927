import { lazy } from 'react';
import { generatePath } from 'react-router-dom';
import { loadable } from 'components/loadable';
import { DOCUMENT_ID, RESOURCE_ID } from 'constants/route-keys';
import ROUTE_PERMISSIONS from 'constants/route-permissions';
import { CLAIM_HIL_TOOL_PAGE } from 'constants/translation-keys';
import { initFabricSDK } from 'utils/init-fabric';

const path = `/c/:${RESOURCE_ID}/h/:${DOCUMENT_ID}`;
const ClaimHILToolPage = loadable(lazy(() => import('./ClaimHILTool')));

const claimHILToolRoute = {
  path,
  createPath: (resourceId: string, documentId: string) =>
    generatePath(path, { resourceId, documentId }),
  element: <ClaimHILToolPage />,
  permission: ROUTE_PERMISSIONS.claim,
  i18nNamespace: CLAIM_HIL_TOOL_PAGE,
  preload: () => {
    initFabricSDK();
    return import('components/HilTool/HiLTool');
  },
};

export default claimHILToolRoute;
