import { Amplify } from 'aws-amplify';

const userPoolId =
  window.__env__.COGNITO_USER_POOL_ID ||
  import.meta.env.VITE_COGNITO_USER_POOL_ID;
const userPoolClientId =
  window.__env__.COGNITO_CLIENT_ID || import.meta.env.VITE_COGNITO_CLIENT_ID;
const oauthDomain =
  window.__env__.COGNITO_OAUTH_DOMAIN ||
  import.meta.env.VITE_COGNITO_OAUTH_DOMAIN;
const redirectUrl =
  import.meta.env.VITE_RUNNING_E2E === 'true'
    ? [window.location.origin]
    : import.meta.env.DEV || import.meta.env.MODE === 'development'
      ? [window.location.origin]
      : [window.__env__.API_URL || import.meta.env.VITE_API_URL];

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId,
      userPoolClientId,
      loginWith: {
        oauth: {
          domain: oauthDomain,
          scopes: [
            'profile',
            'email',
            'openid',
            'aws.cognito.signin.user.admin',
          ],
          redirectSignIn: redirectUrl,
          redirectSignOut: redirectUrl,
          responseType: 'code',
        },
      },
    },
  },
});
