const metlifecl = {
  palette: {
    primary: {
      light: '#60c0ff',
      main: '#0090da',
      dark: '#0063a8',
    },
    secondary: {
      light: '#d8ff7f',
      main: '#a4ce4e',
      dark: '#729d1a',
    },
    chart: {
      primary: ['#005aa8', '#0063a8', '#0090da', '#60c0ff'],
      secondary: ['#699d1a', '#729d1a', '#a4ce4e', '#d8ff7f'],
      blue: ['#3645f3', '#4957de', '#5d6dff', '#7e8aff'],
      yellow: ['#fdd835', '#ffeb3b', '#fceb55', '#fef075'],
      green: ['#00c64a', '#00ab2d', '#008000', '#005000'],
      red: ['#c0802e', '#c0632e', '#c0442e', '#a9412f'],
    },
  },
};

export default metlifecl;
