import { lazy } from 'react';
import { generatePath } from 'react-router-dom';
import { loadable } from 'components/loadable';
import { PERFORMANCE_ICON } from 'constants/public-icons';
import ROUTE_PERMISSIONS from 'constants/route-permissions';
import { PERFORMANCE_PAGE } from 'constants/translation-keys';

const path = '/p';
const PerformancePage = loadable(
  lazy(() => import(/* webpackChunkName: "performance" */ './Performance'))
);

const performanceRoute = {
  path,
  createPath: () => generatePath(path),
  icon: PERFORMANCE_ICON,
  element: <PerformancePage />,
  permission: ROUTE_PERMISSIONS.mi,
  i18nNamespace: PERFORMANCE_PAGE,
};

export default performanceRoute;
