import type { IconButtonProps } from '@mui/material/IconButton';
import { forwardRef } from 'react';
import { alpha, styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

const IconButtonStyle = styled(IconButton)(({ theme }) => ({
  height: '40px',
  transition: theme.transitions.create(['background-color', 'transform'], {
    duration: theme.transitions.duration.shortest,
  }),
  '&:hover': {
    transform: 'scale(1.1)',
  },
}));

interface IconButtonAnimateProps extends IconButtonProps {
  isOpen: boolean;
}

const IconButtonAnimate = forwardRef<HTMLButtonElement, IconButtonAnimateProps>(
  ({ children, isOpen, onClick, ...props }, ref) => {
    return (
      <IconButtonStyle
        ref={ref}
        onClick={onClick}
        sx={{
          height: 44,
          padding: 0,
          width: 44,
          ...(isOpen && {
            '&:before': {
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
              borderRadius: '50%',
              content: "''",
              width: '100%',
              height: '100%',
              position: 'absolute',
              zIndex: 1,
            },
          }),
        }}
        {...props}
      >
        {children}
      </IconButtonStyle>
    );
  }
);
IconButtonAnimate.displayName = 'IconButtonAnimate';

export { IconButtonAnimate };
