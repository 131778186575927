import { HiddenInput } from 'components/form/HiddenInput';
import { isHighLevelItem } from 'state/selectors/documents';
import { toTitleCase } from 'utils/string';

import type { ItemAccordionProps } from './types';
import { AccordionContainer } from './AccordionContainer';
import { InputField } from './InputField';
import { hasDocumentTypeItem } from './utils';

// hardcoding these because AXA want these disabled and there's no way for backend to configure this
const DISABLED_ITEMS = ['document_group', 'document_provider'];

function ItemAccordion({
  defaultExpanded = true,
  heading,
  isCheck,
  isUnidentifiedItems = true,
  list,
  resources,
  setCrop,
  updateIsCheckList,
}: ItemAccordionProps) {
  const highLevelItems = list.filter((id) => isHighLevelItem(resources[id]));
  const documentTypeId = list.find(hasDocumentTypeItem(resources));
  const normalItems = list.filter((id) => {
    return (
      !isHighLevelItem(resources[id]) && !hasDocumentTypeItem(resources)(id)
    );
  });
  const hasItems = !!normalItems.length;

  return (
    <>
      {highLevelItems.map((id) => (
        <HiddenInput key={id} name={id} defaultValue={resources[id].value} />
      ))}
      {documentTypeId && (
        <HiddenInput
          name={documentTypeId}
          defaultValue={resources[documentTypeId].value}
        />
      )}
      {hasItems ? (
        <AccordionContainer defaultExpanded={defaultExpanded} heading={heading}>
          {normalItems.map((id) => {
            const item = resources[id];

            return (
              <InputField
                key={id}
                {...item}
                disabled={DISABLED_ITEMS.includes(item.sproutaiKey)}
                name={id}
                label={toTitleCase(item.clientKey)}
                isUnidentifiedItem={isUnidentifiedItems}
                isCheck={isCheck}
                setCrop={setCrop}
                updateIsCheckList={updateIsCheckList}
              />
            );
          })}
        </AccordionContainer>
      ) : null}
    </>
  );
}

export { ItemAccordion };
