import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel, {
  formControlLabelClasses,
} from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import visuallyHidden from '@mui/utils/visuallyHidden';

import type { SkipCheckboxProps } from './types';

const SkipCheckbox = forwardRef(function SkipCheckbox(
  { isChecked, handleChange, title, ...props }: SkipCheckboxProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  return (
    <Tooltip title={title} placement="top" arrow>
      <Paper
        square
        variant="outlined"
        sx={{
          background: ({ palette }) => palette.background.neutral,
          borderRadius: 1,
          borderColor: ({ palette }) =>
            isChecked ? palette.primary.light : palette.grey[500_12],
          height: 36,
          width: 36,
          [`& .${formControlLabelClasses.root}`]: {
            margin: '0 !important',
          },
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              {...props}
              size="small"
              checked={isChecked}
              onChange={(e) => {
                handleChange(e);
                props.onChange && props.onChange(e);
              }}
              ref={ref}
              sx={{ borderRadius: 1, p: '7px' }}
              tabIndex={-1}
            />
          }
          label={title}
          sx={{
            [`& .${formControlLabelClasses.label}`]: visuallyHidden,
          }}
        />
      </Paper>
    </Tooltip>
  );
});

export { SkipCheckbox };
