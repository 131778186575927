import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import type { Metrics } from 'types/Metrics';
import { LoadingIcon } from 'components/icons/LoadingIcon';
import {
  CardHeaderWithTranslation,
  TypographyWithTranslation,
} from 'components/with-translation';

import { MetricCards } from './MetricCards';
import metricTranslations from './metric-translation-dict.json';

interface DocumentProcessingPerformanceProps {
  data: Metrics | undefined;
  documentMetrics?: string[];
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
}

function OtherPerformanceMetrics({
  data,
  isError,
  isLoading,
  isSuccess,
  documentMetrics = Object.keys(metricTranslations),
}: DocumentProcessingPerformanceProps) {
  let markup;

  if (isLoading) {
    markup = <LoadingIcon />;
  }

  if (isSuccess) {
    const metrics = data!.filter(({ name }) => documentMetrics.includes(name));
    markup = <MetricCards metrics={metrics} />;
  }

  if (isError) {
    markup = (
      <TypographyWithTranslation
        variant="body2"
        i18nKey="otherPerformanceMetrics.failedToLoad"
      />
    );
  }

  if (documentMetrics.length) {
    return (
      <Card>
        <CardHeaderWithTranslation titleI18nKey="otherPerformanceMetrics.title" />
        <CardContent>{markup}</CardContent>
      </Card>
    );
  }

  return null;
}

export { OtherPerformanceMetrics };
