import { lazy } from 'react';
import { generatePath } from 'react-router-dom';
import { loadable } from 'components/loadable';
import { FILE_EARMARK_ICON } from 'constants/public-icons';
import { RESOURCE_ID } from 'constants/route-keys';
import ROUTE_PERMISSIONS from 'constants/route-permissions';
import { CLAIM_PAGE } from 'constants/translation-keys';
import claimHilToolRoute from 'pages/ClaimHILTool/claimHilTool.route';

const path = `/c/:${RESOURCE_ID}`;
const ClaimPage = loadable(lazy(() => import('./Claim')));

const claimRoute = {
  path,
  createPath: (resourceId: string) => generatePath(path, { resourceId }),
  icon: FILE_EARMARK_ICON,
  element: <ClaimPage />,
  children: [claimHilToolRoute],
  permission: ROUTE_PERMISSIONS.claim,
  i18nNamespace: CLAIM_PAGE,
  preload: () => {
    return import('components/HilTool/HiLTool');
  },
};

export default claimRoute;
