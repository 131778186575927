import type { SelectChangeEvent } from '@mui/material/Select';
import type { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled, useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { useBoolean } from 'components/customHooks/useBoolean';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag';
import {
  DialogContentTextWithTranslation,
  DialogTitleWithTranslation,
  LoadingButtonWithTranslation,
} from 'components/with-translation';
import {
  CIRCLE_CHECK_ICON,
  CLOSE_ICON,
  NEXT_ICON,
  PLUS_ICON,
  SAVE_ICON,
  SQUARE_MINUS_ICON,
  TICK_ICON,
} from 'constants/public-icons';
import { HITL_TOOL_PAGE } from 'constants/translation-keys';
import { updateWindowPerformanceObject } from 'analytics/utils';
import { HIL_TOOL_SUBMIT_START } from 'analytics/events';

import type { FilterTypes } from './types';
import { RejectReasonDropDown } from './RejectReasonDropDown';
import { OTHER_REASON } from './utils';
import { SkipCheckbox } from './SkipCheckbox';
import { FilterFields } from './FilterFields';

const FormButton = styled(LoadingButtonWithTranslation)(({ theme }) => ({
  flex: 1,
  [theme.breakpoints.up('lg')]: {
    fontSize: 14,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 12,
  },
  height: 36,
  whiteSpace: 'nowrap',
}));

const StartIcon = styled(SvgIconStyle)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

interface FormButtonsProps {
  addLineItem: VoidFunction;
  disableSubmit: boolean; // not using react-hook-form formState.isSubmitting to handle this
  filterItemsBy: FilterTypes;
  fromClaimView?: boolean;
  handleReject: (reason: string) => void;
  handleSaveDocument: VoidFunction;
  handleNextDocument: VoidFunction;
  hasLineItems: boolean;
  isDocumentTypeValid: boolean;
  isCheckAll: boolean;
  isNotLockedByCurrentUser: boolean;
  isSubmitting: boolean;
  setFilterItemsBy: Dispatch<SetStateAction<FilterTypes>>;
  toggleCheckAll: VoidFunction;
}

interface RejectDialogProps {
  isOpen: boolean;
  closeModal: VoidFunction;
  error: { invalid: boolean; message?: string };
  handleChange: (event: SelectChangeEvent) => void;
  reason: string | undefined;
  handleOtherReasonChange: (event: ChangeEvent<HTMLInputElement>) => void;
  otherReason: string;
  onReject: VoidFunction;
  isSubmitting: boolean;
}

interface CheckAllWarningDialogProps {
  closeModal: VoidFunction;
  isOpen: boolean;
  onConfirm: VoidFunction;
}

function RejectDialog({
  isOpen,
  closeModal,
  error,
  handleChange,
  reason,
  handleOtherReasonChange,
  otherReason,
  onReject,
  isSubmitting,
}: RejectDialogProps) {
  const { t } = useTranslation(HITL_TOOL_PAGE);

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="reject-dialog-title"
    >
      <DialogTitleWithTranslation
        id="reject-dialog-title"
        i18nKey="rejectDialog.title"
      />
      <DialogContent>
        <DialogContentTextWithTranslation
          id="reject-dialog-description"
          sx={{ mb: 2 }}
          i18nKey="rejectDialog.description"
        />
        <Stack spacing={3}>
          <RejectReasonDropDown
            error={error}
            onChange={handleChange}
            value={reason}
          />
          {reason === OTHER_REASON ? (
            <TextField
              id="reason-freetext"
              label={t('rejectReason.otherReason')}
              variant="outlined"
              autoFocus
              onChange={handleOtherReasonChange}
              value={otherReason}
            />
          ) : null}
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButtonWithTranslation
          variant="contained"
          color="error"
          startIcon={
            <StartIcon height={17} src={SQUARE_MINUS_ICON} width={17} />
          }
          onClick={onReject}
          loading={isSubmitting}
          autoFocus
          i18nKey="rejectDialog.rejectDocument"
        />
        <LoadingButtonWithTranslation
          variant="outlined"
          color="inherit"
          startIcon={<StartIcon height={17} src={CLOSE_ICON} width={17} />}
          onClick={closeModal}
          i18nKey="rejectDialog.cancelButton"
        />
      </DialogActions>
    </Dialog>
  );
}

function CheckAllWarningDialog({
  isOpen,
  closeModal,
  onConfirm,
}: CheckAllWarningDialogProps) {
  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="warning-dialog-title"
    >
      <DialogTitleWithTranslation
        id="warning-dialog-title"
        i18nKey="checkAllWarningDialog.title"
      />
      <DialogContent>
        <DialogContentTextWithTranslation
          id="warning-dialog-description"
          sx={{ mb: 2 }}
          i18nKey="checkAllWarningDialog.description"
        />
      </DialogContent>
      <DialogActions>
        <LoadingButtonWithTranslation
          variant="contained"
          color="success"
          startIcon={<StartIcon height={17} src={TICK_ICON} width={17} />}
          onClick={onConfirm}
          autoFocus
          i18nKey="checkAllWarningDialog.confirmButton"
        />
        <LoadingButtonWithTranslation
          variant="outlined"
          color="inherit"
          startIcon={<StartIcon height={17} src={CLOSE_ICON} width={17} />}
          onClick={closeModal}
          i18nKey="checkAllWarningDialog.cancelButton"
        />
      </DialogActions>
    </Dialog>
  );
}

function FormButtonsNew({
  addLineItem,
  disableSubmit,
  filterItemsBy,
  fromClaimView,
  handleReject,
  handleSaveDocument,
  handleNextDocument,
  hasLineItems,
  isCheckAll,
  isDocumentTypeValid,
  isNotLockedByCurrentUser,
  isSubmitting,
  setFilterItemsBy,
  toggleCheckAll,
}: FormButtonsProps) {
  const showSaveButton = useFeatureFlag('enableHILSaveDocument');
  const showNextButton = useFeatureFlag('enableHILSkipDocument');
  const { t } = useTranslation(HITL_TOOL_PAGE);
  const theme = useTheme();
  const [isOpen, openRejectModal, closeRejectModal] = useBoolean();
  const [isWarningOpen, openWarningModal, closeWarningModal] = useBoolean();
  const [reason, setReason] = useState<string | undefined>('');
  const [otherReason, setOtherReason] = useState('');
  const [error, setError] = useState<{ invalid: boolean; message?: string }>({
    invalid: false,
  });

  function clearError() {
    setError({ invalid: false, message: undefined });
  }

  function handleOtherReasonChange(event: ChangeEvent<HTMLInputElement>) {
    setOtherReason(event.currentTarget.value);
  }

  function handleChange(event: SelectChangeEvent) {
    clearError();
    setReason(event.target.value);
  }

  function onReject() {
    if (reason) {
      const text =
        reason === OTHER_REASON ? `${t(reason)}: ${otherReason}` : reason;
      handleReject(text);
    } else {
      setError({
        invalid: true,
        message: 'rejectReason.errorMessage',
      });
    }
  }

  function closeModal() {
    closeRejectModal();
    setReason('');
    clearError();
  }

  function handleCheckAll() {
    if (!isCheckAll) {
      openWarningModal();
    } else {
      toggleCheckAll();
    }
  }

  function confirmCheckAll() {
    closeWarningModal();
    toggleCheckAll();
  }

  return (
    <>
      <Stack
        sx={{
          position: 'sticky',
          top: fromClaimView ? 0 : 60,
          zIndex: ({ zIndex }) => zIndex.appBar,
        }}
      >
        <Stack
          direction="row"
          sx={{
            px: 1,
            py: 3,
            backgroundColor: ({ palette }) => palette.background.paper,
            borderBottom: `1px solid ${theme.palette.divider}`,
            '& > *': { mx: `8px !important` },
          }}
        >
          <FormButton
            data-testid="submit-document-button"
            disabled={
              !isDocumentTypeValid || isNotLockedByCurrentUser || disableSubmit
            }
            variant="contained"
            color="primary"
            startIcon={
              <StartIcon height={17} src={CIRCLE_CHECK_ICON} width={17} />
            }
            type="submit"
            loading={isSubmitting}
            i18nKey="form.submitDocument"
            namespace={HITL_TOOL_PAGE}
            onClick={() => {
              updateWindowPerformanceObject(HIL_TOOL_SUBMIT_START);
            }}
          />
          <FormButton
            data-testid="reject-document-button"
            disabled={isNotLockedByCurrentUser}
            variant="contained"
            color="error"
            startIcon={
              <StartIcon height={17} src={SQUARE_MINUS_ICON} width={17} />
            }
            onClick={openRejectModal}
            i18nKey="form.rejectDocument"
            namespace={HITL_TOOL_PAGE}
          />
          {showSaveButton ? (
            <FormButton
              data-testid="save-document-button"
              disabled={!isDocumentTypeValid || isNotLockedByCurrentUser}
              variant="contained"
              color="info"
              startIcon={<StartIcon height={17} src={SAVE_ICON} width={17} />}
              onClick={handleSaveDocument}
              i18nKey="form.saveDocument"
              namespace={HITL_TOOL_PAGE}
              sx={{ flex: '0 0 auto' }}
            />
          ) : null}

          {showNextButton ? (
            <Tooltip title={t('form.nextDocument')}>
              <span>
                <FormButton
                  data-testid="next-document-button"
                  variant="contained"
                  color="warning"
                  startIcon={
                    <StartIcon height={20} src={NEXT_ICON} width={20} />
                  }
                  i18nKey=""
                  onClick={handleNextDocument}
                  sx={{
                    minWidth: 'auto',
                    flex: '0 1 auto',
                    '& .MuiButton-startIcon': {
                      mr: 0,
                      ml: 0,
                    },
                  }}
                  namespace={HITL_TOOL_PAGE}
                />
              </span>
            </Tooltip>
          ) : null}

          <RejectDialog
            isOpen={isOpen}
            closeModal={closeModal}
            error={error}
            handleChange={handleChange}
            reason={reason}
            handleOtherReasonChange={handleOtherReasonChange}
            otherReason={otherReason}
            onReject={onReject}
            isSubmitting={isSubmitting}
          />
        </Stack>
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 2,
            backgroundColor: ({ palette }) => palette.background.paper,
          }}
        >
          <FilterFields handleChange={setFilterItemsBy} value={filterItemsBy} />
          <Stack direction="row" spacing={1}>
            {hasLineItems ? (
              <FormButton
                variant="contained"
                color="success"
                startIcon={<StartIcon height={17} src={PLUS_ICON} width={17} />}
                onClick={addLineItem}
                i18nKey="form.addLineItemButton"
                namespace={HITL_TOOL_PAGE}
              />
            ) : null}
            <SkipCheckbox
              data-testid="skip-all-checkbox"
              isChecked={isCheckAll}
              handleChange={handleCheckAll}
              title={t('form.checkAllTooltipTitle')}
            />
          </Stack>
        </Stack>
      </Stack>
      <CheckAllWarningDialog
        closeModal={closeWarningModal}
        isOpen={isWarningOpen}
        onConfirm={confirmCheckAll}
      />
    </>
  );
}

export { FormButtonsNew };
