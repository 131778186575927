import { DialogModal } from 'components/dialog/Modal/DialogModal.tsx';
import {
  actionButton,
  deleteButton,
  header,
  text,
} from 'components/dialog/generators';

interface NoChangesModalProps {
  closeModal: VoidFunction;
  isOpen: boolean;
  onSubmit: VoidFunction;
}

function NoChangesModal({ isOpen, closeModal, onSubmit }: NoChangesModalProps) {
  return (
    <DialogModal
      isOpen={isOpen}
      handleClose={closeModal}
      getDialog={{
        index() {
          return [
            header('noChangesModal.title'),
            text('noChangesModal.description'),
            deleteButton({
              action: closeModal,
              icon: null,
              label: 'noChangesModal.closeButton',
            }),
            actionButton({
              action: () => {
                onSubmit();
                closeModal();
              },
              icon: null,
              label: 'noChangesModal.submitButton',
            }),
          ];
        },
      }}
    />
  );
}

export { NoChangesModal };
