import { useState } from 'react';

function usePage(initialPage = 0) {
  const [page, setPage] = useState(initialPage);

  const handleChangePage = (newValue: number) => {
    return new Promise((resolve) => {
      if (newValue !== page) {
        setPage(() => newValue);
      }
      resolve(newValue);
    });
  };

  return {
    page,
    handleChangePage,
  };
}

export { usePage };
