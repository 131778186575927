import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import { Page } from 'components/Page';
import {
  TypographyWithTranslation,
  WithTranslationRoot,
} from 'components/with-translation';
import { PERFORMANCE_CREATE_USER_PAGE } from 'constants/translation-keys';
import { FORM_SUBMIT_REDIRECT_TIMEOUT } from 'constants/redirect-timeouts';
import performanceManageUsersRoute from 'pages/PerformanceManageUsers/performanceManageUsers.route';
import { useCreateUserRole } from 'state/queries/users';

import { CreateUserForm } from './CreateUserForm';
import type { CreateUserFormValues } from './CreateUserForm';

function PerformanceCreateUser() {
  const navigate = useNavigate();
  const { t } = useTranslation(PERFORMANCE_CREATE_USER_PAGE);
  const createUserRole = useCreateUserRole({
    meta: {
      successMessage: t('createUserForm.submitSuccess') as string,
      errorMessage: t('createUserForm.submitError') as string,
      loadingMessage: t('createUserForm.submitLoading') as string,
    },
  });

  function handleSubmit({ email, role }: CreateUserFormValues) {
    const payload = {
      email,
      role,
    };

    createUserRole.mutate(payload, {
      onSuccess: () => {
        setTimeout(() => {
          navigate(performanceManageUsersRoute.createPath());
        }, FORM_SUBMIT_REDIRECT_TIMEOUT);
      },
    });
  }

  return (
    <WithTranslationRoot namespace={PERFORMANCE_CREATE_USER_PAGE}>
      <Page i18nKeyTitle="meta.title">
        <Container maxWidth="xl">
          <TypographyWithTranslation
            i18nKey="createUserForm.heading"
            variant="h4"
            sx={{ mb: 5 }}
          />
          <CreateUserForm onSubmit={handleSubmit} />
        </Container>
      </Page>
    </WithTranslationRoot>
  );
}

export default PerformanceCreateUser;
