/**
 * Claim API Endpoints
 */
import { DOCUMENT_TYPE } from 'constants/route-keys';

export type MakePage = {
  list: string;
  params: { [k: string]: string | undefined };
};

export const CLAIMS = 'claims';
export const CLAIMS_LIST = `${CLAIMS}\\?start=:start&size=:size&order=:order`;
export const CLAIM = `${CLAIMS}/:id`;
export const CLAIM_NEXT = `${CLAIMS}/next`;
export const CLAIM_DOCUMENTS = `${CLAIM}/documents`;
export const CLAIM_FEEDBACK = `${CLAIM}/feedback`;
export const CLAIM_OBSERVATIONS = `${CLAIM}/observations`;
export const CLAIM_PAGEURLS = `${CLAIM}/page_urls`;
export const CLAIM_LOCK = `${CLAIM}/lock`;
export const CLAIM_UNLOCK = `${CLAIM}/unlock\\?force=:force`;
export const CLAIM_ARCHIVE = `${CLAIM}/archive`;
export const CLAIM_REVIEW = `${CLAIM}/review`;

export const CLAIM_REJECT = `${CLAIM}/reject`;

// this endpoint doesn't require claim id
export const CLAIM_VALIDATION = `${CLAIMS}/validation`;

export const CLAIM_REVIEW_VALIDATION = `${CLAIM}/validate`;

// lookup endpoint
export const CLAIM_LOOKUP = `lookup/code\\?search_text=:search_text&search_key=:search_key`;

// lookup mappings endpoint
export const CLAIM_LOOKUP_MAPPING = `lookup/mapping\\?claim_id=:claim_id&search_key=:search_key&search_text=:search_text`;

function generateParam(
  params: string[],
  paramKey: string,
  variableName: string
) {
  return params.reduce(
    (acc, param, index) => {
      const { list, params: newParams } = acc;
      const key = `${variableName}_${index}`;

      // remove last ampersand if it's the last iteration
      const operator = index === params.length - 1 ? '' : '&';

      return {
        list: `${list}${paramKey}=:${key}${operator}`,
        params: {
          ...newParams,
          [key]: param,
        },
      };
    },
    { list: '', params: {} }
  );
}

export function makeClaimsList({
  filters,
  order,
  searchValue,
  searchField,
  stage,
}: {
  filters?: string | null;
  order?: string;
  searchValue?: string | null;
  searchField?: string | null;
  stage?: string | null;
}) {
  const page: MakePage = {
    list: CLAIMS_LIST,
    params: { order },
  };

  if (stage) {
    page.list = `${page.list}&stage_filter=:stage`;
    page.params = {
      ...page.params,
      stage,
    };
  }

  if (searchField) {
    page.list = `${page.list}&search_field=:search_field`;
    page.params = {
      ...page.params,
      search_field: searchField,
    };
  }

  if (searchValue) {
    page.list = `${page.list}&search_value=:search_value`;
    page.params = {
      ...page.params,
      search_value: searchValue,
    };
  }

  if (filters) {
    page.list = `${page.list}&filters=:filters`;
    page.params = {
      ...page.params,
      filters: filters,
    };
  }

  return page;
}

/**
 * Documents API Endpoints
 */
export const DOCUMENTS_REPROCESS = `documents/:id/reprocess` as const;

/**
 * HIL Documents API Endpoints
 */
export const DOCUMENTS = 'hil/documents' as const;
export const DOCUMENT = `${DOCUMENTS}/:id` as const;
export const DOCUMENT_WITH_LANG_QUERY = `${DOCUMENT}\\?lang=:language` as const;
export const DOCUMENTS_HISTORY =
  `${DOCUMENT}/history\\?hide_stage=:hideStage` as const;
export const DOCUMENTS_HISTORY_VERSION =
  `${DOCUMENT}/history/:version` as const;
export const DOCUMENTS_LOCK = `${DOCUMENT}/lock` as const;
export const DOCUMENTS_UNLOCK = `${DOCUMENT}/unlock\\?force=:force` as const;
export const DOCUMENTS_SUBMIT = `${DOCUMENT}/update` as const;
export const DOCUMENTS_SAVE = `${DOCUMENT}/save` as const;
export const DOCUMENTS_REJECT = `${DOCUMENT}/reject` as const;
export const DOCUMENTS_ARCHIVE = `${DOCUMENT}/archive` as const;
export const DOCUMENTS_LISTS =
  `${DOCUMENTS}\\?start=:start&size=:size&order=:order` as const;
export const DOCUMENTS_STAGES_COUNT = `${DOCUMENTS}/stages/count` as const;
export const DOCUMENTS_NEXT = `${DOCUMENTS}/next` as const;
export function makeDocumentStagesCountList({
  documentTypes,
  filters,
}: {
  documentTypes?: string[];
  filters?: string | null;
}) {
  const page: MakePage = {
    list: DOCUMENTS_STAGES_COUNT,
    params: {},
  };

  if (documentTypes?.length) {
    const { list, params } = generateParam(
      documentTypes,
      'document_type_filter',
      'docType'
    );

    page.list = `${page.list}\\?${list}`;
    page.params = {
      ...page.params,
      ...params,
    };
  }

  if (filters) {
    const list = `filters=:filters`;
    // if not documentTypes, then we need to add a question mark instead of ampersand
    const operator = documentTypes?.length ? '&' : '?';
    page.list = `${page.list}\\${operator}${list}`;
    page.params = {
      ...page.params,
      filters,
    };
  }

  return page;
}

export const DOCUMENTS_TYPES_COUNT = `${DOCUMENTS}/types/count` as const;
export const DOCUMENTS_TYPES_COUNT_LIST =
  `${DOCUMENTS_TYPES_COUNT}\\?stage_filter=:stage` as const;
export const DOCUMENTS_TYPES = `${DOCUMENTS}/types` as const;
export const DOCUMENTS_VALIDATION = `${DOCUMENT}/validation` as const;

export function makeDocumentsList({
  documentTypeFilter,
  filters,
  order,
  searchField,
  searchValue,
  stage,
}: {
  documentTypeFilter?: string[];
  filters?: string | null;
  searchField?: string | null;
  searchValue?: string | null;
  order?: string;
  stage?: string | null;
}) {
  /**
   * TODO: quite likely I might need to make this function generic
   * maybe accept a param name alongside an array of strings
   */
  const page: MakePage = {
    list: DOCUMENTS_LISTS,
    params: { order },
  };

  if (stage) {
    page.list = `${page.list}&stage_filter=:stage`;
    page.params = {
      ...page.params,
      stage,
    };
  }

  if (searchField) {
    page.list = `${page.list}&search_field=:search_field`;
    page.params = {
      ...page.params,
      search_field: searchField,
    };
  }

  if (searchValue) {
    page.list = `${page.list}&search_value=:search_value`;
    page.params = {
      ...page.params,
      search_value: searchValue,
    };
  }

  if (documentTypeFilter?.length) {
    const { list, params } = generateParam(
      documentTypeFilter,
      'document_type_filter',
      'docType'
    );

    page.list = `${page.list}&${list}`;
    page.params = {
      ...page.params,
      ...params,
    };
  }

  if (filters) {
    page.list = `${page.list}&filters=:filters`;
    page.params = {
      ...page.params,
      filters,
    };
  }

  return page;
}

/**
 * Metrics API Endpoints
 */
export const METRICS = 'metrics' as const;
const METRICS_LIST =
  `${METRICS}\\?start_time=:startTime&end_time=:endTime` as const;
export function makeMetricsList(documentType?: string) {
  let list = METRICS_LIST;

  if (documentType) {
    list += `&document_type=:${DOCUMENT_TYPE}`;
  }

  return list;
}

/**
 * Users API Endpoints
 */
export const CURRENT_USER = 'currentUser' as const;

// this is the signed-in user, not to be confused with USERS
export const USER = 'user' as const;
export const USERS = 'users';
export const USERS_LIST = `${USERS}\\?start=:start&size=:size` as const;

/**
 * Feature Flags API Endpoints
 */

export const FEATURE_FLAGS = 'feature-flags' as const;
export const FEATURE_FLAG = `${FEATURE_FLAGS}/:name`;
export const FEATURE_FLAGS_CATEGORIES = `${FEATURE_FLAGS}/categories` as const;
export const FEATURE_FLAGS_CATEGORY = `${FEATURE_FLAGS}/:category` as const;
export const FEATURE_FLAGS_CATEGORY_READ =
  `${FEATURE_FLAGS}/read/:category` as const;
export const FEATURE_FLAGS_CATEGORY_ITEM =
  `${FEATURE_FLAGS_CATEGORY}/:feature_flag` as const;

/**
 * Filters API Endpoints
 */
const FILTERS = 'filters' as const;
export const FILTERS_CLAIMS = `${FILTERS}/claims` as const;
export const FILTERS_CLAIMS_STAGE =
  `${FILTERS_CLAIMS}\\?stage_filter=:stage` as const;
export const FILTERS_DOCUMENTS = `${FILTERS}/documents` as const;
export const FILTERS_DOCUMENTS_STAGE =
  `${FILTERS_DOCUMENTS}\\?stage_filter=:stage` as const;

/**
 * Config API Endpoints
 */
export const CONFIG = 'config\\?name=:name' as const;
export const CONFIG_REASONS = 'config/reasons\\?language=:language' as const;

/**
 * Widgets API Endpoints
 */
export const WIDGETS_CLAIM_SUMMARY = 'widgets/claim_summary' as const;

/**
 * AppConfig API Endpoints
 */
export const APP_CONFIGS = 'appconfig/config' as const;
export const APP_CONFIG_NAME = `${APP_CONFIGS}/:config_name` as const;
export const APP_CONFIG_VERSIONS = `${APP_CONFIG_NAME}/versions` as const;
export const APP_CONFIG_DEPLOY =
  `${APP_CONFIG_NAME}/deploy/:version\\?strategy_name=:strategyName` as const;
export const APP_CONFIG_DEPLOYMENTS = `${APP_CONFIG_NAME}/deployments` as const;
export const APP_YAMLCONFIG = `appconfig/yamlconfig` as const;
export const APP_YAMLCONFIG_NAME = `${APP_YAMLCONFIG}/:config_name` as const;
