import type { BoxProps } from '@mui/material/Box';
import type { Theme } from '@mui/material/styles';
import type { ColorSchema } from 'theme/palette';
import type { Namespace } from 'i18next';
import { alpha, styled } from '@mui/material/styles';
import { useTranslationRoot } from 'components/with-translation';

export type LabelColor =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'info'
  | 'success'
  | 'warning'
  | 'error';

type LabelVariant = 'filled' | 'outlined' | 'ghost';

const RootStyle = styled('span')(({
  theme,
  ownerState,
}: {
  theme: Theme;
  ownerState: {
    color: LabelColor;
    variant: LabelVariant;
  };
}) => {
  const { color, variant } = ownerState;

  const styleFilled = (color: ColorSchema) => ({
    color: theme.palette[color].contrastText,
    backgroundColor: theme.palette[color].main,
  });

  const styleOutlined = (color: ColorSchema) => ({
    color: theme.palette[color].main,
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette[color].main}`,
  });

  const styleGhost = (color: ColorSchema) => ({
    color: theme.palette[color]['dark'],
    backgroundColor: alpha(theme.palette[color].main, 0.16),
    ...theme.applyStyles('dark', {
      color: theme.palette[color]['light'],
    }),
  });

  return {
    alignItems: 'center',
    backgroundColor: theme.palette.grey[300],
    borderRadius: 6,
    color: theme.palette.grey[800],
    cursor: 'default',
    display: 'inline-flex',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightBold,
    height: 22,
    justifyContent: 'center',
    lineHeight: 0,
    minWidth: 22,
    padding: theme.spacing(0, 1),
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',

    ...(color !== 'default'
      ? {
          ...(variant === 'filled' && { ...styleFilled(color) }),
          ...(variant === 'outlined' && { ...styleOutlined(color) }),
          ...(variant === 'ghost' && { ...styleGhost(color) }),
        }
      : {
          ...(variant === 'outlined' && {
            backgroundColor: 'transparent',
            border: `1px solid ${theme.palette.grey[500_32]}`,
            color: theme.palette.text.primary,
          }),
          ...(variant === 'ghost' && {
            backgroundColor: theme.palette.grey[500_16],
            color: theme.palette.text.secondary,
            ...theme.applyStyles('dark', {
              color: theme.palette.common.white,
            }),
          }),
        }),
  };
});

interface Props extends BoxProps {
  color?: LabelColor;
  i18nKey?: string;
  namespace?: Namespace;
  variant?: LabelVariant;
}

function Label({
  children,
  color = 'default',
  i18nKey,
  namespace,
  sx,
  variant = 'ghost',
}: Props) {
  const { t } = useTranslationRoot(namespace);

  return (
    <RootStyle ownerState={{ color, variant }} sx={sx}>
      {i18nKey ? t(i18nKey) : children}
    </RootStyle>
  );
}

export { Label };
