import type { ReactNode, RefObject } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { Wrapper } from './Wrapper.tsx';
import { CANVAS_CONTAINER_ID, HEADER_ID } from './utils.ts';

interface ViewProps {
  children: ReactNode;
  containerRef: RefObject<HTMLDivElement>;
  header: ReactNode;
  thumbnails: ReactNode;
}

function View({ children, containerRef, header, thumbnails }: ViewProps) {
  return (
    <Wrapper>
      <div id={HEADER_ID} style={{ height: '63px' }}>
        {header}
      </div>

      <Stack direction="row" sx={{ height: 'calc(100% - 63px)', width: 1 }}>
        <Box
          sx={{
            backgroundColor: 'grey.50016',
            borderRight: '1px solid',
            borderRightColor: 'background.neutral',
            flex: '0 0 150px',
            overflowY: 'auto',
          }}
        >
          {thumbnails}
        </Box>
        <Box
          id={CANVAS_CONTAINER_ID}
          ref={containerRef}
          sx={{
            backgroundColor: 'grey.200',
            flex: '1 1 calc(100% - 150px)',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          {children}
        </Box>
      </Stack>
    </Wrapper>
  );
}

export { View };
