import { Suspense } from 'react';
import { RollbarContext } from '@rollbar/react';
import { matchRoutes, useLocation, useRoutes } from 'react-router-dom';
import type { RouteMatch, RouteObject } from 'react-router-dom';
import { TypographyWithTranslation } from 'components/with-translation';
import i18n from 'locales/i18n';
import Splash from 'pages/Site/Splash';

import { routes } from './routes';

interface CustomRouteObject extends RouteObject {
  i18nNamespace: string;
}

function loadTranslations(routes: RouteMatch[] | null) {
  if (routes?.length) {
    routes.forEach(({ route }) => {
      const namespace = (route as CustomRouteObject)?.i18nNamespace;

      if (namespace) {
        i18n.loadNamespaces(namespace);
      }
    });
  }
}

const routesWithRollbarContext = withRollbarContext(
  routes as CustomRouteObject[]
);

function Router() {
  const location = useLocation();
  const matches = matchRoutes(routes, location.pathname);

  loadTranslations(matches);

  return (
    <Suspense
      fallback={
        <Splash>
          <TypographyWithTranslation i18nKey="loading" />
        </Splash>
      }
    >
      {useRoutes(routesWithRollbarContext)}
    </Suspense>
  );
}

function withRollbarContext(routes: CustomRouteObject[]): CustomRouteObject[] {
  return routes.map((route) => {
    if (route.element && route.path && route.path !== '*') {
      route.element = (
        <RollbarContext context={route.path}>{route.element}</RollbarContext>
      );
    }

    if (route.children) {
      route.children = withRollbarContext(
        route.children as CustomRouteObject[]
      );
    }

    return route;
  });
}

export { Router };
