import { memo } from 'react';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { CLOSE_ICON } from 'constants/public-icons';

import type { Toast, ToastPosition } from './types';
import { ToastIcon } from './ToastIcon';
import { ActionType, dispatch } from './store';

const ToastRoot = styled(Stack)(({ theme }) => {
  return {
    ...theme.typography.body2,
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.customShadows.card,
    flexGrow: 1,
    flexWrap: 'wrap',
    padding: theme.spacing(1),
    pointerEvents: 'auto',
    willChange: 'transform',
    [theme.breakpoints.up('sm')]: {
      flexGrow: 'initial',
      minWidth: 288,
    },
  };
});

interface ToastProps {
  position: ToastPosition;
  toast: Toast;
}

export const ToastContent = memo(function ToastContent({
  position,
  toast,
}: ToastProps) {
  const top = position.includes('top');
  const icon = <ToastIcon toast={toast} />;
  const message = (
    <Typography
      {...toast.ariaProps}
      variant="body2"
      sx={{
        color: 'text.primary',
        fontWeight: 'bold',
        padding: '8px 0',
        flex: 1,
      }}
    >
      {toast.message}
    </Typography>
  );

  return (
    <Slide
      direction={top ? 'down' : 'up'}
      in={toast.visible}
      mountOnEnter
      unmountOnExit
    >
      <ToastRoot direction="row" spacing={2} sx={{ ...toast.style }}>
        {icon}
        {message}
        {toast.dismissBtn && (
          <IconButton
            onClick={() =>
              dispatch({
                type: ActionType.DISMISS_TOAST,
                id: toast.id,
              })
            }
            size="small"
          >
            <SvgIconStyle src={CLOSE_ICON} />
          </IconButton>
        )}
      </ToastRoot>
    </Slide>
  );
});
