import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import Typography from '@mui/material/Typography';
import { postFilesUpload, postFilesReupload } from 'api/files';
import { Page } from 'components/Page';
import { toast } from 'components/toast';
import { COPY_ICON } from 'constants/public-icons';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { WithTranslationRoot } from 'components/with-translation';
import { UPLOAD_DOCUMENT_PAGE } from 'constants/translation-keys';
import { usePages } from 'components/customHooks/usePages';

import type { FormValues } from './UploadDocumentForm';
import type { ReuploadFormValues } from './ReuploadDocumentForm';
import { UploadDocumentForm } from './UploadDocumentForm';
import { ReuploadDocumentForm } from './ReuploadDocumentForm';

function transformMetadata(metadata: Record<string, string>[]) {
  return metadata.reduce(
    (acc, m) => ({
      ...acc,
      [m.key]: m.value,
    }),
    {}
  );
}

function UploadDocument() {
  const [claimId, setClaimId] = useState('');
  const { handleChangePage, page } = usePages();

  const handleSubmission = (
    submitFn: (payload: any) => Promise<any>,
    payload: any,
    claim_id: string | undefined
  ) => {
    toast.promise(submitFn(payload), {
      loading: 'Submitting form',
      success: () => {
        setClaimId(claim_id || '');
        return 'Submitted form successfully';
      },
      error: 'Failed to submit',
    });
  };

  const handleFileSubmit = ({
    document,
    claim_id,
    client_claim_id,
    client_document_type,
    metadata,
  }: FormValues) => {
    const documentItem = Array.from(document)[0];

    const transformedMetadata = transformMetadata(metadata || []);

    const payload = {
      document: documentItem,
      upload_data: {
        claim_id: claim_id || '',
        client_claim_id,
        client_document_type,
        metadata: JSON.stringify(transformedMetadata),
      },
    };

    handleSubmission(postFilesUpload, payload, claim_id);
  };

  const handleReuploadSubmit = ({
    file_id,
    env,
    claim_id,
    client_claim_id,
    client_document_type,
    metadata,
  }: ReuploadFormValues) => {
    const transformedMetadata = transformMetadata(metadata || []);

    const payload = {
      file_id,
      env,
      upload_data: {
        claim_id: claim_id || '',
        client_claim_id,
        client_document_type,
        metadata: JSON.stringify(transformedMetadata),
      },
    };

    handleSubmission(postFilesReupload, payload, claim_id);
  };

  const handleCopyClaimId = () => {
    const copyToClipboard = navigator.clipboard.writeText(claimId);
    toast.promise(copyToClipboard, {
      loading: 'Copying to clipboard...',
      success: 'Copied to clipboard',
      error: 'Error while copying to clipboard',
    });
  };

  return (
    <WithTranslationRoot namespace={UPLOAD_DOCUMENT_PAGE}>
      <Page i18nKeyTitle="meta.title">
        <Container maxWidth="md">
          <TabContext value={page}>
            <TabList
              onChange={handleChangePage}
              aria-label="Document Upload Tabs"
            >
              <Tab key="UploadTab" value={String(0)} label="Upload Document" />
              <Tab
                key="ReuploadDocumentTab"
                value={String(1)}
                label="Reupload Document"
              />
            </TabList>
            <Card
              sx={{
                p: 4,
                mx: 'auto',
                mt: 4,
              }}
            >
              <TabPanel key="upload" value={String(0)}>
                <UploadDocumentForm onSubmit={handleFileSubmit} />
              </TabPanel>
              <TabPanel key="reupload" value={String(1)}>
                <ReuploadDocumentForm onSubmit={handleReuploadSubmit} />
              </TabPanel>
              {claimId ? (
                <Typography>
                  Previous Claim ID: <span>{claimId}</span>
                  <IconButton onClick={handleCopyClaimId}>
                    <SvgIconStyle src={COPY_ICON} />
                  </IconButton>
                </Typography>
              ) : null}
            </Card>
          </TabContext>
        </Container>
      </Page>
    </WithTranslationRoot>
  );
}

export default UploadDocument;
