import Grid from '@mui/material/Grid2';

import type { LayoutProps } from './types';

function ToolLayout({ leftColumn, rightColumn }: LayoutProps) {
  return (
    <Grid
      container
      sx={{
        position: 'relative',
      }}
    >
      <Grid
        sx={{
          borderRight: ({ palette }) => `1px solid ${palette.divider}`,
        }}
        size={{
          xs: 12,
          md: 7,
        }}
      >
        {leftColumn}
      </Grid>
      <Grid
        sx={{ height: 1, position: 'relative' }}
        size={{
          xs: 12,
          md: 5,
        }}
      >
        {rightColumn}
      </Grid>
    </Grid>
  );
}

export { ToolLayout };
