const advcarept = {
  palette: {
    primary: {
      light: '#1ff4ff',
      main: '#00b1d1',
      dark: '#00c2cc',
    },
    secondary: {
      light: '#ff8591',
      main: '#ff4c5d',
      dark: '#e02d5e',
    },
    chart: {
      primary: ['#0093ab', '#00c2cc', '#00b1d1', '#1ff4ff'],
      secondary: ['#b3005f', '#e02d5e', '#ff4c5d', '#ff8591'],
      blue: ['#a3dae1', '#b2e0e6', '#bce4e9', '#d1ecf0'],
      yellow: ['#fdd835', '#ffeb3b', '#fceb55', '#fef075'],
      green: ['#00c64a', '#00ab2d', '#008000', '#005000'],
      red: ['#c0802e', '#c0632e', '#c0442e', '#a9412f'],
    },
  },
};

export default advcarept;
