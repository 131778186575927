import {
  keepPreviousData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import type { Meta } from 'query-client.ts';
import type { UsersList, UsersPage } from 'types/Users.ts';
import type { UserApiResponse } from 'api/transformers/types/users.ts';
import type { PaginationApiResponse } from 'api/transformers/types/pagination.ts';
import api, { makeApiLink } from 'api/api';
import { transformUserResponse } from 'api/transformers/users';
import { USERS, USERS_LIST } from 'constants/api-endpoints';
import { generalConfig } from 'config';
import { updateObjectInArray } from 'utils/array';
import { useGet, useOptimisticMutation } from 'utils/react-query';
import {
  selectUsersData,
  selectUsersDataLength,
  selectUsersTotal,
} from 'state/selectors/users.ts';

const getUsersOptions = (
  start = generalConfig.defaultPaginationStart,
  size = generalConfig.defaultPaginationSize
) => ({
  url: USERS_LIST,
  params: { start: start * size, size },
  prefix: USERS,
  placeholderData: keepPreviousData,
});

export function useGetUsersData(start?: number, size?: number) {
  return useGet<PaginationApiResponse<UserApiResponse>, UsersList>({
    ...getUsersOptions(start, size),
    select: selectUsersData,
  });
}

export function useGetUsersDataLength(start?: number, size?: number) {
  return useGet<PaginationApiResponse<UserApiResponse>, number>({
    ...getUsersOptions(start, size),
    select: selectUsersDataLength,
  });
}

export function useGetUsersTotal(start?: number, size?: number) {
  return useGet<PaginationApiResponse<UserApiResponse>, number>({
    ...getUsersOptions(start, size),
    select: selectUsersTotal,
  });
}

async function updateUserRole({
  email,
  role,
}: {
  email: string;
  role: string;
}) {
  const response = await api.put(
    makeApiLink(`users/${btoa(email)}/role/${role}`)
  );

  return transformUserResponse(response);
}

export function useCreateUserRole({ meta }: { meta: Meta }) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateUserRole,
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: [USERS] });
    },
    meta: meta as Record<string, Meta>,
  });
}

export function useUpdateUserRole({
  meta,
  start,
  size,
}: {
  meta: Meta;
  start: number;
  size: number;
}) {
  return useOptimisticMutation({
    fn: updateUserRole,
    url: USERS_LIST,
    params: { start: start * size, size },
    updater: (old, newData) => {
      if ((old as UsersPage)?.data?.length) {
        const data = updateObjectInArray(
          (old as UsersPage).data,
          (item) => item.email === newData.email,
          { ...newData, role: newData.role }
        );

        return {
          ...(old as UsersPage),
          data,
        };
      }
    },
    prefix: USERS,
    meta,
  });
}
