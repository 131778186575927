import type { SelectChangeEvent } from '@mui/material/Select';
import { useMemo } from 'react';
import { ControllerWithDropDown } from 'components/form/ControllerWithDropDown';
import { useTranslationRoot } from 'components/with-translation';
import { useGetDocumentTypes } from 'state/queries/documents';
import { uniqueBy } from 'utils/array';

interface DocumentTypeSelectorProps {
  handleChange: (event: SelectChangeEvent<HTMLInputElement>) => void;
  size?: 'small' | undefined;
}

const DEFAULT_ITEM = {
  label: 'common.allDocumentTypes',
  value: 'all',
};

function DocumentTypeSelector({
  handleChange,
  size,
}: DocumentTypeSelectorProps) {
  const { t } = useTranslationRoot();
  const { data, isSuccess } = useGetDocumentTypes();

  const menuItems = useMemo(() => {
    if (isSuccess) {
      const { documentTypes } = data;
      return uniqueBy(({ value }: any) => value)([
        DEFAULT_ITEM,
        ...documentTypes.map(({ documentGroup }) => ({
          label: documentGroup,
          value: documentGroup,
        })),
      ]);
    }

    return [DEFAULT_ITEM];
  }, [isSuccess, data]);

  return (
    <ControllerWithDropDown
      htmlFor="document-type-filter"
      label={t('common.filterDropdownLabel')}
      menuItems={menuItems}
      name="documentType"
      size={size}
      labelSize={size}
      onChange={handleChange}
    />
  );
}

export { DocumentTypeSelector, DEFAULT_ITEM };
