import { createSelector } from 'reselect';
import {
  transformAppConfigConfigResponse,
  transformAppConfigConfigVersionsResponse,
  transformAppConfigDeploymentsResponse,
} from 'api/transformers/appconfig.ts';

import { selectState } from './utils.ts';

export const selectAppConfigConfig = createSelector(
  selectState,
  transformAppConfigConfigResponse
);

export const selectAppConfigConfigVersions = createSelector(
  selectState,
  transformAppConfigConfigVersionsResponse
);

export const selectAppConfigDeployments = createSelector(
  selectState,
  transformAppConfigDeploymentsResponse
);
