import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import type { Column, Row } from './types.ts';
import { baseTableStyles } from './constants.ts';
import { FieldsTableRow } from './FieldsTableRow.tsx';

interface FieldsTableProps {
  columns: Column[];
  rows: Row[];
}

function FieldsTable({ columns, rows }: FieldsTableProps) {
  return (
    <TableContainer>
      <Table size="small" sx={{ position: 'relative', tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            {columns.map(({ headerName, startAdornment, width }, index) => (
              <TableCell
                key={index}
                sx={{
                  ...baseTableStyles,
                  backgroundColor: 'transparent',
                  color: 'text.primary',
                  fontWeight: 'bold',
                  width: width ?? 'auto',
                  '&:first-of-type': {
                    boxShadow: 'inherit',
                    pl: 1,
                    borderRadius: 0,
                  },
                  '&:last-of-type': {
                    boxShadow: 'inherit',
                    pr: 1,
                    borderRadius: 0,
                  },
                }}
              >
                <Stack
                  direction="row"
                  sx={{
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  {startAdornment}
                  {headerName}
                </Stack>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <FieldsTableRow key={row.id} columns={columns} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export { FieldsTable };
