import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { MenuPopover } from 'components/MenuPopover';
import { useTooltip } from 'components/customHooks/useTooltip';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { ControllerWithMultiCheckbox } from 'components/form/ControllerWithMultiCheckbox';
import { FILTER_LIST_ICON } from 'constants/public-icons';
import {
  ButtonWithTranslation,
  TypographyWithTranslation,
} from 'components/with-translation';
import { alpha } from '@mui/material/styles';

interface SortTableProps {
  buttonTextKey: string;
  onSelect: (values: string[]) => void;
  options?: { label: string; value: any; endAdornment?: ReactNode }[];
  filters: string[];
  popoverTitleKey: string;
}

function FilterTableDropDown({
  buttonTextKey,
  onSelect,
  options,
  filters,
  popoverTitleKey,
}: SortTableProps) {
  const { element, handleClose, handleOpen, isOpen } = useTooltip();
  const methods = useForm({ defaultValues: { filters } });

  function handleChange({ filters }: { filters: string[] }) {
    onSelect(filters);
  }

  useEffect(() => {
    methods.setValue('filters', filters);
  }, [methods, filters]);

  return (
    <>
      <ButtonWithTranslation
        data-testid="open-filters-button"
        color={filters.length ? 'primary' : 'inherit'}
        variant="outlined"
        i18nKey={buttonTextKey}
        onClick={handleOpen}
        endIcon={<SvgIconStyle src={FILTER_LIST_ICON} />}
        size="small"
        sx={{
          backgroundColor: ({ palette }) =>
            filters.length
              ? alpha(palette.primary.main, palette.action.selectedOpacity)
              : 'inherit',
          textWrap: 'nowrap',
        }}
      />
      <MenuPopover
        data-testid="filters-popover"
        anchorEl={element}
        open={isOpen}
        onClose={handleClose}
        arrow="top-left"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        sx={{
          mt: 1,
        }}
      >
        <Box sx={{ px: 2, py: 1, maxHeight: '80vh', overflowY: 'scroll' }}>
          <Stack
            sx={{
              gap: 2,
            }}
          >
            <TypographyWithTranslation
              variant="subtitle2"
              fontWeight="bold"
              sx={{ textTransform: 'capitalize' }}
              i18nKey={popoverTitleKey}
            />
            <FormProvider {...methods}>
              <form onChange={methods.handleSubmit(handleChange)}>
                <ControllerWithMultiCheckbox name="filters" options={options} />
              </form>
            </FormProvider>
          </Stack>
        </Box>
      </MenuPopover>
    </>
  );
}

export { FilterTableDropDown };
