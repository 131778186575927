import type { SxProps, Theme } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';

import { TimelineIcon } from './TimelineIcon';

interface TimelineWidgetProps {
  sx?: SxProps<Theme>;
  value: string;
}

function TimelineWidget({ sx, value }: TimelineWidgetProps) {
  let timelineContent = [];

  try {
    if (value.length) {
      timelineContent = JSON.parse(value);
    } else {
      throw new Error('No value');
    }
  } catch (e) {
    timelineContent = [];
    console.warn('Error parsing timeline content', e as Error);
  }

  return (
    <Stepper
      alternativeLabel
      activeStep={value.length - 1}
      sx={{
        ...sx,
        overflowX: 'auto',
        gap: 2,
        '& .MuiStepConnector-root': {
          top: '24px',
          left: 'calc(-50% + 8px)',
          right: 'calc(50% + 24px)',
          transform: 'translateY(-50%)',
        },
      }}
    >
      {timelineContent.map((item, index) => {
        const { start_date, end_date, event_type, description } = item;

        return (
          <Step
            key={index}
            expanded={true}
            sx={{
              minWidth: '180px',
            }}
          >
            <StepLabel
              StepIconComponent={TimelineIcon}
              StepIconProps={{
                icon: event_type,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 'bold',
                  color: 'text.secondary',
                  whiteSpace: 'nowrap',
                }}
              >
                {start_date}
                {end_date && ` – ${end_date}`}
              </Typography>

              <Typography variant="body2">{description}</Typography>
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
}

export { TimelineWidget };
