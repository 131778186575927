import { Controller, useFormContext } from 'react-hook-form';
import FormGroup from '@mui/material/FormGroup';
import type { FilterTypes } from 'types/Filters';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';

interface FilterOptionsProps {
  filtersKey: string;
  options: FilterTypes[];
}

function FilterOptions({ filtersKey, options }: FilterOptionsProps) {
  const { control } = useFormContext();

  return (
    <Controller
      render={({ field }) => (
        <FormGroup>
          {options.map(({ label, value, endAdornment }) => (
            <Stack
              key={value}
              direction="row"
              sx={{
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <FormControlLabel
                control={<Checkbox />}
                label={label}
                data-testid="filter-option-checkbox"
                checked={field.value?.includes(value)}
                onChange={() => {
                  const newValue = field.value?.includes(value)
                    ? field.value.filter((v) => v !== value)
                    : [...field.value, value];
                  field.onChange(newValue);
                }}
                value={value}
              />
              {endAdornment}
            </Stack>
          ))}
        </FormGroup>
      )}
      name={filtersKey}
      control={control}
    />
  );
}
export { FilterOptions };
