import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import type {
  AppConfigConfigVersions,
  AppConfigDeployment,
} from 'types/AppConfig.ts';
import { DialogModal } from 'components/dialog/Modal/DialogModal.tsx';
import { useModal } from 'components/customHooks/useModal.ts';
import { renderComponent } from 'components/dialog/generators.tsx';
import {
  useDeployAppConfig,
  useGetAppConfigDeployments,
  useGetConfigVersions,
} from 'state/queries/appconfig.ts';
import { LoadingIcon } from 'components/icons/LoadingIcon.tsx';
import { formatDocumentDate } from 'utils/date.ts';

import { VersionSelector } from './VersionSelector.tsx';
import { StrategySelector } from './StrategySelector.tsx';

interface DeployProps {
  name: string;
}

function Deploy(props: DeployProps) {
  const { handleClose, handleOpen, isOpen } = useModal();

  return (
    <>
      <Button
        variant="contained"
        color="info"
        size="small"
        onClick={handleOpen}
      >
        Deploy Config
      </Button>
      <DialogModal
        getDialog={{
          index() {
            return [
              renderComponent({
                Component: Modal,
                handleClose,
                ...props,
              }),
            ];
          },
        }}
        handleClose={handleClose}
        isOpen={isOpen}
      />
    </>
  );
}

interface ModalProps {
  handleClose: VoidFunction;
  name: string;
}

let didInit = false;

function Modal({ handleClose, name }: ModalProps) {
  const { data, isPending, isError, isSuccess } = useGetConfigVersions(name);
  const deploymentsQuery = useGetAppConfigDeployments(name);
  const deployAppConfig = useDeployAppConfig();

  const getLatestVersion = (versions: AppConfigConfigVersions) => {
    if (versions.length) {
      const config = versions.find((version) => version.isLatest);

      if (config) {
        return config.version;
      }
    }

    return '1';
  };

  const [currentVersion, setCurrentVersion] = useState('1');
  const [strategy, setStrategy] = useState('instant');

  if (isSuccess && !didInit) {
    setCurrentVersion(getLatestVersion(data));
    didInit = true;
  }

  useEffect(() => {
    return () => {
      didInit = false;
    };
  }, []);

  const latestDeployment =
    deploymentsQuery.isPending ||
    deploymentsQuery.isError ||
    !deploymentsQuery.data.length
      ? ({} as AppConfigDeployment)
      : deploymentsQuery.data[0];

  const renderDeployment = (children) =>
    deploymentsQuery.isPending ? (
      <Skeleton variant="text" sx={{ fontSize: 14 }} width={100} />
    ) : deploymentsQuery.isError ? (
      'N/A'
    ) : (
      children
    );

  const handleDeploy = () => {
    deployAppConfig.mutate(
      {
        name,
        version: currentVersion,
        strategyName: strategy,
      },
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  };

  return (
    <>
      <DialogTitle>Start deployment</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1">Currently Deployed Config</Typography>
        <Stack
          direction="row"
          spacing={0.5}
          sx={{
            my: 1,
          }}
        >
          <Typography variant="body2">Version:</Typography>
          <Typography variant="subtitle2">
            {renderDeployment(latestDeployment.configurationVersion)}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={0.5}
          sx={{
            my: 1,
          }}
        >
          <Typography variant="body2">State:</Typography>
          <Typography variant="subtitle2">
            {renderDeployment(latestDeployment.state)}
          </Typography>
        </Stack>
        {latestDeployment.completedAt !== null ? (
          <Stack
            direction="row"
            spacing={0.5}
            sx={{
              my: 1,
            }}
          >
            <Typography variant="body2">Completed at:</Typography>
            <Typography variant="subtitle2">
              {renderDeployment(
                latestDeployment.completedAt &&
                  formatDocumentDate(latestDeployment.completedAt)
              )}
            </Typography>
          </Stack>
        ) : null}
        <Divider sx={{ my: 2 }} />
        {isPending ? (
          <LoadingIcon />
        ) : isError ? (
          'Failed to fetch versions'
        ) : !data.length ? (
          `No versions found for ${name}`
        ) : (
          <Stack spacing={2}>
            <Typography variant="subtitle1">
              Select new version to deploy
            </Typography>
            <Stack spacing={1.5}>
              <Typography variant="body2">Choose a version</Typography>
              <VersionSelector
                handleChange={(value) => {
                  setCurrentVersion(value);
                }}
                value={currentVersion}
                versions={data}
              />
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="body2">
                Choose a deployment strategy
              </Typography>
              <StrategySelector
                handleChange={(value) => {
                  setStrategy(value);
                }}
                value={strategy}
              />
            </Stack>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="error">
          Cancel
        </Button>
        <LoadingButton
          loading={deployAppConfig.isPending}
          variant="contained"
          onClick={handleDeploy}
          color="primary"
        >
          Deploy
        </LoadingButton>
      </DialogActions>
    </>
  );
}

export { Deploy };
