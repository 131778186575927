import Accordion, { accordionClasses } from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary, {
  accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { TypographyWithTranslation } from 'components/with-translation';
import { TRIANGLE_ICON } from 'constants/public-icons';

import type { AccordionContainerProps } from './types';

function AccordionContainer({
  children,
  childrenSpacing = 3,
  defaultExpanded = true,
  heading,
}: AccordionContainerProps) {
  const noItems = (
    <TypographyWithTranslation i18nKey="form.noItems" variant="body1" />
  );

  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      sx={{
        borderRadius: '0 !important',
        boxShadow: 'none !important',
        '&:before': {
          display: 'none',
        },
        [`&.${accordionClasses.expanded}`]: {
          marginTop: 0,
        },
      }}
    >
      <AccordionSummary
        expandIcon={<SvgIconStyle height={7} src={TRIANGLE_ICON} width={7} />}
        sx={{
          [`&.${accordionClasses.expanded}`]: {
            minHeight: '48px',
          },
          [`>.${accordionSummaryClasses.content}`]: {
            my: '12px !important',
          },
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: 12,
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}
        >
          {heading}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={childrenSpacing}>{children ? children : noItems}</Stack>
      </AccordionDetails>
    </Accordion>
  );
}

export { AccordionContainer };
