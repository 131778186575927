import { useLayoutEffect, useRef, useState } from 'react';
import AceEditor from 'react-ace';
import yaml from 'js-yaml';
import 'ace-builds/src-min-noconflict/mode-yaml.js';
import 'ace-builds/src-min-noconflict/theme-monokai.js';
import Box from '@mui/material/Box';

interface EditorProps {
  config: { [key: string]: string };
  readOnly: boolean;
}

const NAME = 'editor';

function Editor({ config, readOnly }: EditorProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(500);

  useLayoutEffect(() => {
    const ref = containerRef.current;

    if (ref) {
      const rect = ref.getBoundingClientRect();

      // set the height of the editor so that it takes up the full height minus
      // top and bottom padding
      setHeight(window.innerHeight - rect.top - 32);
    }
  }, []);

  return (
    <Box ref={containerRef}>
      <AceEditor
        name={NAME}
        mode="yaml"
        theme="monokai"
        readOnly={readOnly}
        value={yaml.dump(config)}
        width="100%"
        height={`${height}px`}
        editorProps={{ $blockScrolling: true }}
        highlightActiveLine={false}
        tabSize={2}
        setOptions={{
          useWorker: false,
        }}
      />
    </Box>
  );
}

export { Editor, NAME };
