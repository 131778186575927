import type { FileCanvas } from './types.ts';
import { customEvent } from './events.ts';

let isDragging = false;
let lastPosX = 0;
let lastPosY = 0;

// to allow dragging of canvas viewport with shift key
function mouseEvents(canvas: FileCanvas) {
  const mouseDown = (event) => {
    const evt = event.e;

    if (evt.shiftKey) {
      canvas.selection = false;

      // set image as active object
      const object = canvas.getObjects().find((obj) => obj.type === 'image');

      if (object) {
        canvas.setActiveObject(object);
      }

      isDragging = true;
      lastPosX = evt.clientX;
      lastPosY = evt.clientY;
    }
  };

  const mouseMove = (event) => {
    const evt = event.e;

    if (evt.shiftKey && isDragging) {
      const vpt = canvas.viewportTransform;
      const x = vpt[4] + evt.clientX - lastPosX;
      const y = vpt[5] + evt.clientY - lastPosY;
      vpt[4] = x;
      vpt[5] = y;

      canvas.renderAll();

      lastPosX = evt.clientX;
      lastPosY = evt.clientY;
    }
  };

  const mouseUp = (event) => {
    const evt = event.e;

    if (evt.shiftKey) {
      canvas.setViewportTransform(canvas.viewportTransform);
      canvas.selection = true;

      isDragging = false;
    }
  };

  canvas.on('mouse:down', mouseDown);
  canvas.on('mouse:move', mouseMove);
  canvas.on('mouse:up', mouseUp);

  canvas.on(customEvent.TOOL_UNMOUNT, () => {
    canvas.off('mouse:down', mouseDown);
    canvas.off('mouse:move', mouseMove);
    canvas.off('mouse:up', mouseUp);
  });
}

export { mouseEvents };
