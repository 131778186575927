import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { FilesCrop } from 'api/files.ts';
import type { Correction } from 'components/ImageEditingTool/state.ts';
import { getFilesPagesPresignedUploadUrl, postFilesCrop } from 'api/files.ts';
import { TypographyWithTranslation } from 'components/with-translation.tsx';
import splitsRoute from 'pages/Splits/splits.route.tsx';
import { ImageEditingTool } from 'components/ImageEditingTool';
import { Loading } from 'components/ImageEditingTool/Loading.tsx';
import { SkeletonWrapper } from 'components/ImageEditingTool/SkeletonWrapper.tsx';
import { toast } from 'components/toast';
import { useTranslationRoot } from 'components/with-translation.tsx';
import {
  lockFile,
  unlockFile,
  useFileLock,
  useGetFile,
  useGetFileUrls,
} from 'state/queries/files.ts';
import { useCurrentUserEmail } from 'state/queries/current-user.ts';

import { dataURItoBlob } from './utils.ts';
import { LockedModal } from './LockedModal.tsx';

interface LandingProps {
  id: string;
}

function Landing({ id }: LandingProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const init = useRef(false);
  const hasSubmitted = useRef(false);

  const navigate = useNavigate();
  const { t } = useTranslationRoot();
  const fileQuery = useGetFile(id);
  const fileUrlsQuery = useGetFileUrls(id);
  const fileLockQuery = useFileLock();
  const currentUserQuery = useCurrentUserEmail();

  // unlock file mechanism on unmount
  useEffect(() => {
    if (fileQuery.data && currentUserQuery.data) {
      const { lastLockedBy, locked } = fileQuery.data;
      const currentUserEmail = currentUserQuery.data;

      return () => {
        if (
          !hasSubmitted.current &&
          locked &&
          lastLockedBy === currentUserEmail
        ) {
          void unlockFile(id);
        }
      };
    }
  }, [currentUserQuery.data, fileQuery.data, id]);

  if (fileQuery.isError || fileUrlsQuery.isError) {
    return (
      <SkeletonWrapper>
        <TypographyWithTranslation i18nKey="common.failedToLoadFile" />
      </SkeletonWrapper>
    );
  }

  if (fileQuery.isPending || fileUrlsQuery.isPending) {
    return <Loading />;
  }

  if (!fileQuery.data || !fileUrlsQuery.data?.length) {
    return (
      <SkeletonWrapper>
        <TypographyWithTranslation i18nKey="common.noFilesToShow" />
      </SkeletonWrapper>
    );
  }

  if (!init.current && !fileQuery.data.locked) {
    fileLockQuery.mutate(id);
    init.current = true;
  }

  const preSubmit = async () => {
    try {
      await lockFile(id);
    } catch (error) {
      toast.error(
        t('editor.notLockedByCurrentUser', {
          user: fileQuery.data.lastLockedBy,
        })
      );
      throw new Error('not locked by current user');
    }
  };

  const handleSubmit = async (corrections: Map<number, Correction>) => {
    setIsSubmitting(true);

    try {
      await preSubmit();
    } catch (error) {
      setIsSubmitting(false);
      return;
    }

    const toastId = toast.loading(t('editor.submitting'));
    const presignedUrls: {
      pageId: string;
      dataUri: string;
    }[] = [];
    const fileUploads: { url: string; body: Blob }[] = [];
    const promises: { id: string; payload: { crops: FilesCrop[] } }[] = [];

    corrections.forEach(({ hasBeenCropped, crops }) => {
      if (hasBeenCropped) {
        crops?.forEach((crop) => {
          presignedUrls.push({
            pageId: crop.cropping!.metadata.new_page_id as string,
            dataUri: crop.snapshot,
          });
        });
      }
    });

    const obj = Object.fromEntries(corrections);
    const crops = Object.values(obj).reduce((acc, val) => {
      const allCroppings = val.crops!.map(({ cropping }) => ({
        bounding_box: {
          top_left: cropping!.bounding_box.top_left,
          top_right: cropping!.bounding_box.top_right,
          bottom_left: cropping!.bounding_box.bottom_left,
          bottom_right: cropping!.bounding_box.bottom_right,
        },
        metadata: cropping!.metadata,
      }));
      return [...acc, ...allCroppings];
    }, [] as FilesCrop[]);

    promises.push({ id, payload: { crops } });

    try {
      const resolvedPresignedUrls = await Promise.allSettled(
        presignedUrls.map((item) => getFilesPagesPresignedUploadUrl(item))
      );

      // @ts-ignore
      resolvedPresignedUrls.forEach(({ status, value }) => {
        if (status === 'fulfilled') {
          fileUploads.push({
            url: value.response.presignedUrl,
            body: dataURItoBlob(value.dataUri),
          });
        }
      });

      await Promise.allSettled(
        fileUploads.map(({ url, body }) => {
          return new Promise((resolve, reject) => {
            fetch(url, {
              method: 'PUT',
              body,
            })
              .then(resolve)
              .catch(reject);
          });
        })
      );
    } catch (error) {
      toast.dismiss(toastId);
      toast.error(t('editor.submitError'));
      setIsSubmitting(false);
    }

    toast.promise(Promise.all(promises.map(postFilesCrop)), {
      loading: t('editor.submitting'),
      success: () => {
        setIsSubmitting(false);
        toast.dismiss(toastId);
        hasSubmitted.current = true;
        navigate(splitsRoute.createPath());
        return t('editor.submitSuccess');
      },
      error: () => {
        setIsSubmitting(false);
        return t('editor.submitError');
      },
    });
  };

  const handleNoChanges = async () => {
    try {
      await preSubmit();
    } catch (error) {
      return;
    }

    toast.promise(postFilesCrop({ id, payload: { crops: [] } }), {
      loading: t('editor.submitting'),
      success: () => {
        hasSubmitted.current = true;
        navigate(splitsRoute.createPath());
        return t('editor.submitSuccess');
      },
      error: () => t('editor.submitError'),
    });
  };

  return (
    <>
      <LockedModal
        user={fileQuery.data.lastLockedBy}
        locked={fileQuery.data.locked}
        lockedByAnotherUser={
          fileQuery.data.lastLockedBy !== currentUserQuery.data
        }
      />
      <ImageEditingTool
        urls={fileUrlsQuery.data}
        onNoChanges={handleNoChanges}
        onSubmit={handleSubmit}
        mode="crop"
        isSubmitting={isSubmitting}
        disableSubmitButton={
          fileQuery.data.lastLockedBy !== currentUserQuery.data
        }
      />
    </>
  );
}

export { Landing };
