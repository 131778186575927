import { lazy } from 'react';
import { generatePath } from 'react-router-dom';
import { loadable } from 'components/loadable';
import { FEATURE_FLAGS_ICON } from 'constants/public-icons';
import ROUTE_PERMISSIONS from 'constants/route-permissions';
import { FEATURE_FLAGS_PAGE } from 'constants/translation-keys';

const path = '/feature-flags';
const FeatureFlagsPageV2 = loadable(lazy(() => import('./FeatureFlagsPageV2')));

const featureFlagsRoute = {
  path,
  createPath: () => generatePath(path),
  element: <FeatureFlagsPageV2 />,
  icon: FEATURE_FLAGS_ICON,
  permission: ROUTE_PERMISSIONS.featureFlags,
  i18nNamespace: FEATURE_FLAGS_PAGE,
};

export default featureFlagsRoute;
