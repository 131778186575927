import { useEffect } from 'react';
import { Rect } from 'fabric';
import { findImage } from 'components/CanvasTool/utils.ts';

import type { FileCanvas } from './types.ts';

interface SelectionToolProps {
  getCanvas: () => FileCanvas;
  isActive: boolean;
  onSelectionReady: (ready: boolean) => void;
}

function useSelectionTool({
  getCanvas,
  isActive,
  onSelectionReady,
}: SelectionToolProps) {
  useEffect(() => {
    const canvas = getCanvas();
    let selectionRef: Rect | null = null;
    let started = false;
    let x = 0;
    let y = 0;

    /* Mousedown */
    const mousedown = (event) => {
      // allow user to select existing rectangle
      if (event.target === selectionRef) {
        return false;
      }

      if (event.e.altKey) {
        return false;
      }

      canvas.set('selection', false);
      onSelectionReady(false);

      // ensure object does not preserve aspect ratio
      canvas.set('uniformScaling', true);

      started = true;
      x = event.absolutePointer.x;
      y = event.absolutePointer.y;

      const foundObject = canvas
        .getObjects()
        .find((object) => object === selectionRef);

      if (foundObject) {
        canvas.remove(foundObject);
      }

      const square = new Rect({
        width: 0,
        height: 0,
        left: x,
        top: y,
        borderColor: 'black',
        borderDashArray: [5, 5],
        fill: 'transparent',
        uniformScaling: false,
        cornerColor: 'blue',
        transparentCorners: false,
        cornerStrokeColor: 'white',
        cornerStyle: 'circle',
      });
      square.setControlsVisibility({
        mtr: false,
      });

      canvas.add(square);
      canvas.bringObjectToFront(square);
      square.setCoords();
      canvas.setActiveObject(square);
      selectionRef = square;
    };

    /* Mousemove */
    const mousemove = (event) => {
      if (event.e.altKey) {
        return false;
      }

      if (!started) {
        return false;
      }

      const mouseX = event.absolutePointer.x;
      const mouseY = event.absolutePointer.y;
      let width = mouseX - x;
      let height = mouseY - y;

      if (!width || !height) {
        return false;
      }

      // Determine the rectangle's new position and size
      let newLeft;
      let newTop;

      if (width < 0) {
        newLeft = mouseX;
        width = -width;
      } else {
        newLeft = x;
      }

      if (height < 0) {
        newTop = mouseY;
        height = -height;
      } else {
        newTop = y;
      }

      const square = canvas.getActiveObject();

      if (square) {
        square.set({
          width,
          height,
          left: newLeft,
          top: newTop,
        });

        canvas.renderAll();
        selectionRef = square as Rect;
      }
    };

    /* Mouseup */
    const mouseup = (event) => {
      if (event.e.altKey) {
        return false;
      }

      if (started) {
        started = false;
      }

      canvas.set('uniformScaling', false);

      const square = canvas.getActiveObject();

      if (square) {
        const height = square.get('height');
        const width = square.get('width');

        // if rectangle has no size then just remove it
        if (!height && !width) {
          onSelectionReady(false);
          canvas.remove(square);
        } else {
          // check if square exceeds image bounds
          const image = canvas.getObjects().find(findImage);

          if (image) {
            // const imageWidth = image.get('width');
            // const imageHeight = image.get('height');
            // const imageLeft = image.get('left');
            // const imageTop = image.get('top');
            //
            // const imageHeightOffset =
            //   (canvas.getHeight() - imageHeight * image.scaleY) / 2;
            //
            // const squareRight = square.left + square.width;
            // const squareBottom = square.top + square.height;
            // const imageRight = imageWidth * image.scaleX;
            // const imageBottom = imageHeight * image.scaleY + imageHeightOffset;
            //
            // if (square.left < 0) {
            //   square.set({
            //     left: 0,
            //     width: square.width - Math.abs(square.left),
            //   });
            // }
            // if (square.top < 0) {
            //   square.top =
            //     (canvas.getHeight() - imageHeight * image.scaleY) / 2;
            //   square.height = square.height - Math.abs(square.top);
            // }
            //
            // if (squareRight > imageRight) {
            //   square.width = square.width - (squareRight - imageRight);
            // }
            //
            // if (squareBottom > imageBottom) {
            //   square.height = square.height - (squareBottom - imageBottom);
            // }
            // if (square.left + square.width > imageWidth) {
            //   square.width = imageWidth - square.left;
            // }
            // if (square.top + square.height > imageHeight) {
            //   square.height = imageHeight - square.top;
            // }
            // if it does, adjust the square
            // if (square.left < imageLeft) {
            //   square.left = imageLeft;
            // }
            // if (square.top < imageTop) {
            //   square.top = imageTop;
            // }
            // if (square.left + square.width > imageLeft + imageWidth) {
            //   square.width = imageWidth - square.left + imageLeft;
            // }
            // if (square.top + square.height > imageTop + imageHeight) {
            //   square.height = imageHeight - square.top + imageTop;
            // }
          }

          canvas.remove(square);
          canvas.add(square);
          square.setControlsVisibility({
            mtr: true,
          });
          canvas.setActiveObject(square);
          onSelectionReady(true);
        }

        canvas.renderAll();
        selectionRef = square as Rect;
        canvas.set('selection', true);
      }
    };

    if (canvas) {
      if (isActive) {
        canvas.on('mouse:down', mousedown);
        canvas.on('mouse:move', mousemove);
        canvas.on('mouse:up', mouseup);
      } else {
        canvas.off('mouse:down', mousedown);
        canvas.off('mouse:move', mousemove);
        canvas.off('mouse:up', mouseup);

        if (selectionRef && (selectionRef as Rect)?.isType('rect')) {
          canvas.remove(selectionRef);
        }
      }

      return () => {
        canvas.off('mouse:down', mousedown);
        canvas.off('mouse:move', mousemove);
        canvas.off('mouse:up', mouseup);

        if (selectionRef && (selectionRef as Rect)?.isType('rect')) {
          canvas.remove(selectionRef);
        }
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);
}

export { useSelectionTool };
