export function updateObjectInArray<T>(
  array: T[],
  filterFn: (arg: T) => boolean,
  update: Partial<T>
): T[] {
  const updatedArray: T[] = [];

  for (let i = 0; i < array.length; i++) {
    const item = array[i];

    if (filterFn(item)) {
      updatedArray.push({ ...item, ...update });
    } else {
      updatedArray.push(item);
    }
  }

  return updatedArray;
}

export function deleteItemInArray<T>(
  array: T[],
  filterFn: (arg: T) => boolean
): T[] {
  const result: T[] = [];

  for (const item of array) {
    if (filterFn(item)) {
      result.push(item);
    }
  }

  return result;
}

export function convertArrayToObject<T>(array: T[], key: keyof T) {
  return array.reduce<{ [key: string]: T }>((obj, item) => {
    obj[String(item[key])] = item;
    return obj;
  }, {});
}

export function uniqueBy<T, U>(
  getCriterion: (item: T) => U
): (array: T[]) => T[] {
  return (array: T[]): T[] => {
    const uniqueArray: T[] = [];
    const criteria: U[] = [];

    const numItems = array.length;
    for (let i = 0; i < numItems; i++) {
      const arrayItem = array[i];
      const uniqueCriterion = getCriterion(arrayItem);

      if (criteria.indexOf(uniqueCriterion) === -1) {
        uniqueArray.push(arrayItem);
        criteria.push(uniqueCriterion);
      }
    }

    return uniqueArray;
  };
}

function returnItem<T>(item: T) {
  return item;
}

export function unique<T>(array: T[]) {
  return uniqueBy(returnItem)(array);
}

export function convertArrayToListAndDict<T>(
  array: T[],
  key: keyof T
): [T[keyof T][], { [key: string]: T }] {
  const list = unique(array.map((item) => item[key]));
  const dict = convertArrayToObject(array, key);

  return [list, dict];
}
