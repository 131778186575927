import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { SvgIconStyle } from 'components/SvgIconStyle';
import {
  CHECK_FILL_ICON,
  EXCLAMATION_ICON,
  WARNING_ICON,
} from 'constants/public-icons';

import type { Toast } from './types';

const iconType = {
  success: {
    boxColor: 'success.lighter',
    boxColorDark: 'success.darker',
    icon: CHECK_FILL_ICON,
    iconColor: 'success.main',
  },
  error: {
    boxColor: 'error.lighter',
    boxColorDark: 'error.darker',
    icon: EXCLAMATION_ICON,
    iconColor: 'error.main',
  },
  warning: {
    boxColor: 'warning.lighter',
    boxColorDark: 'warning.darker',
    icon: WARNING_ICON,
    iconColor: 'warning.main',
  },
  loading: {
    boxColor: 'info.lighter',
    boxColorDark: 'info.darker',
    icon: 'loading',
    iconColor: 'info.main',
  },
  blank: {
    boxColor: 'background.default',
    boxColorDark: 'background.default',
    icon: undefined,
    iconColor: undefined,
  },
  custom: {
    boxColor: undefined,
    boxColorDark: undefined,
    icon: undefined,
    iconColor: undefined,
  },
};

interface ToastIconProps {
  toast: Toast;
}

export function ToastIcon({ toast }: ToastIconProps) {
  const { boxColor, boxColorDark, icon, iconColor } = iconType[toast.type];

  if (icon) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: ({ palette }) =>
            palette.mode === 'dark' ? boxColorDark : boxColor,
          borderRadius: 1,
          display: 'flex',
          justifyContent: 'center',
          width: 40,
          height: 40,
        }}
      >
        {icon !== 'loading' ? (
          <SvgIconStyle src={icon} sx={{ backgroundColor: iconColor }} />
        ) : (
          <CircularProgress color="info" size={16} />
        )}
      </Box>
    );
  }

  return null;
}
