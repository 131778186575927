import type { CircularProgressProps } from '@mui/material/CircularProgress';
import type { Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { TypographyWithTranslation } from 'components/with-translation';

type Props = Omit<CircularProgressProps, 'color'>;
interface CircularProgressWithLabelProps extends Props {
  color: (theme: Theme) => string;
  isSuccess: boolean;
  label?: string;
}

function CircularProgressWithLabel({
  color,
  label,
  isSuccess,
  ...props
}: CircularProgressWithLabelProps) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        {...props}
        variant="determinate"
        value={100}
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        }}
      />
      <CircularProgress
        {...props}
        variant="determinate"
        sx={{
          color: (theme) => color(theme),
          position: 'absolute',
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {label ? (
          <Typography
            variant="body2"
            component="div"
            sx={{
              fontWeight: 600,
            }}
          >
            {label}
          </Typography>
        ) : isSuccess ? (
          <TypographyWithTranslation
            i18nKey="common.noData"
            variant="body2"
            fontSize={12}
            component="div"
          />
        ) : (
          <Skeleton variant="rectangular" width={40} height={22} />
        )}
      </Box>
    </Box>
  );
}

export { CircularProgressWithLabel };
