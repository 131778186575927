import { useEffect, useRef } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import visuallyHidden from '@mui/utils/visuallyHidden';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { SvgIconStyle } from 'components/SvgIconStyle';
import {
  ButtonWithTranslation,
  InputLabelWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';
import InputAdornment from '@mui/material/InputAdornment';
import { CLOSE_ICON, SEARCH_ICON } from 'constants/public-icons';
import { CLAIMS_PAGE, DASHBOARD_PAGE } from 'constants/translation-keys';
import { alpha } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { ControllerWithDropDown } from 'components/form/ControllerWithDropDown';

type SearchFormValues = {
  searchClaimId: string;
  searchField?: string;
};

const SEARCH_VALUE = 'searchClaimId';
const SEARCH_FIELD = 'searchField';

const CLIENT_CLAIM_ID = 'CLIENT_CLAIM_ID';
const CLAIM_ID = 'CLAIM_ID';

const schema = object({
  [SEARCH_VALUE]: string(),
  [SEARCH_FIELD]: string(),
});

interface SearchFormProps {
  defaultValue: SearchFormValues | null;
  onSubmit: (values: SearchFormValues) => void;
  hasSuperAdminPermission?: boolean;
}

// TODO: oh I see, probably wasn't a good idea to mix the 2 forms together...
function SearchForm({
  defaultValue,
  onSubmit,
  hasSuperAdminPermission,
}: SearchFormProps) {
  const isInitialised = useRef(false);
  const { t } = useTranslationRoot(DASHBOARD_PAGE);

  const menuItems = [
    {
      label: t('headerSearch.clientClaimId'),
      value: CLIENT_CLAIM_ID,
    },
    {
      label: t('headerSearch.claimId'),
      value: CLAIM_ID,
    },
  ];

  const searchField = defaultValue?.searchField || CLIENT_CLAIM_ID;
  const methods = useForm<SearchFormValues>({
    resolver: yupResolver(schema),
  });
  const { handleSubmit, setValue, watch } = methods;

  function handleFormSubmit(values: SearchFormValues) {
    onSubmit(values);
  }

  useEffect(() => {
    if (defaultValue) {
      const { searchClaimId, searchField } = defaultValue;
      setValue(SEARCH_VALUE, searchClaimId);
      if (searchField) {
        setValue(SEARCH_FIELD, searchField);
      } else {
        setValue(SEARCH_FIELD, CLIENT_CLAIM_ID);
      }
    }
  }, [defaultValue, setValue, hasSuperAdminPermission]);

  if (
    !isInitialised.current &&
    hasSuperAdminPermission &&
    !defaultValue?.searchField
  ) {
    isInitialised.current = true;
    setValue(SEARCH_FIELD, CLIENT_CLAIM_ID);
  }

  const startAdornment = hasSuperAdminPermission ? (
    <InputAdornment position="start">
      <ControllerWithDropDown
        htmlFor="Search field selector"
        menuItems={menuItems}
        name={SEARCH_FIELD}
        namespace={DASHBOARD_PAGE}
        value={searchField}
        label={t('headerSearch.searchFieldSwitch') as string}
        size="small"
        sx={{
          maxWidth: 160,
          minWidth: 160,

          '& .MuiInputBase-root': {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          },
          '&:hover .MuiOutlinedInput-notchedOutline, .Mui-focused .MuiOutlinedInput-notchedOutline':
            {
              borderColor: 'divider',
            },
        }}
      />
    </InputAdornment>
  ) : (
    <InputAdornment position="start" sx={{ ml: 1 }}>
      <SvgIconStyle src={SEARCH_ICON} />
    </InputAdornment>
  );

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        onSubmit={handleSubmit(handleFormSubmit)}
        sx={{ width: 1 }}
      >
        <FormControl variant="standard" sx={{ width: 1 }}>
          <InputLabelWithTranslation
            htmlFor={SEARCH_VALUE}
            sx={visuallyHidden}
            i18nKey="common.headerSearchLabel"
            namespace={CLAIMS_PAGE}
          />
          <Controller
            key={SEARCH_VALUE}
            name={SEARCH_VALUE}
            defaultValue={defaultValue?.searchClaimId || ''}
            control={methods.control}
            render={({ field }) => (
              <InputBase
                {...field}
                fullWidth
                id={SEARCH_VALUE}
                data-testid="searchInput"
                placeholder={
                  (hasSuperAdminPermission
                    ? t('headerSearch.labelSuperAdmin')
                    : t('headerSearch.label')) as string
                }
                startAdornment={startAdornment}
                endAdornment={
                  watch(SEARCH_VALUE) && (
                    <IconButton
                      onClick={() => {
                        setValue(SEARCH_VALUE, '');
                        setValue(SEARCH_FIELD, '');
                        handleSubmit(handleFormSubmit)();
                      }}
                    >
                      <SvgIconStyle src={CLOSE_ICON} />
                    </IconButton>
                  )
                }
                sx={{
                  backgroundColor: ({ palette }) =>
                    watch(SEARCH_VALUE)
                      ? alpha(
                          palette.primary.main,
                          palette.action.selectedOpacity
                        )
                      : 'transparent',
                  borderRadius: 1.5,
                  borderColor: 'divider',
                  '&> .MuiInputBase-input': hasSuperAdminPermission
                    ? {
                        height: 40,
                        border: hasSuperAdminPermission ? 1 : 0,
                        borderLeft: 0,
                        p: 0,
                        pl: 1,
                        boxSizing: 'border-box',
                        borderRadius: 1.5,
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderColor: 'divider',
                      }
                    : {},
                  '& .MuiInputAdornment-root': {
                    mr: hasSuperAdminPermission ? 0 : 1,
                  },
                }}
              />
            )}
          />
        </FormControl>
        <ButtonWithTranslation
          type="submit"
          sx={visuallyHidden}
          i18nKey="headerSearch.search"
          namespace={DASHBOARD_PAGE}
        />
      </Box>
    </FormProvider>
  );
}

export { SearchForm };
export type { SearchFormValues };
