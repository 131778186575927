import type { Canvas as CanvasType, FabricObject } from 'fabric';
import { Point } from 'fabric';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import { useTranslationRoot } from 'components/with-translation.tsx';
import {
  ARROW_AUTOFIT_WIDTH_ICON,
  MINUS_ICON,
  PLUS_ICON,
  REFRESH_ICON,
  ZOOM_RESET_ICON,
} from 'constants/public-icons.ts';
import { COMMON } from 'constants/translation-keys.ts';

import {
  isPortrait,
  SCALE_STEP,
  ZOOM_IN_THRESHOLD,
  ZOOM_OUT_THRESHOLD,
  zoomToCrop,
} from './utils.ts';

interface ControlsProps {
  canvas: CanvasType;
}

function Controls({ canvas }: ControlsProps) {
  const { t } = useTranslationRoot(COMMON);

  const getType = (obj: FabricObject) => obj.type.toLowerCase();

  const findImage = (obj: FabricObject) => getType(obj) === 'image';
  const findGroup = (obj: FabricObject) => getType(obj) === 'group';
  const findImageOrGroup = (obj: FabricObject) =>
    findImage(obj) || findGroup(obj);
  const findRect = (obj: FabricObject) => getType(obj) === 'rect';

  const getTargetObject = (objects: FabricObject[]) => {
    return objects.find(findImageOrGroup);
  };

  const resetZoomAndPan = () => {
    const targetObject = getTargetObject(canvas.getObjects());

    if (targetObject) {
      const angle = targetObject.get('angle');
      const canvasHeight = canvas.getHeight();
      const canvasWidth = canvas.getWidth();
      const objectHeight = targetObject.get('height');
      const objectWidth = targetObject.get('width');
      const scaleFactor =
        angle % 180 === 0
          ? isPortrait(targetObject)
            ? canvasHeight / objectHeight
            : canvasWidth / objectWidth
          : isPortrait(targetObject)
            ? canvasWidth / objectHeight
            : canvasHeight / objectWidth;
      targetObject.scale(scaleFactor);
      canvas.centerObject(targetObject);
      canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    }
  };

  const fitImage = () => {
    const targetObject = getTargetObject(canvas.getObjects());
    let scaleFactor = 1;

    if (targetObject) {
      const angle = targetObject.angle;
      // if 0, 180 or 360 degrees then fit to width
      const canvasWidth = canvas.getWidth();
      // const canvasHeight = canvas.getHeight();
      const objectWidth = targetObject.get('width');
      const objectHeight = targetObject.get('height');

      if (angle % 180 === 0) {
        scaleFactor = canvasWidth / objectWidth;
        targetObject.scale(scaleFactor);

        if (angle === 180 || angle === -180) {
          targetObject.set({
            left: canvasWidth,
            // top: canvasHeight,
          });
        } else {
          targetObject.set({
            left: 0,
            // top: 0,
          });
        }
      } else {
        scaleFactor = canvasWidth / objectHeight;
        targetObject.scale(scaleFactor);

        if (isPortrait(targetObject)) {
          if (angle === 90 || angle === -270) {
            targetObject.set({
              left: canvasWidth,
              // top: 0,
            });
          } else if (angle === 270 || angle === -90) {
            targetObject.set({
              left: 0,
              // top: objectWidth * scaleFactor,
            });
          }
        } else {
          if (angle === 90 || angle === -270) {
            targetObject.set({
              left: canvasWidth,
              // top: 0,
            });
          } else if (angle === 270 || angle === -90) {
            targetObject.set({
              left: 0,
              // top: canvasHeight,
            });
          }
        }
      }

      const crop = getCropRect();

      if (crop) {
        zoomToCrop(canvas, crop, 1);
        const vpt = canvas.viewportTransform;
        vpt[4] = 0;
      } else {
        canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
      }
      canvas.renderAll();
    }
  };

  const getCenterPoint = () => {
    return new Point({
      x: (canvas.getWidth() as number) / 2,
      y: (canvas.getHeight() as number) / 2,
    });
  };

  const zoomIn = () => {
    const zoom = canvas.getZoom();

    if (zoom < ZOOM_IN_THRESHOLD) {
      canvas.zoomToPoint(getCenterPoint(), zoom + SCALE_STEP);
    }
  };

  const zoomOut = () => {
    const zoom = canvas.getZoom();

    if (zoom > ZOOM_OUT_THRESHOLD) {
      canvas.zoomToPoint(getCenterPoint(), zoom - SCALE_STEP);
    }
  };

  const getCropRect = () => {
    const objects = canvas.getObjects();

    /*
      it's quite likely that the first object is a group if we are going to
      find a rect.
       */
    const group = objects.find(findGroup);

    if (group) {
      // @ts-ignore
      return group._objects.find(findRect);
    }
  };

  const rotateImage = (counterClockwise = false) => {
    const object = getTargetObject(canvas.getObjects());

    if (object) {
      const angle = object.angle % 360;
      const newAngle = angle + (counterClockwise ? -90 : 90);
      object.rotate(newAngle);
      canvas.renderAll();
    }

    const rect = getCropRect();

    if (rect) {
      zoomToCrop(canvas, rect);
    }
  };

  const rotateImageClockwise = () => {
    rotateImage();
  };

  const rotateImageCounterClockwise = () => {
    rotateImage(true);
  };

  return (
    <Stack direction="row">
      <Tooltip title={t('canvas.resetZoomAndPan')}>
        <IconButton onClick={resetZoomAndPan}>
          <SvgIconStyle src={ZOOM_RESET_ICON} />
        </IconButton>
      </Tooltip>

      <Tooltip title={t('canvas.autoFitWidth')}>
        <IconButton onClick={fitImage}>
          <SvgIconStyle src={ARROW_AUTOFIT_WIDTH_ICON} />
        </IconButton>
      </Tooltip>

      <Tooltip title={t('canvas.zoomIn')}>
        <IconButton onClick={zoomIn}>
          <SvgIconStyle src={PLUS_ICON} />
        </IconButton>
      </Tooltip>

      <Tooltip title={t('canvas.zoomOut')}>
        <IconButton onClick={zoomOut}>
          <SvgIconStyle src={MINUS_ICON} />
        </IconButton>
      </Tooltip>

      <Tooltip title={t('canvas.rotateLeft')}>
        <IconButton onClick={rotateImageCounterClockwise}>
          <SvgIconStyle src={REFRESH_ICON} sx={{ transform: 'scaleX(-1)' }} />
        </IconButton>
      </Tooltip>

      <Tooltip title={t('canvas.rotateRight')}>
        <IconButton onClick={rotateImageClockwise}>
          <SvgIconStyle src={REFRESH_ICON} />
        </IconButton>
      </Tooltip>
    </Stack>
  );
}

export { Controls };
