import { createSelector } from 'reselect';
import { transformWidgetsClaimSummaryApiResponse } from 'api/transformers/widgets.ts';

import { selectState } from './utils.ts';

export const selectWidgetsClaimSummaryResponse = createSelector(
  selectState,
  transformWidgetsClaimSummaryApiResponse
);

export const selectWidgetsClaimSummaryData = createSelector(
  selectWidgetsClaimSummaryResponse,
  (state) => state.filter(({ tab }) => tab === 'summary' || !tab)
);

export const selectWidgetsClaimPolicyData = createSelector(
  selectWidgetsClaimSummaryResponse,
  (state) => state.filter(({ tab }) => tab === 'policy')
);

export const selectWidgetsClaimRecommendationData = createSelector(
  selectWidgetsClaimSummaryResponse,
  (state) => state.filter(({ tab }) => tab === 'recommendations')
);
