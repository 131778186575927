import type { MouseEvent } from 'react';
import { useEffect, useState } from 'react';
import type { FilePage } from 'types/Files.ts';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import { TRASH_ICON } from 'constants/public-icons.ts';

import type { CropData } from './crop-tool.ts';
import { Thumbnail } from './Thumbnail.tsx';
import { THUMBNAIL_EVENT } from './utils.ts';

interface ThumbnailsProps {
  onThumbnailClick: (
    page: number,
    subPage?: number,
    thumbnailUri?: string
  ) => void;
  onDeleteThumbnail: (page: number, subPage: number) => void;
  page: number;
  urls: FilePage[];
}

interface State {
  [key: number]: string[];
}

const THUMBNAIL_HEIGHT = 117;

function Thumbnails({
  onThumbnailClick,
  onDeleteThumbnail,
  page,
  urls,
}: ThumbnailsProps) {
  const [childThumbnails, setChildThumbnails] = useState<State>({});
  const [subPage, setSubPage] = useState<number | null>(null);

  useEffect(() => {
    const listener = async (
      e: CustomEvent<{
        page: number;
        url: string;
        updatedCrop: CropData[];
      }>
    ) => {
      const { page, url } = e.detail;

      setChildThumbnails((prev) => {
        if (prev[page]) {
          const items = [...prev[page], url];

          return {
            ...prev,
            [e.detail.page]: items,
          };
        }

        return {
          ...prev,
          [e.detail.page]: [e.detail.url],
        };
      });

      // setSubPage(() => {
      //   if (updatedCrop.length) {
      //     return updatedCrop.length - 1;
      //   }
      //
      //   return 0;
      // });
    };

    document.addEventListener(
      THUMBNAIL_EVENT,
      listener as unknown as EventListener
    );

    return () => {
      document.removeEventListener(
        THUMBNAIL_EVENT,
        listener as unknown as EventListener
      );
    };
  }, []);

  const handleClick = (page: number, subPage?: number) => {
    if (subPage === undefined) {
      onThumbnailClick(page, subPage);
      setSubPage(null);
    }

    if (typeof subPage === 'number') {
      const thumbnailUri = childThumbnails[page][subPage];
      onThumbnailClick(page, subPage, thumbnailUri);
      setSubPage(subPage);
    }
  };

  const handleDelete =
    (page: number, subPage: number) => (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      onDeleteThumbnail(page, subPage);

      // delete from childThumbnails
      const items = childThumbnails[page].filter((_, i) => i !== subPage);
      setChildThumbnails((prev) => ({
        ...prev,
        [page]: items,
      }));
      setSubPage(null);
    };

  return (
    <Stack
      spacing={2}
      sx={{
        height: 1,
        width: 1,
        p: 2,
      }}
    >
      {urls.map((item, index) => (
        <Thumbnail
          key={index}
          collapseSize={THUMBNAIL_HEIGHT}
          page={index}
          selectorNode={
            <Box
              onClick={() => handleClick(index)}
              sx={{
                height: THUMBNAIL_HEIGHT,
                position: 'relative',
              }}
            >
              <Box
                component="img"
                src={item.pageUrl}
                alt=""
                loading="lazy"
                sx={{
                  backgroundColor: 'common.black',
                  cursor: 'pointer',
                  height: 1,
                  inset: 0,
                  objectFit: 'contain',
                  border:
                    index === page && subPage === null
                      ? '2px solid blue'
                      : 'none',
                  position: 'absolute',
                  width: 1,
                }}
              />
            </Box>
          }
          itemsCount={childThumbnails[index]?.length}
          itemsComponent={
            <Stack
              spacing={2}
              sx={{
                mt: 2,
                alignItems: 'flex-end',
              }}
            >
              {childThumbnails[index]?.map((url: string, i: number) => (
                <Box
                  key={i}
                  onClick={() => handleClick(index, i)}
                  sx={{
                    height: 95,
                    position: 'relative',
                    width: 95,
                  }}
                >
                  <Box
                    component="img"
                    src={url}
                    alt=""
                    loading="lazy"
                    sx={{
                      backgroundColor: 'common.black',
                      cursor: 'pointer',
                      height: 1,
                      inset: 0,
                      objectFit: 'contain',
                      border:
                        index === page && i === subPage
                          ? '2px solid blue'
                          : 'none',
                      position: 'absolute',
                      width: 1,
                    }}
                  />
                  <IconButton
                    color="error"
                    size="small"
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                    }}
                    onClick={handleDelete(index, i)}
                  >
                    <SvgIconStyle src={TRASH_ICON} height={18} width={18} />
                  </IconButton>
                </Box>
              ))}
            </Stack>
          }
        />
      ))}
    </Stack>
  );
}

export { Thumbnails };
