import type { TBBox } from 'fabric';
import { FabricImage, Path } from 'fabric';
import { useEffect, useRef } from 'react';

import type { FileCanvas } from './types.ts';
import { customEvent } from './events.ts';
import { findImage, generateRedactPath } from './utils.ts';

interface RedactionToolProps {
  getCanvas: () => FileCanvas;
  isActive: boolean;
}

function useRedactionTool({ getCanvas, isActive }: RedactionToolProps) {
  const imageBoundaryRef = useRef<TBBox>({
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  });
  const imageOffsetLeftRef = useRef<number>(0);
  const imageOffsetTopRef = useRef<number>(0);

  const getImageBoundary = () => imageBoundaryRef.current;
  const getImageOffsetLeft = () => imageOffsetLeftRef.current;
  const getImageOffsetTop = () => imageOffsetTopRef.current;

  const updateImageMeasurements = () => {
    const canvas = getCanvas();

    if (canvas) {
      const image = canvas.getObjects().find(findImage) as FabricImage;
      if (image) {
        imageBoundaryRef.current = image.getBoundingRect();
        imageOffsetLeftRef.current =
          (canvas.width - image.width * image.scaleX) / 2;
        imageOffsetTopRef.current =
          (canvas.height - image.height * image.scaleY) / 2;
      }
    }
  };

  useEffect(() => {
    const canvas = getCanvas();
    let prevRect: Path | null = null;
    let started = false;
    let x = 0;
    let y = 0;

    const mousedown = (event) => {
      // Disable default selection tool to stop fabric from not handling
      // overlapping objects correctly and merging them to create one big
      // object. Re-enable on mouse up.
      canvas.set('selection', false);

      // if alt key is pressed, do not allow redaction
      if (event.e.altKey) {
        return false;
      }

      if (event.target?.isType('path') || event.target?.isType('rect')) {
        canvas.setActiveObject(event.target);
        return false;
      }

      // allow user to select existing rectangle
      if (event.target === prevRect) {
        return false;
      }

      updateImageMeasurements();

      const imageBoundary = getImageBoundary();
      const imageOffsetLeft = getImageOffsetLeft();
      const imageOffsetTop = getImageOffsetTop();

      // don't let user draw redaction boxes outside of image boundary
      if (
        event.absolutePointer.x < imageBoundary.left ||
        event.absolutePointer.x > imageBoundary.width + imageOffsetLeft ||
        event.absolutePointer.y < imageBoundary.top ||
        event.absolutePointer.y > imageBoundary.height + imageOffsetTop
      ) {
        return false;
      }

      // ensure object does not preserve aspect ratio
      canvas.set('uniformScaling', true);

      started = true;

      x = event.absolutePointer.x;
      y = event.absolutePointer.y;

      const square = new Path(generateRedactPath({ width: 0, height: 0 }), {
        // width: 0,
        // height: 0,
        left: x,
        top: y,
        fill: 'black',
        stroke: 'grey',
        uniformScaling: false,
        cornerColor: 'blue',
        transparentCorners: false,
        cornerStrokeColor: 'white',
        cornerStyle: 'circle',
      });
      square.setControlsVisibility({
        mtr: false,
      });

      canvas.add(square);
      canvas.bringObjectToFront(square);
      square.setCoords();
      canvas.setActiveObject(square);
      prevRect = square;
    };

    const mousemove = (event) => {
      // if alt key is pressed, do not allow redaction
      if (event.e.altKey) {
        return false;
      }

      if (!started) {
        return false;
      }

      let mouseX = event.absolutePointer.x;
      let mouseY = event.absolutePointer.y;
      const imageBoundary = getImageBoundary();
      const imageOffsetLeft = getImageOffsetLeft();
      const imageOffsetTop = getImageOffsetTop();

      if (mouseX < imageBoundary.left) {
        mouseX = imageBoundary.left;
      }

      if (mouseX > imageBoundary.width + imageOffsetLeft) {
        mouseX = imageBoundary.width + imageOffsetLeft;
      }

      if (mouseY < imageBoundary.top) {
        mouseY = imageBoundary.top;
      }

      if (mouseY > imageBoundary.height + imageOffsetTop) {
        mouseY = imageBoundary.height + imageOffsetTop;
      }

      let width = mouseX - x;
      let height = mouseY - y;

      if (!width || !height) {
        return false;
      }

      // Determine the rectangle's new position and size
      let newLeft;
      let newTop;

      if (width < 0) {
        newLeft = mouseX;
        width = -width;
      } else {
        newLeft = x;
      }

      if (height < 0) {
        newTop = mouseY;
        height = -height;
      } else {
        newTop = y;
      }

      const square = prevRect;

      if (square) {
        // having to do this roundabout way to update path
        const updatedPath = new Path(generateRedactPath({ width, height }), {
          left: newLeft,
          top: newTop,
          width,
          height,
          fill: 'black',
          uniformScaling: false,
          cornerColor: 'blue',
          transparentCorners: false,
          cornerStrokeColor: 'white',
          cornerStyle: 'circle',
        });

        square.set({
          left: newLeft,
          top: newTop,
          path: updatedPath.path,
          width: updatedPath.width,
          height: updatedPath.height,
          pathOffset: updatedPath.pathOffset,
        });
        square.setCoords();

        canvas.renderAll();
      }
    };

    const mouseup = (event) => {
      // if alt key is pressed, do not allow redaction
      if (event.e.altKey) {
        return false;
      }

      // event.transform is populated when clicking on an existing object
      if (event?.transform?.action) {
        // TODO: I might need to redraw the path?
        return false;
      }

      if (started) {
        started = false;
      }

      canvas.set('uniformScaling', false);

      const square = canvas.getActiveObject();

      if (square) {
        const height = Math.ceil(square.get('height'));
        const width = Math.ceil(square.get('width'));
        const angle = Math.ceil(square.get('angle'));

        let newPath: Path;

        if (!height && !width) {
          newPath = new Path(generateRedactPath({ width: 100, height: 25 }), {
            angle,
            left: Math.ceil(square.left + 50),
            top: Math.ceil(square.top + 12.5),
            width: 100,
            height: 25,
            fill: 'black',
            stroke: 'grey',
            border: 'none',
            uniformScaling: false,
            cornerColor: 'blue',
            transparentCorners: false,
            cornerStrokeColor: 'white',
            cornerStyle: 'circle',
            originX: 'center',
            originY: 'center',
          });
        } else {
          newPath = new Path(generateRedactPath({ width, height }), {
            angle,
            left: Math.ceil(square.left + square.width / 2), // plus width / 2 to account for originX: 'center'
            top: Math.ceil(square.top + square.height / 2), // plus height / 2 to account for originY: 'center'
            width: Math.ceil(square.width),
            height: Math.ceil(square.height),
            fill: 'black',
            stroke: 'grey',
            border: 'none',
            uniformScaling: false,
            cornerColor: 'blue',
            transparentCorners: false,
            cornerStrokeColor: 'white',
            cornerStyle: 'circle',
            originX: 'center',
            originY: 'center',
          });
        }

        canvas.remove(square);
        canvas.add(newPath);
        newPath.setControlsVisibility({
          mtr: true,
        });
        canvas.setActiveObject(newPath);
        canvas.renderAll();

        prevRect = newPath;
        canvas.set('selection', true);
        canvas.fire(customEvent.REDACT_ADDED);
      }
    };

    if (canvas) {
      if (isActive) {
        canvas.on('mouse:down', mousedown);
        canvas.on('mouse:move', mousemove);
        canvas.on('mouse:up', mouseup);
      } else {
        canvas.off('mouse:down', mousedown);
        canvas.off('mouse:move', mousemove);
        canvas.off('mouse:up', mouseup);
      }

      return () => {
        canvas.off('mouse:down', mousedown);
        canvas.off('mouse:move', mousemove);
        canvas.off('mouse:up', mouseup);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);
}

export { useRedactionTool };
