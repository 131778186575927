import type { MouseEvent } from 'react';
import { useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';

export type HandleLegendMouseEnter = (index: number) => VoidFunction;
export type HandleLegendMouseLeave = VoidFunction;

const FILTER = 'brightness(1.2)';

function useChartInteractivityHandlers() {
  const { palette } = useTheme();

  const [popover, setPopover] = useState({
    fill: palette.common.white,
    left: 0,
    top: 0,
    index: 0,
  });
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [selected, setSelected] = useState<undefined | number>(undefined);
  const [hover, setHover] = useState<undefined | number>(undefined);
  const parentRef = useRef<HTMLDivElement | null>(null);
  const tooltipRef = useRef<HTMLElement>(null);

  function handleChartMouseEnter(index: number) {
    return (e: MouseEvent) => {
      const parentBbox = parentRef.current?.getBoundingClientRect();
      const element = e.currentTarget;
      element.setAttribute('filter', FILTER);

      setPopover({
        fill: element.getAttribute('fill') as string,
        top: e.clientY - (parentBbox?.top || 0),
        left: e.clientX - (parentBbox?.left || 0),
        index,
      });
      setAnchorEl(e.currentTarget);
    };
  }

  function handleChartMouseLeave(e: MouseEvent) {
    const element = e.currentTarget;
    element.removeAttribute('filter');
    setAnchorEl(null);
  }

  function handleChartOnClick(i: number, condition?: boolean | undefined) {
    return () => {
      if (condition) {
        return;
      }

      if (selected === i) {
        setSelected(undefined);
      } else {
        setSelected(i);
      }
    };
  }

  function handleLegendMouseEnter(i: number) {
    return () => {
      setHover(i);
    };
  }

  function handleLegendMouseLeave() {
    setHover(undefined);
  }

  function isHovering(i: number) {
    return typeof hover === 'number' && i !== hover;
  }

  return {
    anchorEl,
    handleChartMouseEnter,
    handleChartMouseLeave,
    handleChartOnClick,
    handleLegendMouseEnter,
    handleLegendMouseLeave,
    isHovering,
    open: Boolean(anchorEl),
    parentRef,
    popover,
    selected,
    tooltipRef,
  };
}

export { useChartInteractivityHandlers };
