import Skeleton from '@mui/material/Skeleton';

interface PlaceholderProps {
  children: any;
  height?: number;
  isPending: boolean;
  width?: number;
}

function PlaceholderSkeleton({
  children,
  height = 22,
  isPending,
  width = 50,
}: PlaceholderProps) {
  if (isPending) {
    return <Skeleton variant="rectangular" width={width} height={height} />;
  }

  return children;
}

export { PlaceholderSkeleton };
