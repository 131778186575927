import Box from '@mui/material/Box';

type DotIconProps = {
  active: boolean;
};

function DotIcon({ active }: DotIconProps) {
  return (
    <Box
      component="span"
      sx={{
        width: 4,
        height: 4,
        borderRadius: '50%',
        bgcolor: 'text.disabled',
        transition: (theme) =>
          theme.transitions.create('transform', {
            duration: theme.transitions.duration.shorter,
          }),
        ...(active && {
          transform: 'scale(2)',
          bgcolor: 'primary.main',
        }),
      }}
    />
  );
}

export { DotIcon };
