type AnyObject = { [k: string]: any };

export function transformKeys(
  obj: any,
  keyTransformFn: (key: string) => string
): any {
  if (Array.isArray(obj)) {
    return obj.map((value) => transformKeys(value, keyTransformFn));
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce(
      (result: AnyObject, key: string) => ({
        ...result,
        [keyTransformFn(key)]: transformKeys(obj[key], keyTransformFn),
      }),
      {}
    );
  }
  return obj;
}
