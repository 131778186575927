import { Trans } from 'react-i18next';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Page } from 'components/Page';
import { TypographyWithTranslation } from 'components/with-translation';
import { AppLogo } from 'components/AppLogo';
import dashboardRoute from 'pages/Dashboard/dashboard.route';

interface ErrorFallbackProps {
  additionalMessage?: string;
  error: Error;
}

function SomethingWentWrong({ additionalMessage, error }: ErrorFallbackProps) {
  return (
    <Box sx={{ height: 1 }}>
      <Page i18nKeyTitle="somethingWentWrong.meta.title" sx={{ height: 1 }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            height: 1,
            paddingTop: 15,
            paddingBottom: 10,
          }}
        >
          <Container>
            <Box
              role="alert"
              sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}
            >
              <AppLogo />
              <TypographyWithTranslation
                i18nKey="somethingWentWrong.heading"
                variant="h3"
                paragraph
              />
              <Typography component="pre" sx={{ color: 'text.secondary' }}>
                {typeof error === 'string' ? error : error.message}
              </Typography>
              {additionalMessage ? (
                <Typography
                  component="pre"
                  sx={{ color: 'text.secondary', mt: 2 }}
                >
                  {additionalMessage}
                </Typography>
              ) : null}
              <Typography sx={{ color: 'text.secondary', mt: 2 }}>
                <Trans
                  i18nKey="somethingWentWrong.returnToLogin"
                  components={{
                    anchor: <Link href={dashboardRoute.createPath()} />,
                  }}
                />
              </Typography>
            </Box>
          </Container>
        </Box>
      </Page>
    </Box>
  );
}

export { SomethingWentWrong };
