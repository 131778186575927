import { Controller, useFormContext } from 'react-hook-form';

interface HiddenInputProps {
  defaultValue?: string | number;
  name: string;
}

function HiddenInput({ defaultValue, name, ...props }: HiddenInputProps) {
  const { control } = useFormContext();

  return (
    <Controller
      key={name}
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => <input type="hidden" {...field} {...props} />}
    />
  );
}

export { HiddenInput };
export type { HiddenInputProps };
