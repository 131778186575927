import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// import { alpha, styled } from '@mui/material/styles';
// import { SvgIconStyle } from 'components/SvgIconStyle';
// import { TRENDING_DOWN_ICON, TRENDING_UP_ICON } from 'constants/public-icons';
import type { Metric, Metrics } from 'types/Metrics';
import { TypographyWithTranslation } from 'components/with-translation';
import { convertArrayToObject } from 'utils/array';
import { toFixed } from 'utils/numbers';

import { PlaceholderSkeleton } from './PlaceholderSkeleton';

interface ClaimsProps {
  data: Metrics | undefined;
  isPending: boolean;
}

type Resources = { [key: string]: Metric };

// const IconWrapperStyle = styled('div')(({ theme }) => ({
//   alignItems: 'center',
//   backgroundColor: alpha(theme.palette.success.main, 0.16),
//   borderRadius: '50%',
//   color: theme.palette.success.main,
//   display: 'flex',
//   justifyContent: 'center',
//   height: 24,
//   width: 24
// }));

function Claims({ data, isPending }: ClaimsProps) {
  const resources: Resources = data ? convertArrayToObject(data, 'name') : {};

  return (
    <Box
      sx={{
        flex: {
          xs: '1',
          sm: '0 0 285px',
        },
        '@media print': {
          flexBasis: 'auto',
        },
      }}
    >
      <TypographyWithTranslation
        variant="subtitle2"
        i18nKey="claimsPerformance.title"
      />
      {/*<Stack*/}
      {/*  alignItems="center"*/}
      {/*  direction="row"*/}
      {/*  spacing={1}*/}
      {/*  sx={{ mt: 2, mb: 1 }}*/}
      {/*>*/}
      {/*  <IconWrapperStyle*/}
      {/*    sx={{*/}
      {/*      ...(percent < 0 && {*/}
      {/*        color: 'error.main',*/}
      {/*        bgcolor: (theme) => alpha(theme.palette.error.main, 0.16)*/}
      {/*      })*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <SvgIconStyle*/}
      {/*      height={16}*/}
      {/*      src={percent >= 0 ? TRENDING_UP_ICON : TRENDING_DOWN_ICON}*/}
      {/*      width={16}*/}
      {/*    />*/}
      {/*  </IconWrapperStyle>*/}
      {/*  <Typography component="span" variant="subtitle2">*/}
      {/*    {percent > 0 && '+'}*/}
      {/*    {`${percent}%`}*/}
      {/*  </Typography>*/}
      {/*</Stack>*/}
      <PlaceholderSkeleton isPending={isPending} width={150} height={48}>
        <Typography color="primary" variant="h3">
          {resources['SumClaimsDone']?.value}
        </Typography>
      </PlaceholderSkeleton>
      {resources['SumClaimsDoneFirstTime'] ? (
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            marginTop: 2.5,
          }}
        >
          <TypographyWithTranslation
            i18nKey="claimsPerformance.stpClaims"
            variant="subtitle2"
          />

          <PlaceholderSkeleton isPending={isPending}>
            <Typography color="primary" variant="subtitle2">
              {resources['SumClaimsDoneFirstTime']?.value}
            </Typography>
          </PlaceholderSkeleton>
        </Stack>
      ) : null}
      {resources['AvgClaimsNoAction5d'] ? (
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            marginTop: 1,
          }}
        >
          <TypographyWithTranslation
            i18nKey="claimsPerformance.avgClaimsNoAction5d"
            variant="subtitle2"
          />

          <PlaceholderSkeleton isPending={isPending}>
            <Typography color="primary" variant="subtitle2">
              {toFixed(resources['AvgClaimsNoAction5d']?.value as number, 2)}
            </Typography>
          </PlaceholderSkeleton>
        </Stack>
      ) : null}
    </Box>
  );
}

export { Claims };
