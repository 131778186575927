import dayjs from 'locales/dayjs';

// function padZero(value: string | number) {
//   return String(value).padStart(2, '0');
// }

// e.g. November 22, 2022 10:56AM
const timezoneOffset = new Date().getTimezoneOffset();
const MS_PER_MINUTE = 60000;

// e.g. August 16, 2018 8:02 PM
export function formatDocumentDate(timestamp: string | null) {
  const backendTime = new Date(timestamp as string);
  const displayDate = new Date(
    backendTime.getTime() - timezoneOffset * MS_PER_MINUTE
  );

  return dayjs(displayDate).format('LLL');
}

// e.g. 22 Nov 2022
export function formatDateTime(timestamp: Date) {
  const backendTime = new Date(timestamp);
  const displayDate = new Date(
    backendTime.getTime() - timezoneOffset * MS_PER_MINUTE
  );
  return dayjs(displayDate).format('DD MMM YYYY');
}

export function secondsConverter(duration: number) {
  const secondsPerMinute = 60;
  const secondsPerHour = secondsPerMinute * 60;
  const secondsPerDay = secondsPerHour * 24;

  const days = Math.floor(duration / secondsPerDay);
  const hours = Math.floor((duration % secondsPerDay) / secondsPerHour);
  const minutes = Math.floor((duration % secondsPerHour) / secondsPerMinute);
  const seconds = duration % secondsPerMinute;

  return {
    days,
    hours,
    minutes,
    seconds,
  };
}

export function isValidISODateString(dateString) {
  const date = new Date(dateString);
  const result = !isNaN(date.getTime()) && date.toISOString() === dateString;

  if (!result) {
    return isValidDateTime(dateString);
  }

  return true;
}

export function isValidDateTime(dateTime) {
  const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{6}$/;
  return regex.test(dateTime);
}
