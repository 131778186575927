import { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { toast } from 'components/toast';
import { useTranslationRoot } from 'components/with-translation.tsx';
import { BOOL } from 'constants/value-type.ts';

import type { LineItemColumn, LineItemRow } from './types.ts';
import { useEnrichmentToolContext } from './useEnrichmentToolContext.tsx';
import { LineItemsHeader } from './LineItemsHeader.tsx';
import { LineItemsRow } from './LineItemRow.tsx';

interface LineItemsProps {
  addLineBelow: (index: number) => () => void;
  columns: LineItemColumn[];
  deleteLineItems: (row: LineItemRow) => () => void;
  rows: LineItemRow[];
}

function LineItems({
  addLineBelow,
  columns,
  deleteLineItems,
  rows,
}: LineItemsProps) {
  const { t } = useTranslationRoot();
  const { state } = useEnrichmentToolContext();
  const { setValue } = useFormContext();
  const clipboardRef = useRef<LineItemRow | null>(null);

  const writeToClipboardRef = (row: LineItemRow) => {
    toast.custom(t('enrichmentTool.tooltipCopySuccess'), { duration: 2000 });
    clipboardRef.current = row;
  };

  const pasteToLineItems = (row: LineItemRow) => {
    const { keyToId, lineIdx, ...rest } = row;

    if (clipboardRef.current && clipboardRef.current?.lineIdx !== lineIdx) {
      Object.keys(rest).forEach((key) => {
        const id = keyToId[key];

        if (clipboardRef.current?.[key]) {
          const valueType = state.fields[id].valueType;

          if (valueType === BOOL) {
            setValue(id, clipboardRef.current?.[key] === 'True');
          } else {
            setValue(id, clipboardRef.current?.[key] ?? '');
          }
        }
      });
    }
  };

  return (
    <Paper>
      <Stack
        spacing={2}
        sx={{
          '& > div:first-of-type': {
            borderTop: 'none',
            paddingTop: 0,
          },
        }}
      >
        {rows.map((row, index) => (
          <Grid
            container
            key={index}
            spacing={1}
            sx={{
              alignItems: 'center',
              borderTop: '1px solid',
              borderTopColor: 'divider',
              borderTopWidth: 1,
              paddingTop: 1,
              px: 0.5,
            }}
          >
            <LineItemsHeader
              addLineBelow={addLineBelow}
              deleteLineItems={deleteLineItems}
              index={index}
              pasteToLineItems={pasteToLineItems}
              row={row}
              rowsLength={rows.length}
              writeToClipboardRef={writeToClipboardRef}
            />

            <Grid size={{ xs: 12 }}>
              <Stack
                direction="row"
                sx={{
                  flexWrap: 'wrap',
                }}
              >
                {columns.map((column, idx) => {
                  const { field } = column;
                  const name = row.keyToId[field];
                  const item = state.fields[name];

                  return (
                    <LineItemsRow
                      key={name}
                      {...column}
                      idx={idx}
                      name={name}
                      item={item}
                      row={row}
                    />
                  );
                })}
              </Stack>
            </Grid>
          </Grid>
        ))}
      </Stack>
    </Paper>
  );
}

export { LineItems };
