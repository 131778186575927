import type { UsersPage } from 'types/Users.ts';
import { transformPage } from 'api/transformers/page.ts';
import { transformKeys } from 'utils/object.ts';
import { toCamelCase } from 'utils/string.ts';

import type { UserApiResponse } from './types/users';
import type { PaginationApiResponse } from './types/pagination.ts';

export function transformUserResponse(res: UserApiResponse) {
  return transformKeys(res, toCamelCase);
}

export function transformUsersResponse(
  response: PaginationApiResponse<UserApiResponse>
): UsersPage {
  return transformPage(transformUserResponse)(response);
}
