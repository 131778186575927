import type { FileRejection } from 'react-dropzone';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { TypographyWithTranslation } from 'components/with-translation.tsx';
import { formatBytes } from 'utils/string.ts';

interface RejectedFilesProps {
  fileRejections: FileRejection[];
}

function RejectedFiles({ fileRejections }: RejectedFilesProps) {
  return (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: 'error.light',
        backgroundColor: (theme) => alpha(theme.palette.error.main, 0.08),
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { path, size } = file as File & { path: string };

        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {path} - {formatBytes(size)}
            </Typography>

            <ul>
              {errors.map((error) => (
                <Box component="li" key={error.code} sx={{ ml: 2 }}>
                  <TypographyWithTranslation
                    variant="caption"
                    i18nKey={`modal.${error.code}`}
                  />
                </Box>
              ))}
            </ul>
          </Box>
        );
      })}
    </Paper>
  );
}

export { RejectedFiles };
