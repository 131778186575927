import type { SyntheticEvent } from 'react';
import { Suspense } from 'react';
import Box from '@mui/material/Box';
import TabPanel from '@mui/lab/TabPanel';
import type { Claim } from 'types/Claims';
import type { CurrentUser } from 'types/CurrentUser';
import type { ClaimSummaryWidget } from 'types/Widgets.ts';
import { LoadingIcon } from 'components/icons/LoadingIcon';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag.ts';
import { EnrichmentToolV3 } from 'components/EnrichmentTool';
import { PolicyReviewTool } from 'components/PolicyReviewTool';

import { PAGES } from './Claim';
import { FeedbackV2 } from './FeedbackV2';
import { ReceivedInfoV2 } from './ReceivedInfoV2';
import { SummaryV2 } from './SummaryV2';
import { ReviewTool } from './ReviewTool';
import { Widgets } from './Widgets.tsx';

interface PagesProps {
  claim: Claim;
  currentUser: CurrentUser;
  hasRefetchButton: boolean;
  isPending: boolean;
  isRefetching: boolean;
  navigateToSummaryTab: (event: SyntheticEvent) => void;
  refetchClaim: VoidFunction;
  additionalTabs?: { [key: string]: ClaimSummaryWidget[] };
  widgets: ClaimSummaryWidget[];
}

function PagesV2({
  claim,
  currentUser,
  hasRefetchButton,
  isPending,
  isRefetching,
  refetchClaim,
  additionalTabs,
  widgets,
}: PagesProps) {
  const enableEnrichmentTool = useFeatureFlag('enableAdvReview');
  const enablePolicyAnalysisTool = useFeatureFlag('enablePolicyAnalysisTool');

  return (
    <Box sx={{ width: '100%' }}>
      <Suspense fallback={<LoadingIcon />}>
        <div style={{ opacity: isPending ? 0.8 : 1 }}>
          <TabPanel value={PAGES.summary}>
            <SummaryV2
              claim={claim}
              hasRefetchButton={hasRefetchButton}
              isRefetching={isRefetching}
              refetchClaim={refetchClaim}
            />
          </TabPanel>
          <TabPanel value={PAGES.metadata}>
            <ReceivedInfoV2 properties={claim.properties} />
          </TabPanel>

          <TabPanel value={PAGES.review}>
            {enableEnrichmentTool && !claim.features ? (
              <EnrichmentToolV3 claim={claim} currentUser={currentUser} />
            ) : (
              <ReviewTool claim={claim} currentUser={currentUser} />
            )}
          </TabPanel>

          {enablePolicyAnalysisTool && (
            <TabPanel value={PAGES.analysis}>
              <PolicyReviewTool claim={claim} widgets={widgets} />
            </TabPanel>
          )}

          <TabPanel value={PAGES.feedback}>
            <FeedbackV2 claimId={claim.id} />
          </TabPanel>
          {additionalTabs &&
            Object.keys(additionalTabs).map((tab) => (
              <TabPanel key={tab} value={tab}>
                <Widgets
                  key={tab}
                  features={claim.features}
                  widgets={additionalTabs[tab]}
                />
              </TabPanel>
            ))}
        </div>
      </Suspense>
    </Box>
  );
}

export { PagesV2 };
