import type { ReactNode } from 'react';
import type { SxProps, Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface ChartTooltipProps {
  autoAlign?: boolean;
  children: ReactNode;
  horizontalAlignment?: 'start' | 'center';
  left: number;
  margin?: number;
  sx?: SxProps<Theme>;
  top: number;
  verticalAlignment?: 'top' | 'bottom';
}

const REALIGNMENT_THRESHOLD = 50;
const START = 'start';
const CENTER = 'center';
const TOP = 'top';
const BOTTOM = 'bottom';

function getHorizontalAlignment(alignment = START, bBox: DOMRect) {
  if (alignment === CENTER) {
    const halfWidth = bBox.width / 2;

    return bBox.width - halfWidth;
  }
  return bBox.width;
}

function getVerticalAlignment(alignment = TOP, bBox: DOMRect) {
  if (alignment === BOTTOM) {
    return bBox.height;
  }

  return 0;
}

function ChartTooltip({
  autoAlign = true,
  children,
  horizontalAlignment = START,
  left,
  margin = 0,
  sx,
  top,
  verticalAlignment = TOP,
  ...props
}: ChartTooltipProps) {
  function setLeft(ref: HTMLDivElement, left: number) {
    ref.style.left = `${left}px`;
  }

  function setTop(ref: HTMLDivElement, top: number) {
    ref.style.top = `${top}px`;
  }

  return (
    <Box
      {...props}
      ref={(ref: HTMLDivElement) => {
        if (!ref) {
          return;
        }

        const parentBBox = ref.parentElement?.getBoundingClientRect();

        if (!parentBBox) {
          return;
        }

        const bBox = ref.getBoundingClientRect();
        const height = getVerticalAlignment(verticalAlignment, bBox);

        if (
          autoAlign &&
          top + bBox.height + REALIGNMENT_THRESHOLD > parentBBox.height
        ) {
          setTop(ref, top - bBox.height);
        } else {
          setTop(ref, top - height);
        }

        const width = getHorizontalAlignment(horizontalAlignment, bBox);

        if (autoAlign && left + bBox.width > parentBBox.width) {
          setLeft(ref, left - width - margin);
        } else {
          if (horizontalAlignment === CENTER) {
            setLeft(ref, left - width);
          } else {
            setLeft(ref, left + margin);
          }
        }
      }}
      sx={{
        backgroundColor: 'common.white',
        borderRadius: 1,
        boxShadow: (theme) => theme.shadows[1],
        color: 'common.black',
        pointerEvents: 'none',
        position: 'absolute',
        transition: 'all 150ms ease-out',
        ...sx,
      }}
    >
      <Typography
        variant="body2"
        sx={{
          fontWeight: 'bold',
          p: 1,
          whiteSpace: 'nowrap',
        }}
      >
        {children}
      </Typography>
    </Box>
  );
}

export { ChartTooltip };
