import { useEffect } from 'react';

const appBC = new BroadcastChannel('sproutai');

function useBroadcastChannel(handler: (event: MessageEvent) => void) {
  useEffect(() => {
    appBC.addEventListener('message', handler);

    return () => {
      appBC.removeEventListener('message', handler);
    };
  }, [handler]);
}

export { appBC, useBroadcastChannel };
