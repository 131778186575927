import { Pagination } from 'types/Pagination.ts';
import { transformKeys } from 'utils/object';
import { toCamelCase } from 'utils/string';

import type { PaginationApiResponse } from './types/pagination.ts';

export function transformPage<A, B>(
  itemsTransformer: (arg: A) => B
): (arg: PaginationApiResponse<A>) => Pagination<B[]> {
  return function transformResponse({ data, metadata, next, page, total }) {
    return {
      data: data.map(itemsTransformer),
      metadata: transformKeys(metadata, toCamelCase),
      next,
      page,
      total,
    };
  };
}
