import type { ChangeEvent } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import type { FeatureFlagV1 } from 'types/FeatureFlags';

interface FeatureFlagItemProps {
  feature: FeatureFlagV1;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

function Item({ feature, handleChange }: FeatureFlagItemProps) {
  const { key, name, description, value } = feature;

  return (
    <FormControlLabel
      control={
        <Switch
          name={key}
          onChange={handleChange}
          data-testid="feature-flag-value"
        />
      }
      value="start"
      checked={value}
      label={
        <Box sx={{ ml: 2 }}>
          <Typography variant="subtitle1">{name}</Typography>
          <Typography
            variant="caption"
            sx={{
              color: 'text.secondary',
            }}
          >
            {description}
          </Typography>
        </Box>
      }
      sx={{
        width: 1,
      }}
    />
  );
}

export { Item };
