import type { ReactNode } from 'react';
import { generalConfig } from 'config';
import { Controller, useFormContext } from 'react-hook-form';
import { useState, useEffect, useRef } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import type { LookupMapping, LookupMappings } from 'types/Lookup';
import { useGetLookupMapping } from 'state/queries/lookup';
import { getClaimBusinessUnit } from 'state/selectors/claims';

import { useReviewToolContext } from './useReviewToolContext';
import {
  getLookupMappingCode,
  getLineItemPrefix,
  getIsDescription,
} from './review-tool-dropdowns';

interface ReviewFormDropdownProps {
  children?: ReactNode;
  name: string;
  lineItemKey?: string;
  isLineItem?: boolean;
  testid?: string;
}

// TODO: the following is fixing line item search for the Review Screen. Review it with Soi
const DROPDOWN_LINE_ITEM_KEYS_MAP: Record<string, string> = {
  item_code: 'code',
  item_description: 'description',
};

function ReviewFormDropdown({
  children,
  name,
  lineItemKey,
  isLineItem = false,
  testid = '',
}: ReviewFormDropdownProps) {
  const { claim } = useReviewToolContext();
  const { control, getValues, setValue } = useFormContext();
  const value = getValues(name);

  const [inputValue, setInputValue] = useState(value || '');
  const [searchText, setSearchText] = useState('');
  const prevInputValue = useRef(value);
  // With the line items dropdowns we need to search by the provided searchKey. Otherwise, we can search by the feature's name.
  const searchKey = lineItemKey
    ? DROPDOWN_LINE_ITEM_KEYS_MAP[lineItemKey] || lineItemKey
    : name;

  const businessUnit = getClaimBusinessUnit(claim);

  const [isOpen, setOpen] = useState(false);
  const [options, setOptions] = useState<LookupMappings>([]);
  const { data, isFetching } = useGetLookupMapping({
    claimId: claim.id,
    searchKey,
    searchText,
    businessUnit: businessUnit || '',
    code: getIsDescription(searchKey)
      ? getValues(getLookupMappingCode(name))
      : '',
  });

  function updateRelatedFields(data: LookupMappings, newValue: LookupMapping) {
    if (data?.length && newValue) {
      // TODO: 'item_' is fixing line items related field updating. Review it with Soi
      const prefix = isLineItem ? getLineItemPrefix(name) : '';

      Object.keys(newValue).forEach((key: string) => {
        if (key !== searchKey) {
          setValue(`${prefix}${key}`, newValue[key]);
        }
      });
    }
  }

  function getInputValue(newValue: LookupMapping | string) {
    return newValue
      ? typeof newValue === 'string'
        ? newValue
        : newValue[searchKey]
      : '';
  }

  function getPrevInputValue() {
    return prevInputValue.current;
  }

  function setPrevInputValue(value: string) {
    prevInputValue.current = value;
  }

  // fetch for options when the user searches by entering a value
  useEffect(() => {
    const isDiff = getPrevInputValue() !== inputValue;

    const handler = isDiff
      ? setTimeout(() => setSearchText(inputValue), generalConfig.hilSearchWait)
      : null;

    if (isDiff) {
      setPrevInputValue(inputValue);
    }

    return () => {
      if (handler) {
        clearTimeout(handler);
      }
    };
  }, [inputValue]);

  useEffect(() => {
    if (data) {
      setOptions(data);
    }
  }, [data]);

  useEffect(() => {
    if (!isOpen) {
      setOptions([]);
    }
  }, [isOpen]);

  return (
    <>
      {children}
      <Controller
        name={name} // Name of the field in your form data
        control={control}
        render={({ field, fieldState }) => (
          <Autocomplete
            {...field}
            id={name}
            freeSolo
            fullWidth
            data-testid={testid}
            sx={{
              display: 'flex',
            }}
            ListboxProps={{
              sx: { fontSize: 12 },
            }}
            loading={isFetching}
            open={isOpen}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            options={options}
            onChange={(_event, newValue) => {
              const value = getInputValue(newValue);
              setInputValue(value);
              field.onChange(value);
              if (data) {
                updateRelatedFields(data, newValue);
              }
            }}
            value={field.value}
            onInputChange={(_event, newValue) => {
              const value = getInputValue(newValue);
              setInputValue(value);
              field.onChange(value);
            }}
            inputValue={inputValue}
            getOptionLabel={(option) => {
              return option[searchKey]
                ? Object.keys(option)
                    .map((key) => option[key])
                    .join(', ')
                    .toString()
                : option;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                size="small"
                error={Boolean(fieldState.error)}
                InputProps={{
                  ...params.InputProps,
                  type: 'search',
                  sx: { fontSize: 12 },
                  endAdornment: (
                    <>
                      {isFetching ? (
                        <CircularProgress
                          data-testid="loading-wheel"
                          color="inherit"
                          size={20}
                        />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        )}
      />
    </>
  );
}

export { ReviewFormDropdown };
