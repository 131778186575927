import type { ReactNode } from 'react';
import type { BoxProps } from '@mui/material/Box';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import { useTranslationRoot } from 'components/with-translation';

interface PageProps extends BoxProps {
  children: ReactNode;
  i18nKeyTitle: string;
  namespace?: string | string[];
}

function Page({ children, i18nKeyTitle = '', namespace, ...props }: PageProps) {
  const { t } = useTranslationRoot(namespace);

  useEffect(function setHeadTitle() {
    document.title = `${t(i18nKeyTitle)} - Sprout`;
  });

  return <Box {...props}>{children}</Box>;
}

export { Page };
