import { lazy } from 'react';
import { createPath } from 'react-router-dom';
import { loadable } from 'components/loadable';
import { CROP_ICON } from 'constants/public-icons';
import ROUTE_PERMISSIONS from 'constants/route-permissions';
import { SPLITS_PAGE } from 'constants/translation-keys';
import splitRoute from 'pages/Split/split.route.tsx';

import SplitsLayout from './SplitsLayout.tsx';

const path = '/s';
const SplitsPage = loadable(lazy(() => import('./Splits.tsx')));

const splitsRoute = {
  path,
  createPath: (params?: { search?: string }) =>
    createPath({ pathname: path, ...params }),
  icon: CROP_ICON,
  element: <SplitsLayout />,
  permission: ROUTE_PERMISSIONS.splits,
  children: [
    {
      element: <SplitsPage />,
      index: true,
      permission: ROUTE_PERMISSIONS.splits,
      i18nNamespace: SPLITS_PAGE,
    },
    splitRoute,
  ],
};

export default splitsRoute;
