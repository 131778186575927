import type { ChangeEvent } from 'react';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { ControllerWithSingleCheckbox } from 'components/form/ControllerWithSingleCheckbox.tsx';
import { ControllerWithTextFieldV2 } from 'components/form/ControllerWithTextFieldV2.tsx';
import { useTranslationRoot } from 'components/with-translation.tsx';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import { EDIT_ICON } from 'constants/public-icons.ts';
import { BOOL, STR } from 'constants/value-type.ts';

import type { DocumentContentWithId, Row } from './types.ts';
import {
  generateTranslatedLabel,
  isClientMemIdItem,
  isDisabledDocumentItems,
  isDropdownItems,
  isHardcodedDropdownItems,
  isHardcodedMultiselectItems,
} from './utils.ts';
import { HardcodedDropdown } from './HardcodedDropdown.tsx';
import { EnrichedDropdown } from './EnrichedDropdown.tsx';
import { HardcodedMultiselect } from './HardcodedMultiselect.tsx';
import { FieldsTable } from './FieldsTable.tsx';

interface DocumentItemsProps {
  items: DocumentContentWithId[];
  policyNumberId: string;
}

function DocumentItems({ items, policyNumberId }: DocumentItemsProps) {
  const { t } = useTranslationRoot();
  const { setValue } = useFormContext();

  const columns = [
    {
      field: 'label',
      headerName: t('enrichmentTool.clientKey'),
      width: '160px',
    },
    {
      field: 'value',
      headerName: t('enrichmentTool.value'),
      startAdornment: <SvgIconStyle src={EDIT_ICON} height={17} width={17} />,
      width: 'auto',
    },
  ];
  const rows = useMemo(
    () =>
      items.map((item) => ({
        ...item,
        label: generateTranslatedLabel(item),
        renderCell: (props: Row) => {
          const { id, label, valid, valueType } = props;
          const disabled = isDisabledDocumentItems(props);

          if (isHardcodedDropdownItems(props)) {
            return <HardcodedDropdown {...props} />;
          }

          if (isDropdownItems(props)) {
            return <EnrichedDropdown {...props} />;
          }

          if (isHardcodedMultiselectItems(props)) {
            return <HardcodedMultiselect {...props} />;
          }

          if (valueType === BOOL) {
            return (
              <ControllerWithSingleCheckbox
                name={props.id}
                checkboxProps={{
                  'aria-label': t(label) as string,
                  size: 'small',
                }}
                sx={{
                  ml: 0,
                }}
              />
            );
          }

          if (valueType === STR) {
            if (isClientMemIdItem(props)) {
              const onChangeHandler = (
                _event: ChangeEvent<HTMLInputElement>,
                policyNumberId: string
              ) => {
                if (policyNumberId) {
                  setValue(policyNumberId, '');
                }
              };

              return (
                <ControllerWithTextFieldV2
                  name={id}
                  onChangeHandler={(e: ChangeEvent<HTMLInputElement>) =>
                    onChangeHandler(e, policyNumberId)
                  }
                  textfieldProps={{
                    'aria-label': t(label) as string,
                    disabled,
                    error: typeof valid === 'boolean' && !valid,
                    variant: 'standard',
                    InputProps: { sx: { fontSize: 12 } },
                  }}
                />
              );
            } else {
              return (
                <ControllerWithTextFieldV2
                  name={id}
                  textfieldProps={{
                    'aria-label': t(label) as string,
                    disabled,
                    error: typeof valid === 'boolean' && !valid,
                    variant: 'standard',
                    InputProps: { sx: { fontSize: 12 } },
                  }}
                />
              );
            }
          }

          return null;
        },
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items, policyNumberId]
  );

  return <FieldsTable columns={columns} rows={rows} />;
}

export { DocumentItems };
