import type { ReactNode } from 'react';
import Box from '@mui/material/Box';
import { ControllerWithSingleCheckbox } from 'components/form/ControllerWithSingleCheckbox.tsx';
import { ControllerWithTextFieldV2 } from 'components/form/ControllerWithTextFieldV2.tsx';
import { useTranslationRoot } from 'components/with-translation.tsx';

import type { DocumentContentWithId } from './types.ts';
import { ResizableLineItemBox } from './ResizableLineItemBox.tsx';
import { LineItemDropdown } from './LineItemDropdown.tsx';

interface LineItemRowProps {
  field: string;
  headerName: string;
  hidden: boolean;
  idx: number;
  name: string;
  item: DocumentContentWithId;
  row: { [key: string]: any; lineIdx: number };
  type: string;
}

function LineItemsRow({
  field,
  headerName,
  hidden,
  idx,
  item,
  name,
  row,
  type,
}: LineItemRowProps) {
  const { t } = useTranslationRoot();

  if (type === 'boolean') {
    return (
      <Wrapper key={idx} hidden={hidden} field={field} type={type}>
        <ControllerWithSingleCheckbox
          label={t(headerName) as string}
          name={name}
          checkboxProps={{
            'aria-label': t(headerName) as string,
            size: 'small',
          }}
          sx={{
            alignItems: 'flex-start',
            mx: 0,
            '& .MuiFormControlLabel-label': {
              color: 'grey.500',
              fontSize: 12,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            },
          }}
        />
      </Wrapper>
    );
  }

  if (type === 'singleSelect') {
    return (
      <Wrapper key={idx} hidden={hidden} field={field} type={type}>
        <LineItemDropdown
          name={name}
          label={t(headerName) as string}
          error={!item.valid}
          row={row}
          value={row[field]}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper key={idx} hidden={hidden} field={field} type={type}>
      <ControllerWithTextFieldV2
        name={name}
        textfieldProps={{
          error: !item.valid,
          label: t(headerName),
          InputLabelProps: {
            shrink: true,
          },
          inputProps: {
            sx: {
              fontSize: 12,
              height: 26,
            },
          },
          variant: 'standard',
        }}
      />
    </Wrapper>
  );
}

interface WrapperProps {
  children: ReactNode;
  field: string;
  hidden: boolean;
  type: string;
}

function Wrapper({ children, field, hidden, type }: WrapperProps) {
  return (
    <Box
      component={ResizableLineItemBox}
      sx={{
        backgroundColor: 'inherit',
        color: ({ palette }) => palette.text.primary,
        display: hidden ? 'none' : 'block',
        flex: type === 'singleSelect' ? '1 0 auto' : '0 1 auto',
        fontSize: 12,
        minWidth: type === 'singleSelect' ? 234 : 117,
        maxWidth: type === 'singleSelect' ? 234 : 117,
        width: 1,
      }}
      hidden={hidden}
      field={field}
      type={type}
    >
      {children}
    </Box>
  );
}

export { LineItemsRow };
