import { createSelector } from 'reselect';
import {
  transformPageResponse,
  transformPagesResponse,
} from 'api/transformers/pages.ts';

import { selectState } from './utils.ts';

export const selectPagesResponse = createSelector(
  selectState,
  transformPagesResponse
);

export const selectPagesData = createSelector(
  selectPagesResponse,
  (response) => response.data
);

export const selectPagesTotal = createSelector(
  selectPagesResponse,
  (response) => response.total
);

const selectPagesMetadata = createSelector(
  selectPagesResponse,
  (response) => response.metadata
);

export const selectPagesMetadataSortedColumns = createSelector(
  selectPagesMetadata,
  (metadata) => metadata?.sortedColumns || []
);

export const selectPageResponse = createSelector(
  selectState,
  transformPageResponse
);

export const selectPageUrls = createSelector(
  selectPageResponse,
  (response) => response.urls
);

export const selectPageUrl = createSelector(
  selectPageResponse,
  (response) => response.url
);
