import {
  keepPreviousData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import type { PaginationApiResponse } from 'api/transformers/types/pagination.ts';
import type {
  BaseLevelContentApiResponse,
  PageApiResponse,
} from 'api/transformers/types/pages.ts';
import type { CurrentUser } from 'types/CurrentUser.ts';
import type { Page, PagesList, PageUrl } from 'types/Pages.ts';
import { MakePage, CURRENT_USER } from 'constants/api-endpoints.ts';
import type { Order } from 'constants/sort.ts';
import api, { makeApiLink } from 'api/api.ts';
import { generalConfig } from 'config.ts';
import { DESC } from 'constants/sort.ts';
import {
  CLASSIFICATION_REVIEW,
  REDACTION_REVIEW,
} from 'constants/page-stage.ts';
import {
  selectPageResponse,
  selectPagesData,
  selectPagesMetadataSortedColumns,
  selectPagesTotal,
  selectPageUrl,
  selectPageUrls,
} from 'state/selectors/pages.ts';
import { useGet } from 'utils/react-query.ts';
import { pathToUrl } from 'utils/url.ts';
import { transformKeys } from 'utils/object.ts';
import { toSnakeCase } from 'utils/string.ts';

/**
 * Query Keys
 */
const PAGES = 'pages';
const PAGES_LISTS =
  `${PAGES}\\?start=:start&size=:size&thumbnails=:thumbnails` as const;
const PAGE = `${PAGES}/:id` as const;
const PAGE_REDACT = `${PAGE}/redact` as const;
const PAGE_LOCK = `${PAGE}/lock` as const;
const PAGE_UNLOCK = `${PAGE}/unlock` as const;

function makePagesList({
  order,
  stage,
  thumbnails = true,
}: {
  order: Order;
  stage?: string;
  thumbnails?: boolean;
}) {
  const page: MakePage = {
    list: PAGES_LISTS,
    params: { order, thumbnails: String(thumbnails) },
  };

  if (stage) {
    page.list = `${page.list}&stage_filter=${stage}`;
    page.params.stage = stage;
  }

  return page;
}

type PagesOptions = {
  order?: Order;
  start: number;
  size: number;

  stage?: string;
  thumbnails?: boolean;
};

const getPageClassificationOptions = ({
  start = generalConfig.defaultPaginationStart,
  stage,
  size = generalConfig.defaultPaginationSize,
  order = DESC,
  thumbnails = true,
}: PagesOptions) => ({
  url: makePagesList({ order, stage, thumbnails }).list,
  params: {
    start,
    size,
    ...makePagesList({ order, stage, thumbnails }).params,
  },
  prefix: PAGES,

  placeholderData: keepPreviousData,
  refetchOnWindowFocus: true,
});

export function useGetPagesClassificationData(options: PagesOptions) {
  return useGet<PaginationApiResponse<PageApiResponse>, PagesList>({
    ...getPageClassificationOptions({
      ...options,
      stage: CLASSIFICATION_REVIEW,
    }),
    refetchOnMount: 'always',
    select: selectPagesData,
  });
}

const getPageRedactionOptions = ({
  start = generalConfig.defaultPaginationStart,
  stage,
  size = generalConfig.defaultPaginationSize,
  order = DESC,
  thumbnails = false,
}: PagesOptions) => ({
  url: makePagesList({ order, stage, thumbnails }).list,
  params: {
    start,
    size,
    ...makePagesList({ order, stage, thumbnails }).params,
  },
  prefix: PAGES,

  placeholderData: keepPreviousData,
  refetchOnWindowFocus: true,
});

export function useGetPagesRedactionData(options: PagesOptions) {
  return useGet<PaginationApiResponse<PageApiResponse>, PagesList>({
    ...getPageRedactionOptions({
      ...options,
      stage: REDACTION_REVIEW,
    }),
    refetchOnMount: 'always',
    select: selectPagesData,
  });
}

export function useGetPagesRedactionTotal(options: PagesOptions) {
  return useGet<PaginationApiResponse<PageApiResponse>, number>({
    ...getPageRedactionOptions({
      ...options,
      stage: REDACTION_REVIEW,
    }),
    select: selectPagesTotal,
  });
}

export function useGetPagesRedactionMetadataSortedColumns(
  options: PagesOptions
) {
  return useGet<PaginationApiResponse<PageApiResponse>, string[]>({
    ...getPageRedactionOptions({
      ...options,
      stage: REDACTION_REVIEW,
    }),
    select: selectPagesMetadataSortedColumns,
  });
}

const getPageOptions = (id = '') => ({
  url: PAGE,
  params: { id },
  prefix: PAGES,
});

export function useGetPage(id = '') {
  return useGet<PageApiResponse, Page>({
    ...getPageOptions(id),
    refetchOnMount: 'always',
    select: selectPageResponse,
  });
}

export function useGetPageUrls(id = '') {
  return useGet<PageApiResponse, PageUrl[]>({
    ...getPageOptions(id),
    refetchOnMount: 'always',
    select: selectPageUrls,
  });
}

export function useGetPageUrl(id = '') {
  return useGet<PageApiResponse, string>({
    ...getPageOptions(id),
    refetchOnMount: 'always',
    select: selectPageUrl,
  });
}

export function unlockPage(id: string) {
  return api.put(makeApiLink(pathToUrl(PAGE_UNLOCK, { id })));
}

function lockPage(id: string) {
  return api.put(makeApiLink(pathToUrl(PAGE_LOCK, { id })));
}

export function usePageLock() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: lockPage,
    onMutate: async (id: string) => {
      const queryKey = [PAGES, PAGE, { id }];
      await queryClient.cancelQueries({ queryKey });
      const previousData = queryClient.getQueryData(queryKey);
      const currentUser = queryClient.getQueryData<CurrentUser>([CURRENT_USER]);

      if (currentUser) {
        queryClient.setQueryData(queryKey, (oldData: Page) => ({
          ...oldData,
          locked: true,
          last_locked_on: new Date().toISOString(),
          last_locked_by: currentUser.email,
        }));
      }

      return previousData;
    },
  });
}

export type PostPagesRedact = {
  fields: BaseLevelContentApiResponse[];
  manualRedactions: {
    topLeft: [number, number];
    topRight: [number, number];
    bottomLeft: [number, number];
    bottomRight: [number, number];
  }[];
};

export function postPagesRedact({
  id,
  body,
}: {
  id: string;
  body: PostPagesRedact;
}) {
  return api.post(
    makeApiLink(pathToUrl(PAGE_REDACT, { id })),
    transformKeys(body, toSnakeCase)
  );
}
