import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useTranslationRoot } from 'components/with-translation';

import { type ActionButtonProps, ComponentType } from '../generators';

const modalRenderers = {
  [ComponentType.ACTION_BUTTON](
    { action, icon, isSevere, label, namespace, ...rest }: any,
    i: number
  ) {
    const { t } = useTranslationRoot(namespace);

    return (
      <Button
        key={i}
        onClick={action}
        startIcon={icon}
        variant="contained"
        color={isSevere ? 'error' : 'primary'}
        {...rest}
      >
        {t(label)}
      </Button>
    );
  },
  [ComponentType.HEADER](
    { content, namespace, startAdornment, i18nOptions, ...rest }: any,
    i: number
  ) {
    const { t } = useTranslationRoot(namespace);

    return (
      <DialogTitle
        key={i}
        sx={{ alignItems: 'center', display: 'flex' }}
        {...rest}
      >
        {startAdornment}
        {t(content, i18nOptions)}
      </DialogTitle>
    );
  },
  [ComponentType.TEXT]({ content, namespace, ...rest }: any, i: number) {
    const { t } = useTranslationRoot(namespace);

    return (
      <DialogContent key={i} {...rest}>
        <DialogContentText>{t(content)}</DialogContentText>
      </DialogContent>
    );
  },
  [ComponentType.SEVERE_BUTTON](props: ActionButtonProps, i: number) {
    props.isSevere = true;
    return modalRenderers[ComponentType.ACTION_BUTTON](props, i);
  },
  [ComponentType.COMPONENT]: ({ Component, ...props }: any, i: number) => {
    return <Component key={i} {...props} />;
  },
};

export { modalRenderers };
