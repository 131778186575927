import type { BoxProps } from '@mui/material/Box';
import Box from '@mui/material/Box';

interface Props extends BoxProps {
  height?: number;
  src: string;
  width?: number;
}

export function SvgIconStyle({
  height = 24,
  width = 24,
  src,
  sx,
  ...props
}: Props) {
  return (
    <Box
      component="span"
      sx={{
        bgcolor: 'currentColor',
        display: 'inline-block',
        height,
        mask: `url(${src}) no-repeat center / contain`,
        width,
        WebkitMask: `url(${src}) no-repeat center / contain`,
        ...sx,
      }}
      {...props}
    />
  );
}
