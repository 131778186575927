import { createSelector } from 'reselect';
import {
  transformFeatureFlagsCategoryRead,
  transformFeatureFlagsResponseV1,
} from 'api/transformers/feature-flags';

import { selectState } from './utils.ts';

export const selectFeatureFlagsV1Response = createSelector(
  selectState,
  transformFeatureFlagsResponseV1
);

export const selectFeatureFlagsData = createSelector(
  selectFeatureFlagsV1Response,
  ({ featureFlags }) => featureFlags || []
);

const selectFeatureFlagCategoryRead = createSelector(
  selectState,
  transformFeatureFlagsCategoryRead
);

export const selectCategoryReadFeatureFlagsData = createSelector(
  selectFeatureFlagCategoryRead,
  ({ featureFlags }) => featureFlags || []
);
