import type { ReactNode } from 'react';
import type { PopoverOrigin } from '@mui/material/Popover';
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { menuItemClasses } from '@mui/material/MenuItem';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import type { Arrow } from 'components/MenuPopover';
import { useTooltip } from 'components/customHooks/useTooltip';
import { MenuPopover } from 'components/MenuPopover';
import { renderDialogComponent } from 'components/dialog/generators';

import { tooltipRenderers } from './tooltipRenderers';

const INDEX = 'index';

const renderDialogComponents = renderDialogComponent(tooltipRenderers);

type PageKey = 'index' | string;

type GetDialogProps = {
  changePage: (page: string) => void;
  closeTooltip: () => void;
};
export interface GetDialogPageProps {
  getDialog: {
    [k in PageKey]: ({
      changePage,
      closeTooltip,
    }: GetDialogProps) => Array<any>;
  };
}

interface TooltipProps extends GetDialogPageProps {
  anchorOrigin?: PopoverOrigin;
  arrow?: Arrow;
  buttonAriaLabel?: string;
  children: ReactNode;
  testId?: string;
  transformOrigin?: PopoverOrigin;
}

function DialogTooltip({
  anchorOrigin = { vertical: 'top', horizontal: 'left' },
  arrow = 'right-top',
  buttonAriaLabel,
  children,
  getDialog,
  testId,
  transformOrigin = { vertical: 'top', horizontal: 'right' },
  ...props
}: TooltipProps) {
  const { element, handleClose, handleOpen, isOpen } = useTooltip();
  const [page, setPage] = useState(INDEX);

  function changePage(page: string) {
    setPage(page);
  }

  function generateContents() {
    return Object.keys(getDialog).map((pageKey) => {
      const pageGenerator = getDialog[pageKey];
      const pageProps: {
        changePage: (page: string) => void;
        closeTooltip: () => void;
      } = {
        ...props,
        changePage,
        closeTooltip: handleClose,
      };
      const pageItems = pageGenerator(pageProps);

      return (
        <TabPanel key={pageKey} value={pageKey}>
          {pageItems.map(renderDialogComponents)}
        </TabPanel>
      );
    });
  }

  return (
    <>
      <IconButton
        aria-label={buttonAriaLabel}
        onClick={handleOpen}
        data-testid={testId}
        sx={{
          zIndex: 'tooltip',
        }}
      >
        {children}
      </IconButton>

      <MenuPopover
        anchorEl={element}
        onClose={handleClose}
        onTransitionEnd={() => setPage(INDEX)}
        open={isOpen}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        arrow={arrow}
        sx={{
          minWidth: '160px',
          mt: -1,
          width: 'auto',
          [`& .${menuItemClasses.root}`]: {
            borderRadius: 0.75,
            px: 1,
            typography: 'body2',
          },
        }}
      >
        <TabContext value={page}>{generateContents()}</TabContext>
      </MenuPopover>
    </>
  );
}

export { DialogTooltip };
