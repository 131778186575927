import type { FileCanvas } from './types.ts';
import { bindObjectsToImage, updateBoundObjects } from './utils.ts';
import { mouseEvents } from './mouse-events.ts';
import { keyboardEvents } from './keyboard-events.ts';
import { writeToCorrections } from './state.ts';
import { customEvent } from './events.ts';

function initEventListeners({ canvas }: { canvas: FileCanvas }) {
  const bindAndUpdateObjects = () => {
    bindObjectsToImage(canvas);
    updateBoundObjects(canvas);
  };

  const handleAddedRedact = async () => {
    await writeToCorrections({
      canvas,
      page: canvas.page as number,
      pageId: canvas.pageId,
    });
  };

  canvas.on('object:rotating', bindAndUpdateObjects);
  canvas.on(customEvent.REDACT_ADDED, handleAddedRedact);

  mouseEvents(canvas);
  keyboardEvents(canvas);

  canvas.on(customEvent.TOOL_UNMOUNT, () => {
    canvas.off('object:rotating', bindAndUpdateObjects);
    canvas.off(customEvent.REDACT_ADDED, handleAddedRedact);
    canvas.fire(customEvent.REDACT_INACTIVE);
    canvas.fire(customEvent.SELECTION_TOOL_INACTIVE);
  });
}

export { initEventListeners };
