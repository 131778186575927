import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import type { User } from 'types/Users';
import { useBoolean } from 'components/customHooks/useBoolean';
import { RoleSelector } from 'components/RoleSelector';
import { SUPER_ADMIN } from 'constants/roles';
import { useUpdateUserRole } from 'state/queries/users';
import { useTranslationRoot } from 'components/with-translation.tsx';

type FormValue = {
  role: string;
};

interface RoleSelectorProps {
  isCurrentUser: boolean;
  page: number;
  rowsPerPage: number;
  user: User;
}

function Role({ isCurrentUser, user, page, rowsPerPage }: RoleSelectorProps) {
  const { t } = useTranslationRoot();
  const [isOpen, openModal, closeModal] = useBoolean(false);
  const [newRole, setNewRole] = useState<undefined | string>();
  const methods = useForm<FormValue>({
    defaultValues: {
      role: user.role,
    },
  });
  const updateUserRole = useUpdateUserRole({
    meta: {
      errorMessage: t('userManagement.roleSaveError') as string,
      loadingMessage: t('userManagement.updatingRole') as string,
      successMessage: t('userManagement.roleSaveSuccess') as string,
    },
    start: page,
    size: rowsPerPage,
  });

  function changeUserRole(role: string) {
    updateUserRole.mutate({ ...user, role });
  }

  function confirmDowngrade() {
    if (newRole) {
      changeUserRole(newRole);
    }
    closeModal();
  }

  function cancelChange() {
    methods.setValue('role', user.role);
    closeModal();
  }

  function handleChange() {
    const role = methods.getValues('role');

    if (user.role === SUPER_ADMIN) {
      setNewRole(role);
      openModal();
    } else {
      changeUserRole(role);
    }
  }

  if (isCurrentUser) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <form>
        <RoleSelector name="role" onChange={handleChange} />
        <Dialog open={isOpen} onClose={closeModal}>
          <DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to downgrade a Super Admin role?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={cancelChange} autoFocus>
                No
              </Button>
              <Button onClick={confirmDowngrade}>Yes, do it!</Button>
            </DialogActions>
          </DialogTitle>
        </Dialog>
      </form>
    </FormProvider>
  );
}

export { Role };
