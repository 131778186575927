import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid2';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import visuallyHidden from '@mui/utils/visuallyHidden';
import { TypographyWithTranslation } from 'components/with-translation';
import type { Metrics } from 'types/Metrics';

import type { TranslationDict } from './types';
import translationDict from './metric-translation-dict.json';
import { getMetric } from './utils';

interface MetricsProps {
  metrics: Metrics;
  translationMap?: { [key: string]: string };
}

function MetricCards({
  metrics,
  translationMap = translationDict,
}: MetricsProps) {
  const rows = Math.ceil(metrics.length / 3);

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: `repeat(3, minmax(0, 1fr))`,
        gridTemplateRows: `repeat(${rows}, minmax(0, auto))`,
        gridGap: (theme) => theme.spacing(2),
      }}
    >
      {metrics.length ? (
        metrics.map(({ name, value, unit }) => {
          const { options, translation } = getMetric(unit, Number(value));

          return (
            <Box key={name} sx={{ height: 1 }}>
              <Card sx={{ height: 1 }}>
                <CardContent>
                  <Stack
                    sx={{
                      alignItems: 'center',
                      gap: 2,
                    }}
                  >
                    <TypographyWithTranslation
                      i18nKey={translation}
                      variant="h6"
                      component="span"
                      options={options}
                    />
                    <TypographyWithTranslation
                      i18nKey={(translationMap as TranslationDict)[name]}
                      variant="body2"
                      sx={{
                        textAlign: 'center',
                      }}
                    />
                  </Stack>
                </CardContent>
              </Card>
            </Box>
          );
        })
      ) : (
        <Grid
          sx={{ height: 1 }}
          size={{
            xs: 6,
            md: 6,
            lg: 4,
          }}
        >
          <Card>
            <CardContent>
              <Stack
                sx={{
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <Skeleton variant="circular" width={40} height={40} />
                <Skeleton variant="rectangular" width={150} height={20} />
                <TypographyWithTranslation
                  sx={visuallyHidden}
                  i18nKey="common.loading"
                />
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      )}
    </Box>
  );
}

export { MetricCards };
