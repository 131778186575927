import type { TextFieldProps } from '@mui/material/TextField';
import { Controller, useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useTranslationRoot } from 'components/with-translation.tsx';

interface ControllerWithDatePickerV2Props {
  label: string;
  name: string;
  textfieldProps?: TextFieldProps;
}

dayjs.extend(utc);

function ControllerWithDatePickerV2(props: ControllerWithDatePickerV2Props) {
  const { t } = useTranslationRoot();
  const { control } = useFormContext();
  const { label, name, textfieldProps } = props;

  return (
    <Controller
      key={name}
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { value, ...restField } = field;
        const { error, invalid } = fieldState;
        const defaultValue = value ? dayjs.utc(value).format('YYYY-MM-DD') : '';

        return (
          <TextField
            {...restField}
            {...textfieldProps}
            aria-label={label}
            aria-invalid={invalid}
            defaultValue={defaultValue}
            fullWidth
            helperText={error?.message && t(error.message)}
            label={label}
            type="date"
            InputProps={{
              ...textfieldProps?.InputProps,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              ...textfieldProps?.inputProps,
              placeholder:
                textfieldProps?.inputProps?.placeholder?.toUpperCase(),
              'data-testid': name,
            }}
            onBlur={(e) => {
              field.onBlur();
              textfieldProps?.onBlur && textfieldProps.onBlur(e);
            }}
          />
        );
      }}
    />
  );
}

export { ControllerWithDatePickerV2 };
export type { ControllerWithDatePickerV2Props };
