import type {
  AppConfigConfig,
  AppConfigConfigVersions,
  AppConfigDeployments,
} from 'types/AppConfig';
import { transformKeys } from 'utils/object';
import { toCamelCase } from 'utils/string';

import type {
  AppConfigConfigApiResponse,
  AppConfigConfigVersionsApiResponse,
  AppConfigDeploymentsApiResponse,
} from './types/appconfig';

export function transformAppConfigConfigResponse(
  appConfigs: AppConfigConfigApiResponse
): AppConfigConfig {
  return transformKeys(appConfigs, toCamelCase);
}

export function transformAppConfigConfigVersionsResponse(
  appConfigVersions: AppConfigConfigVersionsApiResponse
): AppConfigConfigVersions {
  return transformKeys(appConfigVersions, toCamelCase);
}

export function transformAppConfigDeploymentsResponse(
  appConfigDeployments: AppConfigDeploymentsApiResponse
): AppConfigDeployments {
  return transformKeys(appConfigDeployments, toCamelCase);
}
