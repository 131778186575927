import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { makeApiLink } from 'api/api';
import { AppLogo } from 'components/AppLogo';
import { Page } from 'components/Page';
import { HEALTH_PAGE } from 'constants/translation-keys';

function Health() {
  const theme = useTheme();
  const { t } = useTranslation(HEALTH_PAGE);
  const [message, setMessage] = useState('');

  useEffect(() => {
    fetch(makeApiLink('health'), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setMessage(data.health);
      })
      .catch((error) => {
        setMessage(error);
      });
  }, []);

  return (
    <Page i18nKeyTitle="meta.title" namespace={HEALTH_PAGE} sx={{ height: 1 }}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          height: 1,
          paddingTop: 15,
          paddingBottom: 10,
        }}
      >
        <Container sx={{ textAlign: 'center' }}>
          <AppLogo
            height={99}
            width={480}
            style={{ marginBottom: theme.spacing(2) }}
          />
          <Typography variant="h3" component="p">
            {t('common.frontend')}
          </Typography>
          {message && (
            <Typography variant="body1">
              {t('common.backend', { message })}
            </Typography>
          )}
        </Container>
      </Box>
    </Page>
  );
}

export default Health;
