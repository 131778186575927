import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import { ARROW_FORWARD_ICON } from 'constants/public-icons.ts';

import { THUMBNAIL_EVENT } from './utils.ts';

interface TreeViewProps {
  collapseSize?: number;
  itemsComponent?: ReactNode;
  itemsCount?: number;
  page: number;
  selectorNode: ReactNode;
}

function Thumbnail({
  collapseSize,
  itemsComponent,
  itemsCount,
  page,
  selectorNode,
}: TreeViewProps) {
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  useEffect(() => {
    const listener = (e: any) => {
      if (e.detail.page === page) {
        setChecked(true);
      }
    };

    document.addEventListener(THUMBNAIL_EVENT, listener, false);

    return () => {
      document.removeEventListener(THUMBNAIL_EVENT, listener, false);
    };
  }, [page]);

  if (itemsCount) {
    return (
      <Collapse in={checked} collapsedSize={collapseSize}>
        <Stack direction="row">
          <Stack onClick={handleChange} sx={{ cursor: 'pointer' }}>
            <IconButton size="small">
              <SvgIconStyle
                src={ARROW_FORWARD_ICON}
                height={12}
                width={12}
                sx={{
                  transform: checked ? 'rotate(90deg)' : 'rotate(0deg)',
                }}
              />
            </IconButton>
          </Stack>
          <Box sx={{ width: 1 }}>{selectorNode}</Box>
        </Stack>
        {itemsComponent}
      </Collapse>
    );
  }

  return <>{selectorNode}</>;
}

export { Thumbnail };
