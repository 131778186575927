import type {
  Claim,
  ClaimObservation,
  ClaimPagesUrls,
  ClaimsPage,
  ClaimsValidateResults,
} from 'types/Claims';
import type { Document } from 'types/Documents';
import { transformPage } from 'api/transformers/page';
import { transformDocumentResponse } from 'api/transformers/documents';
import { toCamelCase } from 'utils/string';
import { transformKeys } from 'utils/object';

import type { PaginationApiResponse } from './types/pagination.ts';
import type {
  ClaimApiResponse,
  ClaimDocumentsResponse,
  ClaimObservationApiResponse,
  ClaimObservationsApiResponse,
  ClaimPagesUrlsApiResponse,
  ClaimsApiResponse,
  ClaimsValidateApiResponse,
} from './types/claims';

export function transformClaimResponse({
  claim_id,
  features,
  ...claim
}: ClaimApiResponse): Claim {
  return {
    ...transformKeys(claim, toCamelCase),
    id: claim_id,
    features,
  };
}

export function transformClaimsResponse(
  response: PaginationApiResponse<ClaimsApiResponse>
): ClaimsPage {
  return transformPage(({ claim_id, ...claim }: ClaimsApiResponse) => ({
    id: claim_id,
    ...transformKeys(claim, toCamelCase),
  }))(response);
}

export function transformClaimDocumentsResponse({
  documents,
}: ClaimDocumentsResponse): Document[] {
  return documents.map(transformDocumentResponse);
}

function transformObservationResponse({
  observation_id,
  ...observation
}: ClaimObservationApiResponse): ClaimObservation {
  return {
    id: observation_id,
    ...transformKeys(observation, toCamelCase),
  };
}

export function transformClaimObservationsResponse({
  observations,
}: ClaimObservationsApiResponse): ClaimObservation[] {
  return observations.map(transformObservationResponse);
}

export function transformClaimsValidateResponse({
  ...results
}: ClaimsValidateApiResponse): ClaimsValidateResults {
  return {
    ...transformKeys(results, toCamelCase),
  };
}

export function transformClaimPagesUrlsResponse(
  response: ClaimPagesUrlsApiResponse[]
): ClaimPagesUrls {
  return [...transformKeys(response, toCamelCase)];
}
