import type { ReactNode } from 'react';
import type { SxProps, Theme } from '@mui/material/styles';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { PLUS_ICON, TRASH_ICON } from 'constants/public-icons';
import {
  ButtonWithTranslation,
  TypographyWithTranslation,
} from 'components/with-translation';
import { useLocales } from 'locales/useLocales';
import { toTitleCase } from 'utils/string';

import { useReviewToolContext } from './useReviewToolContext';
import {
  DROPDOWN_LINE_ITEM_KEYS,
  DOUBLE_WIDTH_LINE_ITEM_KEYS,
} from './review-tool-dropdowns';
import { generateLineName } from './review-tool-utils';
import { ReviewFormDropdown } from './ReviewFormDropdown';
import {
  getTranslatedFeatureKey,
  getUneditableFeature,
} from './review-tool-utils';

interface ReviewFormLineItemsProps {
  name: string;
}

interface LineItemFieldProps {
  children: ReactNode;
  isDoubleWidth?: boolean;
  sx?: SxProps<Theme>;
}

//TODO: we'll do the validation for each line item later
function LineItemField({ children, isDoubleWidth, sx }: LineItemFieldProps) {
  return (
    <Box
      sx={{
        flex: isDoubleWidth ? `2 1 auto` : `0 1 auto`,
        maxWidth: isDoubleWidth ? 408 : 200,
        width: 1,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

function ReviewFormLineItems({ name }: ReviewFormLineItemsProps) {
  const { control } = useFormContext();
  const { config, isNotLockedByCurrentUser } = useReviewToolContext();
  const { append, fields, remove } = useFieldArray({
    name,
  });

  const { currentLanguage } = useLocales();
  const translateFeatureKey = getTranslatedFeatureKey({
    config,
    currentLanguage,
  });
  const isDisabledFeature = getUneditableFeature(config);

  const getFirstField = () => {
    return fields[0];
  };

  const addNewLine = () => {
    const { id, ...firstField } = getFirstField();
    const newLine = Object.keys(firstField).reduce(
      (acc, curr) => ({ ...acc, [curr]: '' }),
      {}
    );

    append(newLine);
  };

  if (!fields.length) {
    return null;
  }

  return (
    <Box
      sx={{
        my: 2,
      }}
    >
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          p: 1,
        }}
      >
        <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
          {toTitleCase(translateFeatureKey(name))}
        </Typography>
        <ButtonWithTranslation
          disabled={isNotLockedByCurrentUser}
          i18nKey="reviewTool.addLineButton"
          startIcon={<SvgIconStyle src={PLUS_ICON} height={14} width={14} />}
          variant="text"
          onClick={() => addNewLine()}
          sx={{ fontSize: 12 }}
        />
      </Stack>
      <Stack
        spacing={2}
        sx={{
          px: 2,
        }}
      >
        {fields.map(
          ({ id, ...field }: Record<string, string>, lineIndex: number) => (
            <Stack key={id} spacing={1} direction="column">
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  alignItems: 'center',
                  height: 28,
                }}
              >
                <TypographyWithTranslation
                  fontSize={12}
                  fontWeight="bold"
                  i18nKey="reviewTool.lineNumber"
                  options={{ number: lineIndex + 1 }}
                />
                {fields.length > 1 ? (
                  <IconButton
                    data-testid="delete-line-item"
                    size="small"
                    color="error"
                    onClick={() => remove(lineIndex)}
                  >
                    <SvgIconStyle src={TRASH_ICON} height={18} width={18} />
                  </IconButton>
                ) : null}
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                useFlexGap
                sx={{
                  alignItems: 'flex-end',
                  flexWrap: 'wrap',
                }}
              >
                {Object.keys(field).map((key) => {
                  const fieldName = generateLineName(name, lineIndex, key);
                  const disabled = isDisabledFeature(key);

                  if (DROPDOWN_LINE_ITEM_KEYS.includes(key)) {
                    return (
                      <LineItemField
                        key={key}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                        }}
                        isDoubleWidth
                      >
                        <ReviewFormDropdown
                          name={fieldName}
                          lineItemKey={key}
                          isLineItem
                        >
                          <InputLabel
                            shrink
                            htmlFor={fieldName}
                            sx={{ textTransform: 'capitalize' }}
                          >
                            {translateFeatureKey(key)}
                          </InputLabel>
                        </ReviewFormDropdown>
                      </LineItemField>
                    );
                  }

                  return (
                    <LineItemField
                      key={key}
                      isDoubleWidth={DOUBLE_WIDTH_LINE_ITEM_KEYS.includes(key)}
                    >
                      <Controller
                        control={control}
                        name={fieldName}
                        render={({ field }) => {
                          return (
                            <>
                              <InputLabel
                                shrink
                                htmlFor={fieldName}
                                sx={{ textTransform: 'capitalize' }}
                              >
                                {translateFeatureKey(key)}
                              </InputLabel>
                              <TextField
                                {...field}
                                disabled={disabled}
                                id={fieldName}
                                variant="standard"
                                multiline
                                maxRows={3}
                                fullWidth
                                inputProps={{
                                  sx: {
                                    fontSize: 12,
                                  },
                                }}
                              />
                            </>
                          );
                        }}
                      />
                    </LineItemField>
                  );
                })}
              </Stack>
            </Stack>
          )
        )}
      </Stack>
    </Box>
  );
}

export { ReviewFormLineItems };
