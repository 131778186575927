import { useFieldArray, useFormContext } from 'react-hook-form';
import FormGroup from '@mui/material/FormGroup';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { ControllerWithTextField } from 'components/form/ControllerWithTextField';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { TRASH_ICON } from 'constants/public-icons';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

function TabularData({ name }: { name: string }) {
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name,
    shouldUnregister: true,
  });

  const removeItem = (index: number) => () => {
    remove(index);
  };

  const addNewItem = () => {
    append({ key: '', value: '' });
  };

  return (
    <FormGroup>
      <Paper square={false}>
        <Typography
          variant="h5"
          sx={{
            mt: 4,
            mb: 2,
          }}
        >
          Metadata
        </Typography>
        <Stack
          spacing={2}
          sx={{
            mt: 1,
            pl: 2,
          }}
        >
          <Stack direction="row" spacing={2}>
            <Box sx={{ flex: 1 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                <label htmlFor="metadata_key">Key</label>
              </Typography>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                <label htmlFor="metadata_value">Value</label>
              </Typography>
            </Box>
            <Box sx={{ flex: '0 0 48px', minWidth: 48 }} />
          </Stack>

          {fields.map(({ id }, index) => (
            <Stack
              direction="row"
              key={id}
              spacing={2}
              sx={{
                alignItems: 'center',
              }}
            >
              <Stack direction="row" key={id} spacing={2} sx={{ flex: 1 }}>
                <Box sx={{ flex: 1 }}>
                  <ControllerWithTextField
                    name={`${name}.${index}.key`}
                    id="metadata_key"
                    required
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <ControllerWithTextField
                    name={`${name}.${index}.value`}
                    id="metadata_value"
                  />
                </Box>
              </Stack>
              <Box sx={{ flex: '0 0 48px', minWidth: 48 }}>
                <IconButton
                  size="large"
                  aria-label="delete item"
                  onClick={removeItem(index)}
                  color="error"
                >
                  <SvgIconStyle src={TRASH_ICON} />
                </IconButton>
              </Box>
            </Stack>
          ))}

          <Box>
            <Button variant="contained" color="secondary" onClick={addNewItem}>
              Add Metadata
            </Button>
          </Box>
        </Stack>
      </Paper>
    </FormGroup>
  );
}

export { TabularData };
