import CircularProgress from '@mui/material/CircularProgress';

import { SkeletonWrapper } from './SkeletonWrapper.tsx';

function Loading() {
  return (
    <SkeletonWrapper>
      <CircularProgress />
    </SkeletonWrapper>
  );
}

export { Loading };
