import type { SyntheticEvent } from 'react';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import CustomProvider from 'rsuite/CustomProvider';
import DateRangePicker from 'rsuite/DateRangePicker';
import {
  TypographyWithTranslation,
  useTranslationRoot,
} from 'components/with-translation.tsx';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import { CALENDAR_ICON } from 'constants/public-icons.ts';
import { useLocales } from 'locales/useLocales.ts';
import 'components/daterange-picker.less';
import { useTheme } from '@mui/material/styles';

interface DateRangePickerV2Props {
  onOk?: (value: [Date, Date], event: SyntheticEvent) => void;
  updateStartDate: (date: Date) => void;
  updateEndDate: (date: Date) => void;
  startDate: Date;
  endDate: Date;
  size: 'xs' | 'sm' | 'md' | 'lg';
}

const currentStartTime = dayjs().toDate();
currentStartTime.setUTCHours(0, 0, 0, 0);
const currentEndTime = dayjs().toDate();
currentEndTime.setUTCHours(23, 59, 59);

function DateRangePickerV2({
  onOk,
  updateStartDate,
  updateEndDate,
  startDate,
  endDate,
  size,
}: DateRangePickerV2Props) {
  const { palette } = useTheme();
  const { currentLanguage } = useLocales();
  const { t } = useTranslationRoot();

  const caretIcon = () => <SvgIconStyle src={CALENDAR_ICON} />;

  const disableFutureDates = (date: Date) => {
    return dayjs(date).isAfter(dayjs(), 'day');
  };

  return (
    <Box
      sx={{
        position: 'relative',
        '& > .MuiBox-root': {
          color: 'text.disabled',
        },
        '&:hover > .MuiBox-root': {
          color: 'primary.main',
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          alignItems: 'center',
          color: 'inherit',
          gap: 0.5,
          padding: '0 7px',
          position: 'absolute',
          top: 0,
          left: 0,
          transform: 'translate(-9px, -12px) scale(0.75)',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          zIndex: 1,
        }}
      >
        <TypographyWithTranslation i18nKey={t('dateRangePicker.startText')} />
        {'–'}
        <TypographyWithTranslation i18nKey={t('dateRangePicker.endText')} />
      </Box>
      <CustomProvider
        locale={currentLanguage.rsuiteLocale}
        theme={palette.mode === 'dark' ? 'dark' : 'light'}
      >
        <Box
          block
          caretAs={caretIcon}
          character=" – "
          cleanable={false}
          component={DateRangePicker}
          disabledDate={disableFutureDates}
          isoWeek
          onChange={(value) => {
            if (value) {
              const [startDate, endDate] = value as [Date, Date];
              updateStartDate(dayjs(startDate).utc(true).toDate());
              updateEndDate(dayjs(endDate).utc(true).toDate());
            }
          }}
          onOk={onOk}
          placement="bottomEnd"
          ranges={[
            {
              label: t('dateRangePicker.today'),
              value: [currentStartTime, currentEndTime],
            },
            {
              label: t('dateRangePicker.yesterday'),
              value: [
                dayjs().add(-1, 'day').toDate(),
                dayjs().add(-1, 'day').toDate(),
              ],
            },
            {
              label: t('dateRangePicker.lastSevenDays'),
              value: [dayjs().add(-7, 'day').toDate(), currentStartTime],
            },
            {
              label: t('dateRangePicker.lastThirtyDays'),
              value: [dayjs().add(-30, 'day').toDate(), currentStartTime],
            },
            {
              label: t('dateRangePicker.previousMonth'),
              value: [
                dayjs().add(-1, 'month').date(1).toDate(),
                dayjs().add(-1, 'month').endOf('month').toDate(),
              ],
            },
          ]}
          showHeader={false}
          size={size}
          sx={{
            '& .rs-input': {
              backgroundColor: 'inherit',
              color: 'text.primary',
              height: 54,
            },
            '& .rs-input-group': {
              borderColor: ({ palette }) => palette.grey[500_32],
            },
            '& .rs-input-group.rs-input-group-inside': {
              backgroundColor: 'inherit',
              color: 'inherit',
            },
          }}
          value={[startDate, endDate]}
        />
      </CustomProvider>
    </Box>
  );
}

export { DateRangePickerV2 };
