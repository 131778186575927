import { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { OCR, POLICY, postFilesUploadXML } from 'api/files';
import { LinkWithRef } from 'components/Link';
import { Page } from 'components/Page';
import {
  TypographyWithTranslation,
  useTranslationRoot,
  WithTranslationRoot,
} from 'components/with-translation';
import { CREATE_CLAIM_PAGE } from 'constants/translation-keys';
import { toast } from 'components/toast';
import claimRoute from 'pages/Claim/claim.route';
import { createClaim } from 'state/queries/claims';

import type { DocumentData } from './utils';
import { CreateForm } from './CreateForm';

type State = {
  clientDocumentType?: string;
  error: ProgressEvent<EventTarget> | undefined;
  file: File;
  metadata?: Record<string, string>[];
  progress: number;
  thumbnail: string;
};

function CreateClaim() {
  const { t } = useTranslationRoot(CREATE_CLAIM_PAGE);
  const [claimId, setClaimId] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [files, setFiles] = useState<State[]>([]);
  const [policyFiles, setPolicyFiles] = useState<State[]>([]);

  function generatePromises(
    docsArray: any[],
    setFiles: React.Dispatch<
      React.SetStateAction<
        {
          clientDocumentType?: string;
          error: ProgressEvent<EventTarget> | undefined;
          file: File;
          metadata?: Record<string, string>[];
          progress: number;
          thumbnail: string;
        }[]
      >
    >,
    claimId: string,
    isPolicyType = false
  ) {
    return docsArray.map(
      ({ client_claim_id, file, metadata, client_document_type }, index) => {
        const payload = {
          document: file,
          upload_data: {
            claim_id: claimId,
            client_claim_id,
            client_document_type,
            metadata: JSON.stringify(metadata),
          },
        };
        return postFilesUploadXML(
          payload,
          {
            onLoad: () => {
              setFiles((prevFiles) => {
                const newFiles = [...prevFiles];
                newFiles[index].progress = 100;
                return newFiles;
              });
            },
            onError: (e) => {
              setFiles((prevFiles) => {
                const newFiles = [...prevFiles];
                newFiles[index].progress = 0;
                newFiles[index].error = e;
                return newFiles;
              });
            },
            onProgress: (e) => {
              setFiles((prevFiles) => {
                const newFiles = [...prevFiles];
                newFiles[index].progress = Math.round(
                  (e.loaded / e.total) * 100
                );
                return newFiles;
              });
            },
          },
          isPolicyType ? POLICY : OCR
        );
      }
    );
  }

  const onSubmit = async ({
    claimData,
    documentsData,
    policyDocumentsData,
  }: {
    claimData: {
      client_claim_id: string;
      properties: Record<string, string> | undefined;
      metadata: Record<string, string> | undefined;
      expected_files: number;
    };
    documentsData: DocumentData[];
    policyDocumentsData: DocumentData[];
  }) => {
    setIsSubmitting(true);

    const response = await toast.promise(
      createClaim({
        ...claimData,
        metadata: JSON.stringify(claimData.metadata),
        properties: JSON.stringify(claimData.properties),
      }),
      {
        loading: t('form.submitFormLoading'),
        success: (res) => {
          setClaimId(res.claim_id);
          return t('form.submitFormSuccess');
        },
        error: () => {
          setIsSubmitting(false);
          return t('form.submitFormError');
        },
      }
    );
    const claimId = response.claim_id;

    if (claimId) {
      if (documentsData.length) {
        const promises = generatePromises(documentsData, setFiles, claimId);

        await toast.promise(Promise.all(promises), {
          loading: t('form.uploadDocumentsLoading'),
          success: () => {
            setIsSubmitting(false);
            return t('form.uploadDocumentsSuccess');
          },
          error: () => {
            setIsSubmitting(false);
            return t('form.uploadDocumentsError');
          },
        });
      }

      if (policyDocumentsData.length) {
        const policyPromises = generatePromises(
          policyDocumentsData,
          setPolicyFiles,
          claimId,
          true
        );

        await toast.promise(Promise.all(policyPromises), {
          loading: t('form.uploadPolicyDocumentsLoading'),
          success: () => {
            setIsSubmitting(false);
            return t('form.uploadPolicyDocumentsSuccess');
          },
          error: () => {
            setIsSubmitting(false);
            return t('form.uploadPolicyDocumentsError');
          },
        });
      }
    }
  };

  return (
    <WithTranslationRoot namespace={CREATE_CLAIM_PAGE}>
      <Page i18nKeyTitle="meta.title">
        <Container maxWidth="md">
          <Box component="header" sx={{ mb: 5 }}>
            <TypographyWithTranslation i18nKey="common.title" variant="h4" />
          </Box>
          <CreateForm
            files={files}
            policyFiles={policyFiles}
            setFiles={setFiles}
            setPolicyFiles={setPolicyFiles}
            handleSubmit={onSubmit}
            isSubmitting={isSubmitting}
          />
          {claimId ? (
            <Typography variant="body1">
              {t('common.claimId')}

              <Link component={LinkWithRef} to={claimRoute.createPath(claimId)}>
                <Typography
                  component="span"
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  {claimId}
                </Typography>
              </Link>
            </Typography>
          ) : null}
        </Container>
      </Page>
    </WithTranslationRoot>
  );
}

export default CreateClaim;
