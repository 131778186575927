import { lazy } from 'react';
import { loadable } from 'components/loadable';
import { createPath } from 'react-router-dom';
import { FILE_EARMARK_ICON } from 'constants/public-icons';
import ROUTE_PERMISSIONS from 'constants/route-permissions';
import { CLAIMS_PAGE } from 'constants/translation-keys';
import claimRoute from 'pages/Claim/claim.route';

import ClaimsLayout from './ClaimsLayout';

const path = '/c';
const ClaimsPage = loadable(lazy(() => import('./Claims')));

const claimsRoute = {
  path,
  createPath: (params?: { search?: string }) =>
    createPath({ pathname: path, ...params }),
  icon: FILE_EARMARK_ICON,
  element: <ClaimsLayout />,
  children: [
    {
      element: <ClaimsPage />,
      index: true,
      permission: ROUTE_PERMISSIONS.claim,
    },
    claimRoute,
  ],
  permission: ROUTE_PERMISSIONS.claim,
  i18nNamespace: CLAIMS_PAGE,
};

export default claimsRoute;
