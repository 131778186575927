import { lazy } from 'react';
import { generatePath } from 'react-router-dom';
import { loadable } from 'components/loadable';
import ROUTE_PERMISSIONS from 'constants/route-permissions';
import { UPLOAD_ICON } from 'constants/public-icons';
import { FILE_UPLOAD_PAGE } from 'constants/translation-keys';

const path = '/file-upload';
const FileUploadPage = loadable(lazy(() => import('./FileUpload.tsx')));

const fileUploadRoute = {
  path,
  createPath: () => generatePath(path),
  element: <FileUploadPage />,
  icon: UPLOAD_ICON,
  permission: ROUTE_PERMISSIONS.fileUpload,
  i18nNamespace: FILE_UPLOAD_PAGE,
};

export default fileUploadRoute;
