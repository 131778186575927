import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  CardHeaderWithTranslation,
  TypographyWithTranslation,
} from 'components/with-translation';

interface ReceivedInfoProps {
  properties: { [key: string]: string } | null;
}

function ReceivedInfoV2({ properties }: ReceivedInfoProps) {
  return (
    <Card>
      <CardHeaderWithTranslation titleI18nKey="receivedInfo.title" />
      <Stack spacing={1} sx={{ p: 3 }}>
        <Box>
          {properties ? (
            Object.entries(properties).map(([key, value]) => (
              <Typography key={key} variant="body2">
                <Typography
                  component="span"
                  variant="body2"
                  sx={{ fontWeight: 'bold' }}
                >
                  {`${key}: `}
                </Typography>
                <Typography component="span" variant="body2">
                  {String(value)}
                </Typography>
              </Typography>
            ))
          ) : (
            <TypographyWithTranslation
              i18nKey="receivedInfo.noProperties"
              variant="body2"
            />
          )}
        </Box>
      </Stack>
    </Card>
  );
}

export { ReceivedInfoV2 };
