import { useEffect, useState } from 'react';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import type { ReactNode } from 'react';
import { generalConfig } from 'config';
import componentsOverrides from 'theme/overrides';
import { isDevEnvironment } from 'utils/client';
import { useSettings } from 'components/customHooks/useSettings';

interface ThemeColorPresetsProps {
  children: ReactNode;
}

function ThemeColorPresets({ children }: ThemeColorPresetsProps) {
  const defaultTheme = useTheme();
  const { themeMode } = useSettings();
  const [clientTheme, setClientTheme] = useState({});

  const theme = createTheme(defaultTheme, clientTheme);
  theme.components = componentsOverrides(theme);

  useEffect(() => {
    if (!isDevEnvironment) {
      const { customer } = generalConfig;
      import(`./palette-${customer}.ts`).then((customerPalette) => {
        setClientTheme(
          createTheme(
            defaultTheme,
            typeof customerPalette.default === 'function'
              ? customerPalette.default(themeMode)
              : customerPalette.default
          )
        );
      });
    }
  }, [defaultTheme, themeMode]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export { ThemeColorPresets };
