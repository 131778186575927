import { useState } from 'react';
import type { MouseEvent } from 'react';

export function useTooltip() {
  const [element, setElement] = useState<HTMLElement | null>(null);

  function handleOpen(event: MouseEvent<HTMLElement>) {
    setElement(event.currentTarget);
  }

  function handleClose() {
    setElement(null);
  }

  return {
    handleClose,
    handleOpen,
    element,
    isOpen: Boolean(element),
  };
}
