import type { SxProps } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';

interface LanguageIconProps {
  colors: string[];
  fontSize?: number;
  sx?: SxProps;
  text: string;
}

function LanguageIcon({
  colors = ['#fff', '#fff'],
  fontSize = 56,
  text = '',
  ...props
}: LanguageIconProps) {
  let markup;

  if (colors.length === 2) {
    const [first, second] = colors;

    markup = (
      <>
        <path fill={first} d="M0 0h128v128H0z" />
        <path fill={second} d="M128 0v128H0L128 0z" />
      </>
    );
  }

  if (colors.length === 3) {
    const [first, second, third] = colors;

    markup = (
      <>
        <path fill={second} d="M0 0h128v128H0z" />
        <path fill={third} d="M128 38v89l-1 1H38l90-90z" />
        <path fill={first} d="M1 0h89L0 90V1l1-1z" />
      </>
    );
  }

  return (
    <SvgIcon viewBox="0 0 128 128" {...props}>
      <g fill="none" fillRule="evenodd">
        {markup}
        <text
          fill="#FFFFFF"
          fontSize={fontSize}
          x="50%"
          y="54%"
          dominantBaseline="middle"
          textAnchor="middle"
        >
          {text.toUpperCase()}
        </text>
      </g>
    </SvgIcon>
  );
}

export { LanguageIcon };
