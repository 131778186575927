import { generalConfig } from 'config';

export const isDevEnvironment =
  generalConfig.customer === 'dev' || generalConfig.customer === 'base';

export function resizer(element: HTMLElement, condition = 900) {
  const boxes = new Map();
  const handles = new Map();
  const children = element.children;

  const setBoxes = (child: Element, index: number) => {
    boxes.set(index, child);
  };

  const setBoxPosition = (child: HTMLElement) => {
    child.style.position = 'relative';
  };

  const setBoxWidth = (child: HTMLElement, width: number | string) => {
    child.style.width = typeof width === 'number' ? `${width}px` : width;
  };

  // store in state
  Array.from(children).forEach(setBoxes);

  // add position relative to each box
  boxes.forEach(setBoxPosition);

  // add handles to each box except last one
  if (handles.size === 0) {
    boxes.forEach((box, index) => {
      // don't add div to last box
      if (index === boxes.size - 1) return;

      const div = document.createElement('div');
      div.style.cssText = `
        background-color: transparent;

        cursor: col-resize;
        touch-action: none;

        position: absolute;
        top: 0;
        right: -5px;
        bottom: 0;
        width: 10px;

        z-index: 1;
      `;

      box.appendChild(div);
      handles.set(index, div);
    });
  }

  // set width of each box
  let existingWidth = 0;

  boxes.forEach((box, index) => {
    const rect = box.getBoundingClientRect();
    // if last box, set width to remaining space
    if (index === boxes.size - 1) {
      setBoxWidth(box, `calc(100% - ${existingWidth}px)`);
    } else {
      setBoxWidth(box, rect.width);
    }
    existingWidth += rect.width;
  });

  // set event listeners
  handles.forEach((handle, index) => {
    handle.addEventListener('mousedown', (event: MouseEvent) => {
      event.preventDefault();

      if (window.innerWidth < condition) return;

      const startX = event.pageX;
      const startWidth = boxes.get(index).getBoundingClientRect().width;

      // add type for event parameter
      const mousemove = (event: MouseEvent) => {
        const diff = event.pageX - startX;
        const width = startWidth + diff;

        setBoxWidth(boxes.get(index), width);
        setBoxWidth(boxes.get(index + 1), `calc(100% - ${width}px)`);
      };

      const mouseup = () => {
        document.removeEventListener('mousemove', mousemove);
        document.removeEventListener('mouseup', mouseup);
      };

      document.addEventListener('mousemove', mousemove);
      document.addEventListener('mouseup', mouseup);
    });
  });
}

export function copyToClipboard(text: string) {
  navigator.clipboard.writeText(text);
}

export function preloadImageLink(url: string) {
  if (url) {
    const link = document.createElement('link');
    link.rel = 'preload';
    link.as = 'image';
    link.href = url;
    document.head.appendChild(link);

    return link;
  }
}

export function getOs() {
  return window.navigator.platform;
}

export function getOsKey() {
  const os = getOs();
  return os.includes('Mac') ? 'Cmd' : 'Ctrl';
}
