import type { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { LoadingIcon } from 'components/icons/LoadingIcon';
import { COMMON } from 'constants/translation-keys';

import { Page } from './Page';

type Props = {
  sx?: SxProps;
};

function LoadingScreen(props: Props) {
  const { t } = useTranslation(COMMON);

  return (
    <Page i18nKeyTitle="loading">
      <Container
        maxWidth="xl"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="body1" {...props}>
          {t('loading')}
        </Typography>
        <LoadingIcon />
      </Container>
    </Page>
  );
}

export { LoadingScreen };
