import { lazy } from 'react';
import { generatePath } from 'react-router-dom';
import { loadable } from 'components/loadable';
import ROUTE_PERMISSIONS from 'constants/route-permissions';
import { CONFIGURATION_PAGE } from 'constants/translation-keys';

const path = '/configuration/appconfig';
const AppConfig = loadable(lazy(() => import('./AppConfig')));

const appConfigRoute = {
  path,
  createPath: () => generatePath(path),
  element: <AppConfig />,
  permission: ROUTE_PERMISSIONS.configuration,
  i18nNamespace: CONFIGURATION_PAGE,
};

export default appConfigRoute;
