import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { LoadingIcon } from 'components/icons/LoadingIcon.tsx';
import { TypographyWithTranslation } from 'components/with-translation.tsx';
import { useLocales } from 'locales/useLocales.ts';
import { useGetConfig } from 'state/queries/config.ts';

import { getTranslatedFeatureKey } from './review-tool-utils.tsx';

interface TableWidgetProps {
  value: Record<string, { key: string; value: string }>[];
}

type Headers = string[];
type Data = Record<string, { key: string; value: string }>[];

// TODO: hardcoding column order specifically for MLJ POC
const columnOrder = [
  'payment_history_claim_period_end_date',
  'payment_history_claim_reason_name',
  'payment_history_paid_days',
  'payment_history_cause_code',
  'payment_history_disease_code_icd10',
  'payment_history_paid_amount',
];

function TableWidget({ value }: TableWidgetProps) {
  const { currentLanguage } = useLocales();
  const configQuery = useGetConfig('claim_review');
  let columns: Headers = [];
  let rows: Data = [];

  if (value?.[0] && Object.keys(value[0]).length) {
    columns = Object.keys(value[0]);
    // sort columns by order defined in columnOrder, if the key exists in columnOrder

    // TODO: more MLJ hack
    columns.sort((a, b) => {
      const aIndex = columnOrder.indexOf(a);
      const bIndex = columnOrder.indexOf(b);
      if (aIndex > -1 && bIndex > -1) {
        return aIndex - bIndex;
      }
      return 0;
    });
  }

  if (value.length && Object.values(value).length) {
    rows = value.filter((item) => {
      const key = Object.keys(item)[0];
      return (
        item[key].value !== undefined &&
        item[key].value !== null &&
        item[key].value !== ''
      );
    });

    // TODO: more MLJ hack
    // sort rows by column containing the word _date
    rows.sort((a, b) => {
      const aKey = Object.keys(a);
      const bKey = Object.keys(b);

      const aDate = aKey.find((key) => key.includes('_date'));
      const bDate = bKey.find((key) => key.includes('_date'));

      if (aDate && bDate && a[aDate].value && b[bDate].value) {
        return (
          new Date(a[aDate].value).getTime() -
          new Date(b[bDate].value).getTime()
        );
      }
      return 0;
    });
  }

  if (!columns.length) {
    return (
      <TypographyWithTranslation
        variant="body2"
        i18nKey="tableWidget.noColumnsDefined"
      />
    );
  }

  let body = (
    <TableRow>
      <TableCell colSpan={columns.length}>
        <TypographyWithTranslation
          variant="body2"
          i18nKey="tableWidget.noRows"
        />
      </TableCell>
    </TableRow>
  );

  if (rows.length) {
    body = (
      <>
        {rows.map((row, index) => (
          <TableRow key={index}>
            {columns.map((column, index) => (
              <TableCell key={`${index}-${column}-${index}`}>
                {row[column].value}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </>
    );
  }

  if (configQuery.isPending) {
    return <LoadingIcon />;
  }

  if (configQuery.isError) {
    console.warn('Failed to load config');
  }

  const translateFeatureKey = getTranslatedFeatureKey({
    config: configQuery.data || ({} as any),
    currentLanguage,
  });

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column}
                sx={{
                  whiteSpace: 'nowrap',
                }}
              >
                {translateFeatureKey(column)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{body}</TableBody>
      </Table>
    </TableContainer>
  );
}

export { TableWidget };
