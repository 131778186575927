import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'dayjs/locale/en-gb';
import { generalConfig } from 'config';

import dayjs from './dayjs';
import languages from './languages-base';

export function useLocales() {
  const { customer } = generalConfig;
  const [dynamicLanguages, setDynamicLanguages] = useState(languages);
  const { i18n } = useTranslation();

  const getCurrentLanguage = useCallback(
    (lngs: typeof languages) => {
      return (
        lngs.find((_lang) => _lang.value === i18n.resolvedLanguage) || lngs[0]
      );
    },
    [i18n.resolvedLanguage]
  );

  const currentLanguage = getCurrentLanguage(dynamicLanguages);

  useEffect(() => {
    if (customer !== 'dev') {
      import(`./languages-${customer}.ts`).then((module) => {
        setDynamicLanguages(module.default);

        const curLng = getCurrentLanguage(module.default);
        dayjs.locale(curLng.value);
      });
    }
  }, [customer, getCurrentLanguage]);

  function handleChangeLanguage(newLang: string) {
    i18n.changeLanguage(newLang);
    dayjs.locale(newLang);
  }

  return {
    onChangeLanguage: handleChangeLanguage,
    currentLanguage,
    allLanguages: dynamicLanguages,
    adapterLocale: currentLanguage.value.includes('en')
      ? 'en-gb'
      : currentLanguage.value,
    resolvedLanguage: i18n.resolvedLanguage,
  };
}
