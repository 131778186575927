import Grid from '@mui/material/Grid2';
import CardContent from '@mui/material/CardContent';
import type { ClaimFeature, ClaimFeatures } from 'types/Claims';
import type { ClaimSummaryWidget } from 'types/Widgets';
import { CollapsibleCard } from 'components/CollapsibleCard';

import { Markdown } from './Markdown.tsx';
import { TableWidget } from './TableWidget.tsx';
import { TimelineWidget } from './TimelineWidget.tsx';
import { VerticalTimelineWidget } from './VerticalTimelineWidget.tsx';

interface WidgetsProps {
  features: ClaimFeatures | null;
  widgets: ClaimSummaryWidget[];
}

function Widgets({ features, widgets }: WidgetsProps) {
  return (
    <Grid container spacing={2}>
      {widgets
        .map((widget, index) => {
          if (features) {
            const type =
              widget.widgetType === 'lines_table' ? 'lines' : 'fields';
            const item = features[type][widget.feature] as ClaimFeature;

            if (typeof item !== 'undefined') {
              return (
                <Grid key={index} size={{ xs: widget.colWidth || 6 }}>
                  <CollapsibleCard
                    titleI18nKey={widget.title}
                    initialExpanded={!widget.collapsed}
                  >
                    <CardContent
                      sx={{
                        pt: 0,
                        pre: {
                          whiteSpace: 'pre-wrap',
                        },
                      }}
                    >
                      <WidgetType type={widget.widgetType} item={item} />
                    </CardContent>
                  </CollapsibleCard>
                </Grid>
              );
            }
          }

          return null;
        })
        .filter(Boolean)}
    </Grid>
  );
}

interface WidgetProps {
  type: string;
  item: ClaimFeature;
}

function WidgetType({ type, item }: WidgetProps) {
  switch (type) {
    case 'lines_table':
      return <TableWidget value={item as unknown as Record<string, any>[]} />;
    case 'timeline_vertical':
      return <VerticalTimelineWidget value={item.value} />;
    case 'timeline':
      return <TimelineWidget value={item.value} />;
    default:
      return <Markdown value={item.value} />;
  }
}

export { Widgets };
