import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import { ButtonWithTranslation } from 'components/with-translation.tsx';
import { CIRCLE_CHECK_ICON } from 'constants/public-icons.ts';

interface SubmitButtonProps {
  disabled: boolean;
  isSubmitting: boolean;
  onSubmit: VoidFunction;
}

function SubmitButton({ disabled, isSubmitting, onSubmit }: SubmitButtonProps) {
  return (
    <ButtonWithTranslation
      disabled={disabled || isSubmitting}
      i18nKey="common.submitButton"
      color="primary"
      variant="contained"
      size="small"
      onClick={onSubmit}
      startIcon={
        <SvgIconStyle height={17} src={CIRCLE_CHECK_ICON} width={17} />
      }
    />
  );
}

export { SubmitButton };
