import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {
  ButtonWithTranslation,
  DialogContentTextWithTranslation,
  DialogTitleWithTranslation,
} from 'components/with-translation';

interface DocumentTypeModalProps {
  closeModal: VoidFunction;
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

function DocumentTypeModal({
  isOpen,
  closeModal,
  onCancel,
  onConfirm,
}: DocumentTypeModalProps) {
  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="document-type-change"
    >
      <DialogTitleWithTranslation
        id="document-locked-title"
        i18nKey="documentTypeDialog.title"
      />
      <DialogContent>
        <DialogContentTextWithTranslation
          id="document-locked-description"
          i18nKey="documentTypeDialog.description"
          sx={{
            py: 5,
          }}
        />
      </DialogContent>
      <DialogActions
        sx={{ borderTop: '1px solid', borderTopColor: 'grey.50024' }}
      >
        <ButtonWithTranslation
          variant="contained"
          color="success"
          i18nKey="documentTypeDialog.confirmButton"
          onClick={() => {
            onConfirm();
            closeModal();
          }}
        />
        <ButtonWithTranslation
          variant="contained"
          color="error"
          i18nKey="documentTypeDialog.closeButton"
          onClick={() => {
            onCancel();
            closeModal();
          }}
        />
      </DialogActions>
    </Dialog>
  );
}

export { DocumentTypeModal };
