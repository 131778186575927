import { createContext, useEffect } from 'react';
import type { ReactNode } from 'react';
import { generalConfig } from 'config';
import { useUnlockDocument } from 'state/queries/documents';

interface HitLContextProviderProps {
  children: ReactNode;
}

const HitLContext = createContext<{
  decrementLockedDocument: (documentId: string) => void;
  incrementLockedDocument: (documentId: string) => void;
  removeDocumentIdFromLocalStorage: (documentId: string) => void;
}>({
  decrementLockedDocument: () => {},
  incrementLockedDocument: () => {},
  removeDocumentIdFromLocalStorage: () => {},
});

/**
 * This hook is used to manage the locked documents in the local storage.
 * Axa, specifically, has a feature that opens a document in a new tab
 * This hook keeps a track of which document the user has locked
 * the increment/decrement lock functions are used inside HIL tool
 */
function useLockedDocumentStorage() {
  const { lockedDocumentsStorage } = generalConfig;
  const unlockDocumentAction = useUnlockDocument();

  const setLocalStorage = (obj: unknown) => {
    localStorage.setItem(lockedDocumentsStorage, JSON.stringify(obj));
  };

  const getLocalStorage = () => {
    return JSON.parse(localStorage.getItem(lockedDocumentsStorage) || '{}');
  };

  const incrementLockedDocument = (documentId: string) => {
    const lockedDocuments = getLocalStorage();

    setLocalStorage({
      ...lockedDocuments,
      [documentId]: parseInt(lockedDocuments[documentId]) + 1 || 1,
    });
  };

  const decrementLockedDocument = (documentId: string) => {
    const lockedDocuments = {
      ...getLocalStorage(),
    };
    const lockedDocument = lockedDocuments[documentId];

    if (lockedDocument) {
      lockedDocuments[documentId] = parseInt(lockedDocument) - 1;

      setLocalStorage(lockedDocuments);
    }
  };

  const removeDocumentIdFromLocalStorage = (documentId: string) => {
    const lockedDocuments = getLocalStorage();
    delete lockedDocuments[documentId];
    setLocalStorage(lockedDocuments);
  };

  // listen for changes in the lockedDocumentsStorage
  useEffect(() => {
    const deleteDocument = () => {
      const lockedDocuments = getLocalStorage();
      Object.keys(lockedDocuments).forEach((documentId) => {
        if (lockedDocuments[documentId] === 0) {
          delete lockedDocuments[documentId];
        }
      });
      setLocalStorage(lockedDocuments);
    };

    const unlockDocument = () => {
      const lockedDocuments = getLocalStorage();
      Object.keys(lockedDocuments).forEach((documentId) => {
        if (lockedDocuments[documentId] === 0) {
          unlockDocumentAction.mutate({ id: documentId });
        }
      });
    };

    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === lockedDocumentsStorage) {
        unlockDocument();
        deleteDocument();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    decrementLockedDocument,
    incrementLockedDocument,
    removeDocumentIdFromLocalStorage,
  };
}

function HitLContextProvider({ children }: HitLContextProviderProps) {
  const {
    decrementLockedDocument,
    incrementLockedDocument,
    removeDocumentIdFromLocalStorage,
  } = useLockedDocumentStorage();

  return (
    <HitLContext.Provider
      value={{
        decrementLockedDocument,
        incrementLockedDocument,
        removeDocumentIdFromLocalStorage,
      }}
    >
      {children}
    </HitLContext.Provider>
  );
}

export { HitLContext, HitLContextProvider };
