import { toast } from 'components/toast/toast.ts';
import { LoadingIcon } from 'components/icons/LoadingIcon.tsx';
import { useGetConfigVersions } from 'state/queries/appconfig.ts';

import { ConfigPanel } from './ConfigPanel.tsx';

interface LoadVersionProps {
  name: string;
}

function LoadVersion({ name }: LoadVersionProps) {
  const { data, isPending, isError, refetch } = useGetConfigVersions(name);

  if (isPending) {
    return <LoadingIcon />;
  }

  if (isError) {
    return <div>Getting /v0/appconfig/config/:name/versions failed.</div>;
  }

  if (!data) {
    return <div>No versions found for {name}</div>;
  }

  return (
    <ConfigPanel
      name={name}
      versions={data}
      refetchVersions={() => {
        toast.promise(refetch(), {
          loading: 'Refreshing versions...',
          success: 'Versions are now up-to-date.',
          error: 'Failed to fetch versions',
        });
      }}
    />
  );
}

export { LoadVersion };
