import type { ClaimSummaryWidget } from 'types/Widgets.ts';
import { transformKeys } from 'utils/object';
import { toCamelCase } from 'utils/string';

import type { ClaimSummaryWidgetsApiResponse } from './types/widgets';

export function transformWidgetsClaimSummaryApiResponse({
  widgets,
}: ClaimSummaryWidgetsApiResponse): ClaimSummaryWidget[] {
  return widgets.map((widget) => transformKeys(widget, toCamelCase));
}
