import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useTheme } from '@mui/material/styles';
import {
  CardHeaderWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';
import { round } from 'utils/numbers';

import type { Resources, TranslationDict } from './types';
import { BarChart } from './BarChart';

interface TrafficLightProps {
  list: string[];
  resources: Resources;
}

const translationDict: TranslationDict = {
  SumRecommendedAction00: 'trafficLight.sumRecommendedAction00',
  SumRecommendedAction10: 'trafficLight.sumRecommendedAction10',
  SumRecommendedAction20: 'trafficLight.sumRecommendedAction20',
  SumRecommendedAction21: 'trafficLight.sumRecommendedAction21',
  SumRecommendedAction30: 'trafficLight.sumRecommendedAction30',
  SumRecommendedAction311: 'trafficLight.sumRecommendedAction311',
  SumRecommendedAction312: 'trafficLight.sumRecommendedAction312',
  SumRecommendedAction32: 'trafficLight.sumRecommendedAction32',
};

function TrafficLight({ list, resources }: TrafficLightProps) {
  const { t } = useTranslationRoot();
  const { palette } = useTheme();

  const colors: { [key: string]: string } = {
    SumRecommendedAction00: palette.chart.green[0],
    SumRecommendedAction10: palette.chart.red[0],
    SumRecommendedAction20: palette.chart.yellow[0],
    SumRecommendedAction21: palette.chart.yellow[1],
    SumRecommendedAction30: palette.chart.blue[0],
    SumRecommendedAction311: palette.chart.blue[1],
    SumRecommendedAction312: palette.chart.blue[2],
    SumRecommendedAction32: palette.chart.blue[3],
  };

  const result = list.map((id) => {
    const { name, value } = resources[id];

    return {
      color: colors[name],
      name: t(translationDict[name]),
      value: round(Number(value), 1),
    };
  });

  return (
    <Card>
      <CardHeaderWithTranslation
        titleI18nKey="trafficLight.heading"
        sx={{ mb: 3 }}
      />
      <CardContent>
        <BarChart data={result} />
      </CardContent>
    </Card>
  );
}

export { TrafficLight };
