import { ReactNode, useState } from 'react';
import { Resizable, ResizeCallback } from 're-resizable';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { useResponsive } from 'components/customHooks/useResponsive.ts';
import { generalConfig } from 'config.ts';

interface ResizableBoxProps {
  children: ReactNode;
}

function ResizableBox({ children }: ResizableBoxProps) {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'md');

  const [size, setSize] = useState({
    width: isDesktop
      ? localStorage.getItem(generalConfig.claimEnrichmentFormWidthKey) || 400
      : '100%',
    height: '100%',
  });

  const onResizeStop: ResizeCallback = (_event, _direction, ref, d) => {
    setSize((prevState) => {
      localStorage.setItem(
        generalConfig.claimEnrichmentFormWidthKey,
        typeof prevState.width === 'number'
          ? String(prevState.width + d.width)
          : String(ref.clientWidth)
      );

      return {
        ...prevState,
        width: (prevState.width as number) + d.width,
      };
    });
  };

  return (
    <Box
      component={Resizable}
      size={isDesktop ? size : { width: '100%', height: '100%' }}
      onResizeStop={onResizeStop}
      style={{
        borderRight: `1px solid ${theme.palette.divider}`,
      }}
      enable={{
        right: true,
      }}
      sx={{
        order: { xs: 2, md: 1 },
        maxWidth: isDesktop ? '80%' : '100%',
        minWidth: '20%',
        minHeight: '100%',
      }}
    >
      {children}
    </Box>
  );
}

export { ResizableBox };
