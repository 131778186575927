import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface TypeSelectProps {
  onChange: (value: string) => void;
  options: string[];
  value: string;
}

function TypeSelect({ onChange, options, value }: TypeSelectProps) {
  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value as string);
  };

  return (
    <Box sx={{ minWidth: 120, p: 1.5 }}>
      <FormControl fullWidth size="small">
        <InputLabel id="type-selector-label">Type</InputLabel>
        <Select
          labelId="type-selector-label"
          id="type-selector"
          value={value}
          label="Type"
          onChange={handleChange}
          sx={{ fontSize: 14 }}
        >
          <MenuItem value="all" sx={{ fontSize: 14 }}>
            All
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option} value={option} sx={{ fontSize: 14 }}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export { TypeSelect };
