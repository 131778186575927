import { enUS, jaJP, ptBR } from '@mui/material/locale';
import en_US from 'rsuite/locales/en_US';
import ja_JP from 'rsuite/locales/ja_JP';
import pt_BR from 'rsuite/locales/pt_BR';

type Language = {
  colors: string[];
  label: string;
  rsuiteLocale: any;
  systemValue: any;
  value: string;
};

const languagesBase: Language[] = [
  {
    colors: ['#c9072a', '#001b69'],
    label: 'English',
    rsuiteLocale: en_US,
    systemValue: enUS,
    value: 'en',
  },
  {
    colors: ['#bd0029', '#bd0029'],
    label: '日本語',
    rsuiteLocale: ja_JP,
    systemValue: jaJP,
    value: 'ja',
  },
  {
    colors: ['#009739', '#FEDD00'],
    label: 'Português',
    rsuiteLocale: pt_BR,
    systemValue: ptBR,
    value: 'pt',
  },
];

export type { Language };
export default languagesBase;
