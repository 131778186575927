import type {
  FeatureFlags,
  FeatureFlagsV1,
  FeatureFlagsCategories,
  FeatureFlagsCategoryRead,
} from 'types/FeatureFlags.ts';
import { transformKeys } from 'utils/object.ts';
import { toCamelCase } from 'utils/string.ts';

import {
  FeatureFlagsApiResponse,
  FeatureFlagsApiResponseV1,
  FeatureFlagsCategoriesApiResponse,
  FeatureFlagsCategoryReadApiResponse,
} from './types/feature-flags';

export function transformFeatureFlagsResponse({
  feature_flags,
}: FeatureFlagsApiResponse): FeatureFlags {
  return feature_flags;
}

export function transformFeatureFlagsCategoriesResponse(
  response: FeatureFlagsCategoriesApiResponse
): FeatureFlagsCategories {
  return response;
}

export function transformFeatureFlagsResponseV1(
  response: FeatureFlagsApiResponseV1
): FeatureFlagsV1 {
  return transformKeys(response, toCamelCase);
}

export function transformFeatureFlagsCategoryRead(
  response: FeatureFlagsCategoryReadApiResponse
): FeatureFlagsCategoryRead {
  return transformKeys(response, toCamelCase);
}
