import { useState, useTransition } from 'react';
import type { SyntheticEvent } from 'react';

function usePages(initialPage = '0') {
  const [isPending, startTransition] = useTransition();
  const [page, setPage] = useState(initialPage);

  function handleChangePage(_event: SyntheticEvent, newValue: string) {
    startTransition(() => {
      setPage(newValue);
    });
  }

  return {
    handleChangePage,
    isPending,
    page,
  };
}

export { usePages };
