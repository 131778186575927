import type { SelectChangeEvent } from '@mui/material/Select';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ControllerWithDropDown } from 'components/form/ControllerWithDropDown';
import {
  ADMIN,
  BANNED,
  CLAIM,
  CLAIM_ADMIN,
  CLAIM_REVIEW,
  CLIENT_ADMIN,
  DOCUMENT_ADMIN,
  HITL,
  HITL_AND_MI,
  MI,
  POV,
  SUPER_ADMIN,
  SYSTEM,
} from 'constants/roles';
import { COMMON } from 'constants/translation-keys';
import { useIsSuperAdminRole } from 'state/queries/current-user';

const menuItems = [
  { value: ADMIN, label: 'roles.admin' },
  { value: BANNED, label: 'roles.banned' },
  { value: CLAIM, label: 'roles.claim' },
  { value: CLAIM_REVIEW, label: 'roles.claim_review' },
  { value: HITL, label: 'roles.hil' },
  { value: HITL_AND_MI, label: 'roles.hil_and_mi' },
  { value: MI, label: 'roles.mi' },
  { value: POV, label: 'roles.pov' },
  { value: SYSTEM, label: 'roles.system' },
  { value: DOCUMENT_ADMIN, label: 'roles.document_admin' },
  { value: CLAIM_ADMIN, label: 'roles.claim_admin' },
  { value: CLIENT_ADMIN, label: 'roles.client_admin' },
];

interface RoleSelectorProps {
  defaultValue?: string;
  name: string;
  onChange?: (e: SelectChangeEvent<HTMLInputElement>) => void;
}

function RoleSelector(props: RoleSelectorProps) {
  const { t } = useTranslation(COMMON);
  const { data: hasSuperAdminPermission, isSuccess } = useIsSuperAdminRole();

  const items = useMemo(() => {
    if (isSuccess && hasSuperAdminPermission) {
      return [...menuItems, { value: SUPER_ADMIN, label: 'roles.super_admin' }];
    }

    return menuItems;
  }, [hasSuperAdminPermission, isSuccess]);

  return (
    <ControllerWithDropDown
      {...props}
      htmlFor="role-selector"
      label={t('rolesDropdown.label')}
      menuItems={items}
      namespace={COMMON}
      size="medium"
    />
  );
}

export { RoleSelector };
