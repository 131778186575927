import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Resizable, ResizeCallback } from 're-resizable';

interface ResizeableLineItemBoxProps {
  children: ReactNode;
  type: string;
  field: string;
  hidden: boolean;
}

function ResizableLineItemBox({
  children,
  hidden,
  type,
  field,
}: ResizeableLineItemBoxProps) {
  const defaultWidth = type === 'singleSelect' ? 234 : 117;
  const storedWidth = localStorage.getItem('claimReviewLineItemsWidth');
  const parsedWidth = storedWidth ? JSON.parse(storedWidth) : {};
  const defaultState = {
    ...parsedWidth,
    [field]: parsedWidth[field] ?? defaultWidth,
  };
  const [widths, setWidths] = useState<Record<string, number>>(defaultState);

  const onResizeStop: ResizeCallback = (_event, _direction, _ref, d) => {
    setWidths((prevState) => {
      const newState = {
        ...prevState,
        [field]: prevState[field] + d.width,
      };

      localStorage.setItem(
        'claimReviewLineItemsWidth',
        JSON.stringify(newState)
      );
      window.dispatchEvent(new Event('UPDATE_LINE_ITEM_WIDTH'));

      return newState;
    });
  };

  useEffect(() => {
    const updateWidths = () => {
      const storedWidth = localStorage.getItem('claimReviewLineItemsWidth');
      const parsedWidth = storedWidth ? JSON.parse(storedWidth) : {};
      setWidths((prevState) => ({
        ...prevState,
        ...parsedWidth,
      }));
    };
    window.addEventListener('UPDATE_LINE_ITEM_WIDTH', updateWidths);

    return () => {
      window.removeEventListener('UPDATE_LINE_ITEM_WIDTH', updateWidths);
    };
  }, []);

  return (
    <Box
      component={Resizable}
      size={{
        width: widths[field],
        height: 'auto',
      }}
      enable={{
        right: true,
      }}
      handleClasses={{ right: 'item-resize' }}
      onResizeStop={onResizeStop}
      sx={{
        order: { xs: 2, md: 1 },
        maxWidth: '100%',
        minWidth: '40px',
        backgroundColor: 'inherit',
        color: (theme) => theme.palette.text.primary,
        display: hidden ? 'none' : 'block',
        fontSize: 12,
        overflow: 'hidden',
        p: 0.5,
        position: 'relative',

        '.item-resize:before': {
          // little accent to show a little box to represent resizing
          content: '""',
          backgroundColor: 'grey.50016',
          display: 'block',
          height: 20,
          width: '1px',

          position: 'absolute',
          right: 8,
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 1,
        },
      }}
    >
      {children}
    </Box>
  );
}

export { ResizableLineItemBox };
