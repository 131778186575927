import type { ChangeEvent, Dispatch, SetStateAction } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { PLUS_ICON } from 'constants/public-icons';
import visuallyHidden from '@mui/utils/visuallyHidden';
import Stack from '@mui/material/Stack';

import { Preview } from './Preview';

interface DocumentFormProps {
  title: string;
  subheader: string;
  buttonLabel: string;
  files: {
    clientDocumentType?: string;
    error: ProgressEvent<EventTarget> | undefined;
    file: File;
    metadata?: Record<string, string>[];
    progress: number;
    thumbnail: string;
  }[];
  setFiles: Dispatch<SetStateAction<any[]>>;
}

function DocumentForm({
  title,
  subheader,
  buttonLabel,
  files,
  setFiles,
}: DocumentFormProps) {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const documents = Array.from(e.target.files as FileList) as File[];

    documents.forEach((document) => {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const thumbnail = e.target?.result;
        const image = new Image();
        image.src = thumbnail as string;

        image.onload = () => {
          const newDocument = {
            error: undefined,
            file: document,
            thumbnail: thumbnail as string,
            size: document.size,
            width: image.width,
            height: image.height,
            progress: 0,
          };

          setFiles((prevFiles) => [...prevFiles, newDocument]);
        };

        // quite likely trying to upload a PDF
        image.onerror = () => {
          const newDocument = {
            error: undefined,
            file: document,
            thumbnail:
              // just a simple PDF thumbnail
              'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxZW0iIGhlaWdodD0iMWVtIiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0icmdiYSgyNTUsIDI1NSwgMjU1LCAwKSIgLz48cGF0aCBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik0xMCA4djhoMmEyIDIgMCAwIDAgMi0ydi00YTIgMiAwIDAgMC0yLTJoLTJ6bS03IDRoMmEyIDIgMCAxIDAgMC00SDN2OG0xNC00aDNtMS00aC00djgiLz48L3N2Zz4=' as string,
            size: document.size,
            width: image.width,
            height: image.height,
            progress: 0,
          };

          setFiles((prevFiles) => [...prevFiles, newDocument]);
        };
      };

      fileReader.readAsDataURL(document);
    }, []);
  };

  return (
    <Card>
      <CardHeader
        action={
          <Button
            variant="contained"
            component="label"
            startIcon={<SvgIconStyle height={16} width={16} src={PLUS_ICON} />}
          >
            {buttonLabel}
            <input
              accept="image/*,.pdf"
              hidden
              multiple
              type="file"
              style={visuallyHidden}
              onChange={onChange}
            />
          </Button>
        }
        title={title}
        subheader={subheader}
      />
      <Stack
        spacing={2}
        sx={{
          p: 3,
        }}
      >
        {files.map((props, index) => (
          <Preview key={index} index={index} setFiles={setFiles} {...props} />
        ))}
      </Stack>
    </Card>
  );
}

export { DocumentForm };
