import type { ClaimSummaryWidget } from 'types/Widgets.ts';

export function getRecommendationsWidgets(widget: ClaimSummaryWidget) {
  return widget.tab === 'recommendations';
}

export function getTimeline(tab = 'recommendations') {
  return (widget: ClaimSummaryWidget) =>
    widget.feature.toLowerCase() === 'timeline' ||
    widget.feature.toLowerCase() === 'timeline_mock' ||
    ((widget.feature.toLowerCase() === 'timeline' ||
      widget.feature.toLowerCase() === 'timeline_mock') &&
      widget.tab === tab);
}
