import type { Path } from 'react-router-dom';
import { lazy } from 'react';
import { createPath } from 'react-router-dom';
import { loadable } from 'components/loadable';
import { FILE_UPLOAD_ICON } from 'constants/public-icons';
import ROUTE_PERMISSIONS from 'constants/route-permissions';
import { UPLOAD_DOCUMENT_PAGE } from 'constants/translation-keys';

const path = '/upload-document';
const UploadDocument = loadable(lazy(() => import('./UploadDocument')));

const uploadDocumentRoute = {
  path,
  createPath: (params?: Partial<Path>) =>
    createPath({ pathname: path, ...params }),
  element: <UploadDocument />,
  icon: FILE_UPLOAD_ICON,
  permission: ROUTE_PERMISSIONS.uploadDocument,
  i18nNamespace: UPLOAD_DOCUMENT_PAGE,
};

export default uploadDocumentRoute;
