import { keepPreviousData, useQueryClient } from '@tanstack/react-query';
import type {
  FeatureFlagsApiResponse,
  FeatureFlagsApiResponseV1,
  FeatureFlagsCategoriesApiResponse,
  FeatureFlagsCategoryReadApiResponse,
} from 'api/transformers/types/feature-flags.ts';
import type {
  CategoryReadFeatureFlagsData,
  FeatureFlag,
  FeatureFlags,
  FeatureFlagsCategories,
  FeatureFlagV1,
} from 'types/FeatureFlags';
import api, { makeApiLink } from 'api/api';
import { transformFeatureFlagsResponse } from 'api/transformers/feature-flags';
import { generalConfig } from 'config';
import {
  FEATURE_FLAG,
  FEATURE_FLAGS,
  FEATURE_FLAGS_CATEGORIES,
  FEATURE_FLAGS_CATEGORY,
  FEATURE_FLAGS_CATEGORY_ITEM,
  FEATURE_FLAGS_CATEGORY_READ,
} from 'constants/api-endpoints';
import { pathToUrl } from 'utils/url';
import { useGet, useOptimisticMutation } from 'utils/react-query';
import {
  selectCategoryReadFeatureFlagsData,
  selectFeatureFlagsData,
} from 'state/selectors/feature-flags';

export function useGetFeatureFlags() {
  return useGet<FeatureFlagsApiResponse, FeatureFlags>({
    url: FEATURE_FLAGS,
    select: transformFeatureFlagsResponse,
    gcTime: 0,
    refetchInterval: generalConfig.featureFlagsInterval,
  });
}

export function useGetFeatureFlagsCategoryRead(name: string = 'frontend') {
  return useGet<
    FeatureFlagsCategoryReadApiResponse,
    CategoryReadFeatureFlagsData
  >({
    url: pathToUrl(FEATURE_FLAGS_CATEGORY_READ, { category: name }),
    select: selectCategoryReadFeatureFlagsData,
    apiVersion: 1,
    gcTime: 0,
    refetchInterval: generalConfig.featureFlagsInterval,
  });
}

const makeFeatureFlagsList = ({ category }: { category: string }) => {
  return {
    list: pathToUrl(FEATURE_FLAGS_CATEGORY, { category }),
    params: { category },
  };
};

const getFeatureFlagsV1 = ({ category }: { category: string }) => ({
  url: makeFeatureFlagsList({ category }).list,
  apiVersion: 1,

  placeholderData: keepPreviousData,
  refetchOnWindowFocus: true,
});

export function useGetFeatureFlagsV1(name: string) {
  return useGet<FeatureFlagsApiResponseV1, FeatureFlagV1[]>({
    ...getFeatureFlagsV1({ category: name }),
    select: selectFeatureFlagsData,
    gcTime: 0,
    apiVersion: 1,
  });
}

export function useGetFeatureFlagsCategories() {
  return useGet<FeatureFlagsCategoriesApiResponse, FeatureFlagsCategories>({
    url: FEATURE_FLAGS_CATEGORIES,
    apiVersion: 1,
  });
}

function updateFeatureFlag({ name, value }: FeatureFlag) {
  return api.put(makeApiLink(pathToUrl(FEATURE_FLAG, { name: name })), {
    value,
  });
}

export function useAddFeatureFlag() {
  return useOptimisticMutation<FeatureFlagsApiResponse, FeatureFlag>({
    fn: updateFeatureFlag,
    url: FEATURE_FLAGS,
    updater: (old, data) =>
      old
        ? { ...old, feature_flags: [...old.feature_flags, data] }
        : { feature_flags: [data] },
    meta: {
      errorMessage: 'Failed to add feature flag',
      loadingMessage: 'Adding feature flag',
      successMessage: 'Feature flag added',
    },
  });
}

export function useUpdateFeatureFlags() {
  return useOptimisticMutation<FeatureFlagsApiResponse, FeatureFlag>({
    fn: updateFeatureFlag,
    url: FEATURE_FLAGS,
    updater: (old, data) => {
      if (old) {
        const newFeatureFlags = old.feature_flags ? [...old.feature_flags] : [];
        const index = newFeatureFlags.findIndex(
          (item: any) => item.name === data.name
        );
        if (index !== -1) {
          newFeatureFlags[index] = data;
        }
        return {
          ...old,
          feature_flags: newFeatureFlags,
        };
      }
    },
    meta: {
      errorMessage: 'Error updating feature flag',
      loadingMessage: 'Updating feature flag',
      successMessage: 'Feature flag updated',
    },
  });
}

function updateFeatureFlagV1({
  category,
  key,
  value,
}: {
  category: string;
  key: string;
  value: boolean;
}) {
  return api.put(
    makeApiLink(
      pathToUrl(FEATURE_FLAGS_CATEGORY_ITEM, { category, feature_flag: key }),
      1
    ),
    {
      value,
    }
  );
}

export function useUpdateFeatureFlagsV1(name: string) {
  const queryClient = useQueryClient();
  const queryKey = [
    '',
    pathToUrl(FEATURE_FLAGS_CATEGORY_READ, { category: name }),
    null,
  ];
  return useOptimisticMutation<
    {
      feature_flags: FeatureFlagV1[];
      categories: string[];
    },
    {
      key: string;
      value: boolean;
    }
  >({
    fn: ({ key, value }) => updateFeatureFlagV1({ category: name, key, value }),
    url: pathToUrl(FEATURE_FLAGS_CATEGORY, { category: name }),
    updater: (old, data) => {
      if (old) {
        const newFeatureFlags = old.feature_flags ? [...old.feature_flags] : [];
        const index = newFeatureFlags.findIndex(
          (item) => item.key === data.key
        );
        if (index !== -1) {
          newFeatureFlags[index] = {
            ...newFeatureFlags[index],
            value: data.value,
          };
        }

        if (name === 'frontend') {
          queryClient.setQueryData(
            queryKey,
            (oldData: {
              feature_flags: FeatureFlagV1[];
              categories: string[];
            }) => {
              return {
                ...oldData,
                feature_flags: newFeatureFlags.map(({ key, value }) => ({
                  key,
                  value,
                })),
              };
            }
          );
        }

        return {
          ...old,
          feature_flags: newFeatureFlags,
        };
      }
    },
    meta: {
      errorMessage: 'Error updating feature flag',
      loadingMessage: 'Updating feature flag',
      successMessage: 'Feature flag updated',
    },
  });
}

function deleteFeatureFlag(name: string) {
  return api.delete(makeApiLink(pathToUrl(FEATURE_FLAG, { name })));
}

export function useDeleteFeatureFlag() {
  return useOptimisticMutation<FeatureFlagsApiResponse, string>({
    fn: deleteFeatureFlag,
    url: FEATURE_FLAGS,
    updater: (old, featureName) => {
      if (old) {
        return {
          ...old,
          feature_flags: old?.feature_flags.filter(
            (item) => item.name !== featureName
          ),
        };
      }
    },
    meta: {
      errorMessage: 'Error deleting feature flag',
      loadingMessage: 'Deleting feature flag',
      successMessage: 'Feature flag deleted',
    },
  });
}
