import { lazy } from 'react';
import { generatePath } from 'react-router-dom';
import claimsRoute from 'pages/Claims/claims.route';
import fileUploadRoute from 'pages/FileUpload/file-upload.route.tsx';
import hitlRoute from 'pages/HitL/hitl.route';
import performanceRoute from 'pages/Performance/performance.route';
import performanceManageUsersRoute from 'pages/PerformanceManageUsers/performanceManageUsers.route';
import ROUTE_PERMISSIONS from 'constants/route-permissions';
import { DASHBOARD_PAGE } from 'constants/translation-keys';
import featureFlagsRoute from 'pages/FeatureFlags/featureFlags.route';
import createClaimRoute from 'pages/CreateClaim/createClaim.route';
import uploadDocumentRoute from 'pages/UploadDocument/uploadDocument.route';
import configurationRoute from 'pages/Configuration/configuration.route';
import { ProtectedRoute } from 'pages/Site/ProtectedRoute';
import appConfigRoute from 'pages/Configuration/appConfigRoute.route';
import classificationRoute from 'pages/Classification/classification.route.tsx';
import redactionsRoute from 'pages/Redactions/redactions.route';
import splitsRoute from 'pages/Splits/splits.route.tsx';

const DashboardLayout = lazy(() => import('./DashboardLayout'));
const path = '/';

const dashboardRoute = {
  path,
  createPath: () => generatePath(path),
  element: (
    <ProtectedRoute>
      <DashboardLayout />
    </ProtectedRoute>
  ),
  children: [
    claimsRoute,
    classificationRoute,
    redactionsRoute,
    splitsRoute,
    hitlRoute,
    performanceRoute,
    performanceManageUsersRoute,
    fileUploadRoute,
    featureFlagsRoute,
    createClaimRoute,
    uploadDocumentRoute,
    configurationRoute,
    appConfigRoute,
  ],
  permission: ROUTE_PERMISSIONS.dashboard,
  i18nNamespace: DASHBOARD_PAGE,
};

export default dashboardRoute;
