import { Controller, useFormContext } from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

interface RadioOptionsProps {
  name: string;
  options: { label: string; value: string | number }[];
}

function RadioOptions({ name, options }: RadioOptionsProps) {
  const { control } = useFormContext();

  return (
    <Controller
      render={({ field }) => {
        const { name, onBlur, onChange, ref, value } = field;

        return (
          <RadioGroup data-testid="filter-group-radio" name={name}>
            {options.map(({ label }, index) => (
              <FormControlLabel
                checked={value?.includes(index)}
                data-testid="filter-option-radio"
                key={index}
                control={<Radio />}
                label={label}
                onBlur={onBlur}
                ref={ref}
                value={index}
                onClick={(event) => {
                  const targetValue = (event.target as HTMLInputElement).value;

                  // If the value is already selected, deselect it
                  if (parseInt(targetValue) === value[0]) {
                    onChange([]);
                  } else {
                    onChange([index]);
                  }
                }}
              />
            ))}
          </RadioGroup>
        );
      }}
      name={name}
      control={control}
    />
  );
}

export { RadioOptions };
