import type { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const HTML_FOR = 'strategy-dropdown';

interface StrategySelectorProps {
  handleChange: (value: string) => void;
  value: string;
}

const strategyOptions = [
  'instant',
  'Quick',
  'AppConfig.AllAtOnce',
  'AppConfig.Linear50PercentEvery30Seconds',
  'AppConfig.Canary10Percent20Minutes',
  'AppConfig.Linear20PercentEvery6Minutes',
];

function StrategySelector({ handleChange, value }: StrategySelectorProps) {
  const onChange = (e: SelectChangeEvent) => {
    handleChange(e.target.value);
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel id={HTML_FOR}>Strategy</InputLabel>
      <Select
        label="Strategy"
        labelId={HTML_FOR}
        onChange={onChange}
        value={value}
        data-testid="strategy-dropdown"
      >
        {strategyOptions.map((option, index) => (
          <MenuItem key={index} value={option} data-testid={HTML_FOR}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export { StrategySelector };
