import {
  keepPreviousData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import debounce from '@mui/utils/debounce';
import type { Meta } from 'query-client.ts';
import type { PaginationApiResponse } from 'api/transformers/types/pagination.ts';
import type { Order } from 'constants/sort';
import type {
  DocumentApiResponse,
  DocumentHistoryApiResponse,
  DocumentStageCountApiResponse,
  DocumentsTypesCountApiResponse,
  DocumentTypesApiResponse,
} from 'api/transformers/types/documents';
import {
  AllDocumentTypes,
  CountPerDocumentType,
  Document,
  DocumentContent,
  DocumentsPage,
  DocumentStageCount,
  DocumentTypes,
  DocumentValidation,
} from 'types/Documents';
import type { CurrentUser } from 'types/CurrentUser.ts';
import api, { makeApiLink } from 'api/api';
import {
  transformDocumentHistoryResponse,
  transformDocumentHistoryVersionResponse,
  transformDocumentResponse,
  transformDocumentsStageCountResponse,
  transformDocumentsValidationResponse,
} from 'api/transformers/documents';
import { generalConfig } from 'config';
import {
  DOCUMENTS,
  DOCUMENTS_ARCHIVE,
  DOCUMENTS_TYPES,
  DOCUMENTS_HISTORY,
  DOCUMENTS_LOCK,
  DOCUMENTS_REJECT,
  DOCUMENTS_SUBMIT,
  DOCUMENTS_UNLOCK,
  makeDocumentsList,
  DOCUMENTS_SAVE,
  DOCUMENTS_REPROCESS,
  makeDocumentStagesCountList,
  DOCUMENTS_TYPES_COUNT,
  DOCUMENTS_TYPES_COUNT_LIST,
  DOCUMENTS_HISTORY_VERSION,
  DOCUMENT_WITH_LANG_QUERY,
  DOCUMENTS_VALIDATION,
  CURRENT_USER,
  DOCUMENTS_NEXT,
} from 'constants/api-endpoints';
import { PROCESSING } from 'constants/document-stage';
import { ASC } from 'constants/sort';
import { useGet } from 'utils/react-query';
import { toSnakeCase } from 'utils/string';
import { pathToUrl } from 'utils/url';
import { transformKeys } from 'utils/object';
import { useLocales } from 'locales/useLocales';
import {
  selectDocumentContent,
  selectDocumentHistorical,
  selectDocumentResponse,
  selectDocumentsResponse,
  selectDocumentsTypesResponse,
  selectDocumentTypes,
  selectDocumentTypesCountDocumentTypes,
  selectIsDocumentAwaitingReview,
} from 'state/selectors/documents.ts';
import {
  HIL_TOOL_VALIDATION_START,
  HIL_TOOL_VALIDATION_END,
} from 'analytics/events.ts';

type UseGetDocumentProps = {
  id: string;
  language: string;
};

/**
 * Lock HitL document mechanism
 */
const lockingQueue = new Map();

function lockDocument(id: string) {
  return api.put(makeApiLink(pathToUrl(DOCUMENTS_LOCK, { id })));
}

function clearQueue() {
  lockingQueue.clear();
}

function flushQueue(id: string) {
  if (lockingQueue.size) {
    lockDocument(id);
    clearQueue();
  }
}

const rateLimitedFlushQueue = debounce(
  flushQueue,
  generalConfig.lockDocumentInterval
);

function addToQueue(id: string) {
  lockingQueue.set(Date.now(), true);
  rateLimitedFlushQueue(id);
}

function lockDocumentWithQueue(id: string) {
  addToQueue(id);
  return new Promise((resolve) => resolve({ id }));
}

/**
 * End Lock HitL document mechanism
 */

export function useGetDocumentsStagesCount({
  documentTypes,
  enabled = false,
  filters,
}: {
  documentTypes?: string[];
  enabled?: boolean;
  filters?: string | null;
}) {
  const { list, params } = makeDocumentStagesCountList({
    documentTypes,
    filters,
  });

  return useGet<DocumentStageCountApiResponse, DocumentStageCount>({
    url: list,
    prefix: DOCUMENTS,
    params,

    select: transformDocumentsStageCountResponse,
    gcTime: 0,
    enabled,
    refetchOnMount: true,
  });
}

const documentTypesCountOptions = ({
  enabled,
  stage,
}: {
  enabled: boolean;
  stage?: string | null;
}) => ({
  url: stage ? DOCUMENTS_TYPES_COUNT_LIST : DOCUMENTS_TYPES_COUNT,
  params: { stage },
  prefix: DOCUMENTS,

  enabled,
  gcTime: 0,
  refetchOnMount: true,
});

export function useGetDocumentTypesCountDocumentTypes(
  enabled = false,
  stage?: string | null
) {
  return useGet<DocumentsTypesCountApiResponse, CountPerDocumentType[]>({
    ...documentTypesCountOptions({ enabled, stage }),

    select: selectDocumentTypesCountDocumentTypes,
  });
}

export function useGetDocument(id: string | undefined, language = 'en') {
  return useGet<DocumentApiResponse, Document>({
    url: id ? DOCUMENT_WITH_LANG_QUERY : '',
    params: { id, language },
    prefix: DOCUMENTS,

    select: selectDocumentResponse,
    gcTime: 0,
    staleTime: 60 * 1000, // if document comes from `/next` api then don't refetch document for 60 seconds
    // refetchOnMount: true,
  });
}

export function useGetDocumentWithSelect<T>({
  select,
  id,
  language,
}: {
  select: (data: DocumentApiResponse) => T;
  id: string | undefined;
  language: string;
}) {
  return useGet<DocumentApiResponse, T>({
    url: DOCUMENT_WITH_LANG_QUERY,
    params: { id, language },
    prefix: DOCUMENTS,

    select,
  });
}

export function useGetDocumentHistorical({
  id,
  language,
}: UseGetDocumentProps) {
  return useGetDocumentWithSelect<boolean>({
    id,
    language,
    select: selectDocumentHistorical,
  });
}

export function useIsDocumentAwaitingReview({
  id,
  language,
}: UseGetDocumentProps) {
  return useGetDocumentWithSelect<boolean>({
    id,
    language,
    select: selectIsDocumentAwaitingReview,
  });
}

export function useGetDocumentContent({ id, language }: UseGetDocumentProps) {
  return useGetDocumentWithSelect<DocumentContent[] | null>({
    id,
    language,
    select: selectDocumentContent,
  });
}

export function useGetDocumentHistory(
  id: string | undefined,
  hideStage = PROCESSING
) {
  return useGet<DocumentHistoryApiResponse, Document[]>({
    url: id ? DOCUMENTS_HISTORY : '',
    params: id ? { hideStage, id } : undefined,
    prefix: DOCUMENTS,

    select: transformDocumentHistoryResponse,
    gcTime: 0,
    refetchOnMount: true,
  });
}

export function useGetDocumentHistoryVersion(
  id: string | undefined,
  version = '0'
) {
  return useGet<DocumentApiResponse, Document>({
    url: id ? DOCUMENTS_HISTORY_VERSION : '',
    params: id ? { version, id } : undefined,
    prefix: DOCUMENTS,

    select: transformDocumentHistoryVersionResponse,
    refetchOnMount: true,
    retry: 0,
  });
}

export function useGetDocuments({
  documentTypeFilter,
  filters,
  start = generalConfig.defaultPaginationStart,
  size = generalConfig.defaultPaginationSize,
  stage,
  order = ASC,
  searchField,
  searchValue = '',
}: {
  documentTypeFilter?: string[];
  filters?: string | null;
  start: number;
  size: number;
  stage?: string | null;
  order?: Order;
  searchField?: string | null;
  searchValue?: string | null;
}) {
  const { list, params } = makeDocumentsList({
    documentTypeFilter,
    filters,
    order,
    stage,
    searchField,
    searchValue,
  });

  return useGet<PaginationApiResponse<DocumentApiResponse>, DocumentsPage>({
    url: list,
    params: {
      start: start * size,
      size,
      ...params,
    },
    prefix: DOCUMENTS,

    select: selectDocumentsResponse,
    placeholderData: keepPreviousData,
    refetchOnMount: 'always',
    refetchOnWindowFocus: true,
  });
}

function patchDocument(endpoint: string) {
  return (document: Document) => {
    clearQueue();

    const content = document.content ? [...document.content] : [];
    const body = { content: transformKeys(content, toSnakeCase) };

    return api
      .patch(makeApiLink(pathToUrl(endpoint, { id: document.id })), body)
      .then(() => document);
  };
}

export function useSubmitDocument({
  isSave = false,
  meta,
}: {
  isSave?: boolean;
  meta: Meta;
}) {
  const queryClient = useQueryClient();
  const endpoint = isSave ? DOCUMENTS_SAVE : DOCUMENTS_SUBMIT;

  return useMutation({
    mutationFn: patchDocument(endpoint),
    onSuccess: async () => {
      await queryClient.cancelQueries({ queryKey: [DOCUMENTS] });
    },
    meta: meta as Record<string, unknown>,
  });
}

function unlockDocument({
  id,
  force = false,
}: {
  id: string;
  force?: boolean;
}) {
  clearQueue();
  return api
    .put(
      makeApiLink(pathToUrl(DOCUMENTS_UNLOCK, { id, force: String(force) })),
      {
        id,
      }
    )
    .then(() => id);
}

export function useLockDocumentWithQueue() {
  return useMutation({ mutationFn: lockDocumentWithQueue });
}

export function useLockDocument() {
  const queryClient = useQueryClient();
  const { resolvedLanguage } = useLocales();
  const currentUser = queryClient.getQueryData<CurrentUser>([CURRENT_USER]);

  return useMutation({
    mutationFn: lockDocument,
    onMutate: async (id) => {
      const queryKey = [
        DOCUMENTS,
        DOCUMENT_WITH_LANG_QUERY,
        { id, language: resolvedLanguage },
      ];
      await queryClient.cancelQueries({ queryKey });
      const old = queryClient.getQueryData(queryKey) as Document;

      queryClient.setQueryData<Document>(queryKey, (oldDocument) => {
        if (oldDocument && currentUser) {
          return {
            ...oldDocument,
            locked: true,
            lastLockedBy: currentUser.email,
            lastLockedOn: new Date().toISOString(),
          };
        }
        return oldDocument;
      });

      return old;
    },
    onError: (_err, id, context) => {
      if (id) {
        queryClient.setQueryData(
          [
            DOCUMENTS,
            DOCUMENT_WITH_LANG_QUERY,
            { id, language: resolvedLanguage },
          ],
          context
        );
      }
    },
    onSuccess: async (id) => {
      await queryClient.cancelQueries({ queryKey: [DOCUMENTS] });

      const queryKey = [
        DOCUMENTS,
        DOCUMENT_WITH_LANG_QUERY,
        { id, language: resolvedLanguage },
      ];
      const old = queryClient.getQueryData(queryKey) as Document;

      queryClient.setQueryData(queryKey, (oldDocument) => {
        if (oldDocument && currentUser) {
          return {
            ...oldDocument,
            locked: true,
            lastLockedBy: currentUser.email,
            lastLockedOn: new Date().toISOString(),
          };
        }
      });

      return old;
    },
  });
}

export function useUnlockDocument() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: unlockDocument,
    onSuccess: async () => {
      await queryClient.cancelQueries({ queryKey: [DOCUMENTS] });
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: [DOCUMENTS] });
    },
  });
}

function rejectDocument({ id, reason }: { id: string; reason: string }) {
  clearQueue();
  const body = {
    reason,
  };
  return api.put(makeApiLink(pathToUrl(DOCUMENTS_REJECT, { id })), body);
}

export function useRejectDocument() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: rejectDocument,
    onSuccess: async () => {
      await queryClient.cancelQueries({ queryKey: [DOCUMENTS] });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [DOCUMENTS] });
    },
  });
}

function archiveDocument({ id }: { id: string }) {
  return api.put(makeApiLink(pathToUrl(DOCUMENTS_ARCHIVE, { id })), { id });
}

export function useArchiveDocument() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: archiveDocument,
    onSuccess: async () => {
      await queryClient.cancelQueries({ queryKey: [DOCUMENTS] });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [DOCUMENTS] });
    },
    meta: {
      errorMessage: 'Failed to archive document',
      loadingMessage: 'Archiving document',
      successMessage: 'Document archived',
    },
  });
}

export function useGetDocumentTypes() {
  return useGet<DocumentTypesApiResponse, AllDocumentTypes>({
    url: DOCUMENTS_TYPES,
    prefix: DOCUMENTS,

    select: selectDocumentsTypesResponse,
  });
}

export function useGetDocumentsTypesDocumentTypes() {
  return useGet<DocumentTypesApiResponse, DocumentTypes>({
    url: DOCUMENTS_TYPES,
    prefix: DOCUMENTS,

    select: selectDocumentTypes,
  });
}

function reprocessDocument({
  id,
  documentType,
}: {
  id: string;
  documentType: string;
}) {
  return api.post(makeApiLink(pathToUrl(DOCUMENTS_REPROCESS, { id })), {
    document_type: documentType,
  });
}

export function useSetDocumentType({ meta }: { meta: Meta }) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: reprocessDocument,
    onSuccess: async () => {
      await queryClient.cancelQueries({ queryKey: [DOCUMENTS] });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [DOCUMENTS] });
    },
    meta: meta as Record<string, unknown>,
  });
}

function patchDocumentValidation({
  id,
  content,
}: {
  id: string;
  content: DocumentContent[];
}): Promise<DocumentValidation> {
  return api
    .patch(makeApiLink(pathToUrl(DOCUMENTS_VALIDATION, { id })), {
      content: transformKeys(content, toSnakeCase),
    })
    .then(transformDocumentsValidationResponse);
}

export function useDocumentValidation(id: string) {
  const queryClient = useQueryClient();
  const { currentLanguage } = useLocales();

  return useMutation({
    mutationFn: patchDocumentValidation,
    onSuccess: async (data, variables) => {
      await queryClient.cancelQueries({ queryKey: [DOCUMENTS] });
      const queryKey = [
        DOCUMENTS,
        DOCUMENT_WITH_LANG_QUERY,
        { id: variables.id, language: currentLanguage.value },
      ];
      const oldDocument = queryClient.getQueryData(queryKey) as Document;
      const newDocument = {
        ...oldDocument,
        content: variables.content,
        validationResults: data.validationResults,
      };
      queryClient.setQueryData(queryKey, newDocument);
      return data;
    },
    meta: {
      gtmEventStart: HIL_TOOL_VALIDATION_START,
      gtmEventEnd: HIL_TOOL_VALIDATION_END,
      documentId: id,
    },
  });
}

type GetNextDocumentParams = {
  claimId?: string | null;
  documentTypeFilter?: string | null;
  filters?: string | null;
  lang: string;
  lock?: string;
  order?: string | null;
  searchField?: string | null;
  searchValue?: string | null;
};

function getNextDocument(
  params: GetNextDocumentParams
): Promise<DocumentApiResponse | null> {
  const url = Object.keys(params).reduce((acc, key) => {
    if (params[key]) {
      if (acc === DOCUMENTS_NEXT) {
        return `${acc}\\?${toSnakeCase(key)}=:${key}`;
      }
      return `${acc}&${toSnakeCase(key)}=:${key}`;
    }

    return acc;
  }, DOCUMENTS_NEXT);

  return api.get(makeApiLink(pathToUrl(url, params)));
}

export function useGetNextDocument(options: GetNextDocumentParams) {
  const queryClient = useQueryClient();
  const newOptions = options.claimId
    ? { claimId: options.claimId, lang: options.lang }
    : options;

  return async (lock = false) => {
    try {
      const data = await getNextDocument({ ...newOptions, lock: String(lock) });

      // if we have data, update the cache but also need to handle 204 response
      if (data) {
        const queryKey = [
          DOCUMENTS,
          DOCUMENT_WITH_LANG_QUERY,
          { id: data.document_id, language: newOptions.lang },
        ];
        queryClient.setQueryData(queryKey, data);

        return Promise.resolve(transformDocumentResponse(data));
      }

      return Promise.resolve(null);
    } catch (error) {
      console.error(error);
    }
  };
}
