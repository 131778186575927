import { FabricObject, Point } from 'fabric';

import type { FileCanvas } from './types.ts';
import {
  bindObjectsToImage,
  findImage,
  SCALE_STEP,
  updateBoundObjects,
  ZOOM_IN_THRESHOLD,
  ZOOM_OUT_THRESHOLD,
} from './utils.ts';

function getControls(getCanvas: () => FileCanvas) {
  const getCenterPoint = () => {
    const canvas = getCanvas();

    return new Point({
      x: (canvas.getWidth() as number) / 2,
      y: (canvas.getHeight() as number) / 2,
    });
  };

  const rotateImage = (counterClockwise = false) => {
    const canvas = getCanvas();
    bindObjectsToImage(canvas);
    const objects = canvas.getObjects();
    const image = objects.find(findImage) as FabricObject;
    const angle = image.get('angle');

    canvas.fire('object:rotating');
    image.rotate(angle + (counterClockwise ? -90 : 90));
    updateBoundObjects(canvas);

    canvas.renderAll();
  };

  return {
    resetZoomAndPan() {
      const canvas = getCanvas();
      const targetObject = canvas.getObjects().find(findImage) as FabricObject;

      if (targetObject) {
        canvas.centerObject(targetObject);
        canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
      }
    },
    rotateImageClockwise() {
      rotateImage();
    },
    rotateImageCounterClockwise() {
      rotateImage(true);
    },
    zoomIn() {
      const canvas = getCanvas();
      const zoom = canvas.getZoom();

      if (zoom < ZOOM_IN_THRESHOLD) {
        canvas.zoomToPoint(getCenterPoint(), zoom + SCALE_STEP);
      }
    },
    zoomOut() {
      const canvas = getCanvas();
      let zoom = canvas.getZoom();
      zoom = zoom - SCALE_STEP;

      if (zoom < ZOOM_OUT_THRESHOLD) {
        zoom = ZOOM_OUT_THRESHOLD;
      }

      canvas.zoomToPoint(getCenterPoint(), zoom);
    },
  };
}

export { getControls };
