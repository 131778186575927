import { ReactNode } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import {
  TypographyWithTranslation,
  useTranslationRoot,
} from 'components/with-translation.tsx';
import { DELETE_SWEEP_ICON, PLUS_ICON } from 'constants/public-icons.ts';

interface LineItemsWrapperProps {
  addLineItems: () => void;
  deleteAllLineItems: () => void;
  fields: ReactNode;
  lineItems: ReactNode;
}

function LineItemsWrapper({
  addLineItems,
  deleteAllLineItems,
  fields: fieldsNode,
  lineItems: lineItemsNode,
}: LineItemsWrapperProps) {
  const { t } = useTranslationRoot();

  const onDeleteAll = () => {
    deleteAllLineItems();
  };

  return (
    <Stack
      spacing={2}
      sx={{
        p: 1,
      }}
    >
      <Stack spacing={1}>
        <TypographyWithTranslation
          variant="subtitle1"
          fontSize={12}
          fontWeight="bold"
          i18nKey="enrichmentTool.documentItems"
        />
        {fieldsNode}
      </Stack>
      <Stack
        sx={{
          gap: 1,

          '& .invalid': {
            border: '1px solid',
            borderColor: ({ palette }) => `${palette.error.main} !important`,
          },
        }}
      >
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <TypographyWithTranslation
            variant="subtitle1"
            fontSize={12}
            fontWeight="bold"
            i18nKey="enrichmentTool.lineItems"
          />
          <Stack direction="row" spacing={1}>
            <Button
              color="error"
              startIcon={
                <SvgIconStyle src={DELETE_SWEEP_ICON} height={14} width={14} />
              }
              size="small"
              sx={{
                fontSize: 12,
              }}
              onClick={onDeleteAll}
            >
              {t('enrichmentTool.deleteAllLinesButton')}
            </Button>
            <Button
              startIcon={
                <SvgIconStyle src={PLUS_ICON} height={14} width={14} />
              }
              size="small"
              sx={{
                fontSize: 12,
              }}
              onClick={addLineItems}
            >
              {t('enrichmentTool.addLineButton')}
            </Button>
          </Stack>
        </Stack>
        {lineItemsNode}
        <Box>
          <Button
            startIcon={<SvgIconStyle src={PLUS_ICON} height={14} width={14} />}
            size="small"
            sx={{
              fontSize: 12,
            }}
            onClick={addLineItems}
          >
            {t('enrichmentTool.addLineButton')}
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
}

export { LineItemsWrapper };
