import { useState } from 'react';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { useGetPagesClassificationData } from 'state/queries/pages.ts';
import {
  TypographyWithTranslation,
  useTranslationRoot,
} from 'components/with-translation.tsx';
import { toast } from 'components/toast';
import { postPagesReclassify } from 'state/queries/files.ts';
import { generateUuid } from 'utils/generate-uuid.ts';

import { Placeholder } from './Placeholder.tsx';
import { Form } from './Form.tsx';
import { EmptyData } from './EmptyData.tsx';
import { TableColumnHead } from './TableColumnHead.tsx';

function ClassificationTable() {
  const { t } = useTranslationRoot();
  const { data, isPending, isError, refetch } = useGetPagesClassificationData({
    start: 0,
    size: 10,
  });
  // using this trick to re-render a new table after form submit
  const [regenTableKey, setRegenTableKey] = useState(generateUuid());

  if (isError) {
    return <TypographyWithTranslation i18nKey="table.error" />;
  }

  if (isPending) {
    return (
      <Card>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ minWidth: 500, mt: 3 }}>
            <Table>
              <TableColumnHead hasIsValidCheckbox={false} />
              <TableBody>
                {Array.from({ length: 10 }, (_, index) => index).map(
                  (index) => (
                    <Placeholder key={index} />
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Card>
    );
  }

  if (!data?.length) {
    return <EmptyData />;
  }

  const submitReclassification = (
    formData: {
      classification: string | null;
      pageId: string;
      isValid: boolean;
    }[],
    callback: () => void
  ) => {
    const payload = formData.reduce(
      (acc, { pageId, classification, isValid }, index) => {
        if (isValid) {
          acc.push({
            pageId,
            classification: data[index].classification,
          });
        }

        if (!isValid && classification === data[index].classification) {
          return acc;
        }

        if (!isValid && !classification) {
          return acc;
        }

        if (
          !isValid &&
          classification !== data[index].classification?.pageType
        ) {
          acc.push({
            pageId,
            classification: {
              pageType: classification as string,
            },
          });
        }

        return acc;
      },
      [] as { pageId: string; classification: { pageType: string } | null }[]
    );

    if (!payload.length) {
      toast.warning(t('table.submitNoChanges'));
      callback();
      return;
    }

    toast.promise(postPagesReclassify(payload), {
      loading: t('table.submitLoading'),
      success: () => {
        setTimeout(() => {
          refetch().then(() => {
            setRegenTableKey(generateUuid());
            callback();
          });
        }, 1500);
        return t('table.submitSuccess');
      },
      error: () => {
        callback();
        return t('table.submitError');
      },
    });
  };

  return (
    <Form
      key={regenTableKey}
      pages={data}
      submitReclassification={submitReclassification}
    />
  );
}

export { ClassificationTable };
