import { asyncInitScript } from 'utils/async-init-script';
import { generalConfig } from 'config';

export function ensureBeamerInit(
  callback: (
    isSuccess: boolean,
    parameters: {
      button_position: string;
      product_id: string;
      selector: string;
      filter: string;
    }
  ) => void,
  maxRetries = 50
) {
  const parameters = {
    button_position: 'top-right',
    product_id: generalConfig.beamerId,
    selector: generalConfig.beamerElementId,
    filter: generalConfig.customer,
  };

  window.beamer_config = parameters;

  asyncInitScript({
    id: generalConfig.beamerScriptId,
    src: 'https://app.getbeamer.com/js/beamer-embed.js',
    defer: false,
  });

  let counter = 0;
  const interval = setInterval(() => {
    counter++;

    if (counter >= maxRetries) {
      clearInterval(interval);
    }

    if (window.Beamer) {
      callback(true, parameters);
      clearInterval(interval);
    } else {
      callback(false, parameters);
    }
  }, 100);
}
