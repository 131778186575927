import type { Properties } from 'posthog-js';
import posthog from 'posthog-js';

/**
 * Send an analytic event to the dataLayer and Posthog
 * Note: be careful about using native posthog variables with this function
 */
export function sendAnalyticEvent({
  event,
  ...props
}: {
  event: string;
  [key: string]: any | Properties;
}) {
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push({
      event,
      ...props,
    });
  }
  if (typeof window !== 'undefined') {
    posthog.capture(event, props);
  }
}

export function updateWindowPerformanceObject(eventName: string) {
  if (typeof window !== 'undefined') {
    window.__performance = {
      ...window.__performance,
      [eventName]: Date.now(),
    };
  }
}

export function getEventTimestamp(name: string) {
  if (typeof window !== 'undefined' && window.__performance) {
    return window.__performance[name];
  }
  return 0;
}
