import 'dayjs/locale/ja';
import { enUS, jaJP } from '@mui/material/locale';
import en_US from 'rsuite/esm/locales/en_US';
import ja_JP from 'rsuite/esm/locales/ja_JP';

const languagesAxaJp = [
  {
    colors: ['#c9072a', '#001b69'],
    label: 'English',
    rsuiteLocale: en_US,
    systemValue: enUS,
    value: 'en',
  },
  {
    colors: ['#bd0029', '#bd0029'],
    label: '日本語',
    rsuiteLocale: ja_JP,
    systemValue: jaJP,
    value: 'ja',
  },
];

export default languagesAxaJp;
