import type { ReactNode } from 'react';
import type { SxProps, Theme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { TypographyWithTranslation } from 'components/with-translation';

interface HeaderItemProps {
  children: ReactNode;
  startComponent?: ReactNode;
  sx?: SxProps<Theme>;
  titleI18Key: string;
}

function SubHeaderItem({
  children,
  startComponent = null,
  titleI18Key,
  ...props
}: HeaderItemProps) {
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        alignItems: 'center',
      }}
      {...props}
    >
      {startComponent}
      <Stack>
        <TypographyWithTranslation
          color="grey.500"
          variant="caption"
          fontSize={12}
          i18nKey={titleI18Key}
        />
        <Typography variant="subtitle2">{children}</Typography>
      </Stack>
    </Stack>
  );
}

export { SubHeaderItem };
