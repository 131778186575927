import type { ReactNode } from 'react';
import { createContext, useContext, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Trans } from 'react-i18next';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { useBoolean } from 'components/customHooks/useBoolean';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag';
import { useTranslationRoot } from 'components/with-translation';
import { COMMON } from 'constants/translation-keys';
import { generalConfig } from 'config';
import { initGtm } from 'utils/init-gtm';

const COOKIE_CONSENT = 'cookie-consent';
const ACCEPTED_COOKIES = 'acceptedCookies';
const CookieBannerContext = createContext({
  isOpen: false,
  openCookieBanner: () => {},
});

function CookieBanner({ children }: { children: ReactNode }) {
  const allowGTM = useFeatureFlag('enableGTM');
  const isDevMode = import.meta.env.DEV;
  const cookieConsent = Cookies.get(COOKIE_CONSENT);
  const isCookieConsent = cookieConsent === 'true';
  const { t } = useTranslationRoot(COMMON);
  const [isOpen, openBanner, closeBanner] = useBoolean(
    allowGTM && !isCookieConsent
  );

  const cookieConsented = () => {
    Cookies.set(COOKIE_CONSENT, 'true', { expires: 365 });
  };

  const cookieAccepted = () => {
    Cookies.set(ACCEPTED_COOKIES, 'true', { expires: 365 });
  };

  const enableGtm = () => {
    if (!isDevMode) {
      initGtm(generalConfig.gtmId);
    }
  };

  const handleAccept = () => {
    closeBanner();
    cookieConsented();
    cookieAccepted();
    enableGtm();
  };

  useEffect(() => {
    /**
     * I'm aware that if a user changes their cookie settings then it will
     * only be affected on the next page load. We can change this in the future
     */
    if (
      allowGTM &&
      isCookieConsent &&
      Cookies.get(ACCEPTED_COOKIES) === 'true'
    ) {
      enableGtm();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CookieBannerContext.Provider
      value={{ isOpen, openCookieBanner: openBanner }}
    >
      {children}
      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('cookieBanner.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Trans
              i18nKey="cookieBanner.message"
              components={{
                anchor: (
                  <Link
                    href={generalConfig.privacyPolicyUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                ),
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              alignItems: 'center',
            }}
          >
            <Link
              href={generalConfig.privacyPolicyUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('cookieBanner.privacyPolicy')}
            </Link>
            <Button onClick={handleAccept} variant="contained" autoFocus>
              {t('cookieBanner.accept')}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </CookieBannerContext.Provider>
  );
}

function useCookieBannerContext() {
  return useContext(CookieBannerContext);
}

export { CookieBanner, useCookieBannerContext };
