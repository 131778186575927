import { lazy } from 'react';
import { createPath } from 'react-router-dom';
import { loadable } from 'components/loadable';
import { HUMAN_IN_THE_LOOP_ICON } from 'constants/public-icons';
import ROUTE_PERMISSIONS from 'constants/route-permissions';
import { HITL_PAGE } from 'constants/translation-keys';
import HitLLayout from 'pages/HitL/HitLLayout';
import hitlToolRoute from 'pages/HitLTool/hitlTool.route';

const path = '/h';
const HitLPage = loadable(
  lazy(() => import(/* webpackChunkName: "hitl" */ './HitL'))
);

const hitlRoute = {
  path,
  createPath: (params?: { search?: string }) =>
    createPath({ pathname: path, ...params }),
  icon: HUMAN_IN_THE_LOOP_ICON,
  element: <HitLLayout />,
  permission: ROUTE_PERMISSIONS.hitl,
  children: [
    {
      element: <HitLPage />,
      index: true,
      permission: ROUTE_PERMISSIONS.hitl,
      i18nNamespace: HITL_PAGE,
    },
    hitlToolRoute,
  ],
};

export default hitlRoute;
