import { lazy } from 'react';
import { generatePath } from 'react-router-dom';
import { loadable } from 'components/loadable';
import { LOGIN_PAGE } from 'constants/translation-keys';

const path = '/login';
const LoginPage = loadable(lazy(() => import('./Login')));

const povRoute = {
  path,
  createPath: () => generatePath(path),
  element: <LoginPage />,
  i18nNamespace: LOGIN_PAGE,
};

export default povRoute;
