import { useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { useGet } from 'utils/react-query.ts';

import type { LineItemRow } from './types.ts';
import { useEnrichmentToolContext } from './useEnrichmentToolContext.tsx';

interface LineItemDropdownProps {
  error: boolean;
  label: string;
  name: string;
  row: LineItemRow;
  value: string;
}

function LineItemDropdown({
  error,
  label,
  name,
  row,
  value = '',
}: LineItemDropdownProps) {
  const { control, register, setValue, getValues } = useFormContext();
  const { businessUnit, state } = useEnrichmentToolContext();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState(value ?? '');
  const prevInputValue = useRef(inputValue);
  const [searchText, setSearchText] = useState('');
  // const loading = open && options.length === 0;
  const { sproutaiKey } = state.fields[name];
  const searchKey = sproutaiKey === 'item_code' ? 'code' : 'description';
  const currentValue = useWatch({ control, name });
  const { data, isFetching } = useGetLookup({
    businessUnit: businessUnit || state.businessUnit,
    searchKey,
    searchText,
    code: searchKey !== 'code' ? getValues(row.keyToId.item_code) : '',
  });

  const dataWithIds =
    data?.map((params) => ({
      id: generateUniqueOptionName(params.code, params.description),
      ...params,
    })) || [];

  const updateRelatedFields = (newValue: Record<string, string>) => {
    if (newValue) {
      Object.keys(newValue).forEach((key) => {
        if (key !== 'id') {
          const lookupName = LOOKUP_MAP[key];
          const id = row.keyToId[lookupName];
          const value = newValue[key];

          setValue(id, value);
        }
      });
    }
  };

  useEffect(() => {
    if (data) {
      setOptions(dataWithIds);
    }
  }, [data]); // eslint-disable-line

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    const isDiff = prevInputValue.current !== inputValue;
    const handler = isDiff
      ? setTimeout(() => {
          setSearchText(inputValue);
        }, 400)
      : null;

    if (isDiff) {
      prevInputValue.current = inputValue;
    }

    return () => {
      if (handler) {
        clearTimeout(handler);
      }
    };
  }, [inputValue]);

  useEffect(() => {
    // this one updates related field inputValue
    // so when a user selects code, the description field will be updated
    if (inputValue !== currentValue) {
      setInputValue(currentValue || '');
    }
  }, [currentValue]); // eslint-disable-line

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      loading={isFetching}
      freeSolo
      onChange={(_event, newValue) => {
        updateRelatedFields(newValue);
      }}
      defaultValue={value ?? ''}
      getOptionLabel={(option) => option[searchKey] || inputValue}
      inputValue={inputValue}
      onInputChange={(_event, newValue) => {
        setInputValue(newValue ?? '');
      }}
      componentsProps={{
        popper: {
          sx: {
            boxShadow: (theme) => theme.shadows[1],
            width: 'auto !important',
          },
        },
      }}
      filterOptions={(x) => x}
      ListboxProps={{
        sx: { fontSize: 12 },
      }}
      options={options}
      renderOption={(props, { id, ...option }) => (
        <Box component="li" {...props} key={id}>
          {Object.values(option).join(', ')}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          {...register(name)}
          error={error}
          label={label}
          variant="standard"
          InputLabelProps={{
            ...params.InputLabelProps,
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isFetching ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          inputProps={{
            ...params.inputProps,
            sx: { fontSize: 12, height: 26 },
          }}
        />
      )}
    />
  );
}

function useGetLookup({
  businessUnit,
  searchText,
  searchKey,
  code,
}: {
  businessUnit: string;
  searchText: string;
  searchKey: string;
  code?: string;
}) {
  const params = {
    business_unit: businessUnit,
    search_text: searchText,
    search_key: searchKey,
    code: code ?? '',
  };

  function generateUrl() {
    return code
      ? `lookup/code\\?search_text=:search_text;code=:code&search_key=:search_key&business_unit=:business_unit`
      : `lookup/code\\?search_text=:search_text&search_key=:search_key&business_unit=:business_unit`;
  }

  return useGet<
    { lookup_codes: Record<string, string>[] },
    Record<string, string>[]
  >({
    url: generateUrl(),
    params: { ...params },

    select: ({ lookup_codes }) => lookup_codes,
    enabled: !!searchText,
    gcTime: Infinity,
    staleTime: Infinity,
  });
}

const LOOKUP_MAP: Record<string, string> = {
  code: 'item_code',
  description: 'item_description',
  specialty: 'item_specialty',
};

function generateUniqueOptionName(code: string, description: string) {
  // replace description spaces with hyphen
  return `${code}-${description?.replace(/\s/g, '-')}`;
}

export { LineItemDropdown };
