import type { Page, PagesPage } from 'types/Pages.ts';
import { transformKeys } from 'utils/object.ts';
import { toCamelCase } from 'utils/string.ts';

import type { PageApiResponse } from './types/pages.ts';
import type { PaginationApiResponse } from './types/pagination.ts';
import { transformPage } from './page.ts';

export function transformPageResponse({
  page_id,
  ...page
}: PageApiResponse): Page {
  return {
    id: page_id,
    ...transformKeys(page, toCamelCase),
  };
}

export function transformPagesResponse(
  response: PaginationApiResponse<PageApiResponse>
): PagesPage {
  return transformPage(transformPageResponse)(response);
}
