import type {
  FilePresignedUploadUrl,
  SproutFile,
  SproutFilesPage,
} from 'types/Files.ts';
import { transformKeys } from 'utils/object.ts';
import { toCamelCase } from 'utils/string.ts';

import type {
  FileApiResponse,
  FilesApiResponse,
  FilePresignedUploadUrlApiResponse,
} from './types/files.ts';
import { transformPage } from './page.ts';

export function transformFileResponse({
  file_id,
  ...file
}: FileApiResponse): SproutFile {
  return {
    id: file_id,
    ...transformKeys(file, toCamelCase),
  };
}

export function transformFilesResponse(
  response: FilesApiResponse
): SproutFilesPage {
  return transformPage(transformFileResponse)(response);
}

export function transformFilePresignedUploadUrlResponse(
  response: FilePresignedUploadUrlApiResponse
): FilePresignedUploadUrl {
  return transformKeys(response, toCamelCase);
}
