import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTooltip } from 'components/customHooks/useTooltip';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { IconButtonAnimate } from 'components/IconButtonAnimate';
import { BASELINE_RECEIPT_ICON } from 'constants/public-icons';
import {
  TypographyWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';

type Field = {
  label: string;
  value: string | string[];
};

interface ReviewFormMetadataProps {
  items: Field[];
}

// will need to hardcode these translated strings
const TRANSLATION_KEYS: Record<string, string> = {
  businessUnit: 'reviewTool.businessUnit',
  reviewStationNumber: 'reviewTool.reviewStationNumber',
  reasonMessage: 'reviewTool.reasonMessage',
};

function ReviewFormMetadata({ items }: ReviewFormMetadataProps) {
  const { t } = useTranslationRoot();
  const { element, handleClose, handleOpen, isOpen } = useTooltip();

  const translateFields = () => {
    return items.map(({ label, value }) => {
      const translationKey = TRANSLATION_KEYS[label];

      return {
        label: translationKey ? t(translationKey) : label,
        value,
      };
    });
  };

  const fields: Field[] = translateFields();
  const hasValues = fields.length;

  return (
    <>
      {hasValues ? (
        <IconButtonAnimate size="large" isOpen={isOpen} onClick={handleOpen}>
          <SvgIconStyle src={BASELINE_RECEIPT_ICON} />
        </IconButtonAnimate>
      ) : null}
      <Popover
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={isOpen}
        onClose={handleClose}
        anchorEl={element}
        sx={{ mt: 1.5 }}
      >
        <Box
          sx={{
            maxWidth: '425px',
            minWidth: '320px',
            p: 2,
            width: 1,
          }}
        >
          <TypographyWithTranslation
            i18nKey="reviewTool.tooltipTitle"
            variant="h6"
            fontWeight="normal"
            sx={{
              mb: 2,
            }}
          />
          <Stack spacing={2}>
            {fields.map(({ label, value }) => {
              const hasValue = value || value?.length;

              if (!hasValue) return null;

              return (
                <Stack key={label}>
                  <TypographyWithTranslation
                    i18nKey={label}
                    variant="subtitle1"
                    sx={{ fontSize: 12 }}
                  />
                  {Array.isArray(value) ? (
                    <List dense disablePadding>
                      {value.map((reasonMessage, index) => (
                        <ListItem key={index} disablePadding>
                          <ListItemText
                            primary={reasonMessage}
                            primaryTypographyProps={{ sx: { fontSize: 12 } }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <Typography variant="body1" sx={{ fontSize: 12 }}>
                      {value}
                    </Typography>
                  )}
                </Stack>
              );
            })}
          </Stack>
        </Box>
      </Popover>
    </>
  );
}

export { ReviewFormMetadata };
