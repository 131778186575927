import { keyframes } from '@mui/system';
import { styled } from '@mui/material/styles';

const fill = keyframes`
  100% {
    box-shadow: inset 0 0 0 30px #54D62C;
  }
`;

const scale = keyframes`
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
`;
const stroke = keyframes`
  100% {
    stroke-dashoffset: 0;
  }
`;

const Svg = styled('svg')({
  animation: `${fill} .4s ease-in-out .4s forwards, ${scale} .3s ease-in-out .9s both`,
  borderRadius: '50%',
});

const Circle = styled('circle')(({ theme }) => ({
  animation: `${stroke} 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards`,
  fill: 'none',
  strokeDasharray: 166,
  strokeDashoffset: 166,
  strokeWidth: 4,
  strokeMiterlimit: 10,
  stroke: theme.palette.success.main,
}));

const Path = styled('path')(({ theme }) => ({
  animation: `${stroke} 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards`,
  stroke: theme.palette.common.white,
  strokeDasharray: 48,
  strokeDashoffset: 48,
  strokeWidth: 4,
  transformOrigin: '50% 50%',
}));

function CircleCheck() {
  return (
    <Svg viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Circle cx="26" cy="26" r="25" fill="none" />
      <Path fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    </Svg>
  );
}

export { CircleCheck };
