import type { TransitionProps } from '@mui/material/transitions';
import type { ReactElement, Ref } from 'react';
import { forwardRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';

import {
  filterActionComponentTypes,
  filterOtherComponentTypes,
  renderDialogComponent,
} from '../generators';
import { modalRenderers } from './modalRenderers';

interface DialogModalProps {
  ariaDescribedBy?: string;
  getDialog: any;
  handleClose: VoidFunction;
  isOpen: boolean;
}

const INDEX = 'index';

const renderDialogComponents = renderDialogComponent(modalRenderers);

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DialogModal({
  ariaDescribedBy,
  getDialog,
  handleClose,
  isOpen,
}: DialogModalProps) {
  const [page, setPage] = useState(INDEX);

  function changePage(page: string) {
    setPage(page);
  }

  function generateContents() {
    if (getDialog) {
      const pageGenerator = getDialog[page];
      const currentScreen = pageGenerator({
        changePage,
        closeModal: handleClose,
      });

      const actions = filterActionComponentTypes(currentScreen);
      const otherComponents = filterOtherComponentTypes(currentScreen);

      // stupid hack
      if (otherComponents[0]?.type === 'COMPONENT') {
        return <>{renderDialogComponents(otherComponents[0], 0)}</>;
      }

      return (
        <>
          {otherComponents.map(renderDialogComponents)}
          <DialogActions>{actions.map(renderDialogComponents)}</DialogActions>
        </>
      );
    }

    return null;
  }

  return (
    <Dialog
      aria-describedby={ariaDescribedBy}
      open={isOpen}
      TransitionComponent={Transition}
      onClose={handleClose}
      fullWidth
    >
      {generateContents()}
    </Dialog>
  );
}

export { DialogModal };
