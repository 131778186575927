import { lazy } from 'react';
import { generatePath } from 'react-router-dom';
import { loadable } from 'components/loadable';
import { PAGE_ID, RESOURCE_ID } from 'constants/route-keys';
import ROUTE_PERMISSIONS from 'constants/route-permissions';
import { HITL_GALLERY_PAGE } from 'constants/translation-keys';

const path = `/h/:${RESOURCE_ID}/gallery/:${PAGE_ID}`;
const HitLGalleryPage = loadable(
  lazy(() => import(/* webpackChunkName: "hitl-gallery" */ './HitLGallery'))
);

const hitlGalleryRoute = {
  path,
  createPath: (resourceId: string, pageId?: string) =>
    generatePath(path, { resourceId, pageId }),
  element: <HitLGalleryPage />,
  permission: ROUTE_PERMISSIONS.hitl,
  i18nNamespace: HITL_GALLERY_PAGE,
};

export default hitlGalleryRoute;
