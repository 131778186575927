import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useTranslationRoot } from 'components/with-translation.tsx';

import { TableColumnHead } from './TableColumnHead.tsx';

function EmptyData() {
  const { t } = useTranslationRoot();

  return (
    <Card>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ minWidth: 500, mt: 3 }}>
          <Table>
            <TableColumnHead hasIsValidCheckbox={false} />
            <TableBody>
              <TableRow>
                <TableCell colSpan={6} align="left">
                  {t('table.noItems')}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Card>
  );
}

export { EmptyData };
